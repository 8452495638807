<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Cambiar password</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="alert alert-warning" *ngIf="isUserLogged">
        Para ver reflejados los cambios cierra la sesión y vuelve a ingresar al sistema.
    </div>
    <h6>Ingresa la nueva contraseña</h6>
    <form autocomplete="off" [formGroup]="formPassword" (ngSubmit)="changePassword()" class="px-3 mt-3" id="passwordForm">
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Contraseña</mat-label>
                    <mat-icon matSuffix (click)="hidePass = !hidePass" role="button">{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="pass" maxlength="30">
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Confirma contraseña</mat-label>
                    <mat-icon matSuffix (click)="hidePassConfirm = !hidePassConfirm" role="button">{{hidePassConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hidePassConfirm ? 'password' : 'text'" formControlName="passConfirm" maxlength="30">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" form="passwordForm" type="submit" mat-dialog-close [disabled]="!formPassword.valid">Guardar <mat-icon>save</mat-icon></button>
</div>
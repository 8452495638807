import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { editProjectModel, newProjectModel } from 'src/app/models/project.model';
import { UserModel } from 'src/app/models/user.model';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-add',
  templateUrl: './projects-add.component.html',
  styleUrls: ['./projects-add.component.scss']
})
export class ProjectsAddComponent implements OnInit {

  isEdit : boolean = false;
  newProject : any;
  newDateTody : Date = new Date();
  minDate : Date = new Date();
  isClientUsr: boolean = false;
  date : Date = new Date();
  visibledeadline : boolean = false;
  userObj!: UserModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main: MainService,
    private projectS: ProjectsService,
    private userS: UsersService,
    private dialogRef: MatDialogRef<ProjectsAddComponent>,
  ) {
    this.isEdit = this.data.edit;
      if(this.isEdit){
        this.LoadProjectData(this.data.data);

      }
  }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    if (!this.isEdit) {
      this.newProject = new newProjectModel();
      this.newProject.name = "";
      this.newProject.id_compania = 1;
      this.newDateTody.setDate(this.newDateTody.getDate() + 1);
      this.date = new Date(this.newDateTody);
    }
  }

  LoadProjectData(proj : any){
    this.newProject = new editProjectModel();
    this.newProject.id_proj = proj.id;
    this.newProject.name = proj.name;
    this.newProject.date = proj.date;
    this.date = new Date(proj.date);
    this.date.setTime(this.date.getTime() + 6 * 60 * 60 * 1000);
    console.log(this.date);

  }

  createProject(){
    if(this.newProject.name!= "" && this.newProject.id_compania!= 0){
      this.main.ShowLoading();
      this.newProject.date = this.date.toISOString().slice(0, 10);
      this.projectS.newCampaignProject(this.newProject).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Creado',
              text: 'Campaña creada correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
      console.log(this.newProject);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  editProject(){
    if(this.newProject.name!= ""){
      this.main.ShowLoading();
      this.newProject.date = this.date.toISOString().slice(0, 10);
      this.projectS.editCampaignProject(this.newProject).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Editado',
              text: 'Campaña editada correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          if(err.error.message != "No changes detected or no valid campaigns were found in the request") {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        }
      );
      console.log(this.newProject);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'No hay nombre de campaña',
        text: 'Por favor ingrese un nombre válido'
      });
    }
  }

  saveProject(){
    if(!this.isEdit){
      this.createProject();
    }else{
      this.editProject();
    }
  }

}

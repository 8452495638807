<div class="content-wrapper">
    <!-- Content -->
    <div class="container-fluid flex-grow-1 container-p-y">        
        <h4 class="fw-bold py-3 mb-4 text-primary"><mat-icon class="align-middle me-2 text-muted" role="button" (click)="goBack()">arrow_back_ios</mat-icon><mat-icon class="align-middle">content_cut</mat-icon> Cortes 
            <button mat-flat-button color="primary" class="ms-4" (click)="openDialogAddCutoff()"  *ngIf="hasPermissions('suc_sup',null,true)" >Crear nuevo corte <mat-icon>add</mat-icon></button>
        </h4>
        <div>
        <!-- Card active cutoffs -->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-success align-middle">stop</mat-icon> Cortes activos</h5>
                        <div class="card-body">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre campaña</th>
                                        <th>Fecha corte</th>
                                        <th>Status</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngFor="let c of nowCutoffs(); let i = index">
                                        <td role="button"><strong>{{ c.id }}</strong></td>
                                        <td role="button"><strong>{{ c.nombre }}</strong></td>
                                        <td role="button"><strong>{{ c.cutoff_date  | date : "dd/MM/yyyy": "-0000"}}</strong></td>
                                        <td role="button" class="">
                                            <button *ngIf="i === 0" type="button" class="btn btn-danger disabled" aria-expanded="false">
                                                PROXIMO
                                            </button>
                                            <button *ngIf="i != 0" type="button" class="btn btn-warning disabled" aria-expanded="false">
                                                SIGUIENTE
                                            </button>
                                        </td>
                                        <td>
                                            <button  *ngIf="c.generated == 1" mat-icon-button matTooltip="Descargar" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon matBadgeColor="warn">download</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent"  *ngIf="hasPermissions('suc_sup',null,true)" (click)="openDialogAddCutoff(true, c)">
                                                <mat-icon matBadgeColor="warn">edit</mat-icon>
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <span *ngIf="nowCutoffs().length == 0" >
                                <div class="text-center">
                                    No hay cortes en cola
                                </div>
                              </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active cutoffs End-->

        <!-- Card inactive cutoffs -->
        <div *ngIf="oldCutoffs().length != 0" class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header"><mat-icon class="text-info align-middle">stop</mat-icon> Cortes antiguos</h5>
                    <div class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nombre campaña</th>
                                    <th>Fecha corte</th>
                                    <th>Status</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let c of oldCutoffs()">
                                    <td role="button"><strong>{{ c.id }}</strong></td>
                                    <td role="button"><strong>{{ c.nombre }}</strong></td>
                                    <td role="button"><strong>{{ c.generated_at | date : "dd/MM/yyyy hh:mma" : "-0000"}}</strong></td>
                                    <td role="button" class="">
                                        <button *ngIf="c.generated == 1" type="button" class="btn btn-success disabled" aria-expanded="false">
                                            COMPLETADO
                                        </button>
                                        <button *ngIf="c.generated == 0" type="button" class="btn btn-warning disabled" aria-expanded="false">
                                            RETRASADO
                                        </button>
                                    </td>
                                    <td>
                                        <button *ngIf="c.generated == 1" (click)="downloadExcel(c.file, c.nombre)" mat-icon-button matTooltip="Descargar" [matTooltipPosition]="'above'" color="accent">
                                            <mat-icon matBadgeColor="warn">download</mat-icon>
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component,Inject,OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { editCampaignModel, newCampaignModel } from 'src/app/models/campaign.model';
import { UserModel } from 'src/app/models/user.model';
import { MainService } from 'src/app/services/main.service';
import { UsersService } from 'src/app/services/users.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-projects-campaign-add',
  templateUrl: './projects-campaign-add.component.html',
  styleUrls: ['./projects-campaign-add.component.scss']
})
export class ProjectsCampaignAddComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectsService : ProjectsService,
    private dialogRef: MatDialogRef<ProjectsCampaignAddComponent>,
    private main : MainService,
    private userS: UsersService
  ) {
    this.isEdit = this.data.edit;
    this.id_proy_camp = this.data.id_proy_camp;
    //this.id_world = this.data.id_world;
    if(this.isEdit){
      this.LoadCampaignData(this.data.data);

    }
   }

  isEdit : boolean = false;
  id_proy_camp : number = 0;
  newProject : any;
  newDateTody : Date = new Date();
  minDate : Date = new Date();
  isClientUsr: boolean = false;
  deadline : Date = new Date();
  visibledeadline : boolean = false;
  companiesList : any;
  userObj!: UserModel;
  //id_world : number = 0

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    if(!this.isEdit){
    this.newProject = new newCampaignModel();
    this.newProject.name="";
    this.newProject.id_compania = 1;
    this.newDateTody.setDate(this.newDateTody.getDate() + 1);
    this.deadline = new Date(this.newDateTody);
    }
  }

  LoadCampaignData(camp : any){
    this.newProject = new editCampaignModel();
    this.newProject.id_camp = camp.id;
    this.newProject.name = camp.name;
    this.newProject.active = camp.active;
  }

  saveProject(){
    if(!this.isEdit){
      this.createProject();
    }else{
      this.editProject();
    }
  }

  createProject(){
    if(this.newProject.name!= "" && this.newProject.id_compania!= 0){
      this.main.ShowLoading();
      this.newProject.deadline = this.deadline.toISOString().slice(0, 10);
      this.newProject.id_proy_camp = this.id_proy_camp;
      //this.newCampaign.id_world = this.id_world;
      this.projectsService.newProject(this.newProject).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Creado',
              text: 'Proyecto creado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
      console.log(this.newProject);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  
  editProject(){
    if(this.newProject.name!= ""){
      this.main.ShowLoading();
      this.projectsService.editProject(this.newProject).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Editado',
              text: 'Proyecto editado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          if(err.error.message != "No changes detected or no valid campaigns were found in the request") {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        }
      );
    }else{
      Swal.fire({
        icon: 'error',
        title: 'No hay nombre de proyecto',
        text: 'Por favor ingrese un nombre válido'
      });
    }
  }

}

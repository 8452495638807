import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderMemberModel } from 'src/app/models/provider.model';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-providers-sign-up',
  templateUrl: './providers-sign-up.component.html',
  styleUrls: ['./providers-sign-up.component.scss']
})
export class ProvidersSignUpComponent implements OnInit {

  member: any;
  hide = true;
  rememberme = false;
  viewData: any;
  dataGet: any;
  formPassword!: FormGroup;

  
  constructor(
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private projectService : ProjectsService,
    private main : MainService,
    private _params: ActivatedRoute,
    private _router : Router,
    private customVal: CustomValidatorsService,

  ) { 
    this._params.params.subscribe(params => {
      if(params.id){
        this.viewData = params.id;
        this.getView();
      }else{
        this._router.navigateByUrl('/login');
      } 
    });
  }

  ngOnInit(): void {  
     
  }
  createFormPassword() {
    this.formPassword = this.fb.group({
      nombre :['', [Validators.required]],
      puesto :['', Validators.required],
      phone : ['', Validators.required, Validators.pattern(/^[0-9]\d*$/)],
      pass: ['', [ Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]],
      passConfirm: ['', [Validators.required]],
      token : [this.viewData]
    },{
      validators: this.customVal.samePasswords("pass", "passConfirm")
    });
  }

  sendPassword(){
    this.ruSure();
    console.log(this.formPassword.controls);
  }

  getView(){
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    }); 
    this.projectService.getProviderMemberSignUpView(this.viewData).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.dataGet = result.data;
       this.createFormPassword()
       Swal.close();

       if(this.dataGet.activated == 1){
        Swal.fire({
          icon: 'error',
          title: 'Este perfil ya fue activado previamente',
          text: 'Si olvidó su contraseña por favor comuníquese con Administración',
          allowOutsideClick: false,
          showConfirmButton:false
        });
       }
       //this.createFormProvider();
       //console.log(this.dataGet);
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener el registro'
          });
          this._router.navigateByUrl('/login');
      }
    );
  }

  ruSure() {
    if(this.formPassword.invalid || this.formPassword.value.pass != this.formPassword.value.passConfirm) { return; }    
    Swal.fire({
      title: '¿Esta seguro de los datos ingresados?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Guardando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        this.projectService.setProviderMemberPassword(this.formPassword.value).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                text: 'Datos guardados correctamente'          
              }).then(() => {            
                this._router.navigateByUrl('/login');
              });
            }
          },
          (err:any) => {

              console.log(err);
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          
        );
      }
    });
  }

  openDialog() {
    Swal.fire({
      icon: 'warning',
      title: '¿Olvidó su contraseña?',
      text: 'Para recuperar su contraseña contacte a su agente de Búho o envié un mensaje solicitando el restablecimiento de su contraseña al correo electrónico "hola@buhologistics.com.mx"',
      heightAuto: false
    });
  }

}

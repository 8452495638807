import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewMediaComponent } from '../../shared/dialogs/new-media/new-media.component';
import { first, firstValueFrom } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ProjectMedia } from 'src/app/models/project.model';
import { ProjectsService } from 'src/app/services/projects.service';



@Component({
  selector: 'app-project-media',
  templateUrl: './project-media.component.html',
  styleUrls: ['./project-media.component.scss']
})
export class ProjectMediaComponent implements OnInit {
  // Data Handlers
  projectID: number | null = null;
  //projectMedia: Array<any> = [
  //  { id: 1, name: "DANGLER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: false, formato : "Reglas" },
  //  { id: 2, name: "COPETE DE MESA PROMOCIONES", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" },
  //  { id: 3, name: "BANNER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: true, formato : "Reglas" },
  //  { id: 4, name: "POSTER CHECKOUT", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" }
  //];
  projectMedia : Array<any> = [];

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private projService : ProjectsService) { }

  ngOnInit(): void {
    this.getData();
  }


  /* void openMediaPOP(1):
      */
  openMediaPOP(id: number): void {
    this.router.navigateByUrl('/admin/projects/project/' + this.projectID + '/media/' + id);
  }


  /* async Promise<void> getData(0):
     Extracts data from URL. */
  async getData(): Promise<void> {
    try {
      // Extract ID
      const url: ParamMap = await firstValueFrom(this.route.paramMap);
      this.projectID = Number(url.get('id'));
      this.projectMedia = this.projService.projectMedia;
      if (!this.projectID)
        throw new Error('No se recibió ningún ID.');
    } catch (error) {
      console.error(error);
    }
  }

  /* async Promise<void> newMedia(0):
     Opens the dialog to create a new media element. Then, extracts data. */
  async newMedia(): Promise<void> {
    // Open data-setting dialog
    const nmDialogRef: MatDialogRef<NewMediaComponent, any> = this.dialog.open(NewMediaComponent, {
      data: {},
      panelClass: 'dialogs-main'
    });

    try {
      // Use firstValueFrom to get the result from the dialog
      const result = await firstValueFrom(nmDialogRef.afterClosed());

      if (result) {
        console.log('Dialog result:', result);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalCatModel } from 'src/app/models/global.model';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.scss']
})
export class UsersAddComponent implements OnInit {

  hideEmail = true;
  hideEmailConfirm = true;
  permissionList : any = [];
  modulesList : any = [];
  notifList : any = [];
  selected : any = [];
  companiesList : any = [];
  zoneslist : any = [];

  rolesList!: {
    id: number,
    rol: string
  }[];

  formUser!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private customVal: CustomValidatorsService,
    private userS: UsersService,
    private dialogRef: MatDialogRef<UsersAddComponent>
  ) {  }

  ngOnInit(): void {
    this.createFormUser();
    this.rolesList = environment.roles;
    console.log(this.formUser);

  }

  createFormUser() {
    this.formUser = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
      email: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],      
      pass: ['', [Validators.required, Validators.minLength(8)]],
      passConfirm: ['', [Validators.required, Validators.minLength(8)]],
      id_rol: ['', [Validators.required]],
      permissions: ['', [Validators.required]],
      modules: [''],
      notifications: [''],
      id_compania : [0]
    },{
      validators: this.customVal.samePasswords("pass", "passConfirm")
    });
  }

  getPermissions(){
    if(this.formUser.value.id_rol > 1) {
      this.userS.getPermissionList(this.formUser.value.id_rol).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.permissionList = result.data;
            this.formUser.controls["permissions"].setValue(result.data.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      )
    }
    else this.permissionList = [];
  }

  getModules(){
    if(this.formUser.value.id_rol > 2 && this.formUser.value.id_compania) {
    let compania = 0;
    if(this.formUser.value.id_compania){
      compania = this.formUser.value.id_compania;
    }
    console.log(compania, this.formUser.value.id_compania);
      this.userS.getModules(compania).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.modulesList = result.data;
            this.formUser.controls["modules"].setValue(result.data.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      );
    }else{
      this.userS.getModules(0).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.modulesList = result.data;
            this.formUser.controls["modules"].setValue(result.data.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      );
    }
  }

  getPermbyModule(id_mod : number){
    return this.permissionList.filter((x:any)=>{ return x.id_modulo == id_mod })
  }


  getNotif(){
    if(this.formUser.value.id_rol > 1) {
      this.userS.getNotifList(this.formUser.value.id_rol).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.notifList = result.data;
            //this.formUser.controls["notifications"].setValue(result.data.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      )
    }
    else{
      this.notifList = [];
    } 
  }
  
  getZones(){
    if(this.formUser.value.id_rol > 2 ) {
      //get companie
      this.userS.getZones(this.formUser.value.id_compania).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.zoneslist = result.data;
            //this.formUser.controls["zones"].setValue(this.data.userSel.permissions.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      )
    }
    else{
      this.zoneslist = [];
    } 
  }

  getCompanias(){
    if(this.formUser.value.id_rol == 3) {
      this.userS.getCompanies().subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.companiesList = result.data;
          }
        },
        (err:any) => {
          console.log(err);
        }
      );

    }else{
      this.formUser.value.id_compania = 0;
      this.companiesList = [];
    }
  }

  ruSure() {
    if(this.formUser.invalid) { return; }
    if(!this.ValidateIfShoulHaveCompany()) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.userS.saveUser(this.formUser.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Usuario guardado de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        if(err.error.message.indexOf("ER_DUP_ENTRY") > -1)
        {
          Swal.fire({
            icon: 'error',
            title: 'Usuario duplicado',
            text: 'Ya existe un usuario con ese email.'
          });
        }
        else {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      }
    );
  }

  ValidateIfShoulHaveCompany() : boolean{
    if(this.formUser.value.id_rol == 3 ){
      return this.formUser.value.id_compania != 0;
    }else{
      return true
    }
  }

  resetForm() {
    this.permissionList = [];
    this.notifList = [];
    this.selected = [];
    this.formUser.reset();
  }

  toggleSelectAllPermissions() {
    let allPermissions = this.permissionList.map((p : any) => p.id);
    let currentPermissions = this.formUser.get('permissions')?.value || [];

    if (currentPermissions.length != 0) {
      this.formUser.get('permissions')?.setValue([]);
    } else {
      console.log("selecciona todos");
      this.formUser.get('permissions')?.setValue(allPermissions); 
    }
  }

  toggleSelectAllNotif() {
    let allNotif = this.notifList.map((p : any) => p.id);
    let currentNotif = this.formUser.get('notifications')?.value || [];

    if (currentNotif.length != 0) {
      this.formUser.get('notifications')?.setValue([]);
    } else {
      this.formUser.get('notifications')?.setValue(allNotif); 
    }
  }

  toggleSelectAllModules() {
    let allModules = this.modulesList.map((p : any) => p.id);
    let currentModules = this.formUser.get('modules')?.value || [];

    if (currentModules.length != 0) {
      this.formUser.get('modules')?.setValue([]);
    } else {
      this.formUser.get('modules')?.setValue(allModules); 
    }
  }

  isModuleFullySelected(id_mod: number): boolean {
    const modulePermissions = this.getPermbyModule(id_mod).map((p : any) => p.id);
    const currentPermissions = this.formUser.get('permissions')?.value || [];
    return modulePermissions.every((id : any) => currentPermissions.includes(id));
  }

  isModulePartiallySelected(id_mod: number): boolean {
    const modulePermissions = this.getPermbyModule(id_mod).map((p : any) => p.id);
    const currentPermissions = this.formUser.get('permissions')?.value || [];
    const anySelected = modulePermissions.some((id : any) =>
      currentPermissions.includes(id)
    );
    const allSelected = modulePermissions.every((id : any) =>
      currentPermissions.includes(id)
    );
    return anySelected && !allSelected;
  }

  toggleSelectAllByModule(id_mod: number, checked: boolean) {
    const modulePermissions = this.getPermbyModule(id_mod).map((p : any) => p.id);
    const currentPermissions = this.formUser.get('permissions')?.value || [];

    if (checked) {
      const newPermissions = [...currentPermissions, ...modulePermissions];
      this.formUser.get('permissions')?.setValue([...new Set(newPermissions)]); 
    } else {
      const newPermissions = currentPermissions.filter(
        (id: any) => !modulePermissions.includes(id)
      );
      this.formUser.get('permissions')?.setValue(newPermissions);
    }
  }

}

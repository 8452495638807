import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-providers-add',
  templateUrl: './projects-providers-add.component.html',
  styleUrls: ['./projects-providers-add.component.scss']
})
export class ProjectsProvidersAddComponent implements OnInit {

  bodyForm : FormData = new FormData(); 
  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  providers : any = [];
  folder : any;
  id_campana_proyecto : any;
  nombre : any = "";
  hostName : any = "";
  listprovsurv : any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,    
    private fb : FormBuilder,    
    private projectService : ProjectsService,
    private main : MainService,
    private dialogRef: MatDialogRef<ProjectsProvidersAddComponent>,
  ) { 

    this.folder = this.data.folder;
    this.id_campana_proyecto = this.data.camp_id;
    this.nombre = this.data.nombre;
    this.listprovsurv = this.data.listprovsurv;
    console.log(this.listprovsurv);
    
    this.hostName = window.location.protocol + '//' + window.location.host + "/providers/";
  }

  ngOnInit(): void {
    this.getProviders();
    this.loadForm();
    this.preloadFormData();
  }

  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}],
      id_provider : [null, {validators:[Validators.required]}],
      provider_msg : [""]
    });
  }

  preloadFormData(){
    this.bodyForm.append('folder', this.folder as string);
    this.bodyForm.append('id_campana_proyecto', this.id_campana_proyecto);
  }


  onValueChange(files : any)
  { 
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!(item.name.endsWith('.pdf')))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato .PDF'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
    }
  }

  saveFile() {
    if(this.form.controls.id_provider.value != null){
      Swal.fire({
        title: '¿Estás seguro que deseas guardar el registro?',
        text: '¡Se enviará notificación a Proveedor!',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
            this.uploadFile();
        }
      });
    }
  }

  getProviders(){
    this.projectService.getProviders().subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.providers = result.data;

      let listIdsProviders = this.listprovsurv.map((x:any)=> x.id_provider)

      this.providers = this.providers.filter((x:any)=>{ return !listIdsProviders.includes(x.id) });
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros de proveedores.'
          });
        
      }
    );
  }


  uploadFile(){ 
    if(this.form.value.blob != null && this.form.value.blob?.length!=0 ){
      this.bodyForm.append("blob", this.form.value.blob[0] as File);
      this.bodyForm.append("id_provider", this.form.controls.id_provider.value);
      this.bodyForm.append("provider_msg", this.form.controls.provider_msg.value);
      this.bodyForm.append("host", this.hostName);
      Swal.fire({
        icon: 'info',
        title: 'Subiendo archivo...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.projectService.uploadCampaignProviderFile(this.bodyForm).subscribe(
        (result:any)=>{
          if(result.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Archivo subido',
              text: 'Archivo subido exitosamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          if(err.error.message == "Invalid file") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'Solo se permiten documentos en formato .PDF'
            });
      
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        }
      )
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
    }
  }

  members : any = [];

  loadproviderInfo(evento : any){
    this.main.ShowLoading();
    console.log(evento);
    this.projectService.getProviderAdminView(evento.value).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {
          console.log(result.data);

          this.members = result.data.members;
         
        }
      },
      (err:any) => {
        this.main.HideLoading();
      }
    );

  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import Swal from 'sweetalert2';
import { SurveysService } from 'src/app/services/surveys.service';

@Component({
  selector: 'app-survey-material-file',
  templateUrl: './survey-material-file.component.html',
  styleUrls: ['./survey-material-file.component.scss']
})
export class SurveyMaterialFileComponent implements OnInit {

  bodyForm : FormData = new FormData(); 

  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  campana : any;
  progress : number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private surveyS : SurveysService,
    private main : MainService,
    private fb : FormBuilder,
    private dialogRef: MatDialogRef<SurveyMaterialFileComponent>,
  ) { 
    this.campana = this.data.campana;
   }

  ngOnInit(): void {
    this.preloadFormData();
    this.loadForm();
  }

  preloadFormData(){
    this.bodyForm.append('folder_encuestas', this.campana.folder_encuestas as string);
    this.bodyForm.append('folder', this.campana.folder as string);
    this.bodyForm.append('id_campana', this.campana.id);
    this.bodyForm.append('id_compania', this.campana.id_compania);
  }

  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }

  saveMaterialFile() {
    Swal.fire({
      title: '¿Desea subir este archivo?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.form.value.blob != null && this.form.value.blob?.length!=0){
          this.bodyForm.append("blob", this.form.value.blob[0] as File);
          Swal.fire({
            icon: 'info',
            title: 'Subiendo archivo...',
            text:'Esto puede tardar algunos minutos, favor de no cerrar o recargar la pagina.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          this.surveyS.uploadImages(this.bodyForm).subscribe(
            (result:any)=>{
              if(result.message == 'Success'){
                Swal.fire({
                  icon: 'success',
                  title: 'Archivo subido',
                  text: 'Archivo subido exitosamente'          
                }).then(() => {            
                  this.dialogRef.close({ reload: true });
                });
                console.log("upload data", result);
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
                });
              }
            },
            (err:any)=>{
              this.main.HideLoading();
              if(err.error.message == "Invalid file") {
                Swal.fire({
                  icon: 'warning',
                  title: 'Error',
                  html: 'Solo se permiten documentos en formato .zip'
                });
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
                });
              }
            }
          )
          
      }else{
          Swal.fire({
            icon: 'warning',
            title: 'Error',
            html: 'El documento no ha sido adjuntado'
          });
        }
      
      }
    });
  }

  onValueChange(files : any)
  { 
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!(item.name.endsWith('.zip') && !(item.name.endsWith('.xlsx'))))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato .ZIP'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
    }
  }

  

}

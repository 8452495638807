<div class="modal-header">
    <h4 class="modal-title">Crea segmentos desde la lista de distribución cargada</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <form [formGroup]="ruleForm" novalidate>
            <div class="row">  
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Nombre de segmento</mat-label>
                        <input matInput placeholder="Nombre de segmento" name="name" required>
                        <mat-icon matSuffix>edit_document</mat-icon>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <b>Reglas</b>
                    <!-- <div class="row">
                        <div class="col-md-6" formArrayName="rules" *ngFor="let rule of getRulesArray(); let i=index">
                            <div [formGroupName]="i">
                                <mat-form-field appearance="outline" class="w-100" name="rules">
                                    <mat-label>Regla {{i+1}}</mat-label>
                                    <mat-select matInput formControlName="selectCtrl">
                                        <mat-option *ngFor="let type of types" [value]="type.type_id">{{type.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div> -->
                        <div>
                            <div *ngFor="let index of addGroup" class="d-flex">
                                <div class="col-5 flex-fill me-3">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Atributos</mat-label>
                                        <mat-select #attribute_value>
                                            <mat-option *ngFor="let item of group_atributes" value={{item.id}}>{{item.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                
                                <div class="col-1 flex-fill me-3">
                                    <ng-container  *ngIf="attribute_value.value" >
                                        <mat-form-field class="w-100" appearance="outline">
                                            <!-- <mat-label>Condición</mat-label> -->
                                            <mat-select matInput>
                                                <mat-option *ngFor="let type of conditions" [value]="type.type_id">{{type.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>

                                </div>

                                <!--<div>{{attribute_value.value}}</div>-->

                                <!--<div class="col-1 flex-fill me-3">
                                    <mat-form-field class="w-100" appearance="outline">-->
                                        <!-- <mat-label>Condición</mat-label> -->
                                        <!--<mat-select matInput>
                                            <mat-option *ngFor="let type of types" [value]="type.type_id">{{type.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>-->
                
                                <div class="col-5 flex-fill">
                                    <mat-form-field class="w-100 my-form-field" appearance="outline">
                                        <mat-label>Valores</mat-label>
                                        <input matInput type="text" placeholder="Cantidad" min="0">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <button mat-button color="primary" (click)="addMore()"><mat-icon>add box</mat-icon>Campo de segmento</button>
                    <!-- <button (click)="addRule()" mat-button color="primary"><mat-icon>add box</mat-icon> Reglas</button> -->
                </div>
            </div>
        </form>      
    </div>
    <div class="modal-footer">
        <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
        <button (click)="onSubmit()" mat-flat-button class="bg-success text-white">Guardar <mat-icon>save</mat-icon></button>
        <!-- <button mat-flat-button class="bg-success text-white" (click)="save()">Guardar <mat-icon>save</mat-icon></button> -->
    </div>
</mat-dialog-content>
<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">
        <div *ngIf="!data.zone"><mat-icon color="warn" class="align-middle">info</mat-icon> Pendientes a cambios</div>
        <div *ngIf="data.zone"><mat-icon color="info" class="align-middle">pending</mat-icon> Status de cambios</div>
    </h4>
    <!--<mat-form-field class="ms-3 mt-1">
        <mat-label>Últimos 7 días</mat-label>
        <mat-select>
            <mat-option>Últimos 7 días</mat-option>
            <mat-option>Últimos 30 días</mat-option>
            <mat-option>Año actual</mat-option>
        </mat-select>
    </mat-form-field>-->
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="row g-2">
            <div class="col mb-0">
                <div class="table-responsive text-nowrap">
                    <table class="table table-hover">
                        <thead>
                            <tr  >
                                <th>Sucursal</th>
                                <th>Atributo</th>
                                <th>Valor anterior</th>
                                <th>Valor nuevo</th>
                                <th>Fecha</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                            <tr *ngFor="let pend of pendientes">
                                <td>
                                    <strong> {{ findSucursal(pend.sucursal_id).Nombre }}</strong>
                                </td>
                                <td matTooltip="{{ findAtributo(pend.atributo_id).name }}" class="truncate-table">
                                    {{ findAtributo(pend.atributo_id).name }}
                                </td>
                                <td  matTooltip="{{ pend.value }}" class="truncate-table">
                                    <strong>{{ pend.value }}</strong>
                                </td>
                                <td  matTooltip="{{ pend.new_value }}" class="truncate-table">
                                    <strong>{{ pend.new_value }}</strong>
                                </td>
                                <td>
                                    {{ pend.new_value_date|date: "dd/MM/yyyy"}}
                                </td>
                                <td>
                                    <div *ngIf="pend.autorization == 1 && pend.approved == 0">
                                        <div *ngIf="hasPermissions('suc_sup',null,true)">
                                            <button type="button" (click)="autorize(pend.id, 1)" class="btn btn-sm btn-success">AUTORIZAR</button>
                                            <button type="button" (click)="autorize(pend.id, 0)" class="ms-2 btn btn-sm btn-danger">NO AUTORIZAR</button>
                                        </div>
                                        <div *ngIf="!hasPermissions('suc_sup',null,true)">
                                            <span class="badge bg-warning">PENDIENTE A AUTORIZAR</span>
                                        </div>

                                    </div>
                                    <div *ngIf="pend.autorization == 0 || (pend.autorization == 1 && pend.approved == 1)">
                                        <span class="badge bg-warning">PENDIENTE A CAMBIO</span>
                                        <button type="button" (click)="cancelDo(pend.id, 0)" class="ms-2 btn btn-sm btn-danger">CANCELAR</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
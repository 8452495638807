
<div class="preprojectaddcont">
    <div class="scrollingcont">
        <div class="modal-header">
            <h4 class="modal-title" id="modalProjectTitle">{{ isEdit ? 'Editar' : 'Nuevo'}} formulario</h4>
            <button type="button" class="btn-close" mat-dialog-close></button>
        </div>
        <div class="modal-body">
            <h6>Ingresa la siguiente información:</h6>
            <form #f="ngForm">
                <div class="row">
                    <div class="row">
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Nombre de formulario</mat-label>
                                <input matInput placeholder="Ej: Banners" name="nombre_form" [(ngModel)]="newStrategy.nombre_str" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Nombre</mat-label>
                                <input matInput name="nombre" [(ngModel)]="newStrategy.nombre" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Proyecto</mat-label>
                                <input matInput name="proyecto" [(ngModel)]="newStrategy.proyecto" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Folio</mat-label>
                                <input matInput name="folio" [(ngModel)]="newStrategy.folio" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Contacto</mat-label>
                                <input matInput name="contacto" [(ngModel)]="newStrategy.contacto" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Cliente</mat-label>
                                <input matInput name="cliente" [(ngModel)]="newStrategy.cliente" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Tipo</mat-label>
                                <input matInput name="tipo" [(ngModel)]="newStrategy.tipo" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Area Responsable</mat-label>
                                <mat-select name="area_responsable" [(ngModel)]="newStrategy.area_responsable" required>
                                    <mat-option *ngFor="let p of tiposArea" [value]="p">{{p}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Impresion</mat-label>
                                <mat-select name="impresion" [(ngModel)]="newStrategy.impresion" required>
                                    <mat-option *ngFor="let p of tiposImpresion" [value]="p">{{p}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Color</mat-label>
                                <input matInput name="color" [(ngModel)]="newStrategy.color" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Tipo envio</mat-label>
                                <mat-select name="tipo_envio" [(ngModel)]="newStrategy.tipo_envio" required>
                                    <mat-option *ngFor="let p of tiposEnvio" [value]="p">{{p}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field class="col-sm-12" appearance="outline">
                                <mat-label>Recepcion</mat-label>
                                <mat-select name="recepcion" [(ngModel)]="newStrategy.recepcion" required>
                                    <mat-option *ngFor="let p of tiposRecepcion" [value]="p">{{p}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12">
                            <mat-form-field class="col-sm-4" appearance="outline">
                                <mat-label>Dia inicio</mat-label>
                                <input matInput name="dia_inicio"  type="number" [(ngModel)]="newStrategy.dia_inicio" required>
                            </mat-form-field>
                            <mat-form-field  class="col-sm-4" appearance="outline">
                                <mat-label>Mes inicio</mat-label>
                                <mat-select name="mes_inicio" [(ngModel)]="newStrategy.mes_inicio" required>
                                    <mat-option [value]="'1'">ENERO</mat-option>
                                    <mat-option [value]="'2'">FEBRERO</mat-option>
                                    <mat-option [value]="'3'">MARZO</mat-option>
                                    <mat-option [value]="'4'">ABRIL</mat-option>
                                    <mat-option [value]="'5'">MAYO</mat-option>
                                    <mat-option [value]="'6'">JUNIO</mat-option>
                                    <mat-option [value]="'7'">JULIO</mat-option>
                                    <mat-option [value]="'8'">AGOSTO</mat-option>
                                    <mat-option [value]="'9'">SEPTIEMBRE</mat-option>
                                    <mat-option [value]="'10'">OCTUBRE</mat-option>
                                    <mat-option [value]="'11'">NOVIEMBRE</mat-option>
                                    <mat-option [value]="'12'">DICIEMBRE</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-sm-4" appearance="outline">
                                <mat-label>Año inicio</mat-label>
                                <input matInput name="ano_inicio" type="number" [(ngModel)]="newStrategy.ano_inicio" required>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-12">
                            <mat-form-field class="col-sm-4" appearance="outline">
                                <mat-label>Dia fin</mat-label>
                                <input matInput name="dia_fin"  type="number" [(ngModel)]="newStrategy.dia_fin" required>
                            </mat-form-field>
                            <mat-form-field class="col-sm-4" appearance="outline">
                                <mat-label>Mes fin</mat-label>
                                <mat-select name="mes_fin" [(ngModel)]="newStrategy.mes_fin" required>
                                    <mat-option [value]="'1'">ENERO</mat-option>
                                    <mat-option [value]="'2'">FEBRERO</mat-option>
                                    <mat-option [value]="'3'">MARZO</mat-option>
                                    <mat-option [value]="'4'">ABRIL</mat-option>
                                    <mat-option [value]="'5'">MAYO</mat-option>
                                    <mat-option [value]="'6'">JUNIO</mat-option>
                                    <mat-option [value]="'7'">JULIO</mat-option>
                                    <mat-option [value]="'8'">AGOSTO</mat-option>
                                    <mat-option [value]="'9'">SEPTIEMBRE</mat-option>
                                    <mat-option [value]="'10'">OCTUBRE</mat-option>
                                    <mat-option [value]="'11'">NOVIEMBRE</mat-option>
                                    <mat-option [value]="'12'">DICIEMBRE</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="col-sm-4" appearance="outline">
                                <mat-label>Año fin</mat-label>
                                <input matInput name="ano_fin" type="number" [(ngModel)]="newStrategy.ano_fin" required>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
            <button mat-flat-button type="submit" *ngIf="id_str == 0" class="bg-success text-white" (click)="saveStrategy()">Guardar <mat-icon>save</mat-icon></button>
            <button mat-flat-button type="submit" *ngIf="id_str != 0" class="bg-success text-white" (click)="editStrategy()">Editar <mat-icon>save</mat-icon></button>
        </div>
    </div>
</div>

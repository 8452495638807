<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Nuevo usuario</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Captura la información del nuevo usuario:</h6>
    <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Email</mat-label>
                    <mat-icon matSuffix>alternate_email</mat-icon>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Selecciona perfil</mat-label>
                    <mat-select formControlName="id_rol" (selectionChange)="getPermissions(); getNotif(); getCompanias(); getZones(); getModules();">
                        <mat-option *ngFor="let rol of rolesList" [value]="rol.id">{{rol.rol}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Compañía</mat-label>
                    <mat-select formControlName="id_compania" [disabled]= "formUser.value.id_rol != 3" (selectionChange)="getZones(); getModules();">
                        <mat-option *ngFor="let p of companiesList" [value]="p.id">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <!--<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Permisos</mat-label>
                    <mat-select formControlName="permissions" multiple>
                        <mat-option *ngFor="let p of permissionList" [value]="p.id">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>-->

                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Permisos</mat-label>
                    <mat-select formControlName="permissions" multiple>
                        <button  mat-flat-button (click)="toggleSelectAllPermissions()"><mat-icon>library_add</mat-icon>Seleccionar/declinar todos los permisos</button>
                        <ng-container  *ngFor="let m of modulesList" >
                            <mat-optgroup *ngIf="getPermbyModule(m.id).length != 0" [label]="''">
                                <div class="optgroup-header">
                                    <span>Permisos del módulo {{ m.nombre }}</span>
                                    <mat-checkbox (change)="toggleSelectAllByModule(m.id, $event.checked)"
                                      [checked]="isModuleFullySelected(m.id)"
                                      [indeterminate]="isModulePartiallySelected(m.id)">
                                    </mat-checkbox>
                                  </div>
                                <mat-option  *ngFor="let p of getPermbyModule(m.id)"  [value]="p.id">{{p.name}}</mat-option>                              
                            </mat-optgroup>
                          </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Activar Notificaciones</mat-label>
                    <mat-select formControlName="notifications" multiple>
                        <button  mat-flat-button (click)="toggleSelectAllNotif()"><mat-icon>library_add</mat-icon>Seleccionar/declinar todas las notificaciones</button>
                        <mat-option *ngFor="let n of notifList" [value]="n.id">{{n.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="formUser.value.id_rol == 3" class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Modulos</mat-label>
                    <mat-select formControlName="modules" [disabled]= "formUser.value.id_rol != 3" multiple>
                        <button  mat-flat-button (click)="toggleSelectAllModules()"><mat-icon>library_add</mat-icon>Seleccionar/declinar todos los módulos</button>
                        <mat-option *ngFor="let p of modulesList" [value]="p.id">{{p.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Password</mat-label>
                    <mat-icon matSuffix (click)="hideEmail = !hideEmail" role="button">{{hideEmail ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hideEmail ? 'password' : 'text'" formControlName="pass" maxlength="30">
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Confirma password</mat-label>
                    <mat-icon matSuffix (click)="hideEmailConfirm = !hideEmailConfirm" role="button">{{hideEmailConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hideEmailConfirm ? 'password' : 'text'" formControlName="passConfirm" maxlength="30">
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button (click)="resetForm()">Limpiar <mat-icon>delete_outline</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PreProjectsTotalAddFileComponent } from '../preprojects-totals-add-file/preprojects-totals-add-file.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { ProjectModel } from 'src/app/models/project.model';
import { PreProjectsPDFsAddFileComponent } from '../preprojects-pdfs-add-file/preprojects-pdfs-add-file.component';

@Component({
  selector: 'app-preprojects-pdfs-add',
  templateUrl: './preprojects-pdfs-add.component.html',
  styleUrls: ['./preprojects-pdfs-add.component.scss']
})
export class ProjectsPDFsAddComponent implements OnInit {
  project: ProjectModel = new ProjectModel();
  id_form : number = 0;
  isLoading = true;
  totalFiles: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectService : ProjectsService,
    private main : MainService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ProjectsPDFsAddComponent>,
  ) {
    this.id_form = this.data.id_form;
    this.loadData();
   }

  ngOnInit(): void {
  }

  loadData(){
    this.projectService.getPreprojectPdfs(this.id_form).subscribe(
      (result:any) => {
        this.isLoading = false;
       this.totalFiles = result.data;
      },
      (err:any) => {
        this.isLoading = false;
        if(err.error.message != "No valid art files were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  openDialogUploadFile() {
    this.matDialog.open(PreProjectsPDFsAddFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        id_form: this.id_form 
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.loadData();
        }
      }
    );
  }

  deletePDFConfirm(strid : any){
    Swal.fire({
      title: '¿Está seguro que desea eliminar este PDF?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.projectService.deletePreprojectPDF(strid).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Eliminada',
              text: 'PDF eliminado correctamente'          
            }).then(s=>{
              this.loadData();
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid strategies were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al eliminar',
              text: 'Ocurrió un error al eliminar.'
            });
          }
        }
      );
    } else {
     console.log("no se elimina")
    }
  });
  }

}

<div class="modal-header">
    <h4 class="modal-title">Crea segmentos desde un excel con FarmID</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <form [formGroup]="groupForm" novalidate>
            <div class="row">  
                <div class="col-md-12">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Nombre de segmento</mat-label>
                        <input matInput placeholder="Nombre de grupo" name="name" required>
                        <mat-icon matSuffix>edit_document</mat-icon>
                    </mat-form-field>
                    <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
                    </ngx-file-drag-drop>
                </div>
            </div>
        </form>      
    </div>
    <div class="modal-footer">
        <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
        <button (click)="onSubmit()" mat-flat-button class="bg-success text-white">Guardar <mat-icon>save</mat-icon></button>
        <!-- <button mat-flat-button class="bg-success text-white" (click)="save()">Guardar <mat-icon>save</mat-icon></button> -->
    </div>
</mat-dialog-content>
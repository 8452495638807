import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsCampaignFileAddComponent } from '../../dialogs/projects-campaign-file-add/projects-campaign-file-add.component';
import { MainService } from 'src/app/services/main.service';
import { ProjectsCampaignCommentsAddComponent } from '../projects-campaign-comments-add/projects-campaign-comments-add.component';

@Component({
  selector: 'app-projects-art-upload',
  templateUrl: './projects-art-upload.component.html',
  styleUrls: ['./projects-art-upload.component.scss']
})
export class ProjectsArtUploadComponent implements OnInit {

  constructor(private matDialog: MatDialog,
    private main : MainService,) { }

  ngOnInit(): void {
  }


  openDialogUploadCampaignFile(phase_id : number) {
    this.matDialog.open(ProjectsCampaignFileAddComponent, {
      panelClass: ['dialogs-main'],
      data: {
        phase_id : phase_id,
        id_campana_proyecto : 1,
        folder : 1,
        nombre : "DANGLER - VERSION 1"
      }
    }).afterClosed().subscribe(
      resp => {
       
      }
    );
  }


  openDialogCommentNoAuth(phase_id : number, rol_id : number) {
    this.matDialog.open(ProjectsCampaignCommentsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
          phase_id : phase_id,
          rol_id : rol_id,
          camp_id : 1,
          nombre : "DANGLERS VERSION 1 - FASE 1"
        }
    }).afterClosed().subscribe(
      resp => {
   
      }
    );
  }

  getArchivosByPhase(id:number) : Array<any>{
    return [{id: 1, nombre : "Artes.pdf", created_at  : new Date(), nombre_usuario : "Usuario FDA", }];
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CampaignAddComponent } from '../shared/dialogs/campaign-add/campaign-add.component';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { getCampaignsModel, newCampaignModel, CampaignModel, DashboardCampaigns } from '../../models/campaign.model';
import { MainService } from 'src/app/services/main.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CampaignLogsComponent } from '../shared/dialogs/campaign-logs/campaign-logs.component';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/services/users.service';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private matDialog: MatDialog,
    private campaignService: CampaignsService,
    private main: MainService,
    private _router: Router,
    private _titleService: Title,
    private userS: UsersService
  ) {

    this.actual_year = (new Date).getFullYear();
    this._titleService.setTitle("Buho Nest");
  }

  userObj!: UserModel;
  companiesList : any = [];
  selectedCompany : number = 0;

  actual_year: number = 0;
  campaignsData: DashboardCampaigns = new DashboardCampaigns();
  years: string[] = [];
  loaded : boolean = false;

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    if(this.userObj.id_rol!= 3){
      this.getListCompanies();
      this.loadCampaigns();
    }else{
      this.loadCampaigns();
    }
    console.log(this.campaignsData);
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.campaignService.hasPermissions(key, buho, client);
  }

  openAddCampaignDialog() {
    this.matDialog.open(CampaignAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : false
      }
    }).afterClosed().subscribe(
      resp => {
        //if (resp.reload) this.loadCampaigns();
        if (resp.reload) this.loadCampaignsFilter();
      }
    );
  }

  loadCampaigns() {
    this.main.ShowLoading();
    this.campaignService.getCampaigns().subscribe((x: any) => {
      this.main.HideLoading();
      this.campaignsData = x;
      this.years = Object.keys(this.campaignsData.pastyears);
      this.loaded = true;
    },
    (err:any) => {
          this.main.HideLoading();
        }
    )
  }

  loadCampaignsFilter() {
    if(this.selectedCompany != 0){
      this.main.ShowLoading();
      this.campaignService.getCampaignsByCompany(this.selectedCompany).subscribe((x: any) => {
        this.main.HideLoading();
        this.campaignsData = x;
        this.years = Object.keys(this.campaignsData.pastyears);
        this.loaded = true;
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }else{
      this.loadCampaigns();
    }
  }

  goToCampaign(id: number) {
    this._router.navigateByUrl('/admin/distribution/' + id)
  }

  openCampaignLogs(campaignSelected: any) {
    this.matDialog.open(CampaignLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        campaign: campaignSelected
      }
    }).afterClosed().subscribe(
      resp => {
        //this.loadCampaigns();
        this.loadCampaignsFilter();
      }
    );
  }

  isBuho():boolean{
   return this.campaignService.isBuhoUsr();
  }

  isClient():boolean{
    return this.campaignService.isClientUsr();
  }

  deleteCampConfirm(camp: any){
    Swal.fire({
      title: '¿Está seguro que desea eliminar esta Campaña?. Esto no se puede deshacer.',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.campaignService.deleteCampaign(camp.id).subscribe(
        (x: any) => {
          this.main.HideLoading();
          //this.loadCampaigns();
          this.loadCampaignsFilter();
        },
        (err:any) => {
          this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al eliminar',
            text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
          });
          //this.loadCampaigns();
          this.loadCampaignsFilter();
        }
      );
    }
  });
  }

  openEditCampaignDialog(camp : any) {
    this.matDialog.open(CampaignAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : true,
        data: camp
      }
    }).afterClosed().subscribe(
      resp => {
        // if (resp.reload) this.loadCampaigns();
        if (resp.reload) this.loadCampaignsFilter();
      }
    );
  }

  getCompanyName(id: any): string{
    let company = "";
    this.companiesList.forEach((x:any) => {
        if(x.id == id){
          company = x.name;
        }
    });
    return company;
  }

  getListCompanies(){
    this.userS.getCompanies().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.companiesList = result.data;
        }
      },
      (err:any) => {
        console.log(err);
      }
    );
  }

}

<div class="layout-wrapper layout-content-navbar" [ngClass]="menu_responsive ? 'layout-menu-expanded' : ''">
    <div class="layout-container">
        <app-sidebar (menuEvent)="menuEvent($event)"></app-sidebar>
        <div class="layout-page">
            <app-header (menuEvent)="menuEvent($event)"></app-header>
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="layout-overlay layout-menu-toggle" (click)="menuEvent(false)"></div>
    <app-loading ></app-loading>
</div>
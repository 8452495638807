import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UsersEditPasswordComponent } from '../users-edit-password/users-edit-password.component';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent implements OnInit {

  rolesList!: {
    id: number,
    rol: string
  }[];

  formUser!: FormGroup;
  id_user!: number;
  isUserLogged!: boolean;
  permissionList : any = [];
  notifList : any = [];
  selected : any = [];
  zoneslist : any = [];
  id_compania! :  number;
  modulesList : any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<UsersEditComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.rolesList = environment.roles;
    this.id_user = this.data.userSel.id;

    this.isUserLogged = this.id_user == this.userS.getUser().id;

    this.id_compania = this.data.userSel.companies.length > 0 ? this.data.userSel.companies[0].id_compania : null;
    console.log(this.id_compania);
    this.createFormUser();
    this.setUserForm();
    console.log(this.data.userSel);
  }

  createFormUser() {
    this.formUser = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
      id_rol: ['', [Validators.required]],
      permissions: ['', []],
      modules: ['', []],
      notifications: ['', []],
      companies : ['', []],
      zones : ['', []]
    });
  }

  
  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.userS.hasPermissions(key, buho, client);
  }

  getPermissions(){
    if(this.formUser.value.id_rol > 1) {
      this.userS.getPermissionList(this.formUser.value.id_rol).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.permissionList = result.data;
            this.formUser.controls["permissions"].setValue(this.data.userSel.permissions.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      )
    }
    else this.permissionList = [];
  }

  getZones(){
    if(this.formUser.value.id_rol > 2 ) {
      //get companie
      this.userS.getZones(this.id_compania).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.zoneslist = result.data;
            this.formUser.controls["zones"].setValue(this.data.userSel.zones.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      )
    }
    else this.zoneslist = [];
  }

  getNotif(){
    if(this.formUser.value.id_rol > 1) {
      this.userS.getNotifList(this.formUser.value.id_rol).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.notifList = result.data;
            this.formUser.controls["notifications"].setValue(this.data.userSel.notifications.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      ) 
    }
    else this.notifList = [];
  }

  getPermbyModule(id_mod : number){
    return this.permissionList.filter((x:any)=>{ return x.id_modulo == id_mod })
  }

  getModules(){
    if(this.formUser.value.id_rol > 2 && this.id_compania) {
    let compania = 0;
    if(this.id_compania){
      compania = this.id_compania;
    }
      this.userS.getModules(compania).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.modulesList = result.data;
            this.formUser.controls["modules"].setValue(this.data.userSel.modules.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      );
    }else{
      this.userS.getModules(0).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.modulesList = result.data;
            this.formUser.controls["modules"].setValue(this.data.userSel.modules.map((elem: { id: any; }) => elem.id));
          }
        },
        (err:any) => {
          console.log(err);
        }
      );
    }
    console.log("mod");
  }

  setUserForm() {
    const { active, email, id, rol_name, ...userFormInfo } = this.data.userSel;
    this.formUser.setValue(userFormInfo);
    console.log("this form", this.formUser);
    this.getPermissions();
    this.getNotif();
    this.getZones();
    this.getModules();
  }

  ruSure() {
    if(this.formUser.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas editar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
   // this.formUser.value.id_compania = this.id_compania;
    this.userS.editUser(this.formUser.value, this.id_user).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Usuario guardado de manera correcta!'
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  openDialogPassword() {
    this.matDialog.open(UsersEditPasswordComponent, {
      data: { 
        id_user: this.id_user 
      },
      panelClass: 'dialogs-sm',
    });
  }


  toggleSelectAllPermissions() {
    let allPermissions = this.permissionList.map((p : any) => p.id);
    let currentPermissions = this.formUser.get('permissions')?.value || [];

    if (currentPermissions.length != 0) {
      this.formUser.get('permissions')?.setValue([]);
    } else {
      console.log("selecciona todos");
      this.formUser.get('permissions')?.setValue(allPermissions); 
    }
  }

  toggleSelectAllNotif() {
    let allNotif = this.notifList.map((p : any) => p.id);
    let currentNotif = this.formUser.get('notifications')?.value || [];

    if (currentNotif.length != 0) {
      this.formUser.get('notifications')?.setValue([]);
    } else {
      this.formUser.get('notifications')?.setValue(allNotif); 
    }
  }

  toggleSelectAllModules() {
    let allModules = this.modulesList.map((p : any) => p.id);
    let currentModules = this.formUser.get('modules')?.value || [];

    if (currentModules.length != 0) {
      this.formUser.get('modules')?.setValue([]);
    } else {
      this.formUser.get('modules')?.setValue(allModules); 
    }
  }

  toggleSelectAllZones() {
    let allZones = this.zoneslist.map((p : any) => p.id);
    let currentZones = this.formUser.get('zones')?.value || [];

    if (currentZones.length != 0) {
      this.formUser.get('zones')?.setValue([]);
    } else {
      this.formUser.get('zones')?.setValue(allZones); 
    }
  }

  isModuleFullySelected(id_mod: number): boolean {
    const modulePermissions = this.getPermbyModule(id_mod).map((p : any) => p.id);
    const currentPermissions = this.formUser.get('permissions')?.value || [];
    return modulePermissions.every((id : any) => currentPermissions.includes(id));
  }

  isModulePartiallySelected(id_mod: number): boolean {
    const modulePermissions = this.getPermbyModule(id_mod).map((p : any) => p.id);
    const currentPermissions = this.formUser.get('permissions')?.value || [];
    const anySelected = modulePermissions.some((id : any) =>
      currentPermissions.includes(id)
    );
    const allSelected = modulePermissions.every((id : any) =>
      currentPermissions.includes(id)
    );
    return anySelected && !allSelected;
  }

  toggleSelectAllByModule(id_mod: number, checked: boolean) {
    const modulePermissions = this.getPermbyModule(id_mod).map((p : any) => p.id);
    const currentPermissions = this.formUser.get('permissions')?.value || [];

    if (checked) {
      const newPermissions = [...currentPermissions, ...modulePermissions];
      this.formUser.get('permissions')?.setValue([...new Set(newPermissions)]); 
    } else {
      const newPermissions = currentPermissions.filter(
        (id: any) => !modulePermissions.includes(id)
      );
      this.formUser.get('permissions')?.setValue(newPermissions);
    }
  }
}

<div class="modal-header">
    <h4 class="modal-title"><strong>Estrategia - {{ data.strategy.name }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        
        <mat-divider></mat-divider>

        <div class="row mt-3 borderMdl">
            <div class="col-md-12 mb-3 mt-3">
                <div class="stepper-wrapper">
                    <div class="stepper-item-art">
                        <div class="step-primary bg-blue"><strong>1</strong></div>
                        <div class="step-name"><strong>Seleccionar SKU</strong></div>
                    </div>
                    <div class="stepper-item-art">
                        <div class="step-primary"  [ngClass]="mostrarSeleccionSku ? 'bg-gray2' : 'bg-blue' " ><strong>2</strong></div>
                        <div class="step-name"><strong>Artes</strong></div>
                    </div>
                </div>
            </div>
        </div>

        
            <div class="row borderMdl">
                <div class="col mb-0 mt-3">

                    <div *ngIf="mostrarSeleccionSku" class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>Producto</th>
                                    <th class="text-center">Ultimo uso</th>
                                    <th class="text-center" >
                                        <button mat-flat-button class="bg-info text-white d-flex align-items-center" (click)="openDialogArtNewProduct()" >
                                            <label style="cursor: pointer !important">Añadir SKU </label>
                                            <mat-icon class="ms-1">add</mat-icon>
                                        </button>
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let sku of skus">
                                    <td>{{sku.sku}}</td>
                                    <td>{{sku.nombre.length > 30 ? sku.nombre.slice(0,35) + "..." : sku.nombre}}</td>
                                    <td>{{sku.ultimo_uso | date: 'dd/MM/yyyy'}}</td>
                                    <td>
                                        <button mat-button color="primary" (click)="openDialogArtUploadFile(sku.sku,sku.idSku,sku.medida_x,sku.medida_y)">
                                            <mat-icon>upload_file</mat-icon>
                                        </button>
                                        <button mat-button color="warn"  (click)="deleteStrategySku(sku.idStrategySku)" class="ms-2">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="skus.length == 0" >
                                    <td colspan="4" class="text-center">
                                        No se han añadido skus
                                    </td>
                                </tr>
                            </tbody>
                                
                        </table>
                    </div>

                    <div *ngIf="!mostrarSeleccionSku">
                        <div *ngFor="let arte of artes" class="card mb-3">
                            <div class="card-body">
                                <div class="row col-12">
                                    <div class="col-md-6">
                                        <label><strong>SKU: </strong>{{arte.sku}}</label>
                                    </div>
                                    <div class="col-md-6 d-flex align-items-center">
                                        <div *ngIf="arte.bitValido == 1">
                                            <label [ngClass]="'text-success d-flex align-items-center ms-2'">
                                                <mat-icon>check_circle</mat-icon>
                                                <strong class="ms-1">Válido</strong>
                                            </label>
                                        </div>
                                        <div *ngIf="arte.bitValido == 0">
                                            <label [ngClass]="'text-danger d-flex align-items-center ms-2'">
                                                <mat-icon>error</mat-icon>
                                                <strong class="ms-1">Error</strong>
                                            </label>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                                    <div class="row mt-3">
                                        <div class="col-md-6">
                                            <label><strong>{{ arte.nombre_archivo }}</strong></label>
                                            <div class="pdf-container"><iframe id="pdf" name="pdf" width="95%" height="250px" [src]="getSourceURL(arte.fileUrlArte)"></iframe></div>
                                        </div>
                                        <div *ngIf="arte.file_respuesta_report != null" [ngClass]="arte.bitValido === 0 ? 'col-md-6' : 'col-md-3'">
                                            <label><strong>Reporte</strong></label>
                                            <div class="pdf-container"><iframe id="pdf" name="pdf" width="95%" height="250px" [src]="getSourceURL(arte.fileUrlReport)"></iframe></div>
                                            <div class="text-end">
                                                <a mat-flat-button class="mt-2 bg-info text-white" matTooltip="Ver reporte" matTooltipPosition="below" href="{{arte.file_respuesta_report}}" target="_blank"> 
                                                    <mat-icon>visibility</mat-icon>   
                                                </a>
                                            </div>
                                        </div>
                                        <div *ngIf="arte.bitValido == 1" [ngClass]="arte.file_respuesta_report != null ? 'col-md-3' : 'col-md-6'">
                                            <div>
                                                <label><strong>Archivo Validado</strong></label>
                                                <div class="pdf-container"><iframe id="pdf" name="pdf" width="95%" height="250px" [src]="getSourceURL(arte.fileUrl)"></iframe></div>
                                                <div class="text-end">
                                                    <a mat-flat-button class="mt-2 bg-info text-white" matTooltip="Ver archivo" matTooltipPosition="below" href="{{arte.file_respuesta}}" target="_blank"> 
                                                        <mat-icon>visibility</mat-icon>   
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                
                            </div>
                        </div>
                    </div>



                </div>
            </div>
    </div>
    <div class="modal-footer mt-3 ms-5 me-5" [ngClass]="{'d-flex justify-content-between': !mostrarSeleccionSku}">
        <button *ngIf="!mostrarSeleccionSku" mat-flat-button class="bg-secondary text-white fw-bold d-flex align-items-center" [style.cursor]="'pointer'" (click)="switchDiv()">
            <mat-icon class="me-1">arrow_back</mat-icon>
            <label [style.cursor]="'pointer'">Atras</label>
        </button>
        <button *ngIf="contFiles > 0 && mostrarSeleccionSku" mat-flat-button class="bg-primary text-white fw-bold d-flex align-items-center" [disabled]="!mostrarSeleccionSku" [style.cursor]="!mostrarSeleccionSku ? 'not-allowed' : 'pointer'" (click)="switchDiv()">
            <label [style.cursor]="!mostrarSeleccionSku ? 'not-allowed' : 'pointer'">Siguiente</label>
            <mat-icon class="ms-1">arrow_forward</mat-icon>
        </button>
    </div>

</mat-dialog-content>
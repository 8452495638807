import { Component, Inject, OnInit } from '@angular/core';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { StoreService } from 'src/app/services/store.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-attributes-reorder',
  templateUrl: './attributes-reorder.component.html',
  styleUrls: ['./attributes-reorder.component.scss'],
})
export class AttributesRedorderComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data : any, private StoreService : StoreService,
  private main : MainService,   private dialogRef: MatDialogRef<AttributesRedorderComponent>, ) {

    this.atributos = this.data.atributos;
   }

   atributos : any[];

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.atributos, event.previousIndex, event.currentIndex);
  }

  saveReOrder(){
    console.log(this.atributos);
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el orden de los registros?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.save();
      }
    });
  }

  save() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    this.StoreService.updateOrder(this.atributos).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Orden aplicado correctamente'
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar los registros, por favor vuelva a intentarlo.'
        });
      }
    );
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { GroupsEditComponent } from '../groups-edit/groups-edit.component';

interface MaterialModel {
  id: number;
  art: string;
  groups: number;
  responsable: string;
  registro: string;
}

@Component({
  selector: 'app-materials-import-distribution',
  templateUrl: './materials-import-distribution.component.html',
  styleUrls: ['./materials-import-distribution.component.scss']
})
export class MaterialsImportDistributionComponent implements OnInit {

  isLoading = false;
  groupForm!: FormGroup;
  displayedColumns: string[] = ['art', 'groups', 'responsable','registro'];
  dataSource!: MatTableDataSource<MaterialModel>;
  panelOpenState = false;
  demoData: MaterialModel[] = [
    {
      id: 1,
      art: 'Estampa productos de belleza',
      groups: 0,
      responsable: 'Luis Miguel',
      registro: '28/12/2023',
    },
    {
      id: 2,
      art: 'Diseño de empaques ecológicos',
      groups: 1,
      responsable: 'Ana García',
      registro: '02/01/2024',
    },
    {
      id: 3,
      art: 'Desarrollo de fragancias naturales',
      groups: 2,
      responsable: 'Javier Sánchez',
      registro: '05/01/2024',
    },
    {
      id: 4,
      art: 'Investigación de nuevos materiales',
      groups: 0,
      responsable: 'María Rodríguez',
      registro: '10/01/2024',
    },
    {
      id: 5,
      art: 'Diseño de catálogo de productos',
      groups: 1,
      responsable: 'Carlos Fernández',
      registro: '15/01/2024',
    },
    {
      id: 6,
      art: 'Desarrollo de envases biodegradables',
      groups: 2,
      responsable: 'Sofía López',
      registro: '20/01/2024',
    },
    {
      id: 7,
      art: 'Producción de muestras de productos',
      groups: 0,
      responsable: 'Juan Martínez',
      registro: '25/01/2024',
    },
    {
      id: 8,
      art: 'Diseño de etiquetas sostenibles',
      groups: 1,
      responsable: 'Laura Pérez',
      registro: '30/01/2024',
    },
    {
      id: 9,
      art: 'Desarrollo de colores exclusivos',
      groups: 2,
      responsable: 'Roberto Castro',
      registro: '05/02/2024',
    },
    {
      id: 10,
      art: 'Investigación de tendencias de mercado',
      groups: 0,
      responsable: 'Marta Gómez',
      registro: '10/02/2024',
    },
  ];  

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
   

  constructor(
    private matDialog: MatDialog,

  ) { 
    this.dataSource = new MatTableDataSource<MaterialModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;   
  }

  ngOnInit(): void {
  }

  onSubmit(){
    console.log('test')
  }

  importDistribution() {
    this.dataSource = new MatTableDataSource<MaterialModel>(this.demoData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;  
  }

  openEditGroupModal(name:string) {
    this.matDialog.open(GroupsEditComponent, { 
      data: 
      {
         name: name, 
         isDisabled: true 
      },
      panelClass: 'dialogs-main'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = false;
          // this.isLoading = true;     TODO:borrar esto
      }
    );
  }

}

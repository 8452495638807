import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistributionArtFileComponent } from '../distribution-art-file/distribution-art-file.component';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-art',
  templateUrl: './distribution-art.component.html',
  styleUrls: ['./distribution-art.component.scss']
})
export class DistributionArtComponent implements OnInit {

  strategy: any;
  artFiles: any;
  isLoading = true;
  canUploadArt: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<DistributionArtComponent>,
  ) {
    this.strategy = this.data.strategy;
    this.loadData();
    this.canUploadArt = !this.data.islocked && this.campaignService.hasPermissions("upl_art",false,true);
   }

  ngOnInit(): void {
    
  }

  loadData(){
    this.campaignService.getStrategyArts(this.strategy.id).subscribe(
      (result:any) => {
        this.isLoading = false;
       this.artFiles = result.data;
      },
      (err:any) => {
        this.isLoading = false;
        if(err.error.message != "No valid art files were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  openDialogArtUploadFile() {
    this.matDialog.open(DistributionArtFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        strategy : this.data.strategy
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.dialogRef.close({ reload: true });
        }
      }
    );
  }

}

<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Diagramar</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body" style="min-height: 580px;">       
        <mat-divider></mat-divider>
        <div>
            <mat-horizontal-stepper [linear]="true" #stepper>
                <!-- Paso 1 -->
                <mat-step [stepControl]="firstFormGroup">
                  <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Paso 1 - Selección POP</ng-template>
                    <!--<mat-form-field>
                      <mat-label>Campo 1</mat-label>
                      <input matInput formControlName="medio" required>
                      <mat-error *ngIf="firstFormGroup.get('medio')?.hasError('required')">
                        Este campo es obligatorio
                      </mat-error>
                    </mat-form-field>-->
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="row">
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Medio</mat-label>
                                        <mat-select matInput formControlName="medio" required name="medio">
                                          <mat-option *ngFor="let m of medios" [value]="m">{{ m }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="firstFormGroup.get('medio')?.hasError('required')">
                                           El medio es requerido.
                                        </mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Elemento POP</mat-label>
                                        <mat-select [disabled]="firstFormGroup.get('medio')?.value == ''" matInput formControlName="elemento_pop" required name="elemento_pop">
                                          <mat-option *ngFor="let m of elementos" [value]="m">{{ m }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="firstFormGroup.get('elemento_pop')?.hasError('required')">
                                           El elemento POP es requerido.
                                        </mat-error>
                                    </mat-form-field>
    
                                    <mat-form-field appearance="outline" class="form-field">
                                        <mat-label>Posición</mat-label>
                                        <input [disabled]="firstFormGroup.get('elemento_pop')?.value == ''"  matInput formControlName="position" required>
                                        <mat-error *ngIf="firstFormGroup.get('elemento_pop')?.hasError('required')">
                                          Este campo es obligatorio
                                        </mat-error>
                                    </mat-form-field>
                            </div>
                        </div>
                        <div class="col-sm-9"  *ngIf="firstFormGroup.get('medio')?.value != ''">
                                                          
                            <div class="selected-codes">
                                <span><b>Promociones seleccionadas:</b> <p *ngIf="selectedPromotions.length == 0">Ninguno</p> <p *ngIf="selectedPromotions.length > 0">{{ selectedPromotions.join(', ') }}</p></span>
                              </div>
                              <div class="promo-container mat-elevation-z0 table-responsive text-nowrap" style="max-height: 300px; overflow-y: auto; padding: 10px; border: 1px solid #ccc;">
                                <table mat-table [dataSource]="promotionFile" class="mat-elevation-z8" style="width: 100%;">
                                  
                                  <!-- Checkbox para seleccionar todos -->
                                  <ng-container matColumnDef="select">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef style="min-width: 80px; margin-left: 14px;"> 
                                      <input type="checkbox" (change)="selectAll($event)">
                                    </th>
                                    <td  class="text-center" mat-cell *matCellDef="let promo" style="min-width: 80px; margin-left: 14px;">
                                      <input type="checkbox" (change)="toggleSelection(promo['CODIGO PRINCIPAL'])" [checked]="selectedPromotions.includes(promo['CODIGO PRINCIPAL'])">
                                    </td>
                                  </ng-container>
                              
                                  
                                  <ng-container *ngFor="let column of displayedColumns">
                                    <ng-container matColumnDef="{{column}}">
                                      <th mat-header-cell *matHeaderCellDef  style="min-width: 170px; margin-left: 14px;"> {{ column }} </th>
                                      <td mat-cell *matCellDef="let promo"  style="min-width: 170px; margin-left: 14px;"> {{ promo[column] }} </td>
                                    </ng-container>
                                  </ng-container>
                              
                                  <tr mat-header-row *matHeaderRowDef="allColumns"></tr>
                                  <tr mat-row *matRowDef="let row; columns: allColumns;"></tr>
                                </table>
                              </div>
                        </div>
    
                    </div>

                    <div>
                      <button mat-raised-button matStepperNext color="primary" [disabled]="!firstFormGroup.valid">Siguiente</button>
                    </div>
                  </form>
                </mat-step>
              
                <!-- Paso 2 -->
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Paso 2 - Inventarios y Sucursales</ng-template>
                    <!--<mat-form-field>
                      <mat-label>Campo 2</mat-label>
                      <input matInput formControlName="secondCtrl" required>
                      <mat-error *ngIf="secondFormGroup.get('secondCtrl')?.hasError('required')">
                        Este campo es obligatorio
                      </mat-error>
                    </mat-form-field>-->

                    <div class="row">
                        <div class="col-sm-3">
                            <div class="row  text-center">
                                <button (click)="goToConfig(1)" mat-flat-button color="primary" class="same-height" style="width: 190px;">
                                    <mat-icon>edit</mat-icon>  Configuración VoBos
                                 </button>
                            </div>
                            <br>
                            <table class="table table-hover">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col">Fases Configuradas</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr >
                                    <td>Fase 1 Artes <span (click)="openVobosDiagramConfigDialog()" class="badge badge-success"  data-toggle="tooltip" data-placement="right" title="4">4</span></td>
                                  </tr>
                                  <tr>
                                    <td>Fase 2 Artes <span class="badge badge-success" (click)="openVobosDiagramConfigDialog()">2</span></td>
                                  </tr>
                                  <tr>
                                    <td>Fase 3 Artes <span class="badge badge-success" (click)="openVobosDiagramConfigDialog()">1</span></td>
                                  </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="col-sm-9"  *ngIf="firstFormGroup.get('medio')?.value != ''">
                                                          
                            <div class="selected-codes">
                                <span><b>Sucursales seleccionadas:</b> <p *ngIf="selectedStores.length == 0">Ninguna</p> <p *ngIf="selectedStores.length > 0">{{ selectedStores.join(', ') }}</p></span>
                              </div>
                              <div class="promo-container mat-elevation-z0 table-responsive text-nowrap" style="max-height: 260px; overflow-y: auto; padding: 10px; border: 1px solid #ccc;">
                                <table mat-table [dataSource]="storeFile" class="mat-elevation-z8" style="width: 100%;">
                                  
                                  <!-- Checkbox para seleccionar todos -->
                                  <ng-container matColumnDef="select">
                                    <th class="text-center" mat-header-cell *matHeaderCellDef style="min-width: 80px; margin-left: 14px;"> 
                                      <input type="checkbox" (change)="selectAllStore($event)">
                                    </th>
                                    <td  class="text-center" mat-cell *matCellDef="let store" style="min-width: 80px; margin-left: 14px;">
                                      <input type="checkbox" (change)="toggleSelectionStore(store['Nombre'])" [checked]="selectedStores.includes(store['Nombre'])">
                                    </td>
                                  </ng-container>
                              
                              
                                  <ng-container *ngFor="let column of displayedColumnsStore">
                                    <ng-container matColumnDef="{{column}}">
                                      <th mat-header-cell *matHeaderCellDef  style="min-width: 170px; margin-left: 14px;"> {{ column }} </th>
                                      <td mat-cell *matCellDef="let store"  style="min-width: 170px; margin-left: 14px;"> {{ store[column] }} </td>
                                    </ng-container>
                                  </ng-container>
                              
                                  <tr mat-header-row *matHeaderRowDef="allColumnsStore"></tr>
                                  <tr mat-row *matRowDef="let row; columns: allColumnsStore;"></tr>
                                </table>
                              </div>

                              <table class="table">
                                <thead>
                                  <tr class="text-center">
                                    <th scope="col">Inventario Inicial</th>
                                    <th scope="col">Inventario Disponible</th>
                                    <th scope="col">Inventario Asignado</th>
                                    <th scope="col">Inventario Selecionado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="text-center">
                                    <td>153</td>
                                    <td>153</td>
                                    <td>0</td>
                                    <td>0</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
    
                    </div>


                    <div>
                      <button mat-button matStepperPrevious>Anterior</button>
                      <button mat-raised-button color="primary" ><mat-icon>save</mat-icon> Finalizar y guardar</button>

                    </div>
                  </form>
                </mat-step>
              </mat-horizontal-stepper>
        </div>
    </div>
</mat-dialog-content>
<div class="content-login d-flex">
    <div class="content-login image-login col d-none d-sm-block"></div>
    <div class="login d-flex p-5">
        <div class="mx-auto w-100">
            <div class="bg-white rounded mt-5">
                <div class="app-brand justify-content-center2">
                    <a href="index.html" class="app-brand-link gap-2">
                        <span class="app-brand-logo demo">
                            <img src="assets/img/Buho_Nest-BK.png" alt="">
                        </span>
                    </a>
                </div>
                <h4 class="my-4">Te damos la bienvenida a Buho Nest 👋</h4>
                <p class="mb-4">Por favor inicia sesion en tu cuenta</p>
                <form (ngSubmit)="login(f)" #f="ngForm" autocomplete="off">
                    <div>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Usuario</mat-label>
                            <input matInput placeholder="Usuario" [(ngModel)]="user.email" name="user" required>
                            <mat-icon matSuffix>account_circle</mat-icon>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" [(ngModel)]="user.pass" name="pass" required>
                            <mat-icon matSuffix (click)="hide = !hide" role="button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="rememberme" name="rememberme" color="primary">
                            Recordarme
                        </mat-checkbox>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" class="w-100 mt-2" type="submit">Iniciar sesión</button>
                    </div>
                </form>
                <div class="mt-2 text-center">
                    <a role="button" (click)="openDialog()">Olvidé mi contraseña</a>
                </div>
            </div>
        </div>
    </div>
</div>
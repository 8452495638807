<div class="modal-header">
    <h4 class="modal-title"><mat-icon color="accent" class="align-middle">store</mat-icon> Valores de <strong>{{atribute.name}}</strong> por sucursal</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="row g-2">
            <div class="col mb-0">
                <div class="col-md-12">
                    <div class="overflow-auto w-100">
                        <div class="float-end">                            
                            <!--<button mat-flat-button color="primary" class="me-3" (click)="openDialogValuesUpload()">Cargar valores<mat-icon>upload</mat-icon></button>-->
                            <button mat-flat-button color="accent" (click)="openDialogValuesSetAll(true)">Asignar valor masivo<mat-icon>spellcheck</mat-icon></button>
                        </div>
                        <mat-form-field>
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej.Botadero" #input>
                        </mat-form-field>
                    </div>
                
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="store">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                <td mat-cell *matCellDef="let row"> <strong>{{row.Nombre}}</strong> </td>
                            </ng-container>
                
                            <ng-container matColumnDef="zone">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Plaza </th>
                                <td mat-cell *matCellDef="let row"> {{row.Plaza}} </td>
                            </ng-container>

                            <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                                <td mat-cell *matCellDef="let row" class="h5"> <span class="badge" [ngClass]="row[atribute.name]== null || row[atribute.name]== ''? 'bg-primary' :'bg-secondary'" (click)="openDialogValuesSetAll(false, row.id, row.Nombre)" role="button"> <ng-container *ngIf="row[atribute.name]== null || row[atribute.name]== ''"> + </ng-container>{{row[atribute.name]}}</span> </td>
                            </ng-container>
                
                            <!-- <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center">
                                    <button mat-icon-button matTooltip="Valores de Sucursales" [matTooltipPosition]="'above'" color="accent" (click)="openDialogAttibutesStoreList()">
                                        <mat-icon>store</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent" (click)="openDialogAttibutesAdd()">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container> -->
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                            </tr>
                        </table>
                        
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-header">
    <h4 class="modal-title">{{data.name}}</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div *ngFor="let item of groups">
            <div class="d-flex">
                <div class="col flex-fill me-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Segmento</mat-label>
                        <mat-select disabled={{data.isDisabled}}>
                            <mat-option *ngFor="let item of groups" value={{item.name}}>{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col flex-fill me-3">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Atributo</mat-label>
                        <mat-select disabled={{data.isDisabled}}>
                            <mat-option  *ngFor="let att of attributes" value="Caja">{{att.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col flex-fill">
                    <mat-form-field class="w-75 my-form-field" appearance="outline">
                        <mat-label>Valor</mat-label>
                        <input disabled={{data.isDisabled}} matInput type="number" placeholder="Valor" min="0">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <button [hidden]="data.isDisabled" mat-button color="primary"><mat-icon>add box</mat-icon>Campo de segmento</button>
    </div>
</mat-dialog-content>
<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Nueva Sucursal</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">

    <h6>Llene los datos de la nueva sucursal</h6>
    <form autocomplete="off" [formGroup]="formC" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="nombre">
                    <mat-icon matSuffix>store</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>FarmID</mat-label>
                    <input matInput formControlName="id_external">
                    <mat-icon matSuffix>store</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <!--<div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Plaza</mat-label>
                    <mat-select formControlName="id_zona">
                        <mat-option *ngFor="let zone of zoneslist" [value]="zone.id">{{zone.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>-->
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Plaza</mat-label>
                    <input type="text" matInput [matAutocomplete]="zonaAutoComplete" (input)="filterZones($event)">
                    <mat-autocomplete #zonaAutoComplete="matAutocomplete">
                        <mat-option *ngFor="let zone of zonesAutoComplete" [value]="zone.nombre" (onSelectionChange)="onZoneSelectionChange(zone.nombre, zone.id)">
                            {{zone.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                    <div *ngIf="hasPermissions('suc_sup',null,true)" class="float-end" style="height: 0px; margin-top: -30px;">
                        <button type="button" (click)="saveZona()" mat-flat-button color="primary" matTooltip ="Dar de alta nueva Plaza">+</button>
                    </div>
                </mat-form-field>
               
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>
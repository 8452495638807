<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{ data.strategy.name }}: <strong>Folio #{{ data.strategy.invoice }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="overflow-auto w-100">
            <mat-form-field>
                <mat-label>
                    <mat-icon>search</mat-icon> Buscar Material</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Unibanner" #input>
            </mat-form-field>
        </div>
        <div class="mat-elevation-z8 table-responsive">
            <table mat-table [dataSource]="dataSource" matSort class="w-100">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc"> ID </th>
                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    <td mat-footer-cell *matFooterCellDef><strong>Materiales Totales</strong></td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                    <td mat-cell *matCellDef="let row"> {{row.cant}} </td>
                    <td mat-footer-cell *matFooterCellDef> <strong>{{ getTotal() }}</strong> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                </tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>

            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>
    </div>
</mat-dialog-content>
import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MainService } from 'src/app/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProjectsService } from 'src/app/services/projects.service';
import { ProjectsVoboAddComponent } from '../../shared/dialogs/projects-vobo-add/projects-vobo-add.component';
import { MatDialog } from '@angular/material/dialog';
import { scheduled } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project-config',
  templateUrl: './project-config.component.html',
  styleUrls: ['./project-config.component.scss']
})
export class ProjectConfigComponent {

  constructor(
    private matDialog: MatDialog,
    private main : MainService,
    private _params: ActivatedRoute,
    private _titleService: Title,
    private _router : Router,
    private projectService : ProjectsService
  ) { }

  id_campana_proyecto = 0;

  ngOnInit(): void {
    this._params.params.subscribe(params => {
      if(params.id){
        this.main.HideLoading();
        this.id_campana_proyecto = params.id;
        this.loadProjectData();
      }else{
        this._router.navigateByUrl('/admin/dashboard');
      } 
    });
  }

  project : any;
  haschanges : boolean = false;

  diagramacionItems = [
    { id: 1,  responsable: 'Juan Pérez', scheluded_time_days: 4, deleted : 0},
    { id: 2, responsable: 'Ana Gómez',  scheluded_time_days: 4, deleted : 0 },
    { id: 3,  responsable: 'Carlos Ruiz',  scheluded_time_days: 4, deleted : 0  },
  ];

  artesItems = [
    { id: 1,  responsable: 'Juan Pérez', scheluded_time_days: 4, deleted : 0 },
    { id: 2, responsable: 'Ana Gómez',  scheluded_time_days: 4, deleted : 0 },
    { id: 3,  responsable: 'Carlos Ruiz',  scheluded_time_days: 4, deleted : 0 }
  ];

  dataPhases : any;

  loadProjectData(){
    console.log("Project data", this._params.snapshot.data.data);
    this.main.HideLoading();
    if( this._params.snapshot.data.data ){
       if(this._params.snapshot.data.data.message = 'Success'){
        console.log(this._params.snapshot.data.data.data);
         this.project = this._params.snapshot.data.data.data.project[0];

         this.dataPhases = this._params.snapshot.data.data.data.data;
        // this.diagramacionItems = this._params.snapshot.data.data.data.fases_diagramacion;
        // this.artesItems = this._params.snapshot.data.data.data.fases_artes;
       }
    }else{
      this._router.navigateByUrl('/admin/dashboard');
    }
   }

  loadDiagramacionItems(){

    return this.diagramacionItems.filter((x:any)=> x.deleted == 0 );
  }

  loadArtesItems(){
    return this.artesItems.filter((x:any)=> x.deleted == 0 );
  }

  drop(list : any, event: CdkDragDrop<string[]>) {
    this.haschanges = true;
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  addItem(lista : any){
    this.haschanges = true;
    let newVobo = {id: 0, responsable: '', scheluded_time_days: 0, id_campana_proyecto: this.id_campana_proyecto, deleted : 0, users: [], vobos: []};
    lista.push(newVobo)
    this.openAddEditVobo(newVobo);
  }

  addPhase(){
    Swal.fire({
      title: 'Nueva fase',
      input: 'text',  
      inputPlaceholder: 'Nombre de la fase',
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`,
      inputValidator: (value :string) => {
          if (!value) {
              return 'El nombre es necesario'
          }else{
            return "";
          }
      }
  }).then((result) => {
      if (result.isConfirmed) {
         this.dataPhases.push({id: 0, nombre : result.value.trim(), id_campana_proyecto : this.id_campana_proyecto, vobos: [], deleted : 0});
         this.haschanges = true;
      }
  });
  }


  removeItem(item : any){
    this.haschanges = true;
    item.deleted = 1;
  }

  openAddEditVobo(vobo:any) {
    this.matDialog.open(ProjectsVoboAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        vobo : vobo
      }
    }).afterClosed().subscribe(
      resp => {
      
        
      }
    );
  }

  ruSure() {
    Swal.fire({
      title: '¿Esta seguro que desea guardar la configuración?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Guardando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        this.projectService.saveProjectVobos({phases: this.dataPhases}).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Guardado',
                text: 'Datos guardados correctamente'          
              }).then(() => {            
               
              });
            }
          },
          (err:any) => {

              console.log(err);
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          
        );
      }
    });
  }


  addDiagramacion(){
    this.haschanges = true;
    let newVobo = {id: 0, responsable: '', scheluded_time_days: 0, id_campana_proyecto: this.id_campana_proyecto, deleted : 0};
    this.diagramacionItems.push(newVobo)
    this.openAddEditVobo(newVobo);
  }

  addArtes(){
    this.haschanges = true;
    let newVobo = {id: 0, responsable: '', scheluded_time_days: 0, id_campana_proyecto: this.id_campana_proyecto, deleted : 0};
    this.artesItems.push(newVobo);
    this.openAddEditVobo(newVobo);
  }
}
<div class="content-wrapped">
  <div class="container-xxl flex-grow-1 main-title-container">
      <h4 class="fw-bold pt-3 text-primary">
          <mat-icon class="align-middle head-icon">table_border</mat-icon>
      </h4>
      <div class="title-container">
          <small>OCTUBRE 2024 <span class="arrow">></span> POP NACIONAL OCTUBRE 2024 </small>
          <h2>Diagramación</h2>
      </div>
  </div>
  <div class="row">
      <div class="col-md-12">
          <div class="card mb-4">
              <div class="heading-container">
                  <div class="button-container">
                      <button mat-raised-button class="nest-button">
                          <mat-icon>add_circle</mat-icon><span class="nb-text">Nueva Diagramación</span>
                      </button>
                      <button (click)="openInventoryDialog()" mat-raised-button class="nest-button">
                          <mat-icon>inboxes</mat-icon><span class="nb-text">Inventario</span></button>
                      <button mat-raised-button class="nest-button" (click)="openDistributionDialog()">
                          <mat-icon>table</mat-icon><span class="nb-text">Distribución</span>
                      </button>
                  </div>
              </div>
              <div class="card-body">
                  <div class="my-3">
                      <div class="row">
                      </div>
                      <div class="card-body">
                        <div class="table-scroll"> 
                          <div class="table-container">
                              <table class="table table-bordered">
                                  <thead>
                                      <tr>
                                          <th>#</th>
                                          <th colspan="3" class="text-center">Diagramación</th>
                                          <th colspan="2" class="text-center">Inventario</th>
                                          <th *ngFor="let c of projectPhases" [attr.colspan]="c.vobos.length + 1" class="text-center">{{ c.name }}</th>
                                      </tr>
                                      <tr>
                                          <th></th>
                                          <th></th>
                                          <th class="text-center">Medio</th>
                                          <th class="text-center">POP</th>
                                          <th class="text-center" style="width: 1%; white-space: nowrap;">Vendidos</th>
                                          <th class="text-center" style="width: 1%; white-space: nowrap;">Sucursales</th>
                                          <ng-container *ngFor="let c of projectPhases">
                                              <th class="text-center">{{c.artes_fecha}}</th>
                                              <th *ngFor="let a of c.vobos " class="text-center">{{ a.fecha }}</th>
                                          </ng-container>
                                      </tr>
                                  </thead>
                                  <tbody class="table-border-bottom-0">
                                      <ng-container *ngFor="let x of projectDiagramations, let i = index">
                                          <tr>
                                              <td style="width: 10px;">{{ x.id }}</td>
                                              <td  class="min-table text-center">
                                                  <button mat-raised-button class="nest-button" (click)="openEditProjectDiagramDialog()">
                                                      <mat-icon>edit</mat-icon><span class="nb-text">Configurar</span>
                                                  </button>
                                              </td>
                                              <td style="width: 15px;">
                                                  <span style="font-size: 12px;">DANGLER</span>
                                              </td>
                                              <td style="width: 15px;">
                                                  <span style="font-size: 12px; white-space: nowrap;">VERSION 1</span>
                                              </td>
                                              <td  class="min-table text-center" style="width: 1%; white-space: nowrap;">{{x.sold}}</td>
                                              <td class="min-table text-center" style="width: 1%; white-space: nowrap;">{{x.stores}}</td>

                                              <ng-container *ngFor="let c of x.phases">
                                                <td class="tex-center no-border">
                                                  <div (click)="openUploadArtFIleDialog()" data-toggle="tooltip" data-placement="top" title="Artes" class="text-center badge-google-like" [ngClass]="c.art_upload? 'success-bdgl' : 'warning-bdgl'">
                                                    Art
                                                  </div>
                                                </td>
                                                <td *ngFor="let vobo of c.vobosList, let i = index" class="text-center" [ngClass]="(i+1) == c.vobosList.length? 'no-border-last' : 'no-border'">
                                                    <div (click)="openVobosDiagramDialog()" data-toggle="tooltip" data-placement="top" title="{{vobo.nombre}}" class="text-center badge-google-like" [ngClass]="vobo.approved? 'success-bdgl' : 'warning-bdgl'">
                                                      {{ onlyPick3Lett(vobo.nombre) }}
                                                    </div>
                                                </td>
                                              </ng-container>
                                              <!--<ng-container *ngFor="let c of x.phases">
                                                  <td [attr.colspan]="c.vobosList.length" class="text-center">
                                                    <span class="ms-4 badge bg-label-success cursor-pointer" style="color:black !important;" (click)="openUploadArtFIleDialog()">
                                                     Subir Artes
                                                    </span>
                                                    <span style="white-space: nowrap;"> - 20/10/2024</span>
                                                  </td>
                                              </ng-container>-->
                                          </tr>
                                          <!--<tr>
                                              <ng-container *ngFor="let c of x.phases">
                                                  <td *ngFor="let vobo of c.vobosList, let i = index" class="text-center" [ngClass]="(i+1) == c.vobosList.length? 'no-border-last' : 'no-border'">
                                                      <div (click)="openVobosDiagramDialog()" data-toggle="tooltip" data-placement="top" title="{{vobo.nombre}}" class="text-center badge-google-like" [ngClass]="vobo.approved? 'success-bdgl' : 'warning-bdgl'">
                                                        {{ onlyPick3Lett(vobo.nombre) }}
                                                      </div>
                                                  </td>
                                              </ng-container>
                                          </tr>-->
                                      </ng-container>
                                  </tbody>
                              </table>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
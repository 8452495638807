import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-campaign-comments-add',
  templateUrl: './projects-campaign-comments-add.component.html',
  styleUrls: ['./projects-campaign-comments-add.component.scss']
})
export class ProjectsCampaignCommentsAddComponent implements OnInit {


  campaign : any;
  phase : any;
  dataSent : any;
  commentary : string = "";

  phases_catalog = [{phase_id : 1, name : "Diagrama"},
  {phase_id : 2, name : "Artes"},
  {phase_id : 3, name : "Artes 2"},
  {phase_id : 4, name : "Artes 3"},
  {phase_id : 5, name : "Distribución"}];

camp_rol_catalog = [{camp_rol : 1 , name : "Marketing"},
    {camp_rol : 2, name : "Promociones"},
    {camp_rol : 3, name : "Comercial"},
    {camp_rol : 4, name : "Legal"},
    {camp_rol : 5, name : "Comercialización"},
    {camp_rol : 6, name : "Proveedores"}]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectsService : ProjectsService,
    private dialogRef: MatDialogRef<ProjectsCampaignCommentsAddComponent>,
    private main : MainService,
    private userS: UsersService
  ) { 
     this.dataSent = this.data;

     console.log(this.dataSent);
  }

  ngOnInit(): void {
    
  }

  saveComment() {
    console.log("d", this.commentary)
    if(this.commentary != ""){
      Swal.fire({
        title: '¿Desea cambiar el status de VoBo. a no Autorizado de la fase '+ this.phases_catalog.filter(x=>{ return x.phase_id == this.dataSent.phase_id})[0].name + 
        ' en ' + this.camp_rol_catalog.filter(x=>{ return x.camp_rol == this.dataSent.rol_id})[0].name  +'?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      })
        .then((result) => {
          if (result.isConfirmed) {
              this.projectsService.setPhase(this.dataSent.phase_id, this.dataSent.rol_id, 3, this.dataSent.camp_id , this.commentary).subscribe((x:any)=>{
                if(x.message == 'Success'){
                  console.log("changed");
                  Swal.fire({
                    icon: 'success',
                    title: 'Estatus actualizado',
                    text: 'Estatus actualizado exitosamente'          
                  });
                   this.dialogRef.close();
                }
              })
          }
        });

    }
  }

}

export class UserLoginModel {
    email!: string;
    pass!: string;
}

export class UserCatalogs {
    name!: string;
    id!: number;
}

export class UserModel {
    id!: number;
    id_rol!: number;
    role!: string;
    email!: string;
    name!: string;
    tel!: string;
    active!: number;
    exp!: number;
    permissions!: any;
    notifications!: any;
    companies!: any;
    zones! : any;
    modules! : any;
    ch_pass!: number;
    id_provider_member! : number;
}

export class UserSaveModel {
    id_user!: number;
    id_rol!: number;    
    id_serv!: number;    
    email!: string;
    pass!: string;
    passConfirm!: string;    
    name!: string;
    active!: number;
    ch_pass!: number;
}
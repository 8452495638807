import { Component, Inject, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { DistributionModel } from '../../../../models/distribution.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DistributionUploadFileComponent } from '../distribution-upload-file/distribution-upload-file.component';
import { CampaignModel } from 'src/app/models/campaign.model';

@Component({
  selector: 'app-distribution-upload',
  templateUrl: './distribution-upload.component.html',
  styleUrls: ['./distribution-upload.component.scss']
})
export class DistributionUploadComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data : any,
      private campaignService : CampaignsService,
      private main : MainService,
      private dialogRef: MatDialogRef<DistributionUploadComponent>,
      private matDialog: MatDialog
    ) 
    { 
      this.campaign = this.data.campaign;
      this.isUnlocked = this.data.isUnlocked;
      this.loadData();
    }
  campaign : any;
  isUnlocked : boolean = false;
  isLoading = true;
  canUploadDis: boolean = false;
  ngOnInit(): void {
    this.canUploadDis = this.campaignService.hasPermissions("upl_dis",true,true);
  }

  distributions : CampaignModel | any;

  loadData(){
    this.campaignService.loadDist(this.campaign.id).subscribe(
      (result:any) => {
        this.isLoading = false;
       this.distributions = result.data;
        console.log({ distribution: this.distributions});
      },
      (err:any) => {
        this.isLoading = false;
        console.log(err);
        if(err.error.message != "No valid distributions were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  openDialogUploadDistFile() {
    if(this.isUnlocked){
      this.matDialog.open(DistributionUploadFileComponent, {
        panelClass: ['dialogs-main'],
        data: {
          campaign : this.data.campaign,
        }
      }).afterClosed().subscribe(
        resp => {
          if(resp?.reload) {
            this.dialogRef.close({ reload: true });
          }
        }
      ); 
    }
  }

}

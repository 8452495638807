import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersEditPasswordFirsttimeComponent } from '../components/shared/dialogs/users-edit-password-firsttime/users-edit-password-firsttime.component';
import { AuthService } from '../services/auth.service';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private userS: UsersService,
    private router: Router,
    private matDialog: MatDialog
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    var expectedRoles = route.data.expectedRoles;
    if (this.auth.isAuthenticated()) {
      if (this.userS.getUser().ch_pass === 1) {
        this.openDialogChangePasswordFirstTime();
      }
      if (expectedRoles.includes(this.userS.getUser().role) || expectedRoles.includes("Any")) {
        return true;
      }
      else {
        this.router.navigateByUrl('/admin/dashboard');        
        return false;
      }
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }

  openDialogChangePasswordFirstTime() {
    this.matDialog.closeAll();
    this.matDialog.open(UsersEditPasswordFirsttimeComponent, {
      panelClass: 'dialogs-main',
      disableClose: true,
      data: { 
        id_user: this.userS.getUser().id
      },
    });
  }

}

import { Injectable } from '@angular/core';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root'
})
export class SkuService {

  constructor(
    private main: MainService
  ) { }
    
  saveSku(form : FormData) {
      return this.main.postRequestFormData(form, "/skus/saveSku");
  }
  deleteSku(id: number) {
    return this.main.postRequest({ id_sku :id}, "/skus/deleteSku");
  }
    
}
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-edit-password-firsttime',
  templateUrl: './users-edit-password-firsttime.component.html',
  styleUrls: ['./users-edit-password-firsttime.component.scss']
})
export class UsersEditPasswordFirsttimeComponent implements OnInit {

  formPassword!: FormGroup;

  hidePass = true;
  hidePassConfirm = true;

  constructor(
    private userS: UsersService,
    private dialogRef: MatDialogRef<UsersEditPasswordFirsttimeComponent>,
    private fb: FormBuilder,
    private customVal: CustomValidatorsService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.createFormPassword();
  }

  createFormPassword() {
    this.formPassword = this.fb.group({
      pass: ['', [ Validators.required, Validators.minLength(8), Validators.pattern(/\d/), Validators.pattern(/[A-Z]/), Validators.pattern(/[a-z]/)]],
      passConfirm: ['', [Validators.required]],
      ch_pass: []
    },{
      validators: this.customVal.samePasswords("pass", "passConfirm")
    });
  }

  changePassword() {
    if(this.formPassword.invalid) { return; }
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formPassword.controls["ch_pass"].setValue(0);
    this.userS.editUser(this.formPassword.value, this.data.id_user).subscribe(
      (result:any) => {
        if(result.message == "Success")        
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Tu contraseña fue actualizada de manera correcta. Por favor ingresa al sistema con tu nueva contraseña',
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then(() => {
          this.auth.logout();
          this.router.navigateByUrl('/login');
        });
      },
      (err:any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

}

<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{ isEdit? 'Editar' : 'Nuevo ' }} Visto Nuevo</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Ingresa la siguiente información:</h6>
    <form #f="ngForm">
        <div class="row">
            <div class="col mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre del Área</mat-label>
                    <input  matInput placeholder="Ej: BEBÉS" name="name" [(ngModel)]="newVobo.responsable" required>
                    <mat-icon matSuffix>users</mat-icon>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Dias programados</mat-label>
                    <input  matInput matTooltip="Dias limite en el que el visto bueno tiene para ser aprobado respecto al visto bueno anterior" placeholder="Ej: 2" name="time" [(ngModel)]="newVobo.scheluded_time_days" type="number" required>
                    <mat-icon matSuffix>schelude</mat-icon>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Usuarios del area</mat-label>
                    <mat-select formControlName="users" (selectionChange)="setUsers($event.value)" multiple>
                        <mat-option value="Adriana Lopez">Adriana Lopez</mat-option>
                        <mat-option value="Laura Servin">Laura Servin</mat-option>
                        <mat-option value="Abril Sanchez">Abril Sanchez</mat-option>
                        <mat-option value="Fabian Toledo">Fabian Toledo</mat-option>
                        <mat-option value="Edgar Rojas">Edgar Rojas</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Folmulario de VoBo</mat-label>
                    <mat-select formControlName="vobos" (selectionChange)="setVobos($event.value)" multiple>
                        <mat-option [value]="'Validación de' + newVobo.responsable">Validación de {{newVobo.responsable}}</mat-option>
                        <mat-option value="Descripción del producto">Descripción del producto</mat-option>
                        <mat-option value="Color de Producto">Color de Producto</mat-option>
                        <mat-option value="VoBo imagen">VoBo imagen</mat-option>
                        <mat-option value="Mecanica promocional">Mecanica promocional</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-flat-button color="primary" disabled>
                    <mat-icon>add</mat-icon> Añadir VoBo a Formulario
                </button>
            </div>
        </div>

    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close class="bg-success text-white" >Ok <mat-icon>save</mat-icon></button>
</div>
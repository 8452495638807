import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AttributeModel } from 'src/app/models/attribute.model';
import { StoreService } from 'src/app/services/store.service';
import { StoresAttributesAddComponent } from '../../shared/dialogs/stores-attributes-add/stores-attributes-add.component';
import { StoresAttributesValuesListComponent } from '../../shared/dialogs/stores-attributes-values-list/stores-attributes-values-list.component';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';
import { StoresAttributesValuesListUploadComponent } from '../../shared/dialogs/stores-attributes-values-list-upload/stores-attributes-values-list-upload.component';
import { AttributesRedorderComponent } from '../../shared/dialogs/attributes-reorder/attributes-reorder.component';


@Component({
  selector: 'app-stores-attributes-list',
  templateUrl: './stores-attributes-list.component.html',
  styleUrls: ['./stores-attributes-list.component.scss']
})
export class StoresAttributesListComponent implements OnInit {
  
  attributeList = new Array<AttributeModel>();
  isLoading = true;  

  attributes : any;

  displayedColumns: string[] = ['id', 'name', 'type', 'values', 'edit', 'autorization', 'createdDate', 'actions'];

  types = [{ type_id : 1, name : "SI/NO"}, { type_id : 2, name : "FECHA"}, { type_id : 3, name : "OPCIONES"}, { type_id : 6, name : "NÚMERO"}, { type_id : 4, name : "RANGO"}, { type_id : 5, name : "TEXTO"}];

  dataSource!: MatTableDataSource<AttributeModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private storeS: StoreService,
    private matDialog: MatDialog,
    private _titleService: Title,
    private _router: Router,
    private _location: Location,
    private main: MainService,
  ) {
    this._titleService.setTitle("Buho Nest - Atributos");
    this.getAttributes();
    this.types = this.getTypes();
  }

  ngOnInit(): void {
  }

  
  getAttributes(){
    this.main.ShowLoading();
    this.storeS.getAtributos().subscribe(
      (result : any) => {
        
      this.main.HideLoading();
        this.attributes = result.data;
        this.dataSource = new MatTableDataSource(this.attributes);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
        this.isLoading = false;
      }
    );
  }

   buscarPorTypeId(typeId  : any) {
    return this.types.filter(function (element) {
      return element.type_id == typeId;
    });
  }

  getTypes(){
    return environment.att_types;
  }

  getAttributesList() {
    this.attributeList = this.storeS.getAttributesList();
    this.dataSource = new MatTableDataSource(this.attributeList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogAttibutesAdd(isEdit : boolean = false , row : any = null) {
    this.matDialog.open(StoresAttributesAddComponent, {panelClass: 'dialogs-sm', data : { isEdit : isEdit, atributo : row}}).afterClosed().subscribe(
      resp => {
        this.getAttributes();
      }
    );
  }

  openDialogAttibutesStoreList(atributo : any) {
    this.matDialog.open(StoresAttributesValuesListComponent, {panelClass: 'dialogs-lg', data : {atributo : atributo}}).afterClosed().subscribe(
      resp => {
        this.getAttributes();
      }
    );
  }


  openDialogAttibutesReorder() {
    this.matDialog.open(AttributesRedorderComponent, {panelClass: 'dialogs-sm', data : {atributos : this.attributes}}).afterClosed().subscribe(
      resp => {
        this.getAttributes();
      }
    );
  }

  getOptions(optionsStr : any){
    return JSON.parse(optionsStr);
  }

  goBack() {
    this._location.back();
  }

  openDialogValuesUpload() {
    this.matDialog.open(StoresAttributesValuesListUploadComponent, {panelClass: 'dialogs-sm'}).afterClosed().subscribe(
      resp => {
      }
    );
  }
}

<div class="modal-header">
    <h4 class="modal-title">Importar Distribución</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content class="mt-2">
    <div class="modal-body">
        <form [formGroup]="groupForm" novalidate>
            <div class="row">  
                <div class="col-md-12">
                    <mat-form-field  class="w-100" appearance="outline"> 
                        <mat-select (selectionChange)="importDistribution()" matInput placeholder="Selecciona una campaña" >  
                          <mat-option *ngFor="let option of ['Pop Derma | Febrero', 'Pop Derma | Marzo']" [value]="option">
                              {{ option }}
                          </mat-option>
                        </mat-select>          
                    </mat-form-field>
                </div>
            </div>
        </form>  

        <div [hidden]="dataSource.data.length == 0" class="mat-elevation-z8 table-responsive mb-4">
            <table mat-table [dataSource]="dataSource" matSort class="w-100">
    
                <ng-container matColumnDef="art">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Arte </th>
                    <td mat-cell *matCellDef="let row"> {{row.art}} </td>
                </ng-container>
                
    
                <ng-container matColumnDef="groups">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Grupos </th>
                    <td mat-cell *matCellDef="let row"> <button color="primary" mat-button role="button" (click)="openEditGroupModal(row.art)">{{row.groups}}</button> </td>
                </ng-container> 

                <ng-container matColumnDef="responsable">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsable </th>
                    <td mat-cell *matCellDef="let row"> {{row.responsable}} </td>
                </ng-container>
    
                <ng-container matColumnDef="registro">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Registro</th>
                    <td mat-cell *matCellDef="let row"> {{row.registro}} </td>
                </ng-container>
                                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                <!-- <tr class="mat-row" *matNoDataRow>
                    <td *ngIf="dataSource.data.length == 0" class="mat-cell text-center" colspan="6">Seleccione una campaña</td>
                    <td *ngIf="dataSource.data.length > 0" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                </tr> -->
                
            </table>
    
            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        </div>
    </div>
    
</mat-dialog-content>
<mat-card-actions>
    <div class="modal-footer mt-4">
        <div class="d-flex w-100 justify-content-end">
            <div>
                <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
                <button (click)="onSubmit()" mat-flat-button class="bg-success text-white">Importar <mat-icon>file_open</mat-icon></button>
                <!-- <button mat-flat-button class="bg-success text-white" (click)="save()">Guardar <mat-icon>save</mat-icon></button> -->
    
            </div>

        </div>
        
    </div>
</mat-card-actions>
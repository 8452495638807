<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-1 text-primary">
            <mat-icon class="me-2 align-middle">design_services</mat-icon>Template Design
        </h4>
        <div class="row">
            <div class="col-md-12">
                <!-- <div class="card mb-4">
                    <div class="card-body"> -->
                <mat-tab-group mat-align-tabs="start">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            POP Tradicional
                        </ng-template>
                        <div class="col-md-12 mt-4">
                            <div class="overflow-auto w-100">
                                <button mat-flat-button color="primary" (click)="downloadAll()">Descargar todo <mat-icon>download</mat-icon></button>
                                <div class="row mw-100 mt-4">
                                    <div *ngFor="let mat of dataMats; let i = index" class="col-sm-6 col-lg-4 mb-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title">{{ mat.namemat }}</h5>
                                                <div class="row mb-3">
                                                    <div class="col-md-6 offset-md-3 text-center pb-4">
                                                        <!--<button mat-raised-button color="basic" class="w-100" (click)="openFileViewer(mat)"><mat-icon>visibility</mat-icon> Ver Archivos</button>-->
                                                        <button mat-stroked-button color="primary" class="w-100"  (click)="openFileViewer(mat)"><mat-icon>folder</mat-icon> Ver Archivos</button>
                                                    </div>
                                                    <!--<div class="col-md-6 text-center">
                                                        <button mat-raised-button color="basic" class="w-100"><mat-icon>download_for_offline</mat-icon> Descargar</button>
                                                    </div>-->
                                                </div>
                                                <p class="card-text"><small class="text-muted">Actualizado: {{ mat.updated }}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <!--<mat-tab>
                        <ng-template mat-tab-label>
                            POP Emotivo
                        </ng-template>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            Folletos
                        </ng-template>
                    </mat-tab>-->
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{data.id > 0 ? 'Editar SKU' : 'Nuevo SKU'}}</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <mat-dialog-content>
        <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>SKU</mat-label>
                        <input matInput [(ngModel)]="data.sku" required>
                      </mat-form-field>
                </div>
                <div class="col-md-6">
                    <div *ngIf="data.nombre_archivo != null"> 
                        <strong>Archivo actual:</strong> 
                        <label class="ms-2 lbl-file" (click)="verSku(data.archivo)">{{data.nombre_archivo}}</label>     
                    </div>
                    <form [formGroup]="form">
                        <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onFileSelected($event)">
                        </ngx-file-drag-drop>
                    </form>
                </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Nombre</mat-label>
                    <input matInput [(ngModel)]="data.nombre">
                  </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Medida X</mat-label>
                    <input matInput [(ngModel)]="data.medida_x">
                  </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Descripción</mat-label>
                    <input matInput [(ngModel)]="data.descripcion">
                  </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Medida Y</mat-label>
                    <input matInput [(ngModel)]="data.medida_y">
                  </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Accesorio 1</mat-label>
                    <input matInput [(ngModel)]="data.accesorio1">
                  </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Mult. Accesorio 1</mat-label>
                    <input matInput [(ngModel)]="data.mult_accesorio1">
                  </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Accesorio 2</mat-label>
                    <input matInput [(ngModel)]="data.accesorio2">
                  </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Mult. Accesorio 2</mat-label>
                    <input matInput [(ngModel)]="data.mult_accesorio2">
                  </mat-form-field>
            </div>
        </div>

      </mat-dialog-content>
</div>
<div class="modal-footer mt-3">
    <button mat-flat-button class="bg-success text-white" (click)="guardarSKU(data)">Guardar <mat-icon>save</mat-icon></button>
</div>

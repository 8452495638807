<div class="content-wrapper">
    <!-- Content -->
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary"><mat-icon class="align-middle">extension</mat-icon> Proyectos
        </h4>
        <div>
            <!-- Card active projects -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <h5 class="card-header">
                            <mat-icon class="text-warning align-middle">stop</mat-icon> Proyectos pendientes de
                            aprobación
                        </h5>
                        <div class="card-body">
                            <mat-accordion *ngFor="let project of proyectosPendientes()" multi>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                                <mat-icon class="aling-middle text-warning me-2">extension</mat-icon> {{ project.nombre }}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <span class="badge bg-label-warning">{{ getAprobacionesPendientesByProyecto(project.id) }}</span>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <mat-divider></mat-divider>
                                    <mat-accordion multi>
                                        <mat-expansion-panel *ngFor="let campana of getCampanasByProyecto(project.id)" class="mat-elevation-z0">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                   <ng-container *ngIf="checkIfCampanaAprobada(campana.id)"><mat-icon  class="aling-middle text-warning me-2">campaign</mat-icon> {{ campana.nombre }}</ng-container> 
                                                   <ng-container *ngIf="!checkIfCampanaAprobada(campana.id)"><mat-icon class="aling-middle text-success me-2">campaign</mat-icon> {{ campana.nombre }}</ng-container>
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    <span class="badge bg-label-primary">{{ campana.nombre_provider }}</span>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div class="table-responsive text-nowrap">
                                                <table class="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Archivo</th>
                                                            <th>Autorización</th>
                                                            <th>Fecha de Registro</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="table-border-bottom-0">
                                                        <tr *ngFor="let surveys of getSurveysByCampana(campana.id, campana.id_provider)">
                                                            <td>
                                                                {{ surveys.id }}
                                                            </td>
                                                            <td>
                                                                {{ surveys.nombre }}
                                                            </td>
                                                            <td>
                                                                <span *ngIf="surveys.status == 1" class="badge bg-warning">PENDIENTE</span>
                                                                <span *ngIf="surveys.status == 2" class="badge bg-success">AUTORIZADO</span><button mat-icon-button class="mb-1" (click)="getPDF(surveys.id,surveys.archivo ,campana.nombre_provider)" *ngIf="surveys.status == 2" matTooltip="Descargar respuesta" [matTooltipPosition]="'above'"><mat-icon>get_app</mat-icon></button>
                                                                <span *ngIf="surveys.status == 3" class="badge bg-danger">RECHAZADO</span>
                                                            </td>
                                                            <td>
                                                                {{ surveys.created_at | date : "dd/MM/yyyy hh:mma" : "-0000" }}
                                                            </td>
                                                            <td>
                                                                <ng-container *ngIf="surveys.locked == 0">
                                                                    <button (click)="DownloadElement('https://drive.google.com/file/d/'  +  surveys.archivo )" mat-icon-button class="mb-1" matTooltip="Descarga archivo" [matTooltipPosition]="'above'">
                                                                        <mat-icon>download</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button class="mb-1" (click)="openDialogFormHistory(surveys.id, campana.nombre_provider)" matTooltip="Ver actividades" [matTooltipPosition]="'above'">
                                                                        <mat-icon>fact_check</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button class="mb-1" (click)="openDialogFileLogs(surveys.id)">
                                                                        <mat-icon>forum</mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button class="mb-1" (click)="goToProviderMaterial(surveys.id)" matTooltip="Ir a material" [matTooltipPosition]="'above'">
                                                                        <mat-icon>preview</mat-icon>
                                                                    </button>
                                                                </ng-container>
                                                            </td>
                                                        </tr>                   
                                                    </tbody>
                                                </table>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>                                    
                                </mat-expansion-panel>
                            </mat-accordion>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active campaigns End-->

        <!-- Card inactive campaigns -->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-success align-middle">stop</mat-icon> Proyectos Aprobados 2023
                    </h5>
                    <div class="card-body">
                        <mat-accordion *ngFor="let project of proyectosAprobados()" multi>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                            <mat-icon class="aling-middle text-success me-2">extension</mat-icon> {{ project.nombre }}
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        <!--<span class="badge bg-label-success">2 de 2 aprobaciones completadas</span>-->
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <mat-divider></mat-divider>
                                <mat-accordion multi>
                                    <mat-expansion-panel *ngFor="let campana of getCampanasByProyecto(project.id)"  class="mat-elevation-z0">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon class="aling-middle text-success me-2">campaign</mat-icon> {{ campana.nombre }}
                                            </mat-panel-title>
                                            <mat-panel-description>
                                                <span class="badge bg-label-primary">{{ campana.nombre_provider }}</span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div class="table-responsive text-nowrap">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Archivo</th>
                                                        <th>Autorización</th>
                                                        <th>Fecha de Registro</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    <tr *ngFor="let surveys of getSurveysByCampana(campana.id, campana.id_provider)">
                                                        <td>
                                                            {{ surveys.id }}
                                                        </td>
                                                        <td>
                                                            {{ surveys.nombre }}
                                                        </td>
                                                        <td>
                                                            <span *ngIf="surveys.status == 1" class="badge bg-warning">PENDIENTE</span>
                                                            <span *ngIf="surveys.status == 2" class="badge bg-success">AUTORIZADO</span><button mat-icon-button style="cursor: pointer;" (click)="getPDF(surveys.id,surveys.archivo ,campana.nombre_provider)" *ngIf="surveys.status == 2"><mat-icon>get_app</mat-icon></button>
                                                            <span *ngIf="surveys.status == 3" class="badge bg-danger">RECHAZADO</span>
                                                        </td>
                                                        <td>
                                                            {{ surveys.created_at | date : "dd/MM/yyyy hh:mma": "-0000"  }}
                                                        </td>
                                                        <td>
                                                            <button (click)="DownloadElement('https://drive.google.com/file/d/'  +  surveys.archivo )" mat-icon-button class="mb-1" matTooltip="Descarga archivo" [matTooltipPosition]="'above'">
                                                                <mat-icon>download</mat-icon>
                                                            </button>
                                                            <button mat-icon-button class="mb-1" (click)="openDialogFormHistory(surveys.id, campana.nombre_provider )" matTooltip="Ver actividades" [matTooltipPosition]="'above'">
                                                                <mat-icon>fact_check</mat-icon>
                                                            </button>
                                                            <button mat-icon-button class="mb-1" (click)="openDialogFileLogs(surveys.id)">
                                                                <mat-icon>forum</mat-icon>
                                                            </button>
                                                            <button mat-icon-button class="mb-1" (click)="goToProviderMaterial(surveys.id)" matTooltip="Ir a material" [matTooltipPosition]="'above'">
                                                                <mat-icon>preview</mat-icon>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>                                    
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
        <!--style="position: absolute;
        left: -9999px;"-->
        <div style="position: absolute;
        left: -9999px;">
            <div id="canvasToCheck" >
                <ng-container  *ngFor="let response of responsedataAux">
                    <div style="max-width: 600px; padding-top: 50px">
                    <div class="container">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <h6>
                                    <span> <!--<mat-icon class="align-middle">update</mat-icon>--> Respuestas del formulario: <strong>{{ response?.created_at | date : "dd/MM/yyyy" : "-0000" }}</strong></span>     
                                    <br>
                                    <br>
                                    <span><b>Respondió : </b>{{ response?.nombre_provider }}</span>  
                                    <br>
                                    <span><b>Puesto :</b> {{ response?.puesto }}</span>     
                                </h6>
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Concepto</th>
                                            <th>Respuesta</th>
                                            <th>Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr>
                                            <td>
                                                Descripción del producto
                                            </td>
                                            <td>
                                                <span *ngIf="response?.desc_producto" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response?.desc_producto" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response?.desc_producto">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response?.desc_producto">
                                                {{ response?.desc_producto_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Descripción SKU (revisar números)
                                            </td>
                                            <td>
                                                <span *ngIf="response?.sku" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response?.sku" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response?.sku">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response?.sku">
                                                {{ response.sku_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                VoBo imagen(es)
                                            </td>
                                            <td>
                                                <span *ngIf="response?.image_vobo"  class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response?.image_vobo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response?.image_vobo">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response?.image_vobo">
                                                {{ response.image_vobo_com }}
                                                <a *ngIf="filterFiles(response?.id, 1).length > 0" href="https://drive.google.com/file/d/{{ filterFiles(response.id, 1)[0].archivo }}" target="_blank">Descarga adjuntos aquí </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Mecanica promocional
                                            </td>
                                            <td>
                                                <span *ngIf="response?.mec_promo" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response?.mec_promo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response?.mec_promo">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response?.mec_promo">
                                                {{ response?.mec_promo_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                VoBo del medio para publicación
                                            </td>
                                            <td>
                                                <span *ngIf="response?.pub_vobo" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response?.pub_vobo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response?.pub_vobo">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response?.pub_vobo">
                                                {{ response?.pub_vobo_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Razón social
                                            </td>
                                            <td colspan="2">
                                               {{ response?.razon_social }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Empresa recuperadora
                                            </td>
                                            <td colspan="2">
                                                {{ response?.empresa_recuperadora }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Concepto (descripción detallada):
                                            </td>
                                            <td colspan="2">
                                               {{ response?.concepto }}
                                                <a *ngIf="filterFiles(response.id, 2).length > 0" href="https://drive.google.com/file/d/{{ filterFiles(response.id, 2)[0].archivo }}" target="_blank">Descarga adjuntos aquí </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Monto
                                            </td>
                                            <td colspan="2">
                                                <strong>$ {{ response?.monto }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Método de pago
                                            </td>
                                            <td colspan="2">
                                                {{ response?.metodo_pago }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>      
                </div>
                </ng-container>
            </div>
        </div>

    </div>
</div>
<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Resumen de Distribución Actual</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body" style="min-height: 580px;">       
        <mat-divider></mat-divider>
        <div>
          <br>
          <div class="float-end align-middle">
            <a target="_blank" mat-flat-button color="primary">Descargar<mat-icon class="align-middle ms-1">file_download</mat-icon></a>
          </div>

            <div class="overflow-auto w-200">
                <mat-form-field>
                    <mat-label>
                        <mat-icon>search</mat-icon> Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Dangler" #input>
                </mat-form-field>
            </div>
            <div class="inventor-container mat-elevation-z0 table-responsive text-nowrap" style="max-height: 500px; overflow-y: auto; padding: 10px;">
                <table mat-table [dataSource]="storeFile" class="mat-elevation-z8" style="width: 100%;">
                    
                  <!--<ng-container matColumnDef="select">
                    <th class="text-center" mat-header-cell *matHeaderCellDef style="min-width: 80px; margin-left: 14px;"> 
                      <input type="checkbox" (change)="selectAll($event)">
                    </th>
                    <td  class="text-center" mat-cell *matCellDef="let promo" style="min-width: 80px; margin-left: 14px;">
                      <input type="checkbox" (change)="toggleSelection(promo['CODIGO PRINCIPAL'])" [checked]="selectedPromotions.includes(promo['CODIGO PRINCIPAL'])">
                    </td>
                  </ng-container>-->
              
                  
                  <ng-container *ngFor="let column of displayedColumnsStore">
                    <ng-container matColumnDef="{{column}}">
                      <th mat-header-cell *matHeaderCellDef  style="min-width: 170px; margin-left: 14px;"> {{ column }} </th>
                      <td mat-cell *matCellDef="let promo"  style="min-width: 170px; margin-left: 14px;"> {{ promo[column] }} </td>
                    </ng-container>
                  </ng-container>
              
                  <tr mat-header-row *matHeaderRowDef="allColumnsStore"></tr>
                  <tr mat-row *matRowDef="let row; columns: allColumnsStore;"></tr>
                </table>
              </div>

        </div>
    </div>
</mat-dialog-content>
<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            {{ campaign.name }} 
            <small class="text-muted">- última actualización {{last_edited.last_mod | date : "dd/MM/yyyy" }}- {{last_edited.nombre}}</small>
            <button *ngIf="(canEditCampaign() && hasPermissions('upd_camp',true,true)) || isBuhoUsr" mat-icon-button [matMenuTriggerFor]="menuCampana" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="primary">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menuCampana="matMenu">
                <button *ngIf="canEditUpload() && hasPermissions('upd_camp',true,true)" (click)="openEditCampaignDialog()" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                </button>
                <button *ngIf="canEditUpload() && isBuhoUsr" (click)="deleteCampConfirm()" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                </button>
            </mat-menu>
            <div class="float-end">
                <button mat-flat-button color="primary" class="ms-4" (click)="openPreprojectsModal()" *ngIf="isBuhoUsr">Preproyectos <mat-icon>library_books</mat-icon></button>
                <button mat-flat-button color="primary" class="ms-4" (click)="goToEncuestas()" *ngIf="isBuhoUsr">Encuestas <mat-icon>playlist_add_check</mat-icon></button>
                <button mat-flat-button color="accent" [disabled]="!hasPermissions('unlock_camp',true,null)" class="ms-4" *ngIf="canShowLockOption()" (click)="unlockCampaign()"> <mat-icon *ngIf="islocked">lock</mat-icon><mat-icon *ngIf="!islocked">lock_open</mat-icon></button>
                <button mat-flat-button color="primary" class="ms-4" (click)="openUploadDist()" >{{ canEditUpload()&&hasPermissions('upl_dis',true,true) ? 'Subir' : 'Ver' }} distribución <mat-icon>upload_file</mat-icon></button>
                <button mat-flat-button color="warn" class="ms-4" *ngIf="campaign.irr>0" (click)="openDialogIrregularities()">Irregularidades: {{campaign.irr}} <mat-icon>report</mat-icon></button>
            </div>
        </h4>
        <div class="row">
            <div class="col-md-12 mb-3">
                <div class="stepper-wrapper">
                    <div class="stepper-item" [ngClass]="progress_bar.dis == 1 ? 'completed' :  'active'">
                        <div class="step-counter">1</div>
                        <div class="step-name">Subir distribución</div>
                    </div>
                    <div class="stepper-item" [ngClass]="progress_bar.str == 1 ? 'completed' :  'active'">
                        <div class="step-counter">2</div>
                        <div class="step-name">Generar Estrategias</div>
                    </div>
                    <div class="stepper-item" [ngClass]="progress_bar.art == 1 ? 'completed' :  'active'" matTooltip="{{progress_bar.art * 100 | number : '1.2-2'}}%" [matTooltipPosition]="'below'">
                        <div class="step-counter">3</div>
                        <div class="step-name">Subir Artes</div>
                    </div>
                    <div class="stepper-item" [ngClass]="progress_bar.pre == 1 ? 'completed' :  'active'" matTooltip="{{progress_bar.pre * 100 | number : '1.2-2'}}%" [matTooltipPosition]="'below'">
                        <div class="step-counter">4</div>
                        <div class="step-name">Esperar Preproyecto</div>
                    </div>
                    <div class="stepper-item" [ngClass]="progress_bar.apr == 1 ? 'completed' :  'active'" matTooltip="{{progress_bar.apr * 100 | number : '1.2-2'}}%" [matTooltipPosition]="'below'">
                        <div class="step-counter">5</div>
                        <div class="step-name">Aprobación (VoBo)</div>
                    </div>
                </div>
            </div>
        </div>

         <!-- Card after deadline strategies -->
         <div *ngIf="strategies_ad.length > 0" class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-warning align-middle">stop</mat-icon> Listado de Estrategias fuera de tiempo
                        <span class="float-end"><strong><mat-icon class="align-middle" matTooltip="Al llegar la fecha límite, se otorga un VoBo a todos los Preproyectos" [matTooltipPosition]="'above'">warning</mat-icon> Fecha límite para cambios: {{ campaign.deadline | date : "dd/MM/yyyy" }}</strong></span>
                    </h5>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Estrategia</th>
                                        <th>Folio <i class="bx bxs-lock"></i></th>
                                        <th>Artes<br>Fuente</th>
                                        <th>Pre<br>Proyecto</th>
                                        <th>VoBo<br>(Artes, Layout)</th>
                                        <th>VoBo ODT</th>
                                        <th>Registro</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngIf="strategies_ad.length == 0">
                                        <td colspan="9">
                                            <h6 class="text-center">No hay registros</h6>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let str of strategies_ad; let i = index" [ngClass]="{'highlight blink': str.id == idSelectedStr}" id="{{ 'str_' + str.id }}">
                                        <td>{{i+1}}</td>
                                        <td class="td-estrategia" matTooltip="Da click para ver lista de materiales" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" role="button" (click)="openDialogMaterialList(str)">
                                            <strong>{{str.name}}</strong>
                                        </td>
                                        <td>{{str.invoice}}</td>
                                        <td class="text-center">
                                            <!-- cambia icono de carga o descarga según perfil -->
                                            <button mat-icon-button (click)="openDialogArt(str)" matTooltip="Sube un nuevo archivo de Artes Fuente" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="accent">
                                                <mat-icon *ngIf="hasPermissions('upl_art',false,true)">file_upload</mat-icon>
                                                <mat-icon [matBadge]="!isClientUsr&&str.art_seen==0?'1':''" *ngIf="!hasPermissions('upl_art',false,true)">file_download</mat-icon>
                                            </button>
                                        </td>
                                        <td class="text-center">
                                            <!-- cambia icono de carga o descarga según perfil -->
                                            <!-- <button mat-icon-button (click)="openDialogPreproject(str)" matTooltip="Descarga archivo de Preproyecto" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" [disabled]="str.has_art==0" [color]="str.has_art==0?'theme':'accent'">
                                                <mat-icon *ngIf="hasPermissions('upl_odt',true,false)">file_upload</mat-icon>
                                                <mat-icon [matBadge]="isClientUsr&&str.odt_seen==0?'1':''" *ngIf="!hasPermissions('upl_odt',true,false)">file_download</mat-icon>
                                            </button> -->
                                            <button *ngIf="!isClientUsr" mat-icon-button (click)="openDialogPreproject(str)" matTooltip="Generar Preproyecto" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" [disabled]="str.has_art==0" [color]="str.has_art==0?'theme':'accent'">
                                                <mat-icon *ngIf="hasPermissions('upl_odt',true,false)">file_upload</mat-icon>
                                            </button>

                                            <button *ngIf="isClientUsr" mat-icon-button (click)="verLayout(str.archivo_layout,str.id)"  matTooltip="Ver Layout" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" [disabled]="str.archivo_layout == null || str.bitPreproyecto != 1" [color]="str.archivo_layout==null?'theme':'accent'">
                                                <mat-icon [matBadge]="str.bitPreproyecto==1&&str.layout_visto!=1?'1':''">file_download</mat-icon>
                                            </button>
                                        </td>
                                        <td>
                                            <button mat-button [disabled]="islocked||!hasPermissions('upd_sta_layout',false,true)" [matMenuTriggerFor]="menu2" [ngClass]="str.st_layout == 0 ? 'bg-lighter text-black' : str.st_layout == 1 ? 'bg-success text-white' : str.st_layout == 2? 'bg-warning text-white' : str.st_layout == 3? 'bg-danger text-white' : ''">
                                                {{ str.st_layout == 0 ? 'Sin Cambios' : str.st_layout == 1 ? 'Aprobado' : str.st_layout == 2? 'Pendiente' : str.st_layout == 3? 'No Aprobado' : 'Unknown Status'}} <mat-icon *ngIf="canEditUpload()&&hasPermissions('upd_sta_layout',false,true)">expand_more</mat-icon>
                                            </button>
                                            <mat-menu #menu2="matMenu">
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 0, str)"><span class="badge bg-lighter text-black">Sin Cambios</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 2, str)"><span class="badge bg-warning">Pendiente</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 1, str)"><span class="badge bg-success">Aprobado</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 3, str)"><span class="badge bg-danger">No Aprobado</span></button>
                                            </mat-menu>
                                        </td>
                                        <td>
                                            <button mat-button [disabled]="!canEditUpload()||!hasPermissions('upd_sta_odt',false,true)" [matMenuTriggerFor]="menu3" [ngClass]="str.st_odt == 0 ? 'bg-lighter text-black' : str.st_odt == 1 ? 'bg-success text-white' : str.st_odt == 2? 'bg-warning text-white' : str.st_odt == 3? 'bg-danger text-white' : ''">
                                                {{ str.st_odt == 0 ? 'Sin Cambios' : str.st_odt == 1 ? 'Aprobado' : str.st_odt == 2? 'Pendiente' : str.st_odt == 3? 'No Aprobado' : 'Unknown Status'}} <mat-icon *ngIf="canEditUpload()&&hasPermissions('upd_sta_odt',false,true)">expand_more</mat-icon>
                                            </button>
                                            <mat-menu #menu3="matMenu">
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 0, str)"><span class="badge bg-lighter text-black">Sin Cambios</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 2, str)"><span class="badge bg-warning">Pendiente</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 1, str)"><span class="badge bg-success">Aprobado</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 3, str)"><span class="badge bg-danger">No Aprobado</span></button>
                                            </mat-menu>
                                        </td>
                                        <td><i> {{ str.last_mod | date : "dd/MM/yyyy"}}</i></td>
                                        <td class="text-center">
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openDialogLogs(str)">
                                                <mat-icon matBadge="{{str.notif==0 ? '' : str.notif}}" matBadgeColor="warn">forum</mat-icon>
                                            </button>
                                            <button mat-icon-button [matMenuTriggerFor]="menuEstrategia" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuEstrategia="matMenu">
                                                <button mat-menu-item (click)="openDialogMaterialList(str)">
                                                    <mat-icon>read_more</mat-icon>
                                                    <span>Ver lista de materiales</span>
                                                </button>
                                                <button *ngIf="canEditUpload()&&hasPermissions('upd_str',true,true)" (click)="openEditStr(str)" mat-menu-item>
                                                    <mat-icon>edit</mat-icon>
                                                    <span>Editar</span>
                                                </button>
                                                <button *ngIf="canEditUpload()&&hasPermissions('del_str',true,true) && isBuhoUsr" (click)="deleteStrConfirm(str.id)"  mat-menu-item>
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Eliminar</span>
                                                </button>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button *ngIf="canEditUpload()&&hasPermissions('cr_str',true,true)" mat-flat-button class="mt-4 bg-info text-white" (click)="openCreateStr()">Generar nueva "Estrategia" <mat-icon>add</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active campaigns strategies-->

        <!-- Card on time strategies -->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-info align-middle">stop</mat-icon> Listado de Estrategias
                        <span *ngIf="strategies_ad.length == 0" class="float-end"><strong><mat-icon class="align-middle" matTooltip="Al llegar la fecha límite, se otorga un VoBo a todos los Preproyectos" [matTooltipPosition]="'above'">warning</mat-icon> Fecha límite para cambios: {{ campaign.deadline | date : "dd/MM/yyyy" }}</strong></span>
                    </h5>
                    <div class="card-body">
                        <div class="table-responsive text-nowrap">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Estrategia</th>
                                        <th>Folio <i class="bx bxs-lock"></i></th>
                                        <th>Artes<br>Fuente</th>
                                        <th>Pre<br>Proyecto</th>
                                        <th>VoBo<br>(Artes, Layout)</th>
                                        <th>VoBo ODT</th>
                                        <th>Registro</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngIf="strategies.length == 0">
                                        <td colspan="9">
                                            <h6 class="text-center">No hay registros</h6>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let str of strategies; let i = index" id="{{ 'str_' + str.id }}" [ngClass]="{'highlight blink': str.id == idSelectedStr}">
                                        <td>{{i+1}}</td>
                                        <td class="td-estrategia" matTooltip="Da click para ver lista de materiales" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" role="button" (click)="openDialogMaterialList(str)">
                                            <strong>{{str.name}}</strong>
                                        </td>
                                        <td>{{str.invoice}}</td>
                                        <td class="text-center">
                                            <!-- cambia icono de carga o descarga según perfil -->
                                            <button mat-icon-button (click)="openDialogArt(str)" matTooltip="Sube un nuevo archivo de Artes Fuente" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" color="accent">
                                                <mat-icon *ngIf="hasPermissions('upl_art',false,true)">file_upload</mat-icon>
                                                <mat-icon [matBadge]="!isClientUsr&&str.art_seen==0?'1':''" *ngIf="!hasPermissions('upl_art',false,true)">file_download</mat-icon>
                                            </button>
                                        </td>
                                        <td class="text-center">
                                            <!-- cambia icono de carga o descarga según perfil -->
                                            <!-- <button mat-icon-button (click)="openDialogPreproject(str)" matTooltip="Descarga archivo de Preproyecto" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" [disabled]="str.has_art==0" [color]="str.has_art==0?'theme':'accent'">
                                                <mat-icon *ngIf="hasPermissions('upl_odt',true,false)">file_upload</mat-icon>
                                                <mat-icon [matBadge]="isClientUsr&&str.odt_seen==0?'1':''" *ngIf="!hasPermissions('upl_odt',true,false)">file_download</mat-icon>
                                            </button> -->

                                            <button *ngIf="!isClientUsr" mat-icon-button (click)="openDialogPreproject(str)" matTooltip="Generar Preproyecto" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" [disabled]="str.has_art==0" [color]="str.has_art==0?'theme':'accent'">
                                                <mat-icon *ngIf="hasPermissions('upl_odt',true,false)">file_upload</mat-icon>
                                            </button>

                                            <button *ngIf="isClientUsr" mat-icon-button (click)="verLayout(str.archivo_layout,str.id)"  matTooltip="Ver Layout" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" [disabled]="str.archivo_layout == null" [color]="str.archivo_layout==null?'theme':'accent'">
                                                <mat-icon [matBadge]="str.bitPreproyecto==1&&str.layout_visto!=1?'1':''">file_download</mat-icon>
                                            </button>
                                            
                                        </td>
                                        <td>
                                            <button mat-button [disabled]="!canEditUpload()||!hasPermissions('upd_sta_layout',false,true)" [matMenuTriggerFor]="menu2" [ngClass]="str.st_layout == 0 ? 'bg-lighter text-black' : str.st_layout == 1 ? 'bg-success text-white' : str.st_layout == 2? 'bg-warning text-white' : str.st_layout == 3? 'bg-danger text-white' : ''">
                                                {{ str.st_layout == 0 ? 'Sin Cambios' : str.st_layout == 1 ? 'Aprobado' : str.st_layout == 2? 'Pendiente' : str.st_layout == 3? 'No Aprobado' : 'Unknown Status'}} <mat-icon *ngIf="canEditUpload()&&hasPermissions('upd_sta_layout',false,true)">expand_more</mat-icon>
                                            </button>
                                            <mat-menu #menu2="matMenu">
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 0, str)"><span class="badge bg-lighter text-black">Sin Cambios</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 2, str)"><span class="badge bg-warning">Pendiente</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 1, str)"><span class="badge bg-success">Aprobado</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_layout', 3, str)"><span class="badge bg-danger">No Aprobado</span></button>
                                            </mat-menu>
                                        </td>
                                        <td>
                                            <button mat-button [disabled]="!canEditUpload()||!hasPermissions('upd_sta_odt',false,true)" [matMenuTriggerFor]="menu3" [ngClass]="str.st_odt == 0 ? 'bg-lighter text-black' : str.st_odt == 1 ? 'bg-success text-white' : str.st_odt == 2? 'bg-warning text-white' : str.st_odt == 3? 'bg-danger text-white' : ''">
                                                {{ str.st_odt == 0 ? 'Sin Cambios' : str.st_odt == 1 ? 'Aprobado' : str.st_odt == 2? 'Pendiente' : str.st_odt == 3? 'No Aprobado' : 'Unknown Status'}} <mat-icon *ngIf="canEditUpload()&&hasPermissions('upd_sta_odt',false,true)">expand_more</mat-icon>
                                            </button>
                                            <mat-menu #menu3="matMenu">
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 0, str)"><span class="badge bg-lighter text-black">Sin Cambios</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 2, str)"><span class="badge bg-warning">Pendiente</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 1, str)"><span class="badge bg-success">Aprobado</span></button>
                                                <button mat-menu-item (click)="updateStatusStr('st_odt', 3, str)"><span class="badge bg-danger">No Aprobado</span></button>
                                            </mat-menu>
                                        </td>
                                        <td><i> {{ str.last_mod | date : "dd/MM/yyyy"}}</i></td>
                                        <td class="text-center">
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openDialogLogs(str)">
                                                <mat-icon matBadge="{{str.notif==0 ? '' : str.notif}}" matBadgeColor="warn">forum</mat-icon>
                                            </button>
                                            <button mat-icon-button [matMenuTriggerFor]="menuEstrategia" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuEstrategia="matMenu">
                                                <button mat-menu-item (click)="openDialogMaterialList(str)">
                                                    <mat-icon>read_more</mat-icon>
                                                    <span>Ver lista de materiales</span>
                                                </button>
                                                <button *ngIf="canEditUpload()&&hasPermissions('upd_str',true,true)" (click)="openEditStr(str)" mat-menu-item>
                                                    <mat-icon>edit</mat-icon>
                                                    <span>Editar</span>
                                                </button>
                                                <button *ngIf="canEditUpload()&&hasPermissions('del_str',true,true) && isBuhoUsr" (click)="deleteStrConfirm(str.id)"  mat-menu-item>
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Eliminar</span>
                                                </button>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button  *ngIf="strategies_ad.length == 0&&canEditUpload()&&hasPermissions('cr_str',true,true)" mat-flat-button class="mt-4 bg-info text-white" (click)="openCreateStr()">Generar nueva "Estrategia" <mat-icon>add</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active campaigns strategies-->

    </div>
</div>
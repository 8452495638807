<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            {{ campana.name }}
            <small class="text-muted">- última actualización {{ campanaHistory.last_mod | date : "dd/MM/yyyy" }} - {{ campanaHistory.nombre }}</small>
            <div class="float-end">
                <!--<button mat-flat-button color="primary" class="ms-4" (click)="generatePDF()" > Generar QR <mat-icon>add</mat-icon></button>-->
                <button mat-flat-button color="primary" class="ms-4" (click)="generateQRPost()" > Generar QR <mat-icon>add</mat-icon></button>
                <button mat-flat-button color="primary" class="ms-4" (click)="openDialogUploadFile()"> Subir imagenes <mat-icon>upload_file</mat-icon></button>
                <button mat-flat-button color="primary" class="ms-4" [routerLink]="'/admin/distribution/' + campana.id"> Regresar a Distribución <mat-icon>keyboard_backspace</mat-icon></button>
            </div>

        </h4>

        <!-- Card on time strategies -->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <mat-tab-group mat-align-tabs="start">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon class="me-2 align-middle">view_list</mat-icon> Detalle de distribución
                                </ng-template>

                                <div class="card mb-4">
                                    <div class="card-body">
                                        <div class="overflow-auto w-200">
                                            <mat-form-field>
                                                <mat-label>
                                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                                <input matInput (keyup)="applyFilter($event)" placeholder="Sobre burbuja" #input>
                                            </mat-form-field>
                                        </div>
                                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                                <table mat-table [dataSource]="dataSource" matSort #sort="matSort" class="w-100">
                                                    <ng-container matColumnDef="id_zona">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                                                        <td mat-cell *matCellDef="let row"> {{ getZonaName(row.id_zona)}} </td>
                                                    </ng-container>
                                        
                                                    <ng-container matColumnDef="id_sucursal">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                                        <td mat-cell *matCellDef="let row"> {{ getSucursalName(row.id_sucursal) }} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="estafeta">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Estafeta </th>
                                                        <td mat-cell *matCellDef="let row"> {{row.estafeta}} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="consecutivo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Consecutivo </th>
                                                        <td mat-cell *matCellDef="let row"> {{row.consecutivo}} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="nombre_mat">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                                                        <td mat-cell *matCellDef="let row"> {{row.nombre_mat}} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="Acciones">
                                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                                        <td mat-cell *matCellDef="let row" class="text-center">
                                                            <!--<button mat-icon-button matTooltip="Subir imagenenes" [matTooltipPosition]="'above'" color="accent">
                                                                <mat-icon>upload_file</mat-icon>
                                                            </button>-->
                                                            <button *ngIf="hasImage(row.id_material_camp)" mat-icon-button matTooltip="Ver imagenes" (click)="getMaterialImage(row.id_material_camp,row.nombre_mat)" [matTooltipPosition]="'above'" color="accent">
                                                                <mat-icon>remove_red_eye</mat-icon>
                                                            </button>
                                                        </td>
                                                    </ng-container>
                                        
                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        
                                                    <tr class="mat-row" *matNoDataRow>
                                                        <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                            </div>        
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon class="me-2 align-middle">category</mat-icon> Materiales
                                </ng-template>

                                <div class="card mb-4">
                                    <div class="card-body">
                                        <form #f="ngForm">
                                            <div class="overflow-auto w-200">
                                                <mat-form-field>
                                                    <mat-label>
                                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                                    <input matInput (keyup)="applyMatFilter($event)" placeholder="Sobre burbuja" #input>
                                                </mat-form-field>
                                            </div>
                                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                                
                                                <table mat-table [dataSource]="materiales_campana" matSort #sort2="matSort" class="w-100">
                                                    <ng-container matColumnDef="id">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                                                        <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
                                                    </ng-container>
                                        
                                                    <ng-container matColumnDef="name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Material </th>
                                                        <td mat-cell *matCellDef="let row"> {{ row.name }} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                                                        <td mat-cell *matCellDef="let row"> {{ row.cantidad_pza>1?(row.name + " (1 kit = " + row.cantidad_pza + " pz)"):row.name }} </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="cantidad_pza">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. de piezas </th>
                                                        <td mat-cell *matCellDef="let row"> 
                                                            <div *ngIf="!row.edit">{{row.cantidad_pza}}</div>
                                                            <mat-form-field *ngIf="row.edit" class="text-center" appearance="outline">
                                                                <input  matInput placeholder="Ej: BEBÉS" name="cantidad_pza" [(ngModel)]="row.cantidad_pza" type="number">
                                                            </mat-form-field>
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="Acciones">
                                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                                        <td mat-cell *matCellDef="let row" class="text-center">
                                                            <button mat-icon-button matTooltip="Editar Material" (click)="openEditMatCantidad(row)" [matTooltipPosition]="'above'" color="accent">
                                                                <mat-icon>edit</mat-icon>
                                                            </button>
                                                            <button *ngIf="hasImage(row.id)" mat-icon-button matTooltip="Ver imagenes" (click)="getMaterialImage(row.id,row.name)" [matTooltipPosition]="'above'" color="accent">
                                                                <mat-icon>remove_red_eye</mat-icon>
                                                            </button>
                                                        </td>
                                                    </ng-container>
                                        
                                                    <tr mat-header-row *matHeaderRowDef="displayedMatColumns"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedMatColumns;"></tr>
                                        
                                                    <tr class="mat-row" *matNoDataRow>
                                                        <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                                    </tr>
                                                </table>
                                               
                                                <mat-paginator #paginator2  [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                            </div>   
                                        </form>   
                                    </div>                                
                                </div>
                                
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <ng-container *ngFor="let grturl of generatedUrls; let i = index">
        <ngx-qrcode [ngStyle]="{'display': 'none'}" id="{{ 'qrcode_' + i }}" [elementType]="elementType" [value]="grturl.url">
        </ngx-qrcode>
    </ng-container>
</div>
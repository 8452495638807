import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsAddComponent } from '../../shared/dialogs/projects-add/projects-add.component';
import { Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { ProjectListModel, ProjectModel } from 'src/app/models/project.model';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { UsersService } from 'src/app/services/users.service';
import { UserModel } from 'src/app/models/user.model';
import { ProjectsLogsComponent } from '../../shared/dialogs/projects-logs/projects-logs.component';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
}) 
export class ProjectsListComponent implements OnInit {

  projectListData: ProjectListModel = new ProjectListModel();
  years: string[] = [];
  loaded : boolean = false;
  actual_year: number = 0;
  companiesList : any = [];
  selectedCompany : number = 1;
  userObj!: UserModel;

  constructor(
    private matDialog: MatDialog, 
    private _router: Router,
    private main: MainService,
    private projectsS: ProjectsService,
    private _titleService: Title,
    private userS: UsersService
  ) {
    this.actual_year = (new Date).getFullYear();
    this._titleService.setTitle("Buho Nest");
  }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
      this.getListCompanies();
      this.loadProjectsList();
  }

  loadProjectsList() {   
    this.main.ShowLoading();
    this.projectsS.getCampaignProjects(this.selectedCompany).subscribe((x: any) => {
      this.main.HideLoading();
      this.projectListData = x;
      console.log(x);
      this.years = Object.keys(this.projectListData.pastyears);
      this.loaded = true;
    },
    (err:any) => {
          this.main.HideLoading();
        }
    )
  }

  openAddProjectDialog() {
    this.matDialog.open(ProjectsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : false
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadProjectsList();
      }
    );
  }

  goToProject(id: number) {
    this._router.navigateByUrl('/admin/projects/' + id)
  }

  openProjectLogs(obj: ProjectModel) {    
    this.matDialog.open(ProjectsLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        project: obj
      }
    }).afterClosed().subscribe(
      resp => {
        //this.loadCampaigns();
        this.loadProjectsList();
      }
    );
  }

  openEditProjectDialog(proy: any) {
    this.matDialog.open(ProjectsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : true,        
        data: proy
      }
    }).afterClosed().subscribe(
      resp => {        
        if(resp?.reload) this.loadProjectsList();
      }
    );
  }

  deleteProjectConfirm(proy: any) {
    Swal.fire({
      title: '¿Está seguro que desea eliminar esta Campaña?. Esto no se puede deshacer.',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.main.ShowLoading();
          this.projectsS.deleteCampaignProject(proy.id).subscribe(
            (x: any) => {
              this.main.HideLoading();
              this.loadProjectsList();
            },
            (err:any) => {
              this.main.HideLoading();
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
              });
              //this.loadCampaigns();
              this.loadProjectsList();
            }
          );    
        }
      });
  }

  getCompanyName(id: any): string{
    let company = "";
    this.companiesList.forEach((x:any) => {
        if(x.id == id){
          company = x.name;
        }
    });
    return company;
  }

  getListCompanies(){
    this.userS.getCompanies().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.companiesList = result.data;
        }
      },
      (err:any) => {
        console.log(err);
      }
    );
  }

}

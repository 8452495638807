<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Lista de próximos cortes</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <form>        
        <div class="row">            
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nombre campaña</th>
                        <th>Fecha corte</th>
                        <th>Status</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody class="table-border-bottom-0"> 
                    <tr>
                        <td role="button"><strong>1</strong></td>
                        <td role="button"><strong>POP FDA AGOSTO 2023</strong></td>
                        <td role="button"><strong> 29/06/2023 </strong></td>
                        <td role="button" class="">
                            <button type="button" class="btn btn-danger disabled" aria-expanded="false">
                                SIGUIENTE
                            </button>
                        </td>
                        <td>
                            <button mat-icon-button matTooltip="Descargar" [matTooltipPosition]="'above'" color="accent">
                                <mat-icon matBadgeColor="warn">download</mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td role="button"><strong>2</strong></td>
                        <td role="button"><strong>POP FDA SEPTIEMBRE 2023</strong></td>
                        <td role="button"><strong> 29/07/2023 </strong></td>
                        <td role="button" class="">
                            <button type="button" class="btn btn-info disabled" aria-expanded="false">
                                PRÓXIMO
                            </button>
                        </td>
                        <td>
                            <button mat-icon-button matTooltip="Descargar" [matTooltipPosition]="'above'" color="accent">
                                <mat-icon matBadgeColor="warn">download</mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td role="button"><strong>3</strong></td>
                        <td role="button"><strong>POP FDA OCTUBRE 2023</strong></td>
                        <td role="button"><strong> 29/08/2023 </strong></td>
                        <td role="button" class="">
                            <button type="button" class="btn btn-info disabled" aria-expanded="false">
                                PRÓXIMO
                            </button>
                        </td>
                        <td>
                            <button mat-icon-button matTooltip="Descargar" [matTooltipPosition]="'above'" color="accent">
                                <mat-icon matBadgeColor="warn">download</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>        
    </form>
</div>
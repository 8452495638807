import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stores-campaign-cutoff-add',
  templateUrl: './stores-campaign-cutoff-add.component.html',
  styleUrls: ['./stores-campaign-cutoff-add.component.scss']
})
export class StoresCampaignCutoffAddComponent implements OnInit {
  newCutoff : any = { id_cutoff : 0, nombre : "", cutoff_date : ""}

  newDateTody : Date = new Date();
  minDate : Date = new Date();
  deadline : Date = new Date();
  

  constructor( public main : MainService,
    public storeService : StoreService,
    @Inject(MAT_DIALOG_DATA) public data : any,
    private dialogRef: MatDialogRef<StoresCampaignCutoffAddComponent>,) { 


    }

  ngOnInit(): void {
    this.newDateTody.setDate(this.newDateTody.getDate() + 1);
    this.deadline = new Date(this.newDateTody);
    if(this.data.edit){
        this.newDateTody = new Date(this.data.data.cutoff_date);
        this.newCutoff.nombre = this.data.data.nombre;
        this.newCutoff.id_cutoff = this.data.data.id;
    }
  }


  save(){
    if(!this.data.edit){
      this.createCutoff();
    }else{
      this.editCutoff();
    }
   
  }

  createCutoff(){
    if(this.newCutoff.nombre!= ""){
      this.main.ShowLoading();
      this.newCutoff.cutoff_date = this.newDateTody.toISOString().slice(0, 10);
      this.storeService.newCutoff(this.newCutoff).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Creado',
              text: 'Corte creado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
      console.log(this.newCutoff);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  editCutoff(){
    if(this.newCutoff.nombre!= ""){
      this.main.ShowLoading();
      this.newCutoff.cutoff_date = this.newDateTody.toISOString().slice(0, 10);
      this.storeService.editCutoff(this.newCutoff).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Editado',
              text: 'Corte editado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
      console.log(this.newCutoff);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }
}
 
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StoreModel } from 'src/app/models/store.model';
import { StoreService } from 'src/app/services/store.service';
import { StoresAttributesEditComponent } from '../../shared/dialogs/stores-attributes-edit/stores-attributes-edit.component';
import { StoresAttributesPendingListComponent } from '../../shared/dialogs/stores-attributes-pending-list/stores-attributes-pending-list.component';
import { StoresCampaignCutoffListComponent } from '../../shared/dialogs/stores-campaign-cutoff-list/stores-campaign-cutoff-list.component';

@Component({
  selector: 'app-stores-list-zone',
  templateUrl: './stores-list-zone.component.html',
  styleUrls: ['./stores-list-zone.component.scss']
})
export class StoresListZoneComponent implements OnInit {

  storeList = new Array<StoreModel>();
  isLoading = true;  

  displayedColumns: string[] = ['id', 'zone', 'store', 'sub', 'farmID', 'city', 'status', 'dateOpen', 'format', 'actions'];
  dataSource!: MatTableDataSource<StoreModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private storeS: StoreService,
    private matDialog: MatDialog,
    private _titleService: Title,
    private _router: Router
  ) {
    this._titleService.setTitle("Buho Nest - Sucursales");
    this.getStoresList();
  }

  ngOnInit(): void {
  }

  getStoresList() {
    this.storeList = this.storeS.getStoresList();
    this.dataSource = new MatTableDataSource(this.storeList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogAttrsPending() {
    this.matDialog.open(StoresAttributesPendingListComponent, {
      panelClass: 'dialogs-lg',
      data: {
        zone : true
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getStoresList();
      }
    );
  }

  openDialogAttributeEditList() {
    this.matDialog.open(StoresAttributesEditComponent, {panelClass: 'dialogs-lg'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getStoresList();
      }
    );
  }

  openDialogCutOffList() {
    this.matDialog.open(StoresCampaignCutoffListComponent, {panelClass: 'dialogs-lg'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getStoresList();
      }
    );
  }

}

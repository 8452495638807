import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SurveysTicketsLogsComponent } from '../../shared/dialogs/surveys-tickets-logs/surveys-tickets-logs.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {
  selectedCampana: any;
  private subscription: Subscription;

  //Material related vars 
  displayedColumns: string[] = ['id', 'producto', 'comentario', 'tipo', 'status','tracking', 'enviado','Acciones','masive'];
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('trackingInput') trackingInput!: ElementRef;
  comp_id : number = 0;
  camp_id : number = 0;
  suc_box_id : number = 0;
  ticketinfo : any;
  ticketlist : any = [];
  statusTracking : any = [];
  statusSurv : any = [];
  ticketTypes : any = [];
  statusTicket : any = [];
  toEditIdsSts: Array<any> = [];
  isBuhoUsr: boolean = false;
  isClaw : boolean = false;
  editingRow: any = null;
  newTrackingNumber: string = ''
  haveExtras!:number;

  constructor(
    private matDialog: MatDialog,  
    private _params: ActivatedRoute,
    private _router : Router,
    private surveyS : SurveysService,
    private main : MainService,
    private _titleService: Title,
    private userS: UsersService,
    private campaignService: CampaignsService,) {

      this._params.params.subscribe(params => {
        if(params.compid&&params.campid&&params.sucid){
          this.camp_id = params.campid;
          this.comp_id = params.compid;
          this.suc_box_id = params.sucid;
          this.isClaw = params.type == "claw";
            console.log(params.compid,params.campid,params.sucid)
        }else{
          this._router.navigateByUrl('/admin/dashboard');
        } 
      });
      this.isBuhoUsr = this.campaignService.isBuhoUsr();
      this.subscription = this.surveyS.selectedCamp$.subscribe(
        (campanaID: number) => {
          this.selectedCampana = campanaID;
          console.log('Selected company in Second Component:', this.selectedCampana);
        }
      );
     }

  ngOnInit(): void {
    this.loadDrops();
    this.loadTickets();   
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.campaignService.hasPermissions(key, buho, client);
  }

  loadTickets(){
    this.main.ShowLoading();
    this.surveyS.getAlerts(this.comp_id, this.camp_id, this.suc_box_id, this.isClaw? "claw" : "nest").subscribe((results:any)=>{
      this.main.HideLoading();
      if(results.message == "Success") {
        this.ticketinfo = results.data.info[0];
        this.ticketlist = results.data.tickets;
        this.dataSource = new MatTableDataSource(this.ticketlist);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log(this.ticketinfo);
        this.haveExtras = results.data.tickets.findIndex((e:any)=> e.extra_ticket === 1 || e.comentario === 'Extraordinario')
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  loadDrops(){
    this.getStatusTracking();
    this.getStatusSurv();
    this.getStatusTickets();
    this.getTicketsTypes();
  }

  getStatusTracking(){
    this.statusTracking = environment.status_seguimientos;
  }
  getStatusSurv(){
    this.statusSurv = environment.status_survticket;
  }

  getStatusSurvDat(idstatus : number){
    return environment.status_survticket.filter(x=> x.id  == idstatus)[0];
  }

  getStatusTrackingDat(idstatus : number){
    return environment.status_seguimientos.filter(x=> x.id  == idstatus)[0];
  }

  getStatusTickets(){
    this.statusTicket = environment.status_ticket;
  }
  getTicketsTypes(){
    this.ticketTypes = environment.tipos_ticket;
  }

  getStatusTicketsDat(idstatus : number){
    return environment.status_ticket.filter(x=> x.id  == idstatus)[0];
  }

  getTicketsTypeDat(idstatus : number){
    return environment.tipos_ticket.filter(x=> x.id  == idstatus)[0];
  }

  statusTicketExclude(){
    return environment.status_ticket.filter(x=> x.id  != 4)
  }

  filterValidTickets(){
    return this.ticketTypes.filter((x:any)=>x.id != 7);
  }

  addTicket(id_encuesta_campana : number, type : number, producto : string, status : number){
    if(status == 2 ? this.hasPermissions('upl_extra_tck',null,true) : this.hasPermissions('upl_sta_al',null,true)){
      Swal.fire({
        title: '¿Estás seguro que deseas crear un nuevo ticket?',
        text: 'Se creará un nuevo ticket tipo ' + this.getTicketsTypeDat(type).nombre + ' para el producto "'+ producto + '"',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.main.ShowLoading();
          this.surveyS.addTicket(this.camp_id, this.suc_box_id, id_encuesta_campana, type, this.comp_id, this.isClaw? "claw" : "nest").subscribe((results:any)=>{
            this.main.HideLoading();
            if(results.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Ticket creado correctamente'          
              }).then(s=>{
                this.loadTickets();
              });
            }
          })
        }
      });
    }
  }

  removeTicket(id_encuesta_campana : number, producto : string){
    if(this.hasPermissions('upl_sta_al',null,true)){
    Swal.fire({
      title: '¿Estás seguro que deseas anular esta alerta?',
      text: 'Esto anulará la alerta y se resolverá automáticamente para '+ producto,
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.main.ShowLoading();
        this.surveyS.removeTicket(this.camp_id, this.suc_box_id, id_encuesta_campana, this.comp_id, this.isClaw? "claw" : "nest").subscribe((results:any)=>{
          this.main.HideLoading();
          if(results.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Ticket anulado correctamente'          
            }).then(s=>{
              this.loadTickets();
            });
          }
        })
      }
    });
   }
  }

  updateTicket(id_encuesta_campana : number, status : number, producto : string){
    if(this.hasPermissions('upl_sta_tck',null,true)){
    Swal.fire({
      title: '¿Estás seguro que deseas cambiar el status del ticket?',
      text: 'Se actualizará el status del ticket a ' + this.getStatusTicketsDat(status).nombre + ' para el producto "'+ producto + '"',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.main.ShowLoading();
        this.surveyS.updateTicket(this.camp_id, this.suc_box_id, id_encuesta_campana, status, this.comp_id, this.getStatusTicketsDat(status).nombre, this.isClaw? "claw" : "nest").subscribe((results:any)=>{
          this.main.HideLoading();
          if(results.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Ticket actualizado correctamente'          
            }).then(s=>{
              this.loadTickets();
            });
          }
        });
      }
    });
    }
  }

  openDialogLogList(item:any) {
    this.matDialog.open(SurveysTicketsLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        item : item,
        id_comp : this.comp_id,
        id_camp : this.camp_id,
        id_suc : this.suc_box_id,
        camp_folder: this.ticketinfo.folder,
        isClaw : this.isClaw
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  checkIfChecked(id_set: any): boolean {
		return this.toEditIdsSts.includes(id_set);
	}

  setTicketListToEdit(id_set:number, evento : any){
    console.log(evento);
      if (evento.currentTarget.checked) {
        if (!this.toEditIdsSts.includes(id_set)) {
          this.toEditIdsSts.push(id_set);
        }
      } else {
        var index = this.toEditIdsSts.indexOf(id_set);
        if (index > -1) {
          this.toEditIdsSts.splice(index, 1);
        }
      }   
      console.log(this.toEditIdsSts);
  }

  private getPlainIds(): string {
		var returnedString: string = "";
		if (this.toEditIdsSts.length > 0) {
			returnedString = '';
			this.toEditIdsSts.forEach((e, i) => {
				returnedString += e;
				if (this.toEditIdsSts.length - 1 != i) {
					returnedString += ",";
				}
			});
		}
		else {
			returnedString = '';
		}
		return returnedString;
	}

  changeMasiveSts(status : number){
    if(this.hasPermissions('upl_sta_tck',null,true)){
    if(this.toEditIdsSts.length > 0){
      Swal.fire({
        title: '¿Estás seguro que deseas cambiar el status de estos tickets?',
        text: 'Se actualizará el status a ' + this.getStatusTicketsDat(status).nombre + ' para ' + this.toEditIdsSts.length +' tickets ',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.main.ShowLoading();
          this.surveyS.updateTicketMasive(this.camp_id, this.suc_box_id, this.toEditIdsSts, status, this.comp_id, this.getStatusTicketsDat(status).nombre, this.isClaw? "claw" : "nest").subscribe((results:any)=>{
            this.main.HideLoading();
            if(results.message == "Success") {
              Swal.fire({
                icon: 'success',
                title: 'Éxito',
                text: 'Tickets actualizados correctamente'          
              }).then(s=>{
                this.loadTickets();
                this.toEditIdsSts = [];
              });
            }
          });
        }
      });
      
    }else{
      Swal.fire({
        icon: 'warning',
        title: 'Alerta',
        text: 'No hay tickets seleccionados'
      });
    }

  }
  }

  showTrackingInput(row: any) {
    this.editingRow = row;
    this.newTrackingNumber = '';
    setTimeout(() => {
      if (this.trackingInput) {
        this.trackingInput.nativeElement.focus(); 
      }
    }, 0);
  }

  saveTrackingNumber(row: any) {
    if(this.newTrackingNumber == '') return;
    this.surveyS.setTrackingNumberTicket(this.suc_box_id, this.newTrackingNumber, row.id).subscribe((results:any)=>{
      if(results.message == "Success") {
        this.loadTickets();
      }
    })
    this.cancelEditing();
  }

  setEnviado(row: any, evEnviado : any) {
    let enviado = evEnviado.checked?1:0;
    this.surveyS.setTicketEnviado(row.id, row.id_ticket, enviado).subscribe((results:any)=>{
      if(results.message == "Success") {
        this.loadTickets();
      }
    })
    this.cancelEditing();
  }
  

  cancelEditing() {
    this.editingRow = null;
    this.newTrackingNumber = '';
  }

}

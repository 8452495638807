<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="d-inline-flex justify-content-between w-100">
            <h4 class="fw-bold py-3 mb-4 text-primary">
                <mat-icon class="me-2 align-middle">mediation</mat-icon>Distribución
            </h4>
            <mat-form-field appearance="outline">
                <mat-label>Corte</mat-label>
                <mat-select (selectionChange)="getCampaign()" name="campaña" >
                    <mat-option value="option">CORTE ENERO 2024</mat-option>
                    <mat-option value="option">CORTE FEBRERO 2024</mat-option>
                    <mat-option value="option">CORTE DICIEMBRE 2023</mat-option>
                    <mat-option value="option">CORTE NOVIEMBRE 2023</mat-option>
                </mat-select>
                <mat-error>Requerido</mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="canloadDist" class="row">
            <div class="col-md-12">
                <div class="col-md-12 ">
                    <div class="card mb-4">
                        <div class="card-body">                        
                            <div class="col-md-12">
                                <div class="overflow-auto w-100">
                                    <div class="float-end">
                                        <button *ngIf="!islocked" mat-raised-button color="primary" (click)="openDialogAddMaterialToDistribution()">
                                            Agregar material a distribución <mat-icon>assignment_add</mat-icon>
                                        </button>
                                        <button *ngIf="!islocked" mat-raised-button color="primary" (click)="openDialogImportDistribution()" class="mt-2 mb-2 ms-4">
                                            Importar distribución
                                            <mat-icon>file_open</mat-icon>
                                        </button>
                                        <!--<button *ngIf="campaignData" (click)="unlockDistribution()" mat-flat-button color="{{islocked ? 'accent': 'primary'}}" class="ms-4"> <mat-icon *ngIf="islocked">lock</mat-icon><mat-icon *ngIf="!islocked">lock_open</mat-icon></button>-->
                                        <button mat-raised-button color="primary" class="mt-2 mb-2 ms-4">
                                            Descargar distribución
                                            <mat-icon>download</mat-icon>
                                        </button>
                                        <!-- <button mat-flat-button color="accent" class="ms-4" *ngIf="canShowLockOption()" (click)="unlockCampaign()"> <mat-icon *ngIf="islocked">lock</mat-icon><mat-icon *ngIf="!islocked">lock_open</mat-icon></button> -->
                                    </div>
                                    
                                    <mat-form-field>
                                        <mat-label>
                                            <mat-icon>search</mat-icon> Buscar</mat-label>
                                        <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Folleto promocional" #input>
                                    </mat-form-field>                                
                                </div>
                            
                                <div class="mat-elevation-z8 table-responsive">
                                    <table mat-table [dataSource]="dataSource" matSort class="w-100">
                            
                                        <ng-container matColumnDef="art">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Material </th>
                                            <td mat-cell *matCellDef="let row"> {{row.art}} </td>
                                        </ng-container>
                                        
                            
                                        <ng-container matColumnDef="groups">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Segmentos </th>
                                            <td mat-cell *matCellDef="let row"> {{row.groups}} </td>
                                        </ng-container> 

                                        <ng-container matColumnDef="responsable">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsable </th>
                                            <td mat-cell *matCellDef="let row"> {{row.responsable}} </td>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="registro">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Registro</th>
                                            <td mat-cell *matCellDef="let row"> {{row.registro}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                            <td mat-cell *matCellDef="let row" class="text-center">
                                                <button (click)="openEditGroupModal(row.art, islocked)" mat-icon-button [matTooltip]="islocked ? 'Ver segmentos': 'Editar segmentos'"  [matTooltipPosition]="'above'" color="accent">
                                                    <mat-icon>group_work</mat-icon>
                                                </button>
                                                <button *ngIf="!islocked" (click)="openEditMaterialModal(row.art)" mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                                <button *ngIf="!islocked" mat-icon-button matTooltip="Eliminar" [matTooltipPosition]="'above'" color="warn">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>
                                                        
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        
                                        <tr class="mat-row" *matNoDataRow>
                                            <td *ngIf="dataSource.data.length == 0" class="mat-cell text-center" colspan="6">Seleccione una campaña</td>
                                            <td *ngIf="dataSource.data.length > 0" class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                        </tr>
                                        
                                    </table>
                            
                                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                                        <mat-progress-spinner color="primary" mode="indeterminate">
                                        </mat-progress-spinner>
                                    </div>
                                    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { TemplatesFileViewertComponent } from '../shared/dialogs/templates-file-viewer/templates-file-viewer.component';

import * as JSZip from 'jszip';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
const fs = require('file-saver');

@Component({
  selector: 'app-template-design',
  templateUrl: './template-design.component.html',
  styleUrls: ['./template-design.component.scss']
})
export class TemplateDesignComponent implements OnInit {
  modules : any;
  isBuhoUsr : boolean = false;
  hostName: string = "";
  constructor(private userS: UsersService,
    private _router : Router,
    private matDialog: MatDialog,
    private main : MainService,) { 
    this.modules = this.userS.getUserModules();
    this.isBuhoUsr = (this.userS.getUser().id_rol == environment.role_buho || this.userS.getUser().id_rol == environment.role_admin_id);
    this.hostName = window.location.protocol + '//' + window.location.host;
  }

  dataMats = [
    { namemat : "Cenefa Self",
      updated : '01/07/2023',
      links : [
        { 
         url: "/assets/docs/res_templates/01-CENEFA_SELF/_DOCU_CENEFA_SELF.pdf",
         name : "_DOCU_CENEFA_SELF.pdf",
         type : "pdf"
        },
        { 
          url: "/assets/docs/res_templates/01-CENEFA_SELF/PLANTILLA_CENEFA_SELF.ai",
          name : "PLANTILLA_CENEFA_SELF.ai",
          type : "ai"
         }
      ]
    },
    { namemat : "Copetes Monitor",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/02-COPETES_MONITOR/_DOCU_COPETE.pdf",
       name : "_DOCU_COPETE.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/02-COPETES_MONITOR/TEMPLATE_COPETE.ai",
        name : "TEMPLATE_COPETE.ai",
        type : "ai"
       }
      ]
    },
    { namemat : "Danglers",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/03-DANGLERS/_DOCU_DANGLER.pdf",
       name : "_DOCU_DANGLER.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/03-DANGLERS/PLANTILLA_DANGLER.ai",
        name : "PLANTILLA_DANGLER.ai",
        type : "ai"
       }
      ]
    },
    { namemat : "Danglers Botadero",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/04-DANGLERS_BOTADERO/_DOCU_DANGLER_BOTADERO.pdf",
       name : "_DOCU_DANGLER_BOTADERO.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/04-DANGLERS_BOTADERO/PLANTILLA_DANGLER_BOTADERO.ai",
        name: "PLANTILLA_DANGLER_BOTADERO.ai",
        type : "ai"
      }
      ]
    },    
    { namemat : "Poster Cancel",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/05-POSTER_CANCEL/_DOCU_POSTER_CANCEL.pdf",
       name : "_DOCU_POSTER_CANCEL.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/05-POSTER_CANCEL/PLANTILLA_POSTER_CANCEL.ai",
       name : "PLANTILLA_POSTER_CANCEL.ai",
       type : "ai"
      }
      ]
    },
    { namemat : "Poster CheckOut",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/06-POSTER_CHECKOUT/_DOCU_POSTER_CHECKOUT.pdf",
       name : "_DOCU_POSTER_CHECKOUT.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/06-POSTER_CHECKOUT/PLANTILLA_POSTER_CHECKOUT.ai",
        name : "PLANTILLA_POSTER_CHECKOUT.ai",
        type : "ai"
      }
      ]
    },
    { namemat : "Preciadores Caja y Estrella",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/_DOCU_PRECIADOR_CAJA_B.pdf",
       name : "_DOCU_PRECIADOR_CAJA_B.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/_DOCU_PRECIADOR_CAJA.pdf",
        name : "_DOCU_PRECIADOR_CAJA.pdf",
        type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/_DOCU_PRECIADOR_ESTRELLA_B.pdf",
        name : "_DOCU_PRECIADOR_ESTRELLA_B.pdf",
        type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/_DOCU_PRECIADOR_ESTRELLA.pdf",
        name : "_DOCU_PRECIADOR_ESTRELLA.pdf",
        type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/PLANTILLA_PRECIADOR_CAJA_B.ai",
        name : "PLANTILLA_PRECIADOR_CAJA_B.ai",
        type : "ai"
      },
      { 
        url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/PLANTILLA_PRECIADOR_CAJA.ai",
        name :"PLANTILLA_PRECIADOR_CAJA.ai",
        type : "ai"
      },
      { 
        url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/PLANTILLA_PRECIADOR_ESTRELLA_B.ai",
        name : "PLANTILLA_PRECIADOR_ESTRELLA_B.ai",
        type : "ai"
      },
      { 
        url: "/assets/docs/res_templates/07-PRECIADORES_CAJA_Y_ESTRELLA/PLANTILLA_PRECIADOR_ESTRELLA.ai",
        name : "PLANTILLA_PRECIADOR_ESTRELLA.ai",
        type : "ai"
      }
      ]
    },
    { namemat : "Preciadores Cenefa",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/08-PRECIADORES_CENEFA/_DOCU_PRECIADOR_CENEFA.pdf",
       name : "_DOCU_PRECIADOR_CENEFA.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/08-PRECIADORES_CENEFA/PLANTILLA_PRECIADOR_CENEFA.ai",
        name : "PLANTILLA_PRECIADOR_CENEFA.ai",
        type : "ai"
       },
      ]
    },
    { namemat : "Protector",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/09-PROTECTOR/_DOCU_PROTECTOR.pdf",
       name :"_DOCU_PROTECTOR.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/09-PROTECTOR/PLANTILLA_PROTECTOR.ai",
        name :  "PLANTILLA_PROTECTOR.ai",
        type : "ai"
       }
      ]
    },
    { namemat : "Refricalco",
    links : [
      { 
       url: "/assets/docs/res_templates/10-REFRICALCO/_DOCU_REFRICALCO.pdf",
       name : "_DOCU_REFRICALCO.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/10-REFRICALCO/PLANTILLA_REFRICALCO.ai",
        name : "PLANTILLA_REFRICALCO.ai",
        type : "ai"
      },

      ]
    },
    { namemat : "Stopper",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/11-STOPPER/_DOCU_STOPPER.pdf",
       name :"_DOCU_STOPPER.pdf",
       type : "pdf"
      },
      { 
        url: "/assets/docs/res_templates/11-STOPPER/PLANTILLA_STOPPER.ai",
        name : "PLANTILLA_STOPPER.ai",
        type : "ai"
       }
      ]
    },
    { namemat : "Unibanner",
    updated : '01/07/2023',
    links : [
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/_DOCU_UNIBANNERS.pdf",
       name : "_DOCU_UNIBANNERS.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_4x40.pdf",
       name : "PLANTILLA_UNIBANNER_4x40.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_4x50.pdf",
       name : "PLANTILLA_UNIBANNER_4x50.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_4x60.pdf",
       name : "PLANTILLA_UNIBANNER_4x60.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_6x40.pdf",
       name : "PLANTILLA_UNIBANNER_6x40.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_6x50.pdf",
       name : "PLANTILLA_UNIBANNER_6x50.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_6x60.pdf",
       name : "PLANTILLA_UNIBANNER_6x60.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_8x40.pdf",
       name : "PLANTILLA_UNIBANNER_8x40.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_8x50.pdf",
       name : "PLANTILLA_UNIBANNER_8x50.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_8x60.pdf",
       name : "PLANTILLA_UNIBANNER_8x60.pdf",
       type : "pdf"
      },
      { 
       url: "/assets/docs/res_templates/12-UNIBANNER/PLANTILLA_UNIBANNER_8x80.pdf",
       name : "PLANTILLA_UNIBANNER_8x80.pdf",
       type : "pdf"
      }
      ]
    }

  ]

  ngOnInit(): void {
    if(!this.hasRecursos()){
      this._router.navigateByUrl('/admin/dashboard');
    }
  }

  hasRecursos(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'recu').length > 0
  }

  openFileViewer(register : any) {
    this.matDialog.open(TemplatesFileViewertComponent, {
      panelClass: ['dialogs-md'],
      data: {
        register : register
      }
    }).afterClosed().subscribe(
      resp => {
        if (resp.reload) {

        }
      }
    );
  }

  async downloadAll(){
    let allLinks = this.dataMats.flatMap((item) => item.links);
    console.log( allLinks.length );
    var promiseValidation = 0; 
    var zip = new JSZip();

    Swal.fire({
      icon: 'info',
      title: 'Descargando archivos...',
      text:'Esto puede tardar unos momentos, favor de no cerrar o recargar la pagina.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    var proccData = new Promise((resolve, reject) => {
   
   this.dataMats.forEach(async (x, i)=>{
      try{
      console.log("empezando descarga")
      let folder = zip.folder(x.namemat);
        x.links.forEach(async (y, i)=>{
          await this.main.getFilefromUrl(this.hostName + y.url).then(fileA=>{
            if(fileA){
              folder?.file(y.name, fileA );
              //console.log("file added")
            }
            promiseValidation = promiseValidation + 1; 
            console.log(promiseValidation);
            if (promiseValidation == allLinks.length) {
              //console.log("fin validacion");
              resolve("ok");
            }
          }); 
        });
        
      }catch(ex){ 
        console.log('error' + ex)
        promiseValidation = promiseValidation + 1; 
      }
      }); 
    });

    proccData.then(x=>{
      zip.generateAsync({ type: "blob" })
      .then((content) => {
        let d = new Date();
        console.log("genero archivo para descarga...");
        fs.saveAs(content, "TemplateDesignGeneral.zip");
        Swal.fire({
          icon: 'success',
          title: 'Archivos descargados',
          text: 'Se descargará en su navegador en unos momentos'          
        }).then(() => {            
         
        });
      });
     })

  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsVoboAddComponent } from '../../shared/dialogs/projects-vobo-add/projects-vobo-add.component';
import Swal from 'sweetalert2';
import { ProjectsService } from 'src/app/services/projects.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-project-pop-config',
  templateUrl: './project-pop-config.component.html',
  styleUrls: ['./project-pop-config.component.scss']
})
export class ProjectPopConfigComponent {
    selectedSucursalCorte: any;
    reglasAlcance: any[] = [];
    alcance: string = '';
    codigosPromo = [
      "7501089809452",
      "7501089809452",
      "7501089809469",
      "7501089809469",
    ]

    sucursalesCorte = [
      { nombre: 'Corte Septiembre' },
      { nombre: 'Corte Agosto' }
    ];
  
    digramationList = [{pop_id: 0, name: "MDP Trovicel", price: 135, cantidad: 1, reglas : [{
      atributo: 'MDP Trovicel',
      comparador: null,
      valor: 1
    }]}];

    atributosSucursales = ['DANGLER', 'MDP Trovicel', 'MDP Couche', 'Mesa Promocional',
      '8 x 60 (Banner Trad.)',
      '8 x 80 (Banner Trad.)'];
    comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];

    popid = 0;
    popActual : any = [];
    mediaActual : any;

  //projectMedia: Array<any> = [
  //  { id: 1, name: "DANGLER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: false, formato : "Reglas" },
  //  { id: 2, name: "COPETE DE MESA PROMOCIONES", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" },
  //  { id: 3, name: "BANNER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: true, formato : "Reglas" },
  //  { id: 4, name: "POSTER CHECKOUT", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" }
  //];
  
   //poplistActual : Array<any> = [];
  
   //poplist: Array<any> = [
   //  { id: 1, media_id: 1, title: 'Dangler', info: [['Posiciones', '-'], ['Prioridad', '-']], rules: []},
  
   //  { id: 5, media_id: 2, title: 'MDP Trovicel', info: [['Posiciones', '3'], ['Prioridad', '-']], rules: [['Copete Mesa Promocional', 'TROVICEL(RIGIDO)']]},
   //  { id: 6, media_id: 2, title: 'MDP Couche', info: [['Posiciones', '3'], ['Prioridad', '-']], rules: [['Copete Mesa Promocional', 'COUCHE']] },
   //
  
   //  { id: 9,  media_id: 3, title: 'Version 1', info: [['Posiciones', '2'], ['Prioridad', '-']], rules: []},
   //  { id: 10, media_id: 3, title: 'Version "N"', info: [['Posiciones', '2'], ['Prioridad', '-']], rules: [] },
  
   //  { id: 13, media_id: 4, title: 'Poster Checkout 1', info: [['Posiciones', '-'], ['Prioridad', '1']], rules: [['POST', '1']]},
   //  { id: 14, media_id: 4, title: 'Poster Checkout 2', info: [['Posiciones', '-'], ['Prioridad', '2']], rules: [['POST', '2']] },
   //  { id: 15, media_id: 4, title: 'Poster Checkout 3', info: [['Posiciones', '-'], ['Prioridad', '3']], rules: [] },
   //];

  
    constructor(private matDialog: MatDialog, private projService : ProjectsService, private route: ActivatedRoute, private router: Router) {
      //this.agregarRegla(this.digramationList[1].reglas); 
    }

    ngOnInit(): void {
      this.getData();
      console.log("fdsf");
    }
  
    onFileSelected(event: any) {
      const file = event.target.files[0];
      // Lógica para manejar el archivo
      console.log(file);
    }
  
    agregarRegla(list : any) {
      list.push({
        atributo: null,
        comparador: null,
        valor: null
      });
    }
  
    eliminarRegla(index: number, list :any) {
      list.splice(index, 1);
    }
  
    guardarConfiguracion() {
     
      console.log('Configuración guardada:', {
        sucursalCorte: this.selectedSucursalCorte,
        reglasAlcance: this.reglasAlcance
      });
    }
  
    openAddEditVobo(vobo: any) {
      this.matDialog.open(ProjectsVoboAddComponent, {
        panelClass: ['dialogs-sm'],
        data: {
          vobo: vobo
        }
      }).afterClosed().subscribe(resp => {
       
      });
    }

    addDigramation(){

      Swal.fire({
        title: 'Nombre de la configuración',
        input: 'text',  
        inputPlaceholder: 'Nombre de la configuración',
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`,
        inputValidator: (value :string) => {
            if (!value) {
                return 'El nombre es necesario'
            }else{
              return "";
            }
        }
      }).then((result) => {
          if (result.isConfirmed) {
             this.digramationList.push({pop_id : this.popid , name: result.value.trim(), price:0, cantidad : 1, reglas : []});
          }
      });
  
    }

    showFilters = true;
    showCantidad = true;
    showPrecio = true;
    async getData(): Promise<void> {
      try {
        // Extract ID
        const url: ParamMap = await firstValueFrom(this.route.paramMap);
        this.popid = Number(url.get('popid'));
        this.popActual = this.getPopList(this.popid);
        this.digramationList = this.popActual.rules;

        //this.popActual.rules[0][1] == '-' //Posiciones
        //this.popActual.rules[1][1] == '-' //Prioridad

        if(this.popActual.info[0][1] == '-' &&
        this.popActual.info[1][1] == '-'){
          this.showFilters = false;
          this.showCantidad = true;
        }
        
        if(this.popActual.info[0][1] != '-' &&
          this.popActual.info[1][1] == '-'){
            this.showFilters = true;
            this.showCantidad = false;
        }

        if(this.popActual.info[0][1] == '-' &&
          this.popActual.info[1][1] !== '-'){
            this.showFilters = false;
            this.showCantidad = false;
        }

        if (!this.popid)
          throw new Error('No se recibió ningún ID.');
      } catch (error) {
        console.error(error);
      }
    }

    getPopList(id:number){
      return this.projService.poplist.filter(x=>( x.id == id))[0];
    }

    getMedia(id:number){
      return this.projService.projectMedia.filter(x=>( x.id == id))[0];
    }

  }
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoresCampaignCutoffAddComponent } from '../../shared/dialogs/stores-campaign-cutoff-add/stores-campaign-cutoff-add.component';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/services/main.service';
import { StoreService } from 'src/app/services/store.service';


@Component({
  selector: 'app-stores-campaign-cutoff',
  templateUrl: './stores-campaign-cutoff.component.html',
  styleUrls: ['./stores-campaign-cutoff.component.scss']
})
export class StoresCampaignCutoffComponent implements OnInit {

  constructor(
    private matDialog: MatDialog,
    private _location: Location,
    private main: MainService,
    private storeS : StoreService
  ) { }

  cutoffs : any = [];

  ngOnInit(): void {
    this.getStoreCutoff();
  }

  openDialogAddCutoff(edit : boolean = false, data : any = null) {
    this.matDialog.open(StoresCampaignCutoffAddComponent, {panelClass: 'dialogs-sm', data:{edit : edit, data : data}}).afterClosed().subscribe(
      resp => {
        this.getStoreCutoff();
      }
    );
  }


  getStoreCutoff(){
    this.main.ShowLoading();
    this.storeS.getCutoffs().subscribe(
      (result : any) => {
        
      this.main.HideLoading();
        this.cutoffs = result.data;

        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
     
      }
    );
  }

  nowCutoffs(){
    let fechaHoy = new Date();

    return this.cutoffs.filter((objeto:any) => new Date(objeto.cutoff_date) >= fechaHoy);
  }

  oldCutoffs(){
    let fechaHoy = new Date();

    return this.cutoffs.filter((objeto:any) => new Date(objeto.cutoff_date) < fechaHoy);
  }

  goBack() {
    this._location.back();
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.storeS.hasPermissions(key, buho, client);
  }

  downloadExcel(idFile : string, nombre : string): void {
    this.main.ShowLoading();
    this.storeS.getCuttOffData(idFile).subscribe(
      (result : any) => {
        
        this.main.HideLoading();
        console.log(result.data);
        this.main.generateExcel(result.data, "Corte_Sucursal_" + nombre, "sucursales")

      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
     
      }
    );
  }

}

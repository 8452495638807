import { Component,Inject,OnInit } from '@angular/core';
import { newStrategyModel } from 'src/app/models/strategy.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ProjectsService, PreProyecto } from 'src/app/services/projects.service';

@Component({
  selector: 'app-preproject-strategy-add',
  templateUrl: './preproject-strategy-add.component.html',
  styleUrls: ['./preproject-strategy-add.component.scss']
})
export class PrepojectStrategyAddComponent implements OnInit {

  newStrategy : PreProyecto = new PreProyecto();

  tiposImpresion: string[] = [
    "INDIGO DIGITAL",
    "OFFSET",
    "ROTATIVA",
    "DIGITAL (DOCUCOLOR)",
    "FLEXOGRAFÍA",
    "SERIGRAFÍA",
    "GRAN FORMATO",
    "GRAN FORMATO/TINTA LATEX",
    "PLOTTER CAMA PLANA",
    "SUBLIMADO",
    "TAMPOGRAFÍA",
    "OFFSET PRENSA PLANA",
    "PLOTTER DE CORTE",
    "N/A"
  ];
  
  tiposArea: string[] = [
    "Comercialización",
    "Trade",
    "Marketing"
  ];
  
  tiposEnvio: string[] = [
    "TERRESTRE",
    "AÉREO",
    "LOCAL",
    "N/A"
  ];
  
  tiposRecepcion: string[] = [
    "SUCURSALES",
    "PLAZAS",
    "GUÍA",
    "GUÍA ESTAFETA",
    "GUÍA PAQUETEXPRESS",
    "DEACERO OFICINAS",
    "DEACERO BODEGA",
    "DEACERO SALTILLO PLANTA ALAMBRE",
    "DEACERO MONTERREY PLANTA ALAMBRE",
    "DEACERO PLANTA GUADALUPE",
    "CEMIX CORPORATIVO",
    "PAK2GO",
    "ULTIMA MILLA",
    "N/A"
  ];

  id_camp = 0;
  id_str = 0;
  camp_data : any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private projService : ProjectsService,
    private main : MainService,
    private dialogRef: MatDialogRef<PrepojectStrategyAddComponent>
  ) { 
    this.isEdit = this.data.edit;
    this.isBuhoUsr = campaignService.isBuhoUsr();
    if(this.data.id_str != 0){
      this.id_str = this.data.id_str;
      this.LoadStrategyData(this.main.cloneObj(this.data.str));
    }else{
     this.newStrategy.id_camp = this.data.id_campana;
     this.camp_data = this.data.camp_data;
     this.getNewStrategy();
     //this.newStrategy.folder_camp = this.data.folder_camp;
    }
    
  }
  isBuhoUsr: boolean = false;
  isEdit : boolean = false;

  ngOnInit(): void {
  }

  LoadStrategyData(strData : any){
    this.newStrategy = strData;
    console.log(this.newStrategy);
  }

  getNewStrategy(){
    let nowdate = new Date();

    let day = nowdate.getDate(); 
    let month = nowdate.getMonth() + 1; 
    let year = nowdate.getFullYear(); 

    let newdate = new Date(nowdate); 

    newdate.setDate(newdate.getDate() + 7);

    let day_end = newdate.getDate(); 
    let month_end = newdate.getMonth() + 1; 
    let year_end = newdate.getFullYear(); 

    this.newStrategy.nombre_str = "";
    this.newStrategy.cliente = this.camp_data? this.camp_data.nombre_comp : "";
    this.newStrategy.nombre = "";
    this.newStrategy.folio = "";
    this.newStrategy.proyecto = this.camp_data? this.camp_data.camp_nombre : "";
    this.newStrategy.contacto = "";
    this.newStrategy.tipo = "";
    this.newStrategy.area_responsable = "";
    this.newStrategy.impresion = "";
    this.newStrategy.color = "";
    this.newStrategy.tipo_envio = "";
    this.newStrategy.recepcion!= "";
    this.newStrategy.dia_inicio = day
    this.newStrategy.mes_inicio = month.toString();
    this.newStrategy.ano_inicio = year;
    this.newStrategy.dia_fin = day_end;
    this.newStrategy.mes_fin = month_end.toString();
    this.newStrategy.ano_fin = year_end;
  }

  saveStrategy(){
    if((new Date( this.newStrategy.ano_inicio, parseInt(this.newStrategy.mes_inicio), this.newStrategy.dia_inicio)) <  
    (new Date( this.newStrategy.ano_fin, parseInt(this.newStrategy.mes_fin), this.newStrategy.dia_fin))){
      if(this.newStrategy.nombre_str != "" &&
      this.newStrategy.cliente != "" &&
      this.newStrategy.nombre != "" &&
      this.newStrategy.folio!= "" &&
      this.newStrategy.proyecto!= "" &&
      this.newStrategy.contacto!= "" &&
      this.newStrategy.tipo!= "" &&
      this.newStrategy.area_responsable!= "" &&
      this.newStrategy.impresion!= "" &&
      this.newStrategy.color!= "" &&
      this.newStrategy.tipo_envio!= "" &&
      this.newStrategy.recepcion!= "" &&
      this.newStrategy.dia_inicio!= 0 &&
      this.newStrategy.mes_inicio!= "" &&
      this.newStrategy.ano_inicio!= 0 &&
      this.newStrategy.dia_fin!= 0 &&
      this.newStrategy.mes_fin!= "" &&
      this.newStrategy.ano_fin!= 0){
      console.log(this.newStrategy);
      this.main.ShowLoading();
      this.projService.newPreprojectForm(this.newStrategy).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Creado',
              text: 'Formulario creada correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
    console.log(this.newStrategy);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'La fecha inicio no puede ser mayor que la fecha fin',
        text: 'Por favor revise las fechas'
      });
    }

  }

  editStrategy(){
    if((new Date( this.newStrategy.ano_inicio, parseInt(this.newStrategy.mes_inicio), this.newStrategy.dia_inicio)) <  
    (new Date( this.newStrategy.ano_fin, parseInt(this.newStrategy.mes_fin), this.newStrategy.dia_fin))){
    if(this.newStrategy.nombre_str != "" &&
      this.newStrategy.cliente != "" &&
      this.newStrategy.nombre != "" &&
      this.newStrategy.folio!= "" &&
      this.newStrategy.proyecto!= "" &&
      this.newStrategy.contacto!= "" &&
      this.newStrategy.tipo!= "" &&
      this.newStrategy.area_responsable!= "" &&
      this.newStrategy.impresion!= "" &&
      this.newStrategy.color!= "" &&
      this.newStrategy.tipo_envio!= "" &&
      this.newStrategy.recepcion!= "" &&
      this.newStrategy.dia_inicio!= 0 &&
      this.newStrategy.mes_inicio!= "" &&
      this.newStrategy.ano_inicio!= 0 &&
      this.newStrategy.dia_fin!= 0 &&
      this.newStrategy.mes_fin!= "" &&
      this.newStrategy.ano_fin!= 0){
      console.log(this.newStrategy);
      this.main.ShowLoading();
      this.projService.updatePreprojectForm(this.newStrategy).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Editado',
              text: 'Formulario editado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
    console.log(this.newStrategy);
  }else{
    Swal.fire({
      icon: 'error',
      title: 'La fecha inicio no puede ser mayor que la fecha fin',
      text: 'Por favor revise las fechas'
    });
  }
  }
}

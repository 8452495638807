import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SurveysTicketsLogsComponent } from '../../shared/dialogs/surveys-tickets-logs/surveys-tickets-logs.component';
import { ChartComponent } from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart } from 'ng-apexcharts';
import { Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any;
  responsive: ApexResponsive[];
};

export interface Metrics {
  total_alertas_pendientes: number;
  total_cajas: number;
  total_cajas_perfectas: number;
  total_encuestas_contestadas: number;
  total_encuestas_pendientes: number;
  total_entregados: number;
  total_extra_tickets: number;
  total_tickets_pendientes: number;
  total_tickets_resueltos: number;
  total_tickets_revision: number;
  tiempo_respuesta: number;
}

export interface TotalReports {
  total_anulacion: number;
  total_faltante: number;
  total_kit_incompleto: number;
  total_material_danado: number;
  total_otro: number;
  total_solicitud_adicional: number;
  promedio_creacion_tickets: number;
  promedio_atencion_buho: number;
  promedio_resolucion_buho: number;
  cant_tickets_resueltos: number;
  cant_cajas_reenviadas: number;
  total_st_pend_revision: number;
  total_st_revision: number;
  total_st_resuelto: number;
  total_st_anulacion: number;
}

@Component({
  selector: 'app-ticket-all-list',
  templateUrl: './ticket-all-list.component.html',
  styleUrls: ['./ticket-all-list.component.scss']
})
export class TicketAllListComponent implements OnInit {
  private subscription?: Subscription;
  //Material related vars 
  displayedColumns: string[] = ['nombre_campana', 'nombre_sucursal', 'nombre_zona', 'comentario','tipo','status' ,'creacion_ticket', 'Acciones'];
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  companias : any = [];
  usrcompany : number = 0;
  campaignsData : any = {};
  zones : any = [];
  expanded_filters : boolean = true;

  //---filter group ---
  selectedcompany : any = null;
  selectedzone : any = null;
  selectedzones : Array<number> = [];
  selectedCamp : any = 0;
  selectedStsSurv : any = null;
  selectedStsTrack : any = null;
  //---end---
  statusTracking : any = [];
  statusSurv : any = [];
  datatickets : any = [];
  hostName : any = "";
  //  this.isback = _router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().includes("/admin/ticket/");
  isback? : boolean = false;
  isClaw? : boolean = false;
  statusTicket: { id: number; nombre: string; class: string; }[] = [];
  ticketTypes: {
    id: number; nombre: string; class: string; 
  }[] = [];

    metrics!: Metrics;
    totalReports!: TotalReports;

  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> = {
    series: [44, 55, 13, 43, 22],
    chart: {
      width: 380,
      type: 'pie'
    },
    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  constructor(
    private matDialog: MatDialog,  
    private _params: ActivatedRoute,
    private _router : Router,
    private surveyS : SurveysService,
    private main : MainService,
    private _titleService: Title,
    private userS: UsersService,
    private campaignService: CampaignsService,
) { 
     //if(_router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString()!= undefined){
     //  this.isback = _router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().includes("/admin/ticket/");
     //}
     //console.log(this.isback);
     
    }
  

  ngOnInit(): void {
    if(this.userS.isBuhoUsr()){
      this.getCompanias();
    }else{
      this.usrcompany = this.userS.getUser().companies[0].id
      this.getCompanias();
      //this.loadFilters();
      //this.loadCampaigns();
      //this.loadZones();
    }
    this.getStatusTracking();
    this.getStatusSurv();
    this.getStatusTickets();
    //this.loadTickets();
    this.hostName = window.location.protocol + '//' + window.location.host + "/surveys/";
  }

  // fillFilters(){
  //   if(this.isback){
  //   this.selectedzone = this.surveyS.getSelecteZone();
  //   this.selectedCamp  = this.surveyS.getSelecteCamp();
  //   this.selectedStsSurv  = this.surveyS.getSelecteStsSurv();
  //   this.selectedStsTrack  = this.surveyS.getSelecteStsTrack();
  //   }else{
  //     this.resetFilters();
  //   }
  // }

  // updateOverallFilters(){
  //   this.surveyS.setSelecteZone(this.selectedzone);
  //   this.surveyS.setSelecteCamp(this.selectedCamp);
  //   this.surveyS.setSelecteStsSurv(this.selectedStsSurv);
  //   this.surveyS.setSelecteStsTrack(this.selectedStsTrack);
  // }

  // resetFilters(){
  //   this.surveyS.setSelecteZone(null);
  //   this.surveyS.setSelecteCamp(0);
  //   this.surveyS.setSelecteStsSurv(null);
  //   this.surveyS.setSelecteStsTrack(null);
  // }

  summary :any;
  loaded = false;

  getCampanaName(id : any){
    if(this.isClawCamp(id)){
      const sel_cn = this.campaignsData.claw.find((x:any)=>{ return x.id == parseInt(id.toString().replace('-c', ''))});
      return sel_cn?.name;
    }else{
      return this.campaignsData.nest.filter((x:any)=>{ return x.id ==  id})[0].name;
    }
  }

  loadTickets(){
    this.main.ShowLoading();

    this.surveyS.getAlltickets(this.isClawCamp(this.selectedCamp) ? this.selectedCamp.replace('-c','') : this.selectedCamp, this.isClawCamp (this.selectedCamp) ? "claw" : "nest", this.selectedzones).subscribe((results:any)=>{
      this.main.HideLoading();
      if(results.message == "Success") {

        if(this.selectedCamp != 0){
          this.campanaName = this.getCampanaName(this.selectedCamp);
        }else{
          this.campanaName = ""
        }
        
        this.datatickets = results.data;
        this.dataSource = new MatTableDataSource(this.datatickets);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //this.updateOverallFilters();
        //this.main.ShowLoading();
        this.isClaw = this.isClawCamp(this.selectedCamp);
        this.loaded = false;
        let list_id_zones = this.zones.map((e:any)=>e.id);
        if(this.selectedzones) {
          list_id_zones = this.selectedzones;
        }
        this.surveyS.getMetricsTickets(this.selectedCamp.replace('-c','') ,list_id_zones ,  this.selectedStsSurv, this.selectedStsTrack).subscribe({
          next: ({data}) => {
            this.metrics = data[0][0];
            this.totalReports = data[1][0];
            this.chartOptions = {
              ...this.chartOptions,
              series: [this.metrics.total_encuestas_contestadas, this.metrics.total_encuestas_pendientes]
            }
            this.loaded = true;
          },
          error: () => {
            this.loaded = true;
          }
        });

        this.surveyS.getTicketsSummary(this.isClawCamp(this.selectedCamp) ? this.selectedCamp.replace('-c','') : this.selectedCamp, this.isClaw? "claw" : "nest" ).subscribe((results:any)=>{
          this.summary = results.data;
        });
        
        this.expanded_filters = true;
        setTimeout(() => {
          this.expanded_filters = false;
        }, 150);
        
      }
    });
  } 

  loadMetrics(): void {
    let list_id_zones = this.zones.map((e:any)=>e.id);
    if(this.selectedzone) {
      list_id_zones = [this.selectedzone];
    }
    
    this.surveyS.getMetrics(this.selectedCamp.replace('-c','') ,list_id_zones ,  this.selectedStsSurv, this.selectedStsTrack).subscribe({
      next: ({data}) => {
        this.metrics = data[0][0];
        this.totalReports = data[1][0];
        this.chartOptions = {
          ...this.chartOptions,
          series: [this.metrics.total_encuestas_contestadas, this.metrics.total_encuestas_pendientes]
        }
        this.main.HideLoading();
      },
      error: () => {
        this.main.HideLoading();
      }
    });
  }

  promedioTiempoRespues(){
    return this.metrics.tiempo_respuesta?this.metrics.tiempo_respuesta:'-';
    //return this.summary.totals.length === 0? '-' : this.summary.totals.reduce((x:any, y:any) => x + y.dias_contesta, 0)/this.summary.totals.length;
  }

  promedioCreacionTickets(){
    return this.totalReports.promedio_creacion_tickets?this.totalReports.promedio_creacion_tickets: '-';
    //return  this.summary.summary.filter((x:any)=>x.tiempo_creacion_tickets != null).length === 0 ? '-' : this.summary.summary.reduce((x:any, y:any) => x + y.tiempo_creacion_tickets, 0)/this.summary.summary.filter((x:any)=>x.tiempo_creacion_tickets != null).length;
  }

  promedioAtencionTickets(){
    return this.totalReports.promedio_atencion_buho?this.totalReports.promedio_atencion_buho:'-';
    //return this.summary.summary.filter((x:any)=>x.tiempo_atencion_buho != null).length === 0? '-' : this.summary.summary.reduce((x:any, y:any) => x + y.tiempo_atencion_buho, 0)/this.summary.summary.filter((x:any)=>x.tiempo_atencion_buho != null).length;
  }

  promedioResTickets(){
    return this.totalReports.promedio_resolucion_buho?this.totalReports.promedio_resolucion_buho:'-';
    //return this.summary.summary.filter((x:any)=>x.tiempo_resolucion_buho != null).length === 0? '-' : this.summary.summary.reduce((x:any, y:any) => x + y.tiempo_resolucion_buho, 0)/this.summary.summary.filter((x:any)=>x.tiempo_resolucion_buho != null).length;
  }

  generateExcelTotal(){
    if(this.summary.totals.length == 0){
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: 'No hay datos de totales actualmente'          
      }).then(s=>{
        
      });
    } else{
      this.main.generateExcel(this.summary.totals_all, "Encuestas_Total_Tiempo_Respuesta", "respuestas");
    }
  }

  generateExcelResumen(){
      if(this.summary.totals.length == 0){
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: 'No hay datos de resumen actualmente'          
        }).then(s=>{
          
        });
      }else{
        this.main.generateExcel(this.summary.summary, "Encuestas_Resumen_Tiempo_Respuesta", "resumen");
      }
  }

  getTypesCount(type_id : number){
   return this.datatickets.filter((x:any)=> x.tipo == type_id).length;
  }

  getStatusCount(status_id : number){
   return this.datatickets.filter((x:any)=> x.status == status_id).length;
  }

  campanaName = "";

  updateCampanaName(){
    if(this.isClaw){
      const sel_cn = this.campaignsData.claw.find((x:any)=>{ return x.id == parseInt(this.selectedCamp.replace('-c', ''))});
      this.campanaName = sel_cn?.name;
    }else{
      this.campanaName = this.campaignsData.nest.filter((x:any)=>{ return x.id ==  this.selectedCamp})[0].name;
    }
    console.log("selected campana name", this.selectedCamp, parseInt(this.selectedCamp.replace('-c', '')));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  getCompanias(){
    this.userS.getCompanies().subscribe((results: any)=>{
      if(results.message == "Success") {
        this.companias = [];
        
        if(results.data.length>1) {
          this.companias.push({ "id": null, "name": "-- Todas --" });
        }
        this.companias = this.companias.concat(results.data);

        if(results.data.length==1) {
          this.selectedcompany = this.companias[0].id;
        // this.loadCampaigns(); 
        // this.loadZones();
        this.loadFilters();
        }
      }
    },
    (err:any) => {
      console.log(err);
    })
  }

  isBuhoUser() : boolean {
    return this.userS.isBuhoUsr();
  }

  loadFilters(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getSurveyCampaigns(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          this.campaignsData = x.data;
          const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
          this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
          this.mergeAndSortCampaigns();
        // if(this.campaignsData.nest.length>0) {
        //   this.selectedCamp = this.campaignsData.nest[0].id;
        //   this.loadTickets();
        // }
          //loadzones
          if(this.selectedcompany && this.selectedcompany != 0){
            this.main.ShowLoading();
            this.surveyS.getZones(this.selectedcompany).subscribe((x: any) => {
              this.main.HideLoading();
              if(x.message == "Success") {
                this.zones = x.data;
                //this.fillFilters()
              }
            },
            (err:any) => {
                  this.main.HideLoading();
                }
            );
          }else{
            this.zones = []; 
          }
          //--------
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.campaignsData = {}; 
      }
    }else{
      this.main.ShowLoading();
      this.surveyS.getSurveyCampaigns(1).subscribe((x: any) => {
        this.main.HideLoading();
        this.campaignsData = x.data;
        const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
        this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
        this.mergeAndSortCampaigns() 
          //loadzones
          this.main.ShowLoading();
          this.surveyS.getZonesByUser().subscribe((x: any) => {
            this.main.HideLoading();
            if(x.message == "Success") {
              this.zones = x.data;
              //this.fillFilters()
            }
          },
          (err:any) => {
                this.main.HideLoading();
              }
          )
          //--------
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }
    //this.selectedCamp = 0
  }

  allCampaigns : any[] = [];

  mergeAndSortCampaigns() {
    const clawCampaigns = this.campaignsData.claw.map((campaign : any) => ({
      ...campaign,
      id: campaign.id + '-c',
    }));

     this.allCampaigns = [...clawCampaigns, ...this.campaignsData.nest];

     this.allCampaigns = this.allCampaigns.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });

  }

  loadCampaigns(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getSurveyCampaigns(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          this.campaignsData = x.data;
          console.log( this.campaignsData)
       //  if(this.campaignsData.nest.length===1) {
       //    this.selectedCamp = this.campaignsData.nest[0].id;
       //  }
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.campaignsData = {}; 
      }
    }else{
      this.main.ShowLoading();
      this.surveyS.getSurveyCampaigns(1).subscribe((x: any) => {
        this.main.HideLoading();
        this.campaignsData = x.data;
        console.log( this.campaignsData)
     //  if(this.campaignsData.nest.length===1) {
     //    this.selectedCamp = this.campaignsData.nest[0].id;
     //  }
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }

    this.selectedCamp = 0
  }

  loadZones(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getZones(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          if(x.message == "Success") {
            this.zones = x.data;
          }
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.zones = []; 
      }
    }else{
      this.main.ShowLoading();
      this.surveyS.getZonesByUser().subscribe((x: any) => {
        this.main.HideLoading();
        if(x.message == "Success") {
          this.zones = x.data;
        }
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }
     this.selectedzone = null
  }

  getStatusTracking(){
    this.statusTracking = environment.status_seguimientos;
  }
  getStatusSurv(){
    this.statusSurv = environment.status_survticket;
  }

  getStatusSurvDat(idstatus : number){
    return environment.status_survticket.filter(x=> x.id  == idstatus)[0];
  }

  getStatusTrackingDat(idstatus : number){
    return environment.status_seguimientos.filter(x=> x.id  == idstatus)[0];
  }

  getStatusTickets(){
    this.statusTicket = environment.status_ticket;
  }
  getTicketsTypes(){
    this.ticketTypes = environment.tipos_ticket;
  }

  getStatusTicketsDat(idstatus : number){
    return environment.status_ticket.filter(x=> x.id  == idstatus).length != 0? environment.status_ticket.filter(x=> x.id  == idstatus)[0] : { nombre : idstatus.toString(), class : "" };
  }

  getTicketsTypeDat(idstatus : number){
    return environment.tipos_ticket.filter(x=> x.id  == idstatus).length != 0? environment.tipos_ticket.filter(x=> x.id  == idstatus)[0] : { nombre : idstatus.toString(), class : "" };
  }

  statusTicketExclude(){
    return environment.status_ticket.filter(x=> x.id  != 4)
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.campaignService.hasPermissions(key, buho, client);
  }

  updateTicket(id_encuesta_campana : number, status : number, producto : string, camp_id : number, suc_box_id : number, comp_id : any){
    if(this.hasPermissions('upl_sta_tck',null,true)){
    Swal.fire({
      title: '¿Estás seguro que deseas cambiar el status del ticket?',
      text: 'Se actualizará el status del ticket a ' + this.getStatusTicketsDat(status).nombre + ' para el producto "'+ producto + '"',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.main.ShowLoading();
        this.surveyS.updateTicket(camp_id, suc_box_id, id_encuesta_campana, status, comp_id, this.getStatusTicketsDat(status).nombre, this.isClaw? "claw" : "nest").subscribe((results:any)=>{
          this.main.HideLoading();
          if(results.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Éxito',
              text: 'Ticket actualizado correctamente'          
            }).then(s=>{
              this.loadTickets();
            });
          }
        });
      }
    });
    }
  }

  openDialogLogList(item:any) {
    this.matDialog.open(SurveysTicketsLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        item : item,
        id_comp : 1,
        id_camp : item.camp_id,
        id_suc : item.suc_id,
        camp_folder: item.folder,
        isClaw : this.isClaw
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  searchedCamp : number = 0;

  search(){
    if(this.selectedCamp != 0){
      this.loadTickets();
    }
 // if(this.isBuhoUser()){
 //   if(this.selectedcompany != null && this.selectedCamp != null){
 //     this.loadTickets();
 //   }
 // }else{
 //       if(this.selectedCamp != null){
 //       this.loadTickets();
 //       }
 // }

  }

  isClawCamp(id_camp : any) : boolean{
    let idcampEv = id_camp.toString();
    return idcampEv.includes("-c");
  }

  gotoSurvey(id_suc  : number){
    this.surveyS.getEncuestaUrl({ id_sucursal : id_suc, id_campana : this.searchedCamp }).subscribe((x:any)=>{
      if(x.message == "Success") {
        //console.log(x.data)
        var url = this.hostName + x.data.token;
        window.open(url, "_blank");
      }
    });

  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { POPMaterial, ProjectMedia } from 'src/app/models/project.model';

@Component({
  selector: 'app-edit-pop-material',
  templateUrl: './edit-pop-material.component.html',
  styleUrls: ['./edit-pop-material.component.scss']
})
export class EditPopMaterialComponent implements OnInit {
  data!: POPMaterial;
  editmode: boolean = false;
  mattypes!: Array<string>;
  media!: ProjectMedia;
  atributosSucursales = ['DANGLER', 'MATERIAL', 'ATRIBUTO X'];
  comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];
  reglasAlcance: any[] = [];
  alcance: string = '';


  constructor(public dref: MatDialogRef<EditPopMaterialComponent>) { }

  ngOnInit(): void {
    this.data = {
      branchFilter: false,
      name: '',
      placements: 0,
      priority: null,
      type: '',
    };
    this.mattypes = [
      'Banner', 'Copete Mesa de Promociones', 'Dangler', 'Poster Checkout', 'Otro'
    ];
  }


  /* void close(0):
     Closes dialog. */
  close(): void { this.dref.close(); }

  /* boolean invalidInput(0):
     Validates all the field's data to enable the "Submit" button.
     > True if all fields valid. Otherwise, false. */
  invalidInput(): boolean {
    return (this.data.name === '' || this.data.placements < 1 || (this.data.priority && this.data.priority < 1) || this.data.type === '');
  }

  /* void mirror(1):
     Inverts the response from the priority input's keyboard arrows to create
     the expected "1-is-top" behavior for it.
     < event: The arrow's event. */
  mirror(event: KeyboardEvent): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    
    // Adjust value based on wheel's direction
    if (event.key === 'ArrowUp') {
      event.preventDefault(); // Prevent default scrolling behavior.
      input.value = Math.max(1, (parseInt(input.value, 10) || 0) - 1).toString(); // Arrow up subtracts
    } else if (event.key === 'ArrowDown') {
      event.preventDefault(); // Prevent default scrolling behavior.
      input.value = ((parseInt(input.value, 10) || 0) + 1).toString(); // Arrow down adds
    }

    // Update value
    this.data.priority = parseInt(input.value, 10);
  }

  /* void register(0):
     Sends set data to the caller component and closes dialog. */
  register(): void { this.dref.close(this.data); }

  agregarRegla() {
    this.reglasAlcance.push({
      atributo: null,
      comparador: null,
      valor: null
    });
  }

  eliminarRegla(index: number) {
    this.reglasAlcance.splice(index, 1);
  }

}

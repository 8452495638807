import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() menuEvent = new EventEmitter<boolean>();
  isClientUsr: boolean = false;
  isBuhoUsr: boolean = false;
  modules :any;
  isProviderMember : boolean = false;
  constructor(
    private userS: UsersService
  ) { 
    this.isClientUsr = (this.userS.getUser().id_rol == environment.role_client);
    this.isBuhoUsr = (this.userS.getUser().id_rol == environment.role_buho || this.userS.getUser().id_rol == environment.role_admin_id);
    this.modules = this.userS.getUserModules();
    this.isProviderMember =  this.userS.isProviderMember();
  }

  ngOnInit(): void {
    console.log(this.isProviderMember);
  }

  menuHide() {
    this.menuEvent.emit(false);
  }

  canShow(idRol:number) {
    if (idRol == 0) return true;
    else if(this.userS.getUser().id_rol == environment.role_admin_id) return true;
    else if(idRol == this.userS.getUser().id_rol) return true;
    else return false;
  }

  hasEncuestas(){
   return this.isBuhoUsr ? true : this.modules.filter((e:any) => e.clave === 'encu').length > 0
  }

  hasDistribucion(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'dist').length > 0
  }

  hasRecursos(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'recu').length > 0
  }

  hasProveedores(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'prov').length > 0
  }

  hasProyectos(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'proy').length > 0
  }

  hasSucursales(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'sucs').length > 0
  }

  hasSegmentacion(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'segs').length > 0
  }

  hasDashboardSeg(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'dash-seg').length > 0
  }
  
}

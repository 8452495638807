<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">No autorizado <mat-icon class="text-danger align-middle">cancel</mat-icon></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Describe la razón del por qué el archivo NO está autorizado.</h6>
    <form #f="ngForm">
        <div class="row">
            <div class="col mb-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Descripción</mat-label>
                    <textarea matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="5"
                              rows="3"></textarea>
                  </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="saveComment()">Guardar <mat-icon>save</mat-icon></button>
</div>
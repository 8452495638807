<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="me-2 align-middle">hub</mat-icon>Proveedores
        </h4>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <mat-tab-group mat-align-tabs="start">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon class="me-2">list</mat-icon> Lista de Proveedores
                                </ng-template>
                                <div class="col-md-12 mt-3">
                                    <div class="overflow-auto w-100">
                                        <div class="float-end">
                                            <button mat-raised-button color="primary" (click)="goToAddProvider()" class="mt-2 mb-2">
                                                Agregar nuevo <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                        <mat-form-field>
                                            <mat-label>
                                                <mat-icon>search</mat-icon> Buscar</mat-label>
                                            <input matInput (keyup)="applyFilter($event)" #input>
                                        </mat-form-field>
                                    </div>
                                
                                    <div class="mat-elevation-z8 table-responsive">
                                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                                
                                            <ng-container matColumnDef="id">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                            </ng-container>
                                
                                            <ng-container matColumnDef="nombre">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                                <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                                            </ng-container>
                                
                                            <ng-container matColumnDef="razon_social">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Razón Social </th>
                                                <td mat-cell *matCellDef="let row"> {{row.razon_social}} </td>
                                            </ng-container>
                                
                                            <ng-container matColumnDef="actions">
                                                <th mat-header-cell *matHeaderCellDef class="text-center" [hidden]="!hasPermissions('upd_usr',true,null)"> Acciones </th>
                                                <td mat-cell *matCellDef="let row" class="text-center" [hidden]="!hasPermissions('upd_usr',true,null)">
                                                    <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" (click)="goToEditProvider(row.id)" color="accent">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                    <mat-slide-toggle [checked]="row.active==1" (click)="activateProvider(row.id, row.active, row.nombre)" matTooltip="¿Activo?" [matTooltipPosition]="'above'" class="text-primary"></mat-slide-toggle>
                                                    <button [hidden]="!isAdmin()" mat-icon-button matTooltip="Eliminar" [matTooltipPosition]="'above'" color="warn" (click)="deleteProvider(row.id)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>
                                
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                
                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                            </tr>
                                        </table>
                                
                                        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                                            <mat-progress-spinner color="primary" mode="indeterminate">
                                            </mat-progress-spinner>
                                        </div>
                                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stores-attributes-add',
  templateUrl: './stores-attributes-add.component.html',
  styleUrls: ['./stores-attributes-add.component.scss']
})
export class StoresAttributesAddComponent implements OnInit {

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  options: any[] = [];
  isEdit : boolean = false;

  new_attribute = {atributo_id : 0,  name : "", type_id : 1, select_data : "" , editable : false, autorization : false, active_since_dt : new Date(), active_since: ""  , default_value : ""}
  reserved =  [
    "id",
    "nombre",
    "plaza",
    "activa",
    "zona"
  ];

  minDate : Date = new Date();

  types = [{ type_id : 1, name : "SI/NO"}, { type_id : 2, name : "FECHA"}, { type_id : 3, name : "OPCIONES"}, { type_id : 6, name : "NÚMERO"}, { type_id : 4, name : "RANGO"}, { type_id : 5, name : "TEXTO"}];

  constructor( private dialogRef: MatDialogRef<StoresAttributesAddComponent>,
    private main : MainService,
    private storeS: StoreService,
    @Inject(MAT_DIALOG_DATA) public data : any,
    ) {
      this.types = this.getTypes();
     }

  ngOnInit(): void {
    if(this.data.isEdit){
      this.new_attribute.atributo_id = this.data.atributo.id;
      this.new_attribute.type_id = this.data.atributo.type_id;
      this.new_attribute.select_data = this.data.atributo.select_data
      this.new_attribute.editable = this.data.atributo.editable;
      this.new_attribute.autorization = this.data.atributo.autorization;
      if(this.new_attribute.type_id == 3){
      this.options = JSON.parse(this.data.atributo.select_data);
      }
      if(this.new_attribute.type_id == 4){
       let coso = this.new_attribute.select_data.split("-");
        this.rangemin = parseInt(coso[0]);
        this.rangemax = parseInt(coso[1]);
      
      }
      console.log("aaa", this.rangemin, this.new_attribute.type_id);
    }
  }

  getTypes(){
    return environment.att_types;
  }

  rangemin : number = 0;
  rangemax : number = 0;

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) { 
      if (!this.options.includes(value)) {
        console.log(this.options.includes(value), value, this.options)
        this.options.push(value);
      } 
    }
    event.chipInput!.clear();
  }

  remove(item: any): void {
    const index = this.options.indexOf(item);

    if (index >= 0) {
      this.options.splice(index, 1);
    }
  }

  onchangedate(e: any): void { 

  }

  save(){  
    if(this.new_attribute.type_id == 3){
      this.new_attribute.select_data = JSON.stringify(this.options);
    }
    if(this.new_attribute.type_id == 4){
      if(this.rangemin>this.rangemax){
        return;
      }
      this.new_attribute.select_data = this.rangemin.toString() + "-" + this.rangemax.toString();
    }
    if(!this.data.isEdit){
      if(!this.reserved.includes(this.new_attribute.name.toLowerCase())){
        if(this.new_attribute.name!= "" ){
          this.main.ShowLoading();
          this.new_attribute.active_since = this.new_attribute.active_since_dt.toISOString().slice(0, 10);
          this.storeS.newAtribute(this.new_attribute).subscribe(
            (result:any) => {
              this.main.HideLoading();
              if(result.message == "Success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Creado',
                  text: 'Atributo creado correctamente'          
                }).then(() => {            
                  this.dialogRef.close({ reload: true });
                });
              }
            },
            (err:any) => {
              this.main.HideLoading();
              console.log(err);
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
              });
            }
          );
    
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Faltan datos',
            text: 'Por favor ingrese los datos requeridos'
          });
        }
    
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Nombre reservado',
          text: 'Por favor ingrese otro nombre de atributo'
        });
      }
    }else{
      this.storeS.editAtribute(this.new_attribute).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Creado',
              text: 'Atributo editado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }


    console.log(this.new_attribute);
  }

  onchangevalue(event : any){
    console.log(event);
    if(event != 2){
      this.new_attribute.select_data = "";
    }
    if(event == 4){
      this.new_attribute.select_data = "1";
    }
  }

}

<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Editar Miembro</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Edición de miembro:</h6>
    <form autocomplete="off" [formGroup]="formProviderMember" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput formControlName="nombre">
                    <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Proveedores</mat-label>
                    <mat-select formControlName="providers" multiple>
                        <mat-option *ngFor="let p of providers" [value]="p.id">{{p.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!--<div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Email</mat-label>
                    <mat-icon matSuffix>alternate_email</mat-icon>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>-->
        </div>
        <div class="row mt-2">

            <!--<div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Permisos</mat-label>
                    <mat-select formControlName="can_edit">
                        <mat-option [value]="true"><span class="badge bg-success">Aprueba</span></mat-option>
                        <mat-option [value]="false"><span class="badge bg-info">Solo lectura</span></mat-option>
                    </mat-select>
                </mat-form-field>
            </div>-->
        </div>
        <div class="row mt-2">
            <!--<div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Password</mat-label>
                    <mat-icon matSuffix (click)="hideEmail = !hideEmail" role="button">{{hideEmail ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hideEmail ? 'password' : 'text'" formControlName="pass" maxlength="30">
                </mat-form-field>
            </div>-->
            <!--<div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Confirma password</mat-label>
                    <mat-icon matSuffix (click)="hideEmailConfirm = !hideEmailConfirm" role="button">{{hideEmailConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hideEmailConfirm ? 'password' : 'text'" formControlName="passConfirm" maxlength="30">
                </mat-form-field>
            </div>-->

            <!--<div class="col-sm-6">
                <mat-slide-toggle  formControlName="active_not" name="active_not" [checked]="true" matTooltip="¿Recibe notificaciones?" [matTooltipPosition]="'above'" class="text-primary mt-4">¿Recibe Notificaciones?</mat-slide-toggle> 
            </div>-->
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button (click)="resetForm()">Limpiar <mat-icon>delete_outline</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>
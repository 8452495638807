<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            Tickets
        </h4>
        
        <div *ngIf="ticketinfo"  class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-info align-middle">label</mat-icon> {{ ticketinfo.campana }}
                    </h5>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                                <h5>Datos de caja</h5>
                                <br>
                                <b>Consecutivo</b>: {{ ticketinfo.consecutivo }}
                                <br>
                                <b>Zona</b>: {{ ticketinfo.zona }}
                                <br>
                                <b>Sucursal</b>: {{ ticketinfo.sucursal }}
                                <br>
                                <b>Estatus de encuestas</b>: <span [ngClass]="getStatusSurvDat(ticketinfo.st_encuesta).class">{{getStatusSurvDat(ticketinfo.st_encuesta).nombre}}</span>
                                <br>
                                <b>Estatus de seguimiento</b>: <span [ngClass]="getStatusTrackingDat(ticketinfo.st_seguimiento).class">{{getStatusTrackingDat(ticketinfo.st_seguimiento).nombre}}</span>
                                <br>
                                <b>Responsable</b>: {{ ticketinfo.responsable }}
                                <br>
                                <b>Puesto</b>: {{ ticketinfo.puesto }}
                            </div>
                            <div class="col-sm-6">
                                <h5>Datos envío</h5>
                                <b>Cantidad de productos enviados</b>: {{ ticketinfo.enviados }}
                                <br>
                                <b>Cantidad de productos con alertas</b>: {{ ticketinfo.alertas }}
                                <br>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <!-- Card on time strategies -->
        <div class="row" [hidden]="dataSource?.data?.length === 0">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-info align-middle">stop</mat-icon> Seguimiento
                    </h5>
                    <div>
                    </div>
                    <div class="card-body">
                        <div class="overflow-auto w-200">
                            <mat-form-field>
                                <mat-label>
                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Sobre burbuja" #input>
                            </mat-form-field>
                        </div>
                            <div class="mat-elevation-z0 table-responsive text-nowrap">
                                <table mat-table [dataSource]="dataSource" matSort class="w-100">
                        
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Ticket </th>
                                        <td mat-cell *matCellDef="let row"  style="max-width: 80px;"> {{row.id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="producto">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                                        <td mat-cell *matCellDef="let row"  style="max-width: 80px;"> {{row.producto}} <!--<span *ngIf="row.comentario != ''"  matTooltip="{{row.comentario}}"
                                            matTooltipPosition="right"  <mat-icon>info</mat-icon></span>>--></td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="comentario">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Comentario </th>
                                        <td mat-cell *matCellDef="let row" style="max-width: 50px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; "> <span style="text-overflow: ellipsis; 
                                            overflow: hidden; 
                                            white-space: nowrap;" matTooltip="{{row.comentario}}"
                                            matTooltipPosition="right">{{row.comentario}}</span> </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tipo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo ticket
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <span *ngIf="row.tipo" [ngClass]="getTicketsTypeDat(row.tipo).class">{{getTicketsTypeDat(row.tipo).nombre}}</span>
                                            <!--<mat-menu #menu2="matMenu">
                                                <button *ngFor="let stat of ticketTypes"  mat-menu-item ><span [ngClass]="stat.class">{{ stat.nombre }}</span></button>
                                            </mat-menu>-->
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 70px;"> Estatus ticket 

                                        </th>
                                        <td mat-cell *matCellDef="let row" style="max-width: 70px;">
                                            <ng-container *ngIf="row.tipo!=null && row.status!=4">
                                                <span [matMenuTriggerFor]="menu3" [ngClass]="getStatusTicketsDat(row.status).class">{{getStatusTicketsDat(row.status).nombre}}</span> 
                                                <mat-menu #menu3="matMenu" >
                                                    <button *ngFor="let stat of statusTicketExclude()" (click)="updateTicket(row.id, stat.id, row.producto)"  mat-menu-item ><span [ngClass]="stat.class">{{ stat.nombre }}</span></button>
                                                </mat-menu>
                                            </ng-container>
                                            <ng-container *ngIf="row.tipo==null">
                                                <span [ngClass]="'badge badge-info'">Pendiente Crear</span> 
                                            </ng-container>
                                            <ng-container *ngIf="row.status==4">
                                                <span [ngClass]="'badge badge-success'">Resuelto (Anulación)</span> 
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="masive" >
                                        <th mat-header-cell *matHeaderCellDef style="max-width: 12px;" class="text-center" [hidden]="!isBuhoUsr"> 
                                            <button  mat-icon-button color="primary" [matMenuTriggerFor]="menuEx1" matTooltip="Cambio de estatus masivo"><mat-icon>cached</mat-icon></button>  
                                            <mat-menu #menuEx1="matMenu" >
                                                <button *ngFor="let stat of statusTicketExclude()" (click)="changeMasiveSts(stat.id)" mat-menu-item ><span [ngClass]="stat.class">{{ stat.nombre }}</span></button>
                                            </mat-menu>    
                                        </th>
                                        <td mat-cell *matCellDef="let row" style="max-width: 12px;" class="text-center" [hidden]="!isBuhoUsr">
                                            <input title="Cambio de estatus masivo"  class="form-check-input custom-checkbox" type="checkbox" [checked]="checkIfChecked(row.id)" (click)="setTicketListToEdit(row.id, $event)" *ngIf="row.tipo && row.status !=4">
                                           
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Acciones">
                                        <th mat-header-cell *matHeaderCellDef class="text-center" style="max-width: 45px;"> Acciones </th>
                                        <td mat-cell *matCellDef="let row" class="text-center" style="max-width: 45px;">
                                            <button *ngIf="row.tipo!=null" (click)="openDialogLogList(row)" mat-icon-button matTooltip="Ver detalle" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>remove_red_eye</mat-icon>
                                            </button>
                                            <button *ngIf="row.tipo==null" [matMenuTriggerFor]="menu4" mat-icon-button matTooltip="Crear Ticket" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                            <mat-menu #menu4="matMenu" >
                                                <button *ngFor="let stat of filterValidTickets()" (click)="addTicket(row.id, stat.id, row.producto)"  mat-menu-item >{{ stat.nombre }}</button>
                                            </mat-menu>
                                            <button *ngIf="row.tipo==null" (click)="removeTicket(row.id, row.producto)" mat-icon-button matTooltip="Anular" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>highlight_off</mat-icon>
                                            </button>

                                        </td>
                                    </ng-container>
                        
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="6">No hay alertas para mostrar</td>
                                    </tr>
                                </table>
                        
                                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>        
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active campaigns strategies-->

    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';
import { UsersEditPasswordComponent } from '../../shared/dialogs/users-edit-password/users-edit-password.component';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  formUser!: FormGroup;
  id_user!: number;

  constructor(
    private fb: FormBuilder,
    private userS: UsersService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.id_user = this.userS.getUser().id;
    this.createFormUser();
    this.setUserForm();
  }

  createFormUser() {
    this.formUser = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
    });
  }

  setUserForm() {;
    this.formUser.controls['name'].setValue(this.userS.getUser().name);
  }

  ruSure() {
    if(this.formUser.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas editar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.userS.editUser(this.formUser.value, this.id_user).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Edición de datos correcta!'
          }).then(() => {
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  openDialogPassword() {
    this.matDialog.open(UsersEditPasswordComponent, {
      data: { 
        id_user: this.id_user 
      },
      panelClass: 'dialogs-sm',
    });
  }

}

<div class="content-wrapped">
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4 text-primary">
      <mat-icon class="me-2 align-middle">dashboard</mat-icon
      ><span class="border-end pe-3">Dashboard Seguimiento</span>
      <span *ngIf="this.campaignData.length > 0" class="ms-3">
        <button
            class="ms-1 mb-2"
            mat-icon-button
            matTooltip="Filtros"
            [matMenuTriggerFor]="filters"
          >
            <mat-icon>tune</mat-icon>
          </button>

          <mat-menu #filters="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="zonas">Zonas</button>
            <button mat-menu-item [matMenuTriggerFor]="estatus">Estatus</button>
          </mat-menu>

          <mat-menu #zonas="matMenu">
            <mat-selection-list #selectorZonas>
              <mat-list-option
                [value]="zona"
                *ngFor="let zona of filterOptions.zonas"
                (click)="
                  filterCampaign(
                    $event,
                    selectorZonas.selectedOptions.selected,
                    selectorEstatus.selectedOptions.selected
                  )
                "
                >{{ zona }}</mat-list-option
              >
            </mat-selection-list>
          </mat-menu>

          <mat-menu #estatus="matMenu">
            <mat-selection-list #selectorEstatus>
              <mat-list-option
                selected
                [value]="estatus"
                *ngFor="let estatus of filterOptions.estatus"
                (click)="
                  filterCampaign(
                    $event,
                    selectorZonas.selectedOptions.selected,
                    selectorEstatus.selectedOptions.selected
                  )
                "
                >{{  estatusMapping[estatus]?.label }}</mat-list-option
              >
            </mat-selection-list>
          </mat-menu>

          <a
          [href]="'https://docs.google.com/spreadsheets/d/' + campaignSelected?.file"
            class="mb-2"
            mat-icon-button
            matTooltip="Descargar Excel"
            target="_blank"
          >
            <mat-icon>download</mat-icon>
        </a>
 

          <!--<button
            (click)="updateTrackingData(campaignSelected!.id)"
            class="mb-2"
            mat-icon-button
            matTooltip="Sincronizar"
          >
            <mat-icon>sync</mat-icon>
          </button>-->
          <!--<span class="fs-6">Ultima actualizacion: 04/02/2024 04:33 a.m.</span>-->

      </span>
      
      <div class="float-end">
        <ol class="breadcrumb me-3 mb-0">
          <li class="breadcrumb-item">
            <span><mat-icon color="accent">dashboard</mat-icon></span>
          </li>
          <li class="breadcrumb-item"><span class="fs-6">Dashboard</span></li>
          <li
            class="breadcrumb-item font-blue dropdown drop-container selector"
          >
            <mat-spinner
              class="ms-2"
              color="accent"
              diameter="20"
              *ngIf="isLoading; else title"
            ></mat-spinner>
            <ng-template #title>
              <span class="fs-6">{{ this.campaignSelected?.name }}</span>
              <i class="ms-2 arrow down"></i>
              <div class="drop bg-white">
                <ul>
                  <li
                    (click)="setCampaignData(campaign)"
                    class="list-item"
                    *ngFor="let campaign of campaignList"
                  >
                    <span class="fs-6" style="color: black; font-weight: 300">{{
                      campaign.name
                    }}</span>
                  </li>
                </ul>
              </div>
            </ng-template>
          </li>
        </ol>
      </div>
    </h4>

    <div *ngIf="isLoading; else emptyCampaign" class="row">
      <div class="col">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 75vh;
          "
        >
          <!--<mat-spinner color="accent"></mat-spinner>-->
        </div>
      </div>
    </div>

    <ng-template #emptyCampaign>
      <div *ngIf="campaignData.length === 0; else content" class="row">
        <div class="col">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 50vh;
            "
          >
            <h5 class="mt-5">Esta campaña no tiene guías asignadas</h5>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #content>
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col">
                  <div class="d-flex justify-content-between">
                    <h5>Progreso:</h5>
                    <span>Total de paquetes: {{ campaignData.length }}</span>
                  </div>
                  <mat-progress-bar
                    mode="determinate"
                    value="{{ processedCampaignData.successfulPercent }}"
                  ></mat-progress-bar>
                  <p class="text-end mt-1">
                    {{ processedCampaignData.successfulPercent }}%
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2 col-6 resume-card">
                  <div class="">
                    <h6 class="align-middle text-center fs-6">
                      <mat-icon class="font-success  me-2 align-middle"
                        >done_all</mat-icon
                      >Entregado
                    </h6>
                    <h4 class="mt-2 text-center">
                      {{ processedCampaignData.successfulQty}}
                    </h4>
                  </div>
                </div>
                <div class="col-md-2 col-6 resume-card-mobile">
                  <div class="">
                    <h6 class="align-middle text-center fs-6">
                      <mat-icon class="font-warning  me-2 align-middle"
                        >local_shipping</mat-icon
                      >En ruta de entrega
                    </h6>
                    <h4 class="mt-2 text-center">
                      {{ processedCampaignData.startedQty }}
                    </h4>
                  </div>
                </div>
                <div class="col-md-2 col-6 resume-card">
                  <div class="">
                    <h6 class="align-middle text-center fs-6">
                      <mat-icon color="accent" class="me-2 align-middle"
                        >moving</mat-icon
                      >En tránsito
                    </h6>
                    <h4 class="mt-2 text-center">
                      {{ processedCampaignData.assignedQty }}
                    </h4>
                  </div>
                </div>

                <!--<div class="col-md-3 col-6 resume-card-mobile">
                  <div class="">
                    <h6 class="align-middle text-center  fs-6">
                      <mat-icon class="font-purple me-2 align-middle"
                        >assignment</mat-icon
                      >Recibido por paquetería
                    </h6>
                    <h4 class="mt-2 text-center">
                      {{ calculateTotals(processedCampaignData.assignedQty, processedCampaignData.orderCreatedQty, processedCampaignData.orderCompletedQty) }}
                    </h4>
                  </div>
                </div>-->
                <div class="col-md-2 col-6 resume-card">
                  <div class="">
                    <h6 class="align-middle text-center fs-6">
                      <mat-icon class="font-light-blue me-2 align-middle"
                        >forklift</mat-icon
                      >Recolectado
                    </h6>
                    <h4 class="mt-2 text-center">
                      {{ processedCampaignData.orderCompletedQty }}
                    </h4>
                  </div>
                </div>
                <div class="col-md-2 col-6 resume-card-mobile">
                  <div class="">
                    <h6 class="align-middle text-center  fs-6">
                      <mat-icon class="font-purple me-2 align-middle"
                        >assignment</mat-icon
                      >Esperando recolección
                    </h6>
                    <h4 class="mt-2 text-center">
                     {{ processedCampaignData.orderCreatedQty }}
                    </h4>
                  </div>
                </div>
                <div class="col-md-2 col-6">
                  <div class="">
                    <h6 class="align-middle text-center fs-6">
                      <mat-icon color="warn" class="me-2 align-middle"
                        >warning</mat-icon
                      >Incidencias
                    </h6>
                    <h4 class="mt-2 text-center">
                    {{ processedCampaignData.orderErrorQty }} 
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div *ngIf="processedCampaignData.successfulPercent > 0" class="col-12 col-md-6">
          <div class="card p-1 overflow-hidden">
            <h1 class="mt-3 ms-4 h5">Seguimiento de envíos</h1>
            <div class="bar-chart-widget">
              <div class="bottom-content card-body">
                <div class="col-12">
                  <div id="chart-widget4">
                    <apx-chart
                      *ngIf="!isLoading"
                      [chart]="processedCampaignData.trackingChartData.chart"
                      [dataLabels]="
                        processedCampaignData.trackingChartData.dataLabels
                      "
                      [stroke]="processedCampaignData.trackingChartData.stroke"
                      [labels]="processedCampaignData.trackingChartData.labels"
                      [grid]="processedCampaignData.trackingChartData.grid"
                      [fill]="processedCampaignData.trackingChartData.fill"
                      [colors]="processedCampaignData.trackingChartData.colors"
                      [markers]="
                        processedCampaignData.trackingChartData.markers
                      "
                      [series]="processedCampaignData.trackingChartData.series"
                      [xaxis]="processedCampaignData.trackingChartData.xaxis"
                      [yaxis]="processedCampaignData.trackingChartData.yaxis"
                      [title]="processedCampaignData.trackingChartData.title"
                      [legend]="processedCampaignData.trackingChartData.legend"
                      [responsive]="
                        processedCampaignData.trackingChartData.responsive
                      "
                    >
                    </apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card p-1 overflow-hidden">
            <h1 class="mt-3 ms-4 h5">Envíos por día</h1>
            <div class="bar-chart-widget">
              <div class="bottom-content card-body">
                <div class="col-12">
                  <div id="chart-widget4">
                    <apx-chart
                      *ngIf="!isLoading"
                      [chart]="
                        processedCampaignData.ShipmentsPerDayChartData.chart
                      "
                      [dataLabels]="
                        processedCampaignData.ShipmentsPerDayChartData
                          .dataLabels
                      "
                      [stroke]="
                        processedCampaignData.ShipmentsPerDayChartData.stroke
                      "
                      [xaxis]="
                        processedCampaignData.ShipmentsPerDayChartData.xaxis
                      "
                      [yaxis]="
                        processedCampaignData.ShipmentsPerDayChartData.yaxis
                      "
                      [grid]="
                        processedCampaignData.ShipmentsPerDayChartData.grid
                      "
                      [fill]="
                        processedCampaignData.ShipmentsPerDayChartData.fill
                      "
                      [colors]="
                        processedCampaignData.ShipmentsPerDayChartData.colors
                      "
                      [series]="
                        processedCampaignData.ShipmentsPerDayChartData.series
                      "
                      [tooltip]="
                        processedCampaignData.ShipmentsPerDayChartData.tooltip
                      "
                      [responsive]="
                        processedCampaignData.ShipmentsPerDayChartData
                          .responsive
                      "
                    >
                    </apx-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col pt-1 mt-4 mb-4">
          <div id="map"></div>
        </div>

        <div
          *ngIf="processedCampaignData.successfulPercent < 100"
          class="col-12 col-md-6"
        >
          <div class="card table-pending-boxes">
            <div class="card-body">
              <h1 class="h5">Cajas pendientes</h1>
              <div class="table-responsive">
                <table
                  mat-table
                  [dataSource]="pendingBoxesDataSource"
                  matSort
                  class="w-100"
                >
                  <ng-container matColumnDef="zona">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Zona
                    </th>
                    <td class="mat-cell" mat-cell *matCellDef="let row">
                      {{ row.zona }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sucursal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Sucursal
                    </th>
                    <td class="mat-cell" mat-cell *matCellDef="let row">
                      {{ row.sucursal }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="guia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Guia
                    </th>
                    <td class="mat-cell" mat-cell *matCellDef="let row">
                      <a
                        style="color: #0091ea"
                        target="_blank"
                        href="https://tracking.buhologistics.com/?id={{
                          row.guia
                        }}"
                        >{{ row.guia }}</a
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Estatus
                    </th>
                    <td class="mat-cell" mat-cell *matCellDef="let row">
                      <span
                        *ngIf="row.estatus != null"
                        [class]="'badge ' + estatusMapping[row.estatus]?.color"
                        >{{
                          estatusMapping[row.estatus]?.label || row.estatus
                        }}</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="lastUpdate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Ultima actualizacion
                    </th>
                    <td class="mat-cell" mat-cell *matCellDef="let row">
                      {{ formatDate(row.last_update) }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></tr>
                </table>

                <mat-paginator
                  #pendingBoxesPaginator="matPaginator"
                  color="accent"
                  pageSize="4"
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="row p-4">
              <h1 class="h5">Base de datos</h1>
              <div class="table-responsive">
                <table
                  mat-table
                  [dataSource]="campaignDataSource"
                  matSort
                  class="w-100"
                >
                  <ng-container matColumnDef="zona">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Zona
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.zona }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="sucursal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Sucursal
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.sucursal }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fechaEntrega">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Fecha de entrega
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ row.fecha_entrega }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="guia">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Guia
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <a
                        style="color: #0091ea"
                        target="_blank"
                        href="https://tracking.buhologistics.com/?id={{
                          row.guia
                        }}"
                        >{{ row.guia }}</a
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="estatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Estatus
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <span
                        *ngIf="row.estatus != null"
                        [class]="'badge ' + estatusMapping[row.estatus]?.color"
                        >{{
                          estatusMapping[row.estatus]?.label || row.status
                        }}</span
                      >
                      <span
                      *ngIf="isDedicated(row)"
                      [class]="'badge bg-success'"
                      >
                        Entregado*
                      </span>
                    </td>

            
                    
                  </ng-container>

                  <ng-container matColumnDef="direccion">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Dirección
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div *ngIf="row.direccion">
                        {{ parseAddress(row.direccion) }}
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="recibio">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Recibio
                    </th>
                    <td mat-cell *matCellDef="let row">{{ row.recibio }}</td>
                  </ng-container>

                  <ng-container matColumnDef="lastUpdate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                      Ultima actualizacion
                    </th>
                    <td mat-cell *matCellDef="let row">
                      {{ formatDate(row.last_update) }}
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="ShipmentsdisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: ShipmentsdisplayedColumns"
                  ></tr>
                </table>
                <mat-paginator
                  color="accent"
                  #campaignDataPaginator="matPaginator"
                  [pageSizeOptions]="[10, 25, 100]"
                ></mat-paginator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

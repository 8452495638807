<div *ngIf="!uploaded">
<div class="modal-header">
    <h4 class="modal-title">Cargar Sucursales Masivas</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="float-end"><button mat-flat-button color="primary" class="mt-2 mb-2 ms-4" (click)="generatePlantillaExcel()" >Descargar Plantilla<mat-icon>download</mat-icon></button></div>
        <h6 class="pb-4">
            <span class="align-middle">Carga el archivo de excel:</span>
            <!--<button mat-stroked-button color="primary" class="float-end">Descargar plantilla<mat-icon>download</mat-icon></button>-->
        </h6>
      
        <form [formGroup]="form">
            <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
            </ngx-file-drag-drop>
        </form>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" (click)="uploadFile()" form="" type="submit">Registrar cambios <mat-icon>save</mat-icon></button>
</div>
</div>
  
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { Title } from '@angular/platform-browser';
import { getCampaignsModel, newCampaignModel, CampaignModel, DashboardCampaigns } from '../../models/campaign.model';
import { catchError, Observable, of} from 'rxjs';
import { Router } from '@angular/router';
import { DistributionUploadComponent } from '../shared/dialogs/distribution-upload/distribution-upload.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DistributionArtComponent } from '../shared/dialogs/distribution-art/distribution-art.component';
import { DistributionPreprojectComponent } from '../shared/dialogs/distribution-preproject/distribution-preproject.component';
import Swal from 'sweetalert2';
import { DistributionLogsComponent } from '../shared/dialogs/distribution-logs/distribution-logs.component';
import { DistributionStrategyAddComponent } from '../shared/dialogs/distribution-strategy-add/distribution-strategy-add.component';
import { DistributionMaterialListComponent } from '../shared/dialogs/distribution-material-list/distribution-material-list.component';
import { DistributionIrregularitiesComponent } from '../shared/dialogs/distribution-irregularities/distribution-irregularities.component';
import { CampaignAddComponent } from '../shared/dialogs/campaign-add/campaign-add.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent implements OnInit {

  constructor(
    private campaignService : CampaignsService,
    private main : MainService,
    private _params: ActivatedRoute,
    private _titleService: Title,
    private _router : Router,
    private matDialog: MatDialog,
    ) {
      this._params.params.subscribe(params => {
        if(params.id){
          this.loadCampaignData();
        }else{
          this._router.navigateByUrl('/admin/dashboard');
        } 
      });
      this.isClientUsr = this.campaignService.isClientUsr();
      this.isBuhoUsr = this.campaignService.isBuhoUsr();
     }

     campaign! : CampaignModel;
     last_edited! : any;
     strategies: any = [];
     strategies_ad: any = [];
     islocked : boolean = false;
     progress_bar! : any;
     activation_request: any = [];
     isClientUsr: boolean = false;
     isBuhoUsr: boolean = false;
     str_save: any = {};

  ngOnInit(): void {
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.campaignService.hasPermissions(key, buho, client);
  }

  loadCampaignData(){
   console.log("Distribución Campaña", this._params.snapshot.data.data);
   this.main.HideLoading();
   if( this._params.snapshot.data.data ){
      if(this._params.snapshot.data.data.message = 'Success'){
        this.campaign = this._params.snapshot.data.data.data.campaign[0];
        this.last_edited = this._params.snapshot.data.data.data.last_mod[0];
        this.islocked = this.campaign.locked == 1 ? true : false;
        this.progress_bar = this._params.snapshot.data.data.data.progress_bar[0];
        this._titleService.setTitle("Buho Nest - Distribución - " + this.campaign.name);
        //this.loadStatus();
        this.activation_request = this._params.snapshot.data.data.data.activation_request[0];
        if(this.campaignService.isBuhoUsr()){
          this.openDialogAlert();
        }  
        this.loadStrategies(this.campaign.id);
      
      }
   }else{
     this._router.navigateByUrl('/admin/dashboard');
   }
  }

  loadCampaignReload(){
    this.main.ShowLoading();
    this.campaignService.getCampaign(this.campaign.id).subscribe((result:any)=>{
      if(result.message = 'Success'){
        this.main.HideLoading();
        this.campaign = result.data.campaign[0];
        this.last_edited = result.data.last_mod[0];
        this.islocked = this.campaign.locked == 1 ? true : false;
        this.progress_bar = result.data.progress_bar[0];
        this._titleService.setTitle("Buho Nest - Distribución - " + this.campaign.name);
        //this.loadStatus();
        this.loadStrategies(this.campaign.id);
      }
     },
     (err:any)=>{
      console.log(err);
     });
   }


  loadStrategies(campid : number){
    this.main.ShowLoading();
    this.campaignService.getCampaignStrategies(campid).subscribe(
        (result:any) => {
          this.main.HideLoading();
          console.log("Campañas",result);
          if(result?.message == 'Success'){
            let tmpdate = new Date(this.campaign.deadline);
            tmpdate.setTime(tmpdate.getTime() + 6 * 60 * 60 * 1000);
            this.strategies = result.data.filter((s : any) => new Date(s.last_mod) < tmpdate);
            this.strategies_ad = result.data.filter((s : any) => new Date(s.last_mod) >= tmpdate);
            //this.strategies = result.data;
          }else{
            console.log("No se cargaron las estrategias:", result.message);
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid strategies were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al obtener registros',
              text: 'Ocurrió un error al obtener los registros.'
            });
          }else{
            this.strategies = [];
          }
        }
      );
  }

  canShowLockOption():boolean{
    let tmpdate = new Date(this.campaign.deadline);
    tmpdate.setTime(tmpdate.getTime() + 6 * 60 * 60 * 1000);
    return new Date() >= tmpdate;
  }

  canEditUpload():boolean{
    return !(this.canShowLockOption() && this.islocked);
  }

  canEditCampaign():boolean{
      return this.canEditUpload() && this.campaignService.isBuhoUsr();
  }

  editField(fieldtype: number){

  }

  goToEncuestas(){
    this._router.navigate(['/admin/surveys/' + this.campaign.id])
  }

  goToPreprojectsGenerate(){
    this._router.navigate(['/admin/preproject-generate/' + this.campaign.id])
  }

  openEditCampaignDialog() {
    this.matDialog.open(CampaignAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : true,
        data: this.campaign
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) this.loadCampaignReload();
      }
    );
  }

  openCreateStr(){
    if(this.canEditUpload()){
            this.matDialog.open(DistributionStrategyAddComponent, {
              panelClass: ['dialogs-sm'],
              data: {
                edit : false,
                id_campana : this.campaign.id,
                folder_camp: this.campaign.folder
              }
            }).afterClosed().subscribe(
              resp => {
                if(resp?.reload) this.loadCampaignReload();
              }
            );
    }
  }

  openEditStr(str: any){
    if(this.canEditUpload()){
      this.matDialog.open(DistributionStrategyAddComponent, {
        panelClass: ['dialogs-sm'],
        data: {
          edit : true,
          data : str
        }
      }).afterClosed().subscribe(
        resp => {
          if(resp?.reload) this.loadCampaignReload();
        }
      );
    }
  }

  openUploadDist(){
      this.matDialog.open(DistributionUploadComponent, {
        panelClass: ['dialogs-md'],
        data: {
          campaign : this.campaign,
          isUnlocked : this.canEditUpload()
        }
      }).afterClosed().subscribe(
        resp => {
          if(resp?.reload) this.loadCampaignReload();
        }
      );
  }

  updateStatusStr(whichData: string, value : number , str: any){
    if(this.canEditUpload()){
      if(str.can_approve>0 && this.isClientUsr && str.odt_seen == 1) {
        this.str_save = { id_str : str.id };
        switch(whichData){
          case "st_brand":
            str.st_brand = value;
            this.str_save.st_brand = value;
            break;
          case "st_layout":
            str.st_layout = value;
            this.str_save.st_layout = value;
            break;
          case "st_odt":
            str.st_odt = value;
            this.str_save.st_odt = value;
            break;
          default:
            break;
        }
        this.main.ShowLoading();
        this.campaignService.updateStrategyStatus(this.str_save).subscribe((result:any)=>{
          this.main.HideLoading();
          this.loadCampaignReload();
          console.log(result);
          if(result.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Estatus actualizado',
              text: 'Estatus actualizado exitosamente'          
            });
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          this.loadCampaignReload();
          console.log(err);
        })
      }
      else if (str.can_approve == 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Falta subir un Pre Proyecto para la ultima actualizacion de artes para poder dar VOBO.'
        });
      }
      else {
        Swal.fire({
          icon: 'warning',
          title: 'Necesita ver el último Pre Proyecto para poder dar VOBO.'
        });
      }
    }
  }

  unlockCampaign(){
    let t1 = '¿Está seguro que desea desbloquear esta campaña?';
    let r1 = 'Campaña desbloqueada';
    if(this.isClientUsr) {
      t1 = '¿Desea solicitar que se desbloquee la campaña?';
      r1 = 'Solicitud enviada';
    }
    if(this.hasPermissions('unlock_camp',true,null)){
      if(this.campaign.locked && this.canShowLockOption()){
        Swal.fire({
          title: t1,
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'Con estos cambios se podrán realizar cambios en la distribucion y estrategias.',
              icon: 'warning',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: `Confirmar`,
              cancelButtonText: `Cancelar`
            }).then((result) => {
              if (result.isConfirmed) {
                this.main.ShowLoading();
                this.campaignService.unlockCampaign(this.campaign.id).subscribe((result:any)=>{
                  this.main.HideLoading();
                  this.loadCampaignReload();
                  if(result.message == 'Success'){
                    Swal.fire({
                      icon: 'success',
                      title: r1,
                      text: `${r1} exitosamente`
                    });
                  }
                },
                (err:any)=>{
                  this.main.HideLoading();
                  Swal.fire({
                    icon: 'error',
                    title: 'Error al desbloquear',
                    text: 'Ocurrió un error al desbloquear.'
                  });
                })
              }
            });
          }
        });
      }
      else if(!this.isClientUsr) {      
        Swal.fire({
          title: '¿Está seguro que desea bloquear esta campaña?',
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: `Confirmar`,
          cancelButtonText: `Cancelar`
        }).then((result) => {
          this.main.ShowLoading();
          this.campaignService.lockCampaign(this.campaign.id).subscribe((result:any)=>{
            this.main.HideLoading();
            this.loadCampaignReload();
            if(result.message == 'Success'){
              Swal.fire({
                icon: 'success',
                title: 'Campaña bloqueada',
                text: 'Campaña bloqueada exitosamente'
              });
            }
          },
          (err:any)=>{
            this.main.HideLoading();
            Swal.fire({
              icon: 'error',
              title: 'Error al bloquear',
              text: 'Ocurrió un error al bloquear.'
            });
          })
        });
      }
    }
  }

  openDialogArt(strategySelected:any) {
    if(this.isBuhoUsr){
      this.campaignService.updateSeenArtFile(strategySelected.id).subscribe();
    }
    this.matDialog.open(DistributionArtComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        strategy : strategySelected,
        islocked: !this.canEditUpload()
      }
    }).afterClosed().subscribe(
      resp => {
        this.loadCampaignReload();
      }
    );
  }

  openDialogPreproject(strategySelected:any) {
    if(strategySelected.has_art > 0) {
      if(this.isClientUsr){
        this.campaignService.updateSeenOdtFile(strategySelected.id).subscribe();
      }
      this.matDialog.open(DistributionPreprojectComponent, {
        panelClass: ['dialogs-md'],
        data: {
          strategy : strategySelected,
          islocked: !this.canEditUpload()
        }
      }).afterClosed().subscribe(
        resp => {
          this.loadCampaignReload();
        }
      );
    }
  }

  openDialogLogs(strategySelected:any) {
    this.matDialog.open(DistributionLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        strategy : strategySelected
      }
    }).afterClosed().subscribe(
      resp => {
        this.loadCampaignReload();
      }
    );
  }

  openDialogAlert() {
    if (this.activation_request) {
      Swal.fire({
        title: `El usuario ${this.activation_request.name } solicitó desbloquear la campaña el día ${ formatDate(new Date(this.activation_request.created_at),  'yyyy-MM-dd hh:mma', 'en_US','-0000') }`,
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Con estos cambios se podrán realizar cambios en la distribucion y estrategias.',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `Confirmar`,
            cancelButtonText: `Cancelar`
          }).then((result) => {
            if (result.isConfirmed) {
              this.main.ShowLoading();
              this.campaignService.unlockCampaign(this.campaign.id).subscribe((result:any)=>{
                this.main.HideLoading();
                this.loadCampaignReload();
                if(result.message == 'Success'){
                  Swal.fire({
                    icon: 'success',
                    title: 'Campaña desbloqueada',
                    text: 'Campaña desbloqueada exitosamente'
                  });
                }
              },
              (err:any)=>{
                this.main.HideLoading();
                Swal.fire({
                  icon: 'error',
                  title: 'Error al desbloquear',
                  text: 'Ocurrió un error al desbloquear.'
                });
              })
            }
          });
        }
      })
    }
  }

  openDialogMaterialList(strategySelected:any) {
    this.matDialog.open(DistributionMaterialListComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        strategy : strategySelected
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogIrregularities() {
    this.matDialog.open(DistributionIrregularitiesComponent, {
      panelClass: ['dialogs-md'],
      data: {
        campaign : this.campaign
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  deleteStrConfirm(strid : any){
    Swal.fire({
      title: '¿Está seguro que desea eliminar esta estrategia?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.campaignService.deleteStrategy(strid).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Eliminada',
              text: 'Estrategia eliminada correctamente'          
            }).then(s=>{
              this.loadCampaignReload();
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid strategies were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al eliminar',
              text: 'Ocurrió un error al eliminar.'
            });
          }
        }
      );
    } else {
     console.log("no se elimina")
    }
  });
  }

  deleteCampConfirm(){
    Swal.fire({
      title: '¿Está seguro que desea eliminar esta Campaña?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete) => {
    if (willDelete.isConfirmed) {
      console.log("se elimina")
    } else {
     console.log("no se elimina")
    }
  });
  }
}

<div class="modal-header">
    <h4 class="modal-title" id="modalEditNotifTitle">Activar notificaciones</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body modal-notif">
    <div class="row mt-2">
        <div class="col-sm-12">
            <mat-label>Lista de Notificaciones</mat-label>
            <mat-slide-toggle class="py-3" *ngFor="let n of notifList; let i = index" [(ngModel)]="n.active" >{{n.name}}</mat-slide-toggle>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button mat-flat-button class="bg-success text-white" (click)="ruSure();" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>
<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 mat-dialog-title class="m-0">Nuevo elemento POP</h1>

<div class="modal-body-scroll">
  <form class="inputform">
    <mat-form-field appearance="outline">
      <mat-label>Nombre </mat-label>
      <input matInput [(ngModel)]="data.name" name="name" required>
    </mat-form-field>
    <!--<mat-form-field appearance="outline">
      <mat-label>Elemento</mat-label>
      <mat-select [(ngModel)]="data.type" name="type" required>
        <mat-option *ngFor="let x of mattypes" [value]="x">
          {{ x }}
        </mat-option>
      </mat-select>
    </mat-form-field>-->
    <!-- <div class="row" *ngIf="media.allowMultipleItems && media.segmentable"> -->
    <div class="row">
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Prioridad</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="data.priority" (keydown)="mirror($event)" name="priority" step="-1">
          <mat-hint>1 es la prioridad más alta.</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Posiciones</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="data.placements" name="placements" step="1">
        </mat-form-field>
      </div>
    </div>
    <!--<div *ngIf="media.allowMultipleItems">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Prioridad</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="data.priority" (keydown)="mirror($event)" name="priority" step="-1">
          <mat-hint>1 es la prioridad más alta.</mat-hint>
        </mat-form-field>
    </div>
    <div *ngIf="media.segmentable">
      <div>
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Posiciones</mat-label>
          <input matInput type="number" min="1" [(ngModel)]="data.placements" step="1">
        </mat-form-field>
      </div>
    </div>-->
    <div class="mb-4">
      <h5 class="fw-bold text-primary">Filtro sucursales</h5>
      <p *ngIf="reglasAlcance.length>0"><span class="tinyfont">*Cantidad de sucursales filtradas : <b>25</b></span></p>
      <div *ngFor="let regla of reglasAlcance; let i = index" class="mb-3">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Atributo</mat-label>
              <mat-select [(ngModel)]="regla.atributo" name="atributo">
                <mat-option *ngFor="let atributo of atributosSucursales" [value]="atributo">{{ atributo }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Condición</mat-label>
              <mat-select [(ngModel)]="regla.comparador" name="comparador">
                <mat-option *ngFor="let comparador of comparadores" [value]="comparador">{{ comparador }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Valor</mat-label>
              <input matInput [(ngModel)]="regla.valor" name="valor" placeholder="Ej: 10" />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <button mat-icon-button color="warn" (click)="eliminarRegla(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <button mat-flat-button color="primary" (click)="agregarRegla()">
        <mat-icon>add</mat-icon> Añadir Regla
      </button>
    </div>

  </form>
</div>
<div mat-dialog-actions class="button-container">
  <button mat-raised-button class="nest-button" (click)="register()" [disabled]="invalidInput()">Agregar</button>
</div>
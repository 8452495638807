import { Injectable } from '@angular/core';
import { getCampaignsModel, newCampaignModel, CampaignModel, DashboardCampaigns, CampaignTrackingModel } from '../models/campaign.model';
import { MainService } from './main.service';
import jwt_decode from 'jwt-decode';
import { map } from 'rxjs';
import { newStrategyModel } from '../models/strategy.model';
import { UsersService } from './users.service';
import { environment } from 'src/environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(
    private main: MainService,
    private usrService : UsersService
  ) { }
    areaName : string = '/campaigns'
    distribution : string = "/distribution"
//Miscellaneous area
    getUsrRol():  number{
        return this.usrService.getUser().id_rol;
    }

    hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean{
        return this.usrService.getUser().id_rol == environment.role_admin_id || (buho==null&&this.isBuhoUsr()) || (client==null&&this.isClientUsr()) || (((buho?this.isBuhoUsr():false) || (client?this.isClientUsr():false)) && (this.usrService.getUser().permissions.includes(key)));
    }

    isBuhoUsr(): boolean{
        return  this.usrService.getUser().id_rol == environment.role_buho ||  this.usrService.getUser().id_rol == environment.role_admin_id;
    }
    isClientUsr(): boolean{
        return  this.usrService.getUser().id_rol == environment.role_client;
    }


//Campaigns area
    getCampaigns(){
        var get : getCampaignsModel =  new getCampaignsModel()
        get.active = 1;
        return this.main.postRequest(get, this.areaName + "/show").pipe(
        map((x:any)=>{
                let objReturn = new DashboardCampaigns();
                if(x.message == 'Success'){
                    x.data.forEach((e:any) => {
                        let tmpdate = new Date(e.deadline);
                        tmpdate.setTime(tmpdate.getTime() + 6 * 60 * 60 * 1000);
                        let tmpYear = tmpdate.getFullYear();
                        if(tmpYear != tmpdate.getFullYear()){
                            if(!objReturn.pastyears[tmpYear]){
                                objReturn.pastyears[tmpYear] =  new Array<any>()
                            }
                            objReturn.pastyears[tmpYear].push(e);
                        }else{
                           //if active then {this code } else push inactive   
                           if(new Date(Date.now()) < tmpdate || e.locked == 0){
                               objReturn.progress.push(e)
                           }else{
                               objReturn.finished.push(e)
                           }  
                           objReturn.all.push(e);
                        }
                    });
                }
                return objReturn;
        }));
    }

    getCampaignsByCompany(compId : number){
        var get : getCampaignsModel =  new getCampaignsModel()
        get.active = 1;
        return this.main.postRequest(get, this.areaName + "/filterbycompany/" + compId).pipe(
        map((x:any)=>{
                let objReturn = new DashboardCampaigns();
                if(x.message == 'Success'){
                    x.data.forEach((e:any) => {
                        let tmpdate = new Date(e.deadline);
                        tmpdate.setTime(tmpdate.getTime() + 6 * 60 * 60 * 1000);
                        let tmpYear = tmpdate.getFullYear();
                        if(tmpYear != tmpdate.getFullYear()){
                            if(!objReturn.pastyears[tmpYear]){
                                objReturn.pastyears[tmpYear] =  new Array<any>()
                            }
                            objReturn.pastyears[tmpYear].push(e);
                        }else{
                           //if active then {this code } else push inactive   
                           if(new Date(Date.now()) < tmpdate || e.locked == 0){
                               objReturn.progress.push(e)
                           }else{
                               objReturn.finished.push(e)
                           }  
                           objReturn.all.push(e);
                        }
                    });
                }
                return objReturn;
        }));
    }

    getCampaignsQRByCompany(compId : number){
        var get : getCampaignsModel =  new getCampaignsModel()
        get.active = 1;
        console.log("load by")
        return this.main.postRequest(get, this.areaName + "/filterqrbycompany/" + compId).pipe(
        map((x:any)=>{
                let objReturn = new DashboardCampaigns();
                if(x.message == 'Success'){
                    x.data.forEach((e:any) => {
                        let tmpdate = new Date(e.deadline);
                        tmpdate.setTime(tmpdate.getTime() + 6 * 60 * 60 * 1000);
                        let tmpYear = tmpdate.getFullYear();
                        if(tmpYear != tmpdate.getFullYear()){
                            if(!objReturn.pastyears[tmpYear]){
                                objReturn.pastyears[tmpYear] =  new Array<any>()
                            }
                            objReturn.pastyears[tmpYear].push(e);
                        }else{
                           //if active then {this code } else push inactive   
                           if(new Date(Date.now()) < tmpdate || e.locked == 0){
                               objReturn.progress.push(e)
                           }else{
                               objReturn.finished.push(e)
                           }  
                           objReturn.all.push(e);
                        }
                    });
                }
                return objReturn;
        }));
    }

    newCampaign(newCampain : newCampaignModel){
        return this.main.postRequest(newCampain, this.areaName +"/create");
    }

    editCampaign(newCampain : newCampaignModel){
        return this.main.postRequest(newCampain, this.areaName +"/edit");
    }

    deleteCampaign(campid : number){
        return this.main.deleteRequest("/campaigns/delete/" + campid);
    }

    getCampaign(campid : number){
        return this.main.getRequest("/campaigns/show/" + campid);
    }

    getCampaignChangelog(campid : number){
        return this.main.getRequest("/campaigns/changelog/" + campid);
    }

    newCampaignMessage(newMessage : any){
        return this.main.postRequest(newMessage, "/campaigns/message/");
    }

    unlockCampaign(campid : number){
        return this.main.postRequest({id_camp: campid}, "/campaigns/unlock/");
    }

    lockCampaign(campid : number){
        return this.main.postRequest({id_camp: campid}, "/campaigns/lock/");
    }
//Strategies area
    saveStrategy(newStrategy : newStrategyModel, isEdit : boolean){
        var createEdit = ""
        isEdit ? createEdit  = "edit" : createEdit  = "create";
        return this.main.postRequest(newStrategy, "/strategies/" + createEdit);
    }

    getCampaignStrategies(campid : number){
       return this.main.postRequest({ id_camp : campid }, "/strategies/show");
    }

    updateStrategyStatus(strategy: any){
        return this.main.postRequest(strategy, "/strategies/status");
    }

    getStrategiesChangelog(strid : number){
        return this.main.getRequest("/strategies/changelog/" + strid);
    }

    newStrategyMessage(newMessage : any){
        return this.main.postRequest(newMessage, "/strategies/message/");
    }
    
    getStrategyArts(strid : number){
        return this.main.getRequest("/strategies/files/art/" + strid);
    }

    getStrategyOdts(strid : number){
        return this.main.getRequest("/strategies/files/odt/" + strid);
    }

    deleteStrategy(strid : number){
        return this.main.deleteRequest("/strategies/delete/" + strid);
    }

    uploadArtFile(form : FormData){
        return this.main.postRequestFormData(form, "/strategies/files/art/");
    }

    uploadOdtFile(form : FormData){
        return this.main.postRequestFormData(form, "/strategies/files/odt/");
    }

    updateSeenArtFile(strid : number){
        return this.main.postRequest ({ id_str : strid }, "/strategies/files/art/seen");
    }

    updateSeenOdtFile(strid : number){
        return this.main.postRequest({ id_str : strid }, "/strategies/files/odt/seen");
    }
//Distribution uploading area
    loadDist(campid: number){
        return this.main.getRequest( "/campaigns/distributions/" + campid);
    }

    validateUploadDist(form : FormData){
        return this.main.postRequestFormData(form, "/campaigns/files/distribution/validate");
    }

    uploadDist(form : FormData){
        return this.main.postRequestFormData(form, "/campaigns/files/distribution");
    }

    uploadDistTest(form : FormData){
        return this.main.postRequestFormData(form, "/campaigns/files/distribution_test");
    }

    getStrategyMaterialList(strID : number) {
        return this.main.getRequest("/strategies/odt/" + strID);
    }

    getIrregularities(campID : number) {
        return this.main.getRequest("/campaigns/irr/" + campID);
    }

    getCampaingList(){
        return this.main.getRequest("/campaigns/show-tracking-campaign-list").pipe(
          map((resp: any) => {
            let campaignList: Array<CampaignModel> = [];
            if(resp.message == "Success") {          
              resp.data.forEach((e:any) => {
                campaignList.push(e);
              });
            }     
            return campaignList;
          })
        );
    }

    getCampaignData(id:number){
        return this.main.getRequest(`/campaigns/get-campaign-tracking-info/${ id.toString() }`).pipe(
          map((resp: any) => {
            let campaignList: Array<CampaignTrackingModel> = [];
            if(resp.data.message == "Success") {          
              resp.data.data.forEach((e:any) => {
                campaignList.push(e);
              });
            }     
            return campaignList;
          })
        );
    }

    updateTracking (id:string) {
        return this.main.getRequest(`/tracking/update-tracking/${ id }`);
      }

}
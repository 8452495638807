<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Fase 1 - Marketing VoBo</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body" style="min-height: 580px;">
        <mat-divider></mat-divider>
        <div>
            <div class="row mt-2">
           
                <div class="col-12">
                    <div class="card h-100">
                        <div class="card-body text-center">
                            <h5 class="card-title">Medio - DANGLER</h5>
                            <h6 class="card-subtitle text-muted">DANGLER V1</h6>
                        </div>
                        <div class="col-md-12 text-center">
                            <h6>
                                <mat-icon class="align-middle">update</mat-icon>
                                Última actualización: <strong> 23/09/2024 - Buho User </strong>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-sm-8">
                    <div class="card h-100">
                        <div class="text-center">
                            <iframe id="pdf" name="pdf" width="95%" height="500px" src="../../../../assets/examples/ejemplo.pdf"></iframe>
                        </div>
                    </div>
                </div>

      
                <div class="col-sm-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="card-title">Formulario de aprobación</h4>
                            <mat-divider></mat-divider>
                            <form autocomplete="off" class="mt-3">
                                <small>Todos los campos son obligatorios *</small>
                                <div class="row">
                                    <div class="col-sm-8 pt-4">
                                        <span class="h5">Descripción del producto:</span>
                                    </div>
                                    <div class="col-sm-4">
                                        <mat-button-toggle-group appearance="legacy" class="mt-3">
                                            <mat-button-toggle [value]="true">SI</mat-button-toggle>
                                            <mat-button-toggle [value]="false">NO</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-8 pt-4">
                                        <span class="me-3 h5">Color de Producto:</span>
                                    </div>
                                    <div class="col-sm-4">
                                        <mat-button-toggle-group appearance="legacy" class="mt-3">
                                            <mat-button-toggle [value]="true">SI</mat-button-toggle>
                                            <mat-button-toggle [value]="false">NO</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-8 pt-4">
                                        <span class="me-3 h5">VoBo imagen(es):</span>
                                    </div>
                                    <div class="col-sm-4">
                                        <mat-button-toggle-group appearance="legacy" class="mt-3">
                                            <mat-button-toggle [value]="true">SI</mat-button-toggle>
                                            <mat-button-toggle [value]="false">NO</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-8 pt-4">
                                        <span class="me-3 h5">Mecánica promocional:</span>
                                    </div>
                                    <div class="col-sm-4">
                                        <mat-button-toggle-group appearance="legacy" class="mt-3">
                                            <mat-button-toggle [value]="true">SI</mat-button-toggle>
                                            <mat-button-toggle [value]="false">NO</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-4 pt-4">
                                        <span class="me-3 h5">Comentarios:</span>
                                    </div>
                                    <div class="col-sm-8 pt-3">
                                        <mat-form-field class="w-100 no-space-bottom" appearance="outline">
                                            <mat-label>Ingrese sus comentarios</mat-label>
                                            <input matInput value="">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-sm-12 text-end">
                                        <button mat-flat-button mat-dialog-close>Limpiar <mat-icon>clear</mat-icon></button>
                                        <button mat-raised-button class="bg-success text-white">Guardar <mat-icon>save</mat-icon></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

          
            <div class="row mt-4">
                <div class="col-md-12">
                    <span class="align-middle">Histórico de Archivos de Diagramación</span>
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover overflow-auto">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Archivo</th>
                                    <th>Chat</th>
                                    <th>Cargado por</th>
                                    <th>Fecha de Registro</th>
                                    <th>Equipo</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let p of getArchivosByPhase(1)">
                                    <td>{{ p.id }}</td>
                                    <td>
                                        <a href="https://drive.google.com/file/d/{{p.archivo}}">
                                            {{ p.nombre }}
                                        </a>
                                    </td>
                                    <td>
                                        <button mat-icon-button>
                                            <mat-icon *ngIf="p.msg_inc == 0">chat</mat-icon>
                                            <mat-icon *ngIf="p.msg_inc > 0" [matBadge]="p.msg_inc">chat</mat-icon>
                                        </button>
                                    </td>
                                    <td>{{ p.nombre_usuario }}</td>
                                    <td>{{ p.created_at | date : 'dd/MM/yyyy HH:mma': '-0000' }}</td>
                                    <td><span class="badge bg-warning">FDA</span></td>
                                </tr>
                                <tr *ngIf="getArchivosByPhase(1).length == 0">
                                    <td colspan="6"><i>No se ha cargado ningún archivo</i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div>
</mat-dialog-content>

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-logs',
  templateUrl: './projects-logs.component.html',
  styleUrls: ['./projects-logs.component.scss']
})
export class ProjectsLogsComponent implements OnInit {

  project: any;
  mainLogoRoute = "../../assets/img/clients/";
  regLogs : any = [];
  message : string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private projectService : ProjectsService,
    private main : MainService,
  ) {
    this.project = this.data.project;
    this.loadData();
   }

  ngOnInit(): void {
  }

  loadData(first:boolean = true){
    if(first)this.main.ShowLoading();
    this.projectService.getProjectsChangelog(this.project.id).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.regLogs = result.data;
      },
      (err:any) => {
        this.main.HideLoading();
        if(err.error.message != "No changelog were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  saveNewMessage(){
    if(this.message!= ""){
      this.main.ShowLoading();
      this.projectService.newProjectsMessage({"id_proj": this.project.id, "message": this.message}).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            this.message = "";
            this.loadData(false);
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }
  }

  getUserCompanyOrRol(log : any){
    if(log.id_rol == 3 && log.company_name != null){
      return log.company_name;
    }else{
      return log.rol;
    }
  }

  getLogoCompany(logImage : string):string{
    return "url(" + this.mainLogoRoute + logImage + ")"
  }

}

<div class="modal-header">
    <h4 class="modal-title">Distribución: <strong>{{ data.campaign.name }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <h6 class="pb-4" >
            <span class="align-middle">Sube un nuevo archivo de distribución:</span>
        </h6>
        <form [formGroup]="form">
            <ngx-file-drag-drop [disabled]="validated" class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
            </ngx-file-drag-drop>
        </form>
        <div class="row mb-4">
            <div class="col-sm-12" *ngIf="file_validated" >
                
                <h6 *ngIf="!responsedata?.hasChanges; else elseBlock" class="pt-4">No se detectaron modificaciones que afecten alguna estrategia</h6>
                <ng-template #elseBlock>
                    <h6 class="pt-4">Lista de modificaciones a realizarse:</h6>
                    <mat-accordion class="elementsList">
                        <mat-expansion-panel *ngIf="responsedata?.changes.modified.length!=0">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Estrategias afectadas <span class="ms-2 badge badge-center rounded-pill bg-primary">{{responsedata?.changes.modified.length }}</span>
                                    <mat-panel-description>
                                        &nbsp;
                                        <mat-icon>border_color</mat-icon>
                                    </mat-panel-description>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="demo-inline-spacing">
                                <div class="list-group list-group-flush">
                                    <a *ngFor="let mat of responsedata?.changes.modified" class="list-group-item list-group-item-action">{{mat}}</a>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>    
                </ng-template>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button *ngIf="!file_validated && !isClientUsr" (click)="validateFile()" mat-flat-button class="btn bg-success text-white" form="" type="submit">Validar archivo <mat-icon>save</mat-icon></button>
    <button *ngIf="file_validated || isClientUsr" (click)="uploadFile()" mat-flat-button class="btn bg-success text-white" form="" type="submit">Registrar cambios <mat-icon>save</mat-icon></button>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistributionPreprojectFileComponent } from '../distribution-preproject-file/distribution-preproject-file.component';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import Swal from 'sweetalert2';
import { DistributionArtFileComponent } from '../distribution-art-file/distribution-art-file.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-distribution-preproject',
  templateUrl: './distribution-preproject.component.html',
  styleUrls: ['./distribution-preproject.component.scss']
})
export class DistributionPreprojectComponent implements OnInit {
  
  strategy: any;
  odtFiles: any;
  isClientUsr: boolean = false;
  isLoading = true;
  canUploadOdt: boolean = false;
  materiales: { id: number , nombre: string}[] = [];

  artes: { 
    nombre_archivo: string, 
    archivo: string, 
    sku: string, 
    id_sku: number,
    bitValido: number, 
    blob: string,
    nombre_op: string,
    accesorio1: string,
    mult_accesorio1: string,
    accesorio2: string,
    mult_accesorio2: string,
    idArtePreproyecto: number,
    medida_x: number,
    medida_y: number,
    file_respuesta: string, 
    file_respuesta_report: string, 
    nombre_file: string, 
    nombre_file_report: string, 
    fileUrl: SafeResourceUrl, 
    fileUrlReport: SafeResourceUrl,
    fileUrlArte: SafeResourceUrl,
    nombre_layout: string,
    layout: string,
    fyv: string,
    material_id: number,
    bitEnviaAccesorios: number,
    hasChanged: boolean
  }[] = [];

  objSend = {};
  objSendPreproyecto = {};
  flagArtesPreproyecto = true;

  dataPreproject: {
    contacto: string,
    impresion: string,
    color: string,
    envio: string,
    recepcion: string,
    fecha_inicio: Date,
    fecha_fin: Date,
    id_preproyecto: number,
    tipo: string,
    area_responsable: string,
    archivo_layout: string,
    archivo_odt: string
  } = {
    contacto: '',
    impresion: '',
    color: '',
    envio: '',
    recepcion: '',
    fecha_inicio: new Date(),
    fecha_fin: new Date(),
    id_preproyecto: 0,
    tipo: '',
    area_responsable: '',
    archivo_layout: '',
    archivo_odt: ''
  }

  public sanitizedSourceArte!: SafeResourceUrl;
  public sanitizedSource!: SafeResourceUrl;
  public sanitizedSourceReport!: SafeResourceUrl;

  archivos: any = [];

  tiposImpresion: string[] = [
    "INDIGO DIGITAL",
    "OFFSET",
    "ROTATIVA",
    "DIGITAL (DOCUCOLOR)",
    "FLEXOGRAFÍA",
    "SERIGRAFÍA",
    "GRAN FORMATO",
    "GRAN FORMATO/TINTA LATEX",
    "PLOTTER CAMA PLANA",
    "SUBLIMADO",
    "TAMPOGRAFÍA",
    "OFFSET PRENSA PLANA",
    "PLOTTER DE CORTE",
    "N/A"
  ];
  
  tiposEnvio: string[] = [
    "TERRESTRE",
    "AÉREO",
    "LOCAL",
    "N/A"
  ];
  
  tiposRecepcion: string[] = [
    "SUCURSALES",
    "PLAZAS",
    "GUÍA",
    "GUÍA ESTAFETA",
    "GUÍA PAQUETEXPRESS",
    "DEACERO OFICINAS",
    "DEACERO BODEGA",
    "DEACERO SALTILLO PLANTA ALAMBRE",
    "DEACERO MONTERREY PLANTA ALAMBRE",
    "DEACERO PLANTA GUADALUPE",
    "CEMIX CORPORATIVO",
    "PAK2GO",
    "ULTIMA MILLA",
    "N/A"
  ];

  originalData: any = [];
  mostrarAccesorios: boolean[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<DistributionPreprojectComponent>,
    public sanitizer: DomSanitizer,
  ) { 
    this.strategy = this.data.strategy;
    this.loadData();
    this.isClientUsr = campaignService.isClientUsr();
    this.canUploadOdt = !this.data.islocked && this.campaignService.hasPermissions("upl_odt",true,false);
  }

  ngOnInit(): void {
    this.sanitizedSourceArte = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.sanitizedSourceReport = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
  }

  loadData(){

    this.main.ShowLoading();
    this.campaignService.getArtesPreproyecto(this.strategy.id).subscribe(
            (result:any) => {
              this.materiales = result.dataMat;
              this.artes = result.data;
    
              this.artes.forEach((item) => {
                item.fileUrlArte = this.sanitizer.bypassSecurityTrustResourceUrl(item.archivo);
                item.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.file_respuesta);
                item.fileUrlReport = this.sanitizer.bypassSecurityTrustResourceUrl(item.file_respuesta_report);
                item.bitEnviaAccesorios = 1;
              })

              this.originalData = JSON.parse(JSON.stringify(this.artes));

              this.mostrarAccesorios = new Array(this.artes.length).fill(true);

              this.main.HideLoading();
            },
            (err:any) => {
              this.main.HideLoading();
                Swal.fire({
                  icon: 'error',
                  title: 'Error al obtener registros',
                  text: 'Ocurrió un error al obtener los registros.'
                });
            }
          );
  }

  openDialogPreprojectFile() {
    this.matDialog.open(DistributionPreprojectFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        strategy : this.data.strategy
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.dialogRef.close({ reload: true });
        }
      }
    );
  }

  saveInfoArtes(data: any){
  
    this.objSend = {
      idStrategy: this.strategy.id,
      dataForm: data
    }

    const cambios = this.artes.filter(arte => arte.hasChanged);

    //Si hubo cambios en el form de artes entonces lo guarda
    if(cambios.length > 0){
      this.campaignService.setArtesPreproyecto(this.objSend).subscribe(
        (result:any) => {
  
          Swal.fire({
            icon: 'success',
            title: 'Información de artes guardada'
          });
        
        },
        (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar registros',
              text: 'Ocurrió un error al guardar los registros.'
            });
        }
      );
    }
    
  }

  openDialogArtUploadFile(sku: string, idSku: number, medidaX: number, medidaY: number) {
      this.matDialog.open(DistributionArtFileComponent, {
        panelClass: ['dialogs-md'],
        data: {
          strategy : this.data.strategy,
          sku: sku,
          idSku: idSku,
          medida_x: medidaX,
          medida_y: medidaY,
          toggleEnfocus: 1
        }
      }).afterClosed().subscribe(
        resp => {
          this.loadData();
        }
      );
  }

  switchArtesPreproyecto(artes: any, formPreproject: any, type: number) {
  
      this.flagArtesPreproyecto = !this.flagArtesPreproyecto;

      //Guarda artes form y Carga la parte de preproyecto
      if(!this.flagArtesPreproyecto){

        this.saveInfoArtes(artes);
        this.getPreproyectoEstrategia();
      }

      this.loadData();
      /* else{
        if(type == 1){
          this.savePreproyecto(formPreproject);
        }
      } */
    }

    savePreproyecto(data: any){

      if(data.id_preproyecto == undefined){ data.id_preproyecto = 0}

      this.objSendPreproyecto = {
        idStrategy: this.strategy.id,
        dataForm: data
      }
  
      this.campaignService.setFormPreproyecto(this.objSendPreproyecto).subscribe(
        (result:any) => {
  
          //this.dialogRef.close();
          this.generarPreproyecto();
 
        },
        (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar registros',
              text: 'Ocurrió un error al guardar los registros.'
            });
        }
      );
    }

    getPreprojectGlobal(){

      this.campaignService.getPreprojectGlobal(this.data.campaign.id).subscribe(
        (result:any) => {         
          const fechaOriginal1 = result.data[0]?.fecha_inicio || null;
          const fechaOriginal2 = result.data[0]?.fecha_fin || null;
  
          if(fechaOriginal1 != null){
            const [year, month, day] = fechaOriginal1.split('-').map(Number);
            const fecha1 = new Date(year, month - 1, day);
            result.data[0].fecha_inicio = fecha1;
          }
          if(fechaOriginal2 != null){
            const [year2, month2, day2] = fechaOriginal2.split('-').map(Number);
            const fecha2 = new Date(year2, month2 - 1, day2);
            result.data[0].fecha_fin = fecha2;
          }

          if(result.data[0] != undefined){
            this.dataPreproject = result.data[0];
          }
  
        },
        (err:any) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }    
      ); 

    }

    generarPreproyecto(){

      Swal.fire({
        icon: 'info',
        title: 'Generando Preproyecto...',
        text:'Por favor espera, no cierres ni recargues la página.',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      this.campaignService.generatePreproyecto(this.strategy.id).subscribe(
        (result:any) => {

          this.getPreproyectoEstrategia()
          Swal.fire({
            icon: 'success',
            title: 'Generación correcta'
          });
          this.data.strategy.bitPreproyecto = 0;
        },
        (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al generar preproyecto',
              text: 'Ocurrió un error al generar preproyecto.'
            });
        }
      );

    }

    getSourceURL(fileUrl: SafeResourceUrl): SafeResourceUrl {
        return fileUrl;
    }


    getPreproyectoEstrategia(){
      this.campaignService.getPreprojectEstrategia(this.strategy.id).subscribe(
        (result:any) => { 
                  
          const fechaOriginal1 = result.data[0]?.fecha_inicio || null;
          const fechaOriginal2 = result.data[0]?.fecha_fin || null;
  
          if(fechaOriginal1 != null){
            const [year, month, day] = fechaOriginal1.split('-').map(Number);
            const fecha1 = new Date(year, month - 1, day);
            result.data[0].fecha_inicio = fecha1;
          }
          if(fechaOriginal2 != null){
            const [year2, month2, day2] = fechaOriginal2.split('-').map(Number);
            const fecha2 = new Date(year2, month2 - 1, day2);
            result.data[0].fecha_fin = fecha2;
          }

          if(result.data[0] != undefined){
            this.dataPreproject = result.data[0];
          }
          else{
            this.getPreprojectGlobal()
          }
  
        },
        (err:any) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }    
      );
    }

    cargaVoBoCliente(){

      this.campaignService.setVoBoCliente(this.strategy.id).subscribe(
        (result:any) => {

          if(result.message == "Success"){
            Swal.fire({
              icon: 'success',
              title: 'Listo'
            });
            this.data.strategy.bitPreproyecto = 1;
            this.dialogRef.close();

          }
  
        },
        (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Ocurrió un error en la operación.'
            });
        }
      );

    }

    checkChanges(arte: any) {
      // Buscamos el arte original y comparamos si cambió
      const original = this.originalData.find((a: any) => a.idArtePreproyecto === arte.idArtePreproyecto);
      arte.hasChanged = original 
      ? (original.material_id !== arte.material_id || 
        original.nombre_layout !== arte.nombre_layout || 
        original.layout !== arte.layout || 
        original.fyv !== arte.fyv ||
        original.bitEnviaAccesorios !== arte.bitEnviaAccesorios)
      : false;
    }

    onToggleChangeAcc(event: MatSlideToggleChange, index: number){
      this.mostrarAccesorios[index] = event.checked;
    }


}

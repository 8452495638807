<div class="modal-header">
    <h4 class="modal-title">Diagramación de: <strong>Campaña {{ dataSent.nombre }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <h6 class="pb-4">
            <span class="align-middle">Cargar un nuevo archivo de diagramación:</span>
        </h6>
        <form [formGroup]="form">
            <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
            </ngx-file-drag-drop>
        </form>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" form="" (click)="uploadFile()" type="submit">Cargar Archivo <mat-icon>upload</mat-icon></button>
</div>
<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Archivos del Recurso <strong>{{this.urls.namemat}}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">

        <h6 class="pb-4">
            <span class="align-middle">Listado de archivos</span>
            <div class="float-end align-middle">
                <button mat-stroked-button color="primary" (click)="downloadAll()">Descargar todos <mat-icon class="align-middle ms-1">file_download</mat-icon></button>
            </div>
        </h6>

        <mat-divider></mat-divider>
        <div>
            <div class="row g-2">
                <div class="col mb-0">
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead>
                                <tr>     
                                    <th>Archivo</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let url of urls.links">
                                    <td>
                                        <mat-icon *ngIf="url.type=='pdf'">picture_as_pdf</mat-icon><mat-icon *ngIf="url.type=='ai'">insert_drive_file</mat-icon>{{ url.name }}
                                    </td>
                                    <td>
                                        <a matTooltip="Descargar" href="{{url.url}}" download><mat-icon>download</mat-icon> </a>
                                        &nbsp; &nbsp;
                                        <a *ngIf="url.type=='pdf'" matTooltip="Ver" href="{{url.url}}" target="_blank"><mat-icon>visibility</mat-icon> </a>         
                                    </td>
                                </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-survey-image-slider',
  templateUrl: './survey-image-slider.component.html',
  styleUrls: ['./survey-image-slider.component.scss']
})
export class SurveyImageSliderComponent implements OnInit {


  images : any;
  material_info : any;
  campana : any;
  validated: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
  ) { 
    this.images = this.data.images;
    this.material_info = this.data.material_info;
    this.campana = this.data.campana;

  }

  imageObject = [
    {
        image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
        nombre : ""
    }];


  ngOnInit(): void {
    this.generateImagesObjs();
  }

  private apiURL = environment.API_URL;
  //private apiURL = "http://localhost:3004";

  urlImage = this.apiURL + "/surveys/image/";

  generateImagesObjs(){
    console.log(this.images,this.material_info,this.campana)
    this.imageObject = [];
    this.images.forEach((x:any)=>{
      if(x.archivo){
        this.imageObject.push({ "image" : this.urlImage + x.archivo , "nombre" : x.nombre});
      }else{
        this.imageObject.push({ "image" : x , "nombre" : "imagen"});
      }
      
    });
  }

}
 
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-projects-providers-form-history',
  templateUrl: './projects-providers-form-history.component.html',
  styleUrls: ['./projects-providers-form-history.component.scss']
})
export class ProjectsProvidersFormHistoryComponent implements OnInit {

  nombre_proveedor : any;
  responses : any = [];
  files : any = [];

  constructor( @Inject(MAT_DIALOG_DATA) public data : any,       
  private projectService : ProjectsService,
  private main : MainService,) { 

    this.nombre_proveedor = this.data.nombre_proveedor;
    this.responses = this.data.responses;
    this.files = this.data.files;
  }

  ngOnInit(): void {
    console.log(this.nombre_proveedor, this.responses);
  }

  filterFiles(id_provider_survey_response : number, type : number){
    return this.files.filter((x:any)=>{ return x.id_provider_survey_response == id_provider_survey_response && x.type ==type})
  }

  filterFilesCount(id_provider_survey_response : number){
    return this.files.filter((x:any)=>{ return x.id_provider_survey_response == id_provider_survey_response}).length;
  }

  filterCurrent(){
    return this.responses.filter((x:any)=>{ return x.current == 1 });
  }

  filterOld(){
    return this.responses.filter((x:any)=>{ return x.current == 0 });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-vobo-add',
  templateUrl: './projects-vobo-add.component.html',
  styleUrls: ['./projects-vobo-add.component.scss']
})
export class ProjectsVoboAddComponent implements OnInit {
  newVobo!: any;
  id_proj : number = 0;
  isEdit : boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main: MainService,
    private projectS: ProjectsService,
    private userS: UsersService,
    private dialogRef: MatDialogRef<ProjectsVoboAddComponent>,
  ) { 
    this.newVobo = this.data.vobo;
    this.isEdit = this.data.vobo.id != 0;
  }

  ngOnInit(): void {
    if(!this.isEdit) {
     
    }
  }

  setUsers(t: any) {
    this.newVobo.users = t;
  }

  setVobos(t: any) {
    this.newVobo.vobos = t;
  }

  saveProject(){
  }

}

<div style="display: flex; justify-content: flex-end;">
  <button type="button" class="btn-close" [mat-dialog-close]=""></button>
</div>
<h1 mat-dialog-title class="m-0">Nuevo Medio</h1>

<div class="modal-body-scroll">
  <form class="inputform">
    <mat-form-field appearance="outline">
      <mat-label>Nombre: </mat-label>
      <input matInput [(ngModel)]="data.name" name="name" required>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="data.allowMultipleItems" name="select1">
      Permitir múltiples elementos por sucursal
    </mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="data.allowEmptyBranches" name="select2">
      Permitir dejar sucursales vacías
    </mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="data.segmentable" name="select3">
      Segmentable
    </mat-slide-toggle>

    <mat-form-field appearance="outline">
      <mat-label>Formato de Distribución</mat-label>
      <mat-select  [(ngModel)]="selectedFormato" name="formato">
        <mat-option [value]="1">Por Prioridad</mat-option>
        <mat-option [value]="2">Por Reglas</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mb-4" *ngIf="selectedFormato == 1">
      <div class="row align-items-center"> 
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Atributo</mat-label>
            <mat-select>
              <mat-option *ngFor="let atributo of atributosSucursales" [value]="atributo">{{ atributo }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        
        <div class="col-md-3 d-flex justify-content-center">
          <span class="x-label">Multiplicado por:</span> 
        </div>
    
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Valor</mat-label>
            <input matInput placeholder="Ej: 10" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <h5 class="fw-bold text-primary">Filtro sucursales</h5>
      <p *ngIf="reglasAlcance.length>0"><span class="tinyfont">*Cantidad de sucursales filtradas : <b>25</b></span></p>
      <div *ngFor="let regla of reglasAlcance; let i = index" class="mb-3">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Atributo</mat-label>
              <mat-select [(ngModel)]="regla.atributo" name="atributo">
                <mat-option *ngFor="let atributo of atributosSucursales" [value]="atributo">{{ atributo }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Condición</mat-label>
              <mat-select [(ngModel)]="regla.comparador" name="comparador">
                <mat-option *ngFor="let comparador of comparadores" [value]="comparador">{{ comparador }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Valor</mat-label>
              <input matInput [(ngModel)]="regla.valor" name="valor" placeholder="Ej: 10" />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <button mat-icon-button color="warn" (click)="eliminarRegla(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <button mat-flat-button color="primary" (click)="agregarRegla()">
        <mat-icon>add</mat-icon> Añadir Regla
      </button>
    </div>
  </form>
</div>

<!-- Footer del modal -->
<div mat-dialog-actions class="button-container">
  <button mat-raised-button class="nest-button" (click)="register()" [disabled]="invalidInput()">Agregar</button>
</div>
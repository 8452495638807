import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ProviderModel } from 'src/app/models/provider.model';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-providers-list',
  templateUrl: './providers-list.component.html',
  styleUrls: ['./providers-list.component.scss']
})
export class ProvidersListComponent implements OnInit {

  private idRoleAdmin = environment.role_admin_id;
  providersList = new Array<any>();
  isLoading = false;  

  displayedColumns: string[] = ['id', 'nombre', 'razon_social', 'actions'];
  dataSource!: MatTableDataSource<ProviderModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private projectService : ProjectsService,
    private matDialog: MatDialog,
    private _titleService: Title,
    private _router: Router,
    private main : MainService
  ) {
    this._titleService.setTitle("Buho Nest - Proveedores");
    this.getProvidersList();
  }

  ngOnInit(): void {
  }

  getProvidersList() {
   // this.providersList = [
   //   {
   //     id: 1,
   //     name: "Pfizer",
   //     companyName: "PFIZER S.A. DE C.V.",
   //     logo: "",
   //     active: true,
   //     members: [],
   //   },
   //   {
   //     id: 2,
   //     name: "BAYER",
   //     companyName: "BAYER DE MÉXICO S.A. DE C.V.",
   //     logo: "",
   //     active: true,
   //     members: [],
   //   },
   //   {
   //     id: 3,
   //     name: "PISA",
   //     companyName: "Laboratorios PiSA S.A. DE C.V.",
   //     logo: "",
   //     active: true,
   //     members: [],
   //   }
   // ];
    this.main.ShowLoading();
    this.projectService.getProvidersList().subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.providersList  = result.data;

       this.dataSource = new MatTableDataSource(this.providersList);        
       this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;
       this.isLoading = false;
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros de proveedores.'
          });
        
      }
    );
  }

  goToAddProvider() {
    this._router.navigateByUrl('/admin/providers/add')
  }

  goToEditProvider(id : number){
    this._router.navigateByUrl('/admin/providers/edit/'+  id)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.userS.hasPermissions(key, buho, client);
  }

  openDialogProviderEdit(provider: ProviderModel) {
  }

  activateProvider(id: number, active: number,name: string) {
    this.main.ShowLoading();
    this.projectService.activeProvider({id_provider : id, active : active == 0? 1 : 0}).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.getProvidersList();
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al cambiar es status',
            text: 'Ocurrió un error inesperado al cambiar de status'
          });
          this.getProvidersList();
      }
    );
  }

  isAdmin() {
    return (this.userS.getUser().id_rol == this.idRoleAdmin);
  }

  deleteProvider(id:any): void {
    Swal.fire({
      title: '¿Estás seguro que deseas eliminar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        
        this.main.ShowLoading();
        this.projectService.deleteProvider(id).subscribe(
          (result:any) => {
           this.main.HideLoading();
           this.getProvidersList();
          },
          (err:any) => {
            this.main.HideLoading();
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error inesperado al eliminar'
              });
              this.getProvidersList();
          }
        );

      }
    });
  }

}

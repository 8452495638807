import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProjectsProvidersAddComponent } from '../projects-providers-add/projects-providers-add.component';
import { CampaignLogsComponent } from '../campaign-logs/campaign-logs.component';
import { ProjectsProvidersFormHistoryComponent } from '../projects-providers-form-history/projects-providers-form-history.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { ProjectsProvidersReloadComponent } from '../projects-providers-reload/projects-providers-reload.component';
import { ProvidersSurveysLogsComponent } from '../providers_surveys_logs/providers_surveys_logs.component';
import { ProjectsProvidersReSendComponent } from '../projects-providers-resend/projects-providers-resend.component';

@Component({
  selector: 'app-projects-providers-list',
  templateUrl: './projects-providers-list.component.html',
  styleUrls: ['./projects-providers-list.component.scss']
})
export class ProjectsProvidersListComponent implements OnInit {

  dataSent : any;
  hostName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,    
    private matDialog: MatDialog,
    private projectService : ProjectsService,
    private main : MainService,
  ) {
      this.dataSent = this.data;
      this.hostName = window.location.protocol + '//' + window.location.host + "/providers-preview/";
    }

  folder : any;
  id_campana_proyecto : any;
  providerSurveyList : any = [];
  
  ngOnInit(): void {
    this.getProvidersSurv();
  }

  openDialogAddProvidersFile() {
    this.matDialog.open(ProjectsProvidersAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        camp_id : this.dataSent.camp_id,
        folder : this.dataSent.folder,
        nombre : this.dataSent.nombre,
        listprovsurv : this.providerSurveyList
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }

  openDialogReloadProvidersFile(id_provider_survey : number, id_provider : number) {
    this.matDialog.open(ProjectsProvidersReloadComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        id_provider_survey : id_provider_survey,
        folder : this.dataSent.folder,
        nombre : this.dataSent.nombre,
        id_provider : id_provider,
        id_campana_proyecto : this.dataSent.camp_id
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }

  
  openDialogReSendProvidersFile(id_provider_survey : number, id_provider : number) {
    this.matDialog.open(ProjectsProvidersReSendComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        id_provider_survey : id_provider_survey,
        folder : this.dataSent.folder,
        nombre : this.dataSent.nombre,
        id_provider : id_provider,
        id_campana_proyecto : this.dataSent.camp_id
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }

  openDialogFileLogs(id_provider_survey : number) {
    this.matDialog.open(ProvidersSurveysLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        id_provider_survey : id_provider_survey 
      }
    }).afterClosed().subscribe(
      resp => {
        this.getProvidersSurv();
      }
    );
  }
  
  openDialogFormHistory(id_provider_survey : number, nombre : string) {
    this.matDialog.open(ProjectsProvidersFormHistoryComponent, {
      panelClass: ['dialogs-lg'],
      data: {        
        responses : this.responses.filter((x : any )=>{ return x.id_provider_survey == id_provider_survey }),
        files : this.files,
        nombre_proveedor : nombre,
        folder : ""
      }
    }).afterClosed().subscribe(
      resp => {
        
      }
    );
  }

  responses : any  = [];
  files :  any = [];

  getProvidersSurv(){
    this.projectService.getProvidersSurveys(this.dataSent.camp_id).subscribe((result:any)=>{
      if(result.message = 'Success'){
      
        this.providerSurveyList = result.data.surveys;
        this.responses = result.data.responses;
        this.files = result.data.files;

        console.log("prosurv", this.providerSurveyList);
      }
     },
     (err:any)=>{
      console.log(err);
     });

  }

  DownloadElement(url : string){
    window.open(url, "_blank");
  }


  deleteProvSurv(id: number){
    Swal.fire({
      title: '¿Está seguro que desea eliminar este registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete : any) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.projectService.deleteProviderSurvey(id).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Eliminado',
              text: 'Registro eliminado correctamente'          
            }).then(s=>{
              this. getProvidersSurv();
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid provider surveys were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al eliminar',
              text: 'Ocurrió un error al eliminar.'
            });
          }
        }
      );
    } else {
    
    }
  });
  }

  lockProviderSurv(id_provider_survey: number, lock : number){
    let lockeunlocked = ""
    let sucesslock = ""
    if(lock == 0){
      lockeunlocked = "desbloquear"
      sucesslock = "desbloqueado"
    }else{
      lockeunlocked = "bloquear"
      sucesslock = "bloqueado"
    }
    Swal.fire({
      title: '¿Está seguro que desea '+ lockeunlocked +' este registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
   .then((willDelete : any) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.projectService.setProvidersSurvLock({ id_provider_survey : id_provider_survey, lock : lock }).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Exito',
              text: 'Registro '+ sucesslock +' correctamente'          
            }).then(s=>{
              this. getProvidersSurv();
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid provider surveys were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al '+ lockeunlocked +'.',
              text: 'Ocurrió un error al '+ lockeunlocked +'.'
            });
          }
        }
      );
    } else {
    
    }
  });
  }

  goToPreview(id: number){
    let urlSend = this.hostName + id + '/0';
    window.open(urlSend, "_blank");
  }

}

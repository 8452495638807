import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreModel } from 'src/app/models/store.model';
import { ProjectsService, PreProyecto } from 'src/app/services/projects.service';
import { PrepojectStrategyAddComponent } from '../shared/dialogs/preproject-strategy-add/preproject-strategy-add.component';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { ProjectsTotalsAddComponent } from '../shared/dialogs/preprojects-totals-add/preprojects-totals-add.component';
import { PreProjectsDocsAddFileComponent } from '../shared/dialogs/preprojects-form-docs-add-file/preprojects-form-docs-add-file.component';
import { ProjectsPDFsAddComponent } from '../shared/dialogs/preprojects-pdfs-add/preprojects-pdfs-add.component';
//import { StoresAttributesEditComponent } from '../../shared/dialogs/stores-attributes-edit/stores-attributes-edit.component';


@Component({
  selector: 'app-preproject-generate',
  templateUrl: './preproject-generate.component.html',
  styleUrls: ['./preproject-generate.component.scss']
})
export class PreprojectGenerateComponent implements OnInit {

  strategies : PreProyecto[] = [];
  storeList = new Array<StoreModel>();
  isLoading = true;  

  dataSource!: MatTableDataSource<StoreModel>;

  tiposImpresion: string[] = [
    "INDIGO DIGITAL",
    "OFFSET",
    "ROTATIVA",
    "DIGITAL (DOCUCOLOR)",
    "FLEXOGRAFÍA",
    "SERIGRAFÍA",
    "GRAN FORMATO",
    "GRAN FORMATO/TINTA LATEX",
    "PLOTTER CAMA PLANA",
    "SUBLIMADO",
    "TAMPOGRAFÍA",
    "OFFSET PRENSA PLANA",
    "PLOTTER DE CORTE",
    "N/A"
  ];
  
  tiposArea: string[] = [
    "Comercialización",
    "Trade",
    "Marketing"
  ];
  
  tiposEnvio: string[] = [
    "TERRESTRE",
    "AÉREO",
    "LOCAL",
    "N/A"
  ];
  
  tiposRecepcion: string[] = [
    "SUCURSALES",
    "PLAZAS",
    "GUÍA",
    "GUÍA ESTAFETA",
    "GUÍA PAQUETEXPRESS",
    "DEACERO OFICINAS",
    "DEACERO BODEGA",
    "DEACERO SALTILLO PLANTA ALAMBRE",
    "DEACERO MONTERREY PLANTA ALAMBRE",
    "DEACERO PLANTA GUADALUPE",
    "CEMIX CORPORATIVO",
    "PAK2GO",
    "ULTIMA MILLA",
    "N/A"
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  isBuhoUsr: any;
  PreprojectTotals : any = [];
  CampData : any; 

  id_camp = 0;

  constructor(
    private projS: ProjectsService,
    private matDialog: MatDialog,
    private _titleService: Title,
    private _router: Router,
    private _params: ActivatedRoute,
    private main : MainService,
  ) {
    this._titleService.setTitle("Buho Nest - Generar Preproyecto");
    this.isBuhoUsr = this.projS.isBuhoUsr();

    this._params.params.subscribe(params => {
      if(params.id){
        this.id_camp = params.id;
        this.loadStrategies(this.id_camp);
      }else{
        this._router.navigateByUrl('/admin/dashboard');
      } 
    });
  
  }

  ngOnInit(): void {
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.projS.hasPermissions(key, buho, client);
  }

  getStoresList() {
    this.dataSource = new MatTableDataSource(this.storeList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  loadStrategies(campid : number){
    this.main.ShowLoading();
    this.projS.getPreprojectCamp(campid).subscribe(
        (result:any) => {
          this.main.HideLoading();
          console.log("preprojects",result);
          if(result?.message == 'Success'){
            this.strategies = result.data;
          }else{
            console.log("No se cargaron los formularios:", result.message);
          }
          this.projS.getPreprojectTotals(campid).subscribe(
            (result:any) => {
              this.main.HideLoading();
              console.log("preprojects",result);
              if(result?.message == 'Success'){
                this.PreprojectTotals = result.data.totals;
                console.log(this.PreprojectTotals);
                this.CampData = result.data.campdata;
                console.log(this.CampData);
              }else{
                console.log("No se cargaron los formularios:", result.message);
              }
             },
             (err:any) => {
              this.main.HideLoading();
              console.log(err);
              if(err.error.message != "No valid strategies were found in the request")
              {
                Swal.fire({
                  icon: 'error',
                  title: 'Error al obtener registros',
                  text: 'Ocurrió un error al obtener los registros.'
                });
              }else{
                this.strategies = [];
              }
            }
          );

         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid strategies were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al obtener registros',
              text: 'Ocurrió un error al obtener los registros.'
            });
          }else{
            this.strategies = [];
          }
        }
      );
  }

  openDialogAttrsPending() {
   // this.matDialog.open(StoresAttributesPendingListComponent, {
   //   panelClass: 'dialogs-lg',
   //   data: {
   //     zone : true
   //   }
   // }).afterClosed().subscribe(
   //   resp => {        
   //       this.isLoading = true;
   //       this.getStoresList();
   //   }
   // );
  }

  openEditStr(id_str : number, id_campana : number, str : any){
    this.matDialog.open(PrepojectStrategyAddComponent, {
      panelClass: 'dialogs-md',
      data: {
        id_str: id_str,
        id_campana : this.id_camp,
        str : str,
        camp_data : this.CampData
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.loadStrategies(this.id_camp);
      }
    );

  }


  openTotales(){
    this.matDialog.open(ProjectsTotalsAddComponent, {
      panelClass: 'dialogs-md',
      data: {
        id_camp : this.id_camp
      }
    }).afterClosed().subscribe(
      resp => {        
        this.loadStrategies(this.id_camp);
      }
    );

  }

  openUploadForm(id_form : number){
    this.matDialog.open(PreProjectsDocsAddFileComponent, {
      panelClass: 'dialogs-md',
      data: {
        id_form : id_form
      }
    }).afterClosed().subscribe(
      resp => {        
        this.loadStrategies(this.id_camp);
      }
    );
  }

  openUploadPDF(id_form : number){
    this.matDialog.open(ProjectsPDFsAddComponent, {
      panelClass: 'dialogs-md',
      data: {
        id_form : id_form
      }
    }).afterClosed().subscribe(
      resp => {        
        this.loadStrategies(this.id_camp);
      }
    );

  }

  deleteStrConfirm(strid : any){
    Swal.fire({
      title: '¿Está seguro que desea eliminar este formulario?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete) => {
    if (willDelete.isConfirmed) {
      this.main.ShowLoading();
      this.projS.deletePreprojectForm(strid).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result?.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Eliminada',
              text: 'Formulario eliminado correctamente'          
            }).then(s=>{
              this.loadStrategies(this.id_camp);
            });
          }
         },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          if(err.error.message != "No valid strategies were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al eliminar',
              text: 'Ocurrió un error al eliminar.'
            });
          }
        }
      );
    } else {
     console.log("no se elimina")
    }
  });
  }

  pdfFull : any = [];

  openGenerate(str : PreProyecto){
    this.main.ShowLoading();
    this.projS.getPreprojectPdfs(str.id).subscribe(
      (result:any) => {
        this.main.HideLoading();
        this.pdfFull = result.data;
        let pdfList = this.pdfFull.map((item:any) => item.file);
        str.pdfs = pdfList;
        str.excel_totales =  this.PreprojectTotals[0].file;
        console.log(str);
        this.main.ShowLoading();
        this.projS.generatePreproject(str).subscribe((result:any)=>{
          this.main.HideLoading();
          if(result.message === "Success"){
            console.log(result);  
            this.main.dowloadConvertFile(result.data.layout_pdf, 'application/pdf', 'layout_pdf.pdf');
            this.main.dowloadConvertFile(result.data.odt, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'odt.xlsx');
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error al generar',
              text: 'Ocurrió un error al generar preproyeccto.'
            });
          }

        },
        (err:any) => {
          this.main.HideLoading();
       
            Swal.fire({
              icon: 'error',
              title: 'Error al generar',
              text: 'Ocurrió un error al generar preproyeccto.'
            });
          
        });
      },
      (err:any) => {
        this.main.HideLoading();
        if(err.error.message != "No valid art files were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  openDialogAttributeEditList() {

  }

  openDialogCutOffList() {

  }

}


import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ProviderMemberModel } from 'src/app/models/provider.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ProvidersAddMemberComponent } from '../../shared/dialogs/providers-add-member/providers-add-member.component';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersEditPasswordComponent } from '../../shared/dialogs/users-edit-password/users-edit-password.component';
import { ProvidersEditMemberComponent } from '../../shared/dialogs/providers-edit-member/providers-edit-member.component';


@Component({
  selector: 'app-providers-add',
  templateUrl: './providers-add.component.html',
  styleUrls: ['./providers-add.component.scss']
})
export class ProvidersAddComponent implements OnInit {

  hideEmail = true;
  hideEmailConfirm = true;

  formProvider!: FormGroup;

  providerMembersList = new Array<ProviderMemberModel>();
  isLoading = false;  

  isEdit : boolean = false;

  id_provider : number = 0;

  displayedColumns: string[] = ['id', 'name', 'email', 'permissions', 'notifications', 'actions'];
  dataSource!: MatTableDataSource<ProviderMemberModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  memberInfo: any;
  providerInfo : any;

  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private projectService : ProjectsService,
    private main : MainService,
    private _params: ActivatedRoute,
    private _router : Router,
  ) { 
    this._params.params.subscribe(params => {
      if(params.id){
        this.id_provider = params.id;
        this.isEdit = true;
      }else{
        this.isEdit = false;
      } 
    });

   }

  ngOnInit(): void {
    this.createFormProvider();
    if(this.isEdit){
      this.loadProviderInfo()
    }else{
      
     //do something
    }
    //this.getProviderMembersList();
  }

 

  loadProviderInfo(){
    this.main.ShowLoading();
    this.isLoading = true;
    this.projectService.getProviderAdminView(this.id_provider).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {
          console.log(result.data);
          this.providerInfo = result.data.provider;
          this.memberInfo = result.data.members;
          this.formProvider = this.fb.group({
            name: [this.providerInfo.nombre, [ Validators.required, Validators.minLength(3)]],
            companyName: [this.providerInfo.razon_social, [ Validators.required, Validators.minLength(3)]]
          });
          
          if(this.memberInfo.length != 0){
            this.dataSource = new MatTableDataSource(this.memberInfo);        
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          this.isLoading = false;
        }
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registro',
            text: 'Ocurrió un error inesperado al obtener el registro'
          });
          this._router.navigateByUrl('/admin/dashboard');
      }
    );
  }
  
  createFormProvider() {
    this.formProvider = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
      companyName: ['', [ Validators.required, Validators.minLength(3)]]
    });
  }

  ruSure() {
    if(this.formProvider.invalid) { return; }    
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        if(!this.isEdit){
          this.saveProvider();
        }else{
          this.editProvider();
        } 
      
      }
    });
  }

  saveProvider() {
    let providerPayload = {  nombre : this.formProvider.value.name, razon_social: this.formProvider.value.companyName }
    this.main.ShowLoading();
    this.projectService.setProvider(providerPayload).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Datos guardados correctamente'          
        }).then(() => {            
          this._router.navigateByUrl('/admin/providers');
        });
      }
      
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar registro',
            text: 'Ocurrió un error inesperado al guardar el registro'
          });
      
      }
    );
  }

  editProvider() {
    let providerPayload = {id_provider : this.id_provider,  nombre : this.formProvider.value.name, razon_social: this.formProvider.value.companyName }
    this.main.ShowLoading();
    this.projectService.editProvider(providerPayload).subscribe(
      (result:any) => {
       this.main.HideLoading();
       if(result.message == "Success") {
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Datos guardados correctamente'          
        }).then(() => {            
          this.loadProviderInfo();
        });
      }
      
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar registro',
            text: 'Ocurrió un error inesperado al guardar el registro'
          });
      
      }
    );
  }

  setCanEdit(id : number, can_edit: number){
    this.main.ShowLoading();
    this.projectService.setProviderMemberCanEdit({id_provider_member : id, can_edit : can_edit}).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.loadProviderInfo();
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al cambiar este registro',
            text: 'Ocurrió un error inesperado al cambiar el registro'
          });
          this.loadProviderInfo();
      }
    );
  }

  setNotifyMember(id: number, active_not: number) {
    this.main.ShowLoading();
    this.projectService.setProviderMemberNotify({id_provider_member : id, active_not : active_not == 0? 1 : 0}).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.loadProviderInfo();
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al cambiar es status',
            text: 'Ocurrió un error inesperado al cambiar de status'
          });
          this.loadProviderInfo();
      }
    );
  }

  activateProviderMember(id: number, active: number, id_user: number) {
    this.main.ShowLoading();
    this.projectService.setProviderMemberActive({id_provider_member : id, active : active == 0? 1 : 0, id_usuario : id_user}).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.loadProviderInfo();
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al cambiar es satsus',
            text: 'Ocurrió un error inesperado al cambiar de status'
          });
          this.loadProviderInfo();
      }
    );
  }

  resetForm() {
    this.formProvider.reset();
  }

  openDialogProvidersMemberAdd() {
    this.matDialog.open(ProvidersAddMemberComponent, {panelClass: 'dialogs-main',  data: {
      id_provider : this.id_provider
    }}).afterClosed().subscribe(
      resp => {        
        this.loadProviderInfo()  
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteUser(id_member : number) { 
    Swal.fire({
      title: '¿Estás seguro que deseas eliminar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.main.ShowLoading();
        this.projectService.deleteProviderMember(id_member).subscribe(
          (result:any) => {
            this.main.HideLoading();
            if(result.message == "Success") {
             Swal.fire({
               icon: 'success',
               title: 'Eliminado',
               text: 'Miembro eliminado correctamente'          
             }).then(() => {            
               this.loadProviderInfo();
             });
            }
          },
          (err:any) => {
            this.main.HideLoading();
              Swal.fire({
                icon: 'error',
                title: 'Error al cambiar este registro',
                text: 'Ocurrió un error inesperado al cambiar el registro'
              });
              this.loadProviderInfo();
          }
        );
      
      }
    });
  }

  getProviderMembersList() {
    this.providerMembersList = [
      {
        id: 1,
        name: "Isabela Martínez",
        email: "imartinez@pfizer.com.mx",        
        active: true,
        canAprove: true,
        notifications: true,
        phone: "-",
        pass: ""
      },
      {
        id: 2,
        name: "Sofia Ramírez",
        email: "sramirez@pfizer.com.mx",
        active: true,
        canAprove: true,
        notifications: true,
        phone: "-",
        pass: ""
      },
      {
        id: 3,
        name: "Diego García",
        email: "dgarcia@pfizer.com.mx",
        active: true,
        canAprove: false,
        notifications: true,
        phone: "-",
        pass: ""
      },
      {
        id: 1,
        name: "Santiago Gómez",
        email: "sgomez@pfizer.com.mx",        
        active: true,
        canAprove: false,
        notifications: false,
        phone: "-",
        pass: ""
      } 
    ];
    this.dataSource = new MatTableDataSource(this.providerMembersList);        
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  openDialogPassword(id_user : number) {
    this.matDialog.open(UsersEditPasswordComponent, {
      data: { 
        id_user: id_user 
      },
      panelClass: 'dialogs-sm',
    });
  }

  openDialogEdit(id_member : number) {
    this.matDialog.open(ProvidersEditMemberComponent, {
      data: { 
        id_member: id_member
      },
      panelClass: 'dialogs-md',
    }).afterClosed().subscribe(
      resp => {        
        this.loadProviderInfo();
      }
    );
  }
}
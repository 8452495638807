<div class="modal-header">
    <h4 class="modal-title">Artes SKU - <strong>{{ data.sku }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="d-flex justify-content-between align-items-center pb-4">
            <h6 class="mb-0">
                    <span>Sube un nuevo archivo de artes:</span>
            </h6>
            <mat-slide-toggle *ngIf="data.toggleEnfocus == 1" class="custom-toggle" (change)="onToggleChange($event)">Omitir Validación Enfocus</mat-slide-toggle>
        </div>


        <form [formGroup]="form">
            <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivos" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
            </ngx-file-drag-drop>
        </form>

        
        <div class="row borderMdl">
            <div class="col mb-0 mt-2">
                <h6 class="mt-1">
                    <span class="align-middle">Archivos subidos</span>
                </h6>
                <div class="table-responsive text-nowrap">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Archivo</th>
                                <th>Responsable</th>
                                <th>Fecha de Registro</th>
                                <th>Equipo</th>
                            </tr>
                        </thead>
                        <tbody class="table-border-bottom-0">
                            <tr *ngFor="let file of filesSku">
                                <td>{{ file.id }}</td>
                                <td><a href="{{file.archivo}}" target="_blank">{{file.nombre_archivo}}</a></td>
                                <td>{{ file.user }}</td>
                                <td>{{ file.created_at | date : "dd/MM/yyyy hh:mma": "-0000" }}</td>
                                <td><span class="badge {{file.id_rol==3?'bg-info':'bg-warning'}}">{{file.rol}}</span></td>
                            </tr> 
                            <tr *ngIf="filesSku.length == 0" >
                                <td colspan="5" class="text-center">
                                    No se han añadido archivos
                                </td>
                            </tr>
                        </tbody>
                            
                    </table>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" form="" type="submit" (click)="saveDistributionArt()">Registrar cambios <mat-icon>save</mat-icon></button>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/services/main.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-distribution-art-product',
  templateUrl: './distribution-art-product.component.html',
  styleUrls: ['./distribution-art-product.component.scss']
})
export class DistributionArtProductComponent implements OnInit {

  productos: { id: number, sku: string, nombre: string, descripcion: string, archivo: string }[] = [];
  productSelect: any = null;
  newObj = {};
  blob: string = '';
  public sanitizedSource!: SafeResourceUrl;
  public sanitizedSourceReport!: SafeResourceUrl;
  fileUrl: SafeResourceUrl = '';
  archivos: any = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data : any,
      private campaignService : CampaignsService,
      private dialogRef: MatDialogRef<DistributionArtProductComponent>,
      private main : MainService,
      public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getSkus();
  }

  getSkus(){
    this.main.ShowLoading();
    this.campaignService.getSkus().subscribe(
      (result:any) => {
        this.productos = result.data;
        if (this.productos.length > 0) {
          this.productSelect = this.productos.find(o => o.id === this.productos[0].id);

          this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
          this.sanitizedSourceReport = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.productSelect.archivo);
          this.main.HideLoading();
          
        }
      },
      (err:any) => {
          this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        
      }
    );
  }

  seleccionarOpcion(event: any) {
    this.main.ShowLoading();
    const idSeleccionado = Number(event.target.value);
    this.productSelect = this.productos.find(o => o.id === idSeleccionado);  
    this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.sanitizedSourceReport = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.productSelect.archivo);
    this.main.HideLoading();
  }

  guardarSKU(idStrategy: number){
    this.newObj = {
      idStrategy: idStrategy,
      idSku: this.productSelect.id
    }

    this.campaignService.saveSku(this.newObj).subscribe(
      (result:any) => {
        this.dialogRef.close();
        Swal.fire("Añadido", "", "success");        
      },
      (err:any) => {
        if(err.error.message != "Error al guardar sku")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al guardar los registros.'
          });
        }
      }
    );
  }

  getSourceURL(fileUrl: SafeResourceUrl): SafeResourceUrl {
    return fileUrl;
  }
}

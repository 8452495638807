import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-groups-create-from-list',
  templateUrl: './groups-create-from-list.component.html',
  styleUrls: ['./groups-create-from-list.component.scss']
})
export class GroupsCreateFromListComponent implements OnInit {

  ruleForm!: FormGroup;

  conditions = [{ type_id : 1, name : "="}, { type_id : 2, name : "<="}, { type_id : 3, name : ">="}, { type_id : 6, name : ">"}, { type_id : 4, name : "<"}];
  types = [{ type_id:1, name: "Texto", value:'text'}, { type_id:1, name: "Numero", value:'number'}];
  addGroup = ["","",""];
  groups = [
    {
      id:1,
      name: 'Tipo de sucursal',
    },
    {
      id:2,
      name: '# Cajas',
    },
    {
      id:3,
      name: '# Refrigeradores',
    },
    {
      id:4,
      name: '# Estantes',
    },
    {
      id:4,
      name: 'AhorroLab',
    },
    {
      id:4,
      name: 'Optica',
    }
  ]

   groups_simple = [
    { id: 5, name: "Estatus", group: null },
    { id: 6, name: "Ciudad", group: null },
    { id: 7, name: "#Cajas", group: 2 },
    { id: 8, name: "Póster de Caja", group: null },
    { id: 9, name: "Formato Actual", group: null },
    { id: 10, name: "Espacio para exhibidores", group: null },
    { id: 11, name: "Preciador de Caja", group: null },
    { id: 12, name: "Botadero Impulsivo", group: null },
    { id: 13, name: "Botadero Emotivo", group: null },
    { id: 14, name: "Mesa Promocional", group: null },
    { id: 15, name: "Copete Mesa Promocional", group: null },
    { id: 16, name: "8MTS X 60CMS (Banner Tradicional)", group: null },
    { id: 17, name: "8MTS X 50CMS (Banner Tradicional)", group: null },
    { id: 18, name: "8MTS X 40CMS (Banner Tradicional)", group: null },
    { id: 19, name: "6MTS X 60CMS (Banner Tradicional)", group: null },
    { id: 20, name: "6MTS X 50CMS (Banner Tradicional)", group: null },
    { id: 21, name: "6MTS X 40CMS (Banner Tradicional)", group: null },
    { id: 22, name: "4MTS X 60CMS (Banner Tradicional)", group: null },
    { id: 23, name: "4MTS X 50CMS (Banner Tradicional)", group: null },
    { id: 24, name: "4MTS X 40CMS (Banner Tradicional)", group: null },
    { id: 25, name: "8MTS X 80CMS (Banner Tradicional)", group: null },
    { id: 26, name: "3MTS X 60CMS (Banner Emotivo)", group: null },
    { id: 27, name: "4MTS x 40CMS (Banner Emotivo)", group: null },
    { id: 28, name: "4MTS X 50CMS (Banner Emotivo)", group: null },
    { id: 29, name: "4MTS X 60CMS (Banner Emotivo)", group: null },
    { id: 30, name: "4MTS X 75CMS (Banner Emotivo)", group: null },
    { id: 31, name: "6MTS X 40CMS (Banner Emotivo)", group: null },
    { id: 32, name: "6MTS X 50CMS (Banner Emotivo)", group: null },
    { id: 33, name: "6MTS X 60CMS (Banner Emotivo)", group: null },
    { id: 34, name: "7MTS X 60CMS (Banner Emotivo)", group: null },
    { id: 35, name: "8MTS X 40CMS (Banner Emotivo)", group: null },
    { id: 36, name: "8MTS X 50CMS (Banner Emotivo)", group: null },
    { id: 37, name: "8MTS X 60CMS (Banner Emotivo)", group: null },
    { id: 38, name: "Cantidad Total Banners", group: null },
    { id: 39, name: "Calle (Dirección)", group: null },
    { id: 40, name: "Número (Dirección)", group: null },
    { id: 41, name: "Código Postal (Dirección)", group: null },
    { id: 42, name: "Colonia (Dirección)", group: null },
    { id: 43, name: "Ciudad (Dirección)", group: null },
    { id: 44, name: "Estado (Dirección)", group: null },
    { id: 45, name: "Filial", group: null }
  ];


  group_atributes = [
    {
      "id": 29,
      "name": "Estatus",
      "type_id": 3,
      "select_data": "[\"ABIERTA\",\"PROXIMA APERTURA\",\"CERRADA\"]",
      "default_value": "",
      "created_at": "2024-01-15T12:28:43.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 1,
      "show": 0
    },
    {
      "id": 72,
      "name": "Ciudad",
      "type_id": 5,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-22T15:14:10.000Z",
      "deleted": 0,
      "active_since": "2024-01-21T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 2,
      "show": 0
    },
    {
      "id": 32,
      "name": "#Cajas",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T12:31:48.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 3,
      "show": 0
    },
    {
      "id": 33,
      "name": "Póster de Caja",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T12:32:43.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 4,
      "show": 0
    },
    {
      "id": 30,
      "name": "Formato Actual",
      "type_id": 3,
      "select_data": "[\"TRADICIONAL\",\"PLUS\"]",
      "default_value": "",
      "created_at": "2024-01-15T12:29:43.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 5,
      "show": 0
    },
    {
      "id": 31,
      "name": "Espacio para exhibidores",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T12:30:56.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 6,
      "show": 0
    },
    {
      "id": 34,
      "name": "Preciador de Caja",
      "type_id": 4,
      "select_data": "0-1",
      "default_value": "",
      "created_at": "2024-01-15T12:35:17.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 0,
      "autorization": 0,
      "order": 7,
      "show": 0
    },
    {
      "id": 35,
      "name": "Botadero Impulsivo",
      "type_id": 4,
      "select_data": "0-1",
      "default_value": "",
      "created_at": "2024-01-15T12:37:15.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 8,
      "show": 0
    },
    {
      "id": 36,
      "name": "Botadero Emotivo",
      "type_id": 4,
      "select_data": "0-1",
      "default_value": "",
      "created_at": "2024-01-15T12:38:31.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 9,
      "show": 0
    },
    {
      "id": 37,
      "name": "Mesa Promocional",
      "type_id": 4,
      "select_data": "0-2",
      "default_value": "",
      "created_at": "2024-01-15T12:44:27.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 10,
      "show": 0
    },
    {
      "id": 38,
      "name": "Copete Mesa Promocional",
      "type_id": 3,
      "select_data": "[\"COUCHE\",\"TROVICEL(RIGIDO)\"]",
      "default_value": "",
      "created_at": "2024-01-15T12:45:20.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 11,
      "show": 0
    },
    {
      "id": 40,
      "name": "8MTS X 60CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:03:48.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 12,
      "show": 0
    },
    {
      "id": 41,
      "name": "8MTS X 50CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:04:38.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 13,
      "show": 0
    },
    {
      "id": 42,
      "name": "8MTS X 40CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:05:11.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 14,
      "show": 0
    },
    {
      "id": 43,
      "name": "6MTS X 60CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:05:33.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 15,
      "show": 0
    },
    {
      "id": 44,
      "name": "6MTS X 50CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:05:52.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 16,
      "show": 0
    },
    {
      "id": 45,
      "name": "6MTS X 40CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:06:13.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 17,
      "show": 0
    },
    {
      "id": 46,
      "name": "4MTS X 60CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:06:48.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 18,
      "show": 0
    },
    {
      "id": 47,
      "name": "4MTS X 50CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:07:55.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 19,
      "show": 0
    },
    {
      "id": 49,
      "name": "4MTS X 40CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:08:45.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 20,
      "show": 0
    },
    {
      "id": 50,
      "name": "8MTS X 80CMS (Banner Tradicional)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:09:12.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 21,
      "show": 0
    },
    {
      "id": 51,
      "name": "3MTS X 60CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:17:52.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 22,
      "show": 0
    },
    {
      "id": 52,
      "name": "4MTS x 40CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:18:17.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 23,
      "show": 0
    },
    {
      "id": 53,
      "name": "4MTS X 50CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:19:45.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 24,
      "show": 0
    },
    {
      "id": 54,
      "name": "4MTS X 60CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:20:21.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 25,
      "show": 0
    },
    {
      "id": 55,
      "name": "4MTS X 75CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:20:50.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 26,
      "show": 0
    },
    {
      "id": 56,
      "name": "6MTS X 40CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:21:16.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 27,
      "show": 0
    },
    {
      "id": 57,
      "name": "6MTS X 50CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:21:30.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 28,
      "show": 0
    },
    {
      "id": 58,
      "name": "6MTS X 60CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:22:00.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 29,
      "show": 0
    },
    {
      "id": 59,
      "name": "7MTS X 60CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:22:23.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 30,
      "show": 0
    },
    {
      "id": 60,
      "name": "8MTS X 40CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:22:41.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 31,
      "show": 0
    },
    {
      "id": 61,
      "name": "8MTS X 50CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:22:57.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 32,
      "show": 0
    },
    {
      "id": 62,
      "name": "8MTS X 60CMS (Banner Emotivo)",
      "type_id": 6,
      "select_data": "",
      "default_value": "",
      "created_at": "2024-01-15T13:23:10.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 33,
      "show": 0
    },
    {
      "id": 63,
      "name": "Cantidad Total Banners",
      "type_id": 4,
      "select_data": "0-4",
      "default_value": "",
      "created_at": "2024-01-15T13:24:22.000Z",
      "deleted": 0,
      "active_since": "2024-01-14T18:00:00.000Z",
      "id_compania": 1,
      "active": 1,
      "editable": 1,
      "autorization": 0,
      "order": 34,
      "show": 0
    }
  ]

  constructor(
    private fb: FormBuilder
  ) { }

  get rules(): FormArray {
    return this.ruleForm.get('rules') as FormArray;
  }

  ngOnInit(): void {
    this.ruleForm = this.fb.group({
      rules: this.fb.array([])
    });
    for (let i = 0; i < 6; i++) {
      this.addRule(); 
    }
  }

  getRule() {
    return this.fb.group({
      selectCtrl: ['']
    });
  }

  getRulesArray(): AbstractControl[] {
    const rulesControl = this.ruleForm.get('rules');
    return rulesControl instanceof FormArray ? rulesControl.controls : [];
  }
  

  addRule() {
    const control = <FormArray>this.ruleForm.controls['rules'];
    control.push(this.getRule());
  }

  removeRule(i: number) {
    const control = <FormArray>this.ruleForm.controls['rules'];
    control.removeAt(i);
  }

  onSubmit() {
    console.log(this.ruleForm.value);
  }

  addMore(){
    this.addGroup.push("");
  }
}

<div class="modal-header">
    <h4 class="modal-title">Imágen: <strong>{{imagedata.name}}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="row mb-4">
             <div class="picsum-img-wrapper">
                 <img class="center img-view" [src]="imgroot + imagedata.image" alt="Imagen msg" />
             </div>     
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">

</div>
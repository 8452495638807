import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-providers-response',
  templateUrl: './projects-providers-response.component.html',
  styleUrls: ['./projects-providers-response.component.scss']
})
export class ProjectsProvidersResponseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  saveComment() {
    
  }

}

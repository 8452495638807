<div class="modal-header">
    <h4 class="modal-title">Material: <strong>{{material_info}}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="row mb-4" style="background-color: #0000001A;">
            <ngb-carousel > 
                <ng-template ngbSlide *ngFor="let imga of imageObject">
                    <div class="picsum-img-wrapper p-5">
                        <img class="center img-view" [src]="imga.image" alt="Material file" />
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">

</div>
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-layout-view',
  templateUrl: './layout-view.component.html',
  styleUrls: ['./layout-view.component.scss']
})
export class LayoutViewComponent implements OnInit {
  
    public sanitizedSource!: SafeResourceUrl;
    public sanitizedSourceReport!: SafeResourceUrl;
    fileUrl: SafeResourceUrl = '';
    
    archivos: any = [];
  
    constructor(
      public dialogRef: MatDialogRef<LayoutViewComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private main: MainService,
      public sanitizer: DomSanitizer
    ) { }
  
    ngOnInit(): void {
  
      this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
      this.sanitizedSourceReport = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
  
      this.main.ShowLoading();
  
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data);
  
      this.main.HideLoading();
  
    }
  
    getSourceURL(fileUrl: SafeResourceUrl): SafeResourceUrl {
      return fileUrl;
    }

}

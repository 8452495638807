import { Injectable } from '@angular/core';
import { UserModel, UserSaveModel } from '../models/user.model';
import { MainService } from './main.service';
import jwt_decode from 'jwt-decode';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private main: MainService
  ) { }

  getUser() : UserModel {
    return jwt_decode(localStorage.getItem('token')!);
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean{
      return this.getUser().id_rol == environment.role_admin_id || (buho==null&&(this.getUser().id_rol == environment.role_buho)) || (client==null&&(this.getUser().id_rol == environment.role_client)) || (((buho?(this.getUser().id_rol == environment.role_buho):false) || (client?(this.getUser().id_rol == environment.role_client):false)) && (this.getUser().permissions.includes(key)));
  }

  getUserList() {
    return this.main.postRequest({}, "/users/show").pipe(
      map((resp: any) => {
        let userList: Array<UserModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
              userList.push(e);
          });
        }     
        return userList;
      })
    );
  }

  deleteUser(id: string) {
    return this.main.deleteRequest(`/users/delete/${ id }`); 
  }

  changeStatusUser(id: number, active: number, idServ: number) {
    var userModel = new UserSaveModel();
    userModel.id_user = id;
    userModel.active = active;
    userModel.id_serv = idServ;
    return this.main.postRequest(userModel, "/users/edit/");
  }

  saveUser(userModel: UserSaveModel) {    
    const { passConfirm, id_user, active, ...rest } = userModel;
    return this.main.postRequest(rest, "/users/create");
  }

  editUser(userModel: UserSaveModel, id_user: number) {
    userModel.id_user = id_user;
    const { passConfirm, email, active, ...rest } = userModel;
    return this.main.postRequest(rest, "/users/edit");
  }

  getNotifUserList(id_user: number) {
    return this.main.getRequest("/users/notifications/user/" + id_user);
  }

  getPermissionList(id_rol: number) {
    return this.main.getRequest("/users/permissions/" + id_rol);
  }

  getNotifList(id_rol: number) {
    return this.main.getRequest("/users/notifications/" + id_rol);
  }

  getCompanies() {
    return this.main.getRequest("/users/companies/show");
  }

  getUserModules(){
      return this.getUser().modules;
  }

  getZones(id_comp : number) {
    return this.main.getRequest("/users/zones/show/" + id_comp);
  }

  getZonesByUser() {
    return this.main.getRequest("/users/zones/user");
  }

  getModules(id_comp : number) {
    let pl = { id_compania : id_comp }
    return this.main.postRequest(pl,"/users/modules/show");
  }


  isBuhoUsr(): boolean{
    return  this.getUser().id_rol == environment.role_buho ||  this.getUser().id_rol == environment.role_admin_id;
  }
  isClientUsr(): boolean{
    return  this.getUser().id_rol == environment.role_client;
  }

  isProviderMember(){
    return this.getUser().id_provider_member != null;
}
}

<div style="z-index: 1300; position: relative;" *ngIf="loading"> 
    <div class="loading-backdrop" id="full-backdrop">
        <div class="loading-comp">
          <mat-progress-spinner [diameter]="140" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</div>
<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Registrar Responsable</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="alert alert-warning">
       <h5> <mat-icon class="align-middle">warning</mat-icon>Importante</h5>
       Una vez enviada la encuesta no se permitirá hacer modificaciones 
    </div>
  
    <div class="px-3 mt-3" id="sendEncuestaForm">
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre del responsble</mat-label>
                    <input matInput [type]="'text'" [(ngModel)]="name" name="name" maxlength="50" required>
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Puesto</mat-label>
                    <input matInput [type]="'text'" [(ngModel)]="puesto" name="puesto" maxlength="50" required>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" type="submit" [disabled]=" name == '' || puesto == ''" (click)="enviarEncuesta()">Enviar </button>
</div>
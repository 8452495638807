import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-survey-material-edit',
  templateUrl: './survey-material-edit.component.html',
  styleUrls: ['./survey-material-edit.component.scss']
})
export class SurveyMaterialEditComponent implements OnInit {
  cant!: number;
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main: MainService,
    private surveyS: SurveysService,
    private dialogRef: MatDialogRef<SurveyMaterialEditComponent>,
  ) {
    this.material = this.data.material;
    this.cant = this.material.cantidad_pza;
   }
  material: any = {};
  ngOnInit(): void {
  }

  cancelProjectMaterial(){
    this.material.cantidad_pza = this.cant;
  }

  editProjectMaterial(){
    if(this.cant != this.material.cantidad_pza){
      this.main.ShowLoading();
      this.surveyS.uploadMaterialsCampana(this.material).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Editado',
              text: 'Material editado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }
    else {
    }
  }
}

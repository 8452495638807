<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Editar material</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Ingresa la siguiente información para modificar el material:</h6>
    <form #f="ngForm">
        <div class="row">
            <div class="col mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{material.cantidad_pza>1?(material.name + " (1 kit = " + material.cantidad_pza + " pz)"):material.name}}</mat-label>
                    <input matInput name="name" disabled>
                </mat-form-field>
            </div>
            <div class="col mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Número de piezas</mat-label>
                    <input matInput name="cantidad_pza" [(ngModel)]="material.cantidad_pza" required type="number">
                </mat-form-field>
            </div>
        </div>

     </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close (click)="cancelProjectMaterial()">Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="editProjectMaterial()">Guardar <mat-icon>save</mat-icon></button>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { PDFDocument } from 'pdf-lib';
import { SkuService } from 'src/app/services/sku.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { SkuImageComponent } from '../../../shared/dialogs/sku-image/sku-image.component';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-sku-add',
  templateUrl: './sku-add.component.html',
  styleUrls: ['./sku-add.component.scss']
})
export class SkuAddComponent implements OnInit {

  pdfWidth: number | null = null;
  pdfHeight: number | null = null;
  newObj = {};
  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  bodyForm : FormData = new FormData(); 

  constructor(
    public dialogRef: MatDialogRef<SkuAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private skuService : SkuService,
    private fb : FormBuilder,
    private matDialog: MatDialog,
    private main: MainService,
  ) { }

  ngOnInit(): void {
    this.loadForm();
  }

  preloadFormData(data: any){
    this.bodyForm.append('sku', data.sku);
    this.bodyForm.append('nombre', data.nombre ?? null);
    this.bodyForm.append('descripcion',data.descripcion ?? null);
    this.bodyForm.append('medida_x',data.medida_x ?? 0);
    this.bodyForm.append('medida_y',data.medida_y ?? 0);
    this.bodyForm.append('id_sku',data.id);
    this.bodyForm.append('archivo',data.archivo ?? null);
    this.bodyForm.append('nombre_archivo',data.nombre_archivo ?? null);
    this.bodyForm.append('accesorio1',data.accesorio1 ?? null);
    this.bodyForm.append('mult_accesorio1',data.mult_accesorio1 ?? null);
    this.bodyForm.append('accesorio2',data.accesorio2 ?? null);
    this.bodyForm.append('mult_accesorio2',data.mult_accesorio2 ?? null);
  }

  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }

  async onFileSelected(event: any) {

    const file = event[0];

    if (!file) return;

    const reader = new FileReader();
    reader.onload = async () => {
      const arrayBuffer = reader.result as ArrayBuffer;
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      const firstPage = pdfDoc.getPage(0);
      
      const { width, height } = firstPage.getSize();
      this.data.medida_x = width;
      this.data.medida_y = height;
    };
    
    reader.readAsArrayBuffer(file);
  }

  guardarSKU(data: any){

    if(data?.sku){
      this.main.ShowLoading();
      this.preloadFormData(data);
      if(this.form.value.blob != null && this.form.value.blob?.length!=0){
        this.bodyForm.append("blob", this.form.value.blob[0] as File);
      }

      this.skuService.saveSku(this.bodyForm).subscribe(
        (result:any) => {
          this.dialogRef.close();
          Swal.fire("Guardado", "", "success"); 
          this.main.HideLoading();       
        },
        (err:any) => {
          this.main.HideLoading();       
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Ocurrió un error al guardar los registros.'
          });
        }
        
      );
    }
    else{
      Swal.fire({
        icon: 'warning',
        title: 'Faltan datos requeridos',
        text: 'Por favor ingresa un SKU para continuar'
      });
    }

    
  }

  verSku(element: any) {
    const dialogRef = this.matDialog.open(SkuImageComponent, {
      panelClass: 'dialogs-lg', 
      data : element
    })
  }

}

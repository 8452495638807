import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FileValidators } from 'ngx-file-drag-drop';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-groups-create-from-excel',
  templateUrl: './groups-create-from-excel.component.html',
  styleUrls: ['./groups-create-from-excel.component.scss']
})
export class GroupsCreateFromExcelComponent implements OnInit {

  groupForm!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);


  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(){
    console.log('test')
  }

  onValueChange(files : any)
  { 
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!(item.name.endsWith('.zip') || item.name.endsWith('.xls') || item.name.endsWith('.xlsx')))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato .ZIP o .XLSX'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
    }
  }

}

export class getCampaignsModel {
    active!: number;
}

export class newCampaignModel {
    name!: string;
    deadline!: string;
    id_compania!: number;
}

export class CampaignModel {
    id!:number;
    name!:string;
    deadline!:string;
    locked!:number;
    active!:number;
    irr!: number;
    notif!: number;
    alert!: number;
    folder!: string;
    id_compania!: number;
    date!:string;
    status!: number;
}

export class editCampaignModel{
    id_camp!: number;
    name! : string;
    deadline!: string;
    locked! : number;
    active! : number;
    id_compania!: number;
}

export class DashboardCampaigns {
    progress : Array<CampaignModel> = [];
    finished : Array<CampaignModel> = [];
    inactive : Array<CampaignModel> = [];
    pastyears: any = {};
    all: Array<CampaignModel> = [];
}

export class ClawCampaignModel {
    id!: number;
    name!: string;
    created_at!: string;
    file!: string;
    file_name!: string;
    active!: number;
    id_company!: number;
}

export class CampaignTrackingModel {
    guia!: string;
    fecha_entrega!: string;
    recibio!: string;
    estatus!: string;
    last_update!: string;
    zona!: string;
    sucursal!: string;
    direccion!: string;
    coords!: string;
}

export class PendingBoxes {
    zona!:         string;
    sucursal!:     string;
    paqueteria!:   string;
    guia!:         string;
    estatus!:      string;
    lastUpdate!:  string;
}

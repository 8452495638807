<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Edita usuario</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <div class="alert alert-warning" *ngIf="isUserLogged">
        Para ver reflejados los cambios cierra la sesión y vuelve a ingresar al sistema.
    </div>
    <h6>Edita la información del usuario: {{data.userSel.name}}</h6>
    <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <!--<mat-form-field class="w-100" appearance="outline">
                    <mat-label>Permisos</mat-label>
                    <mat-select formControlName="permissions" multiple>
                        <mat-option *ngFor="let p of permissionList" [value]="p.id">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>-->
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Permisos</mat-label>
                    <mat-select formControlName="permissions" multiple>
                        <!--<mat-option *ngFor="let p of permissionList" [value]="p.id">{{p.name}}</mat-option>-->
                            <ng-container  *ngFor="let m of modulesList" >
                            <mat-optgroup *ngIf=" getPermbyModule(m.id).length != 0" [label]="'---'+ m.nombre + '---'">
                                <mat-option  *ngFor="let p of getPermbyModule(m.id)"  [value]="p.id">{{p.name}}</mat-option>                              
                            </mat-optgroup>
                          </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Activar Notificaciones</mat-label>
                    <mat-select formControlName="notifications" multiple>
                        <mat-option *ngFor="let n of notifList" [value]="n.id">{{n.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Zonas</mat-label>
                    <mat-select formControlName="zones" multiple>
                        <mat-option *ngFor="let zone of zoneslist" [value]="zone.id">{{zone.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Modulos</mat-label>
                    <mat-select formControlName="modules" [disabled]= "formUser.value.id_rol != 3" multiple>
                        <mat-option *ngFor="let p of modulesList" [value]="p.id">{{p.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <input formControlName="companies" type="hidden" name="">
    </form>
</div>
<div class="modal-footer">
    <button [hidden]="!hasPermissions('upd_pass',true,null)" mat-stroked-button color="primary" class="me-3 mb-1" type="button" (click)="openDialogPassword()">
        Cambiar contraseña <mat-icon>password</mat-icon>
    </button>
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</div>
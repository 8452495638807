import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-sku-image',
  templateUrl: './sku-image.component.html',
  styleUrls: ['./sku-image.component.scss']
})
export class SkuImageComponent implements OnInit {

  blob: any;

  public sanitizedSource!: SafeResourceUrl;
  public sanitizedSourceReport!: SafeResourceUrl;
  fileUrl: SafeResourceUrl = '';
  
  archivos: any = [];

  constructor(
    public dialogRef: MatDialogRef<SkuImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private campaignService : CampaignsService,
    private main: MainService,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.sanitizedSourceReport = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);

    this.main.ShowLoading();

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data);

    this.main.HideLoading();

  }

  getSourceURL(fileUrl: SafeResourceUrl): SafeResourceUrl {
    return fileUrl;
  }

}

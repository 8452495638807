import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-survey-msg-img-viewer',
  templateUrl: './survey-msg-img-viewer.component.html',
  styleUrls: ['./survey-msg-img-viewer.component.scss']
})
export class SurveyImageMsgViewerComponent implements OnInit {


  private apiURL = environment.API_URL;
 
  imagedata : any;
  //imgroot = "https://lh3.google.com/u/0/d/";
  imgroot = this.apiURL + "/surveys/image/";
  isClientUsr : boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
  ) { 
    this.imagedata = this.data.imagedata;
    this.isClientUsr = this.campaignService.isClientUsr();
  }
 
  ngOnInit(): void {
    
  }



}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { StoreModel } from 'src/app/models/store.model';
import { StoreService } from 'src/app/services/store.service';
import { Router } from '@angular/router';
import { StoresAttributesEditComponent } from '../../../shared/dialogs/stores-attributes-edit/stores-attributes-edit.component';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-stores-attributes-modal',
  templateUrl: './stores-attributes-modal.component.html',
  styleUrls: ['./stores-attributes-modal.component.scss']
})
export class StoresAttributesModalComponent implements OnInit {

  displayedCols : string[] = [];
  attributes = [];
  sucursales = [];
  viewData : any = [];
  relations = [];


 //@ViewChild("pagi") paginator!: MatPaginator;
 //@ViewChild("sorting") sort!: MatSort;
 @ViewChild(MatPaginator) paginator!: MatPaginator;
 @ViewChild(MatSort) sort!: MatSort;


  dataSource!: MatTableDataSource<any>

  constructor(
    private storeS: StoreService,
    private matDialog: MatDialog,
    private _titleService: Title,
    private _router: Router,
    private main: MainService,
    @Inject(MAT_DIALOG_DATA) public data : any,
  ) { }
  tempArray : any[] = [];
  ngOnInit(): void {
    setTimeout(() =>{              
      this.loadSucursales();
    },300);
    //this.loadSucursales();
   
  }

  loadSucursales(){
    this.attributes = this.storeS.attributes;
    //this.viewData = this.storeS.viewData;
    this.displayedCols = this.storeS.allAtributes;
    this.relations = this.storeS.relations;

    this.dataSource = new MatTableDataSource(this.storeS.viewData);    
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.main.HideLoading();
  }

  isLoading = true;

  openDialogAttributeEditList(row : any) {
    this.matDialog.open(StoresAttributesEditComponent, {panelClass: 'dialogs-lg', data : { data : row, attributes : this.attributes, relation : this.relations.filter((x:any)=> row.id == x.sucursal_id) }}).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
      }
    );
  }

  retireActionsCol(){
    return this.displayedCols.filter(item => item !== 'actions')
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

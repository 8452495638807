import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-art-file',
  templateUrl: './distribution-art-file.component.html',
  styleUrls: ['./distribution-art-file.component.scss']
})
export class DistributionArtFileComponent implements OnInit {

  bodyForm : FormData = new FormData(); 

  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  strategy : any;
  progress : number = 0;
  filesSku: { nombre_archivo: string, archivo: string, id: number, user: string, created_at: string, id_rol: number, rol: string }[] = [];
  isToggleChecked: boolean = false;

  maxIntentos = 4; // 1 minuto (4 intentos de 15 segundos)
  intentos = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private fb : FormBuilder,
    private dialogRef: MatDialogRef<DistributionArtFileComponent>,
  ) { 
    this.strategy = this.data.strategy;
   }

  ngOnInit(): void {
    //this.preloadFormData();
    this.loadForm();
    this.loadData();
  }

  preloadFormData(){
    this.bodyForm.append('folder_str', this.strategy.folder as string);
    this.bodyForm.append('id_str', this.strategy.id);
    this.bodyForm.append('id_sku', this.data.idSku);
    this.bodyForm.append('medida_x', this.data.medida_x);
    this.bodyForm.append('medida_y', this.data.medida_y);
    this.bodyForm.append('validaEnfocus', this.isToggleChecked == true ? '1' : '0'); 
  }

  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }

  onToggleChange(event: any) {
    this.isToggleChecked = event.checked;
  }

  saveDistributionArt() {
    Swal.fire({
      title: '¿Seguro que quiere subir este archivo?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed) {
        if(this.form.value.blob != null && this.form.value.blob?.length!=0){
          this.preloadFormData()
          this.bodyForm.append("blob", this.form.value.blob[0] as File);
          Swal.fire({
            icon: 'info',
            title: 'Subiendo archivo...',
            text:'Esto puede tardar algunos minutos, favor de no cerrar o recargar la pagina.',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          this.campaignService.uploadArtFile(this.bodyForm).subscribe(
            (result:any)=>{
              if(result.message == 'Success'){
                if(this.isToggleChecked == false){
                  this.obtenerFilesFtp(result.nombre_arte,result.id_archivo);
                }
                else{
                  Swal.fire({
                    icon: 'success',
                    title: 'Archivo subido',
                    text: 'Archivo subido exitosamente'          
                  }).then(() => {      
                    this.loadData();
                    this.form.reset();
                    this.dialogRef.close();            
                  });
                }
                console.log("upload data", result);
              }
              else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
                });
              }
            },
            (err:any)=>{
              this.main.HideLoading();
              Swal.fire({
                icon: 'error',
                title: 'Error al guardar',
                text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
              });
            }
          )
          
        }else{
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'El documento no ha sido adjuntado'
            });
          }
      
      }
    });
  }

  onValueChange(files : any)
  {     
    for (const item of files) {
      if(!(item.name.endsWith('.pdf')))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato .PDF'
        });
    
        if (index >= 0) {
          files.splice(index, 1); //Mantiene solo los archivos pdf en files
        }
      }
    }
  }

  loadData(){
      this.campaignService.getFilesSkuStrategy(this.strategy.id,this.data.idSku).subscribe(
        (result:any) => {
          console.log(result.data)
          this.filesSku = result.data
        },
        (err:any) => {
          if(err.error.message != "No data were found in the request")
          {
            Swal.fire({
              icon: 'error',
              title: 'Error al obtener registros',
              text: 'Ocurrió un error al obtener los registros.'
            });
          }
        }
      );
    }

    obtenerFilesFtp(nombre_arte: string, id_archivo: number){

      const intervalo = setInterval(() => {
        this.intentos++;
        this.campaignService.getFilesFtp(nombre_arte,id_archivo).subscribe(
          (result: any) => {

            if(result.noEncontrado != 4){

              console.log(result.message);
              clearInterval(intervalo); // Detiene la verificación cuando se obtenga algun archivo

              if(result.noEncontrado < 2){
                Swal.fire({
                  icon: 'success',
                  title: 'Archivo subido',
                  text: 'Archivo subido exitosamente'          
                }).then(() => {      
                  this.loadData();
                  this.form.reset();
                  this.dialogRef.close();            
                });
              }
              else{
                Swal.fire({
                  icon: 'warning',
                  title: 'Archivo subido con errores',
                  text: 'El archivo se subió, pero tiene errores, favor de validar'          
                }).then(() => {      
                  this.loadData();
                  this.form.reset();
                  this.dialogRef.close();            
                });
              }
              
  
              
            }
            else{
              console.log("Archivo no encontrado, reintentando en 15 segundos...");
              // Si ya alcanzó el límite de intentos, detenemos la ejecución
              if (this.intentos >= this.maxIntentos) {
                clearInterval(intervalo);
                Swal.fire({
                  icon: 'warning',
                  title: 'Atención',
                  text: 'No se encontraron los archivos de respuesta después de 1 minuto. Inténtelo más tarde.'
                });
              }
            }
            
          },
          (err: any) => {
              clearInterval(intervalo);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ocurrió un error al obtener los archivos.'
              });
          }
        );
      }, 15000);

    }

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProjectMedia } from 'src/app/models/project.model';


@Component({
  selector: 'app-new-media',
  templateUrl: './new-media.component.html',
  styleUrls: ['./new-media.component.scss']
})
export class NewMediaComponent implements OnInit {
  data!: ProjectMedia;

  constructor(public dref: MatDialogRef<NewMediaComponent>) { }

  ngOnInit(): void {
    this.data = {
      allowEmptyBranches: false, allowMultipleItems: false,
      name: '', popMaterials: [], segmentable: false, formato: ""
    }
  }

  atributosSucursales = ['DANGLER', 'MATERIAL', 'ATRIBUTO X'];
  comparadores = ['Igual a', 'Mayor o igual que', 'Menor o igual que'];
  
  reglasAlcance: any[] = [];
  alcance: string = '';
  selectedFormato = 0;

  /* void close(0):
     Closes dialog. */
  close(): void { this.dref.close(); }

  /* boolean invalidInput(0):
     Validates all the field's data to enable the "Submit" button.
     > True if all fields valid. Otherwise, false. */
  invalidInput(): boolean {
    return (this.data.name === '');
  }

  /* void register(0):
     Sends set data to the caller component and closes dialog. */
  register(): void { this.dref.close(this.data); }


  agregarRegla() {
    this.reglasAlcance.push({
      atributo: null,
      comparador: null,
      valor: null
    });
  }

  eliminarRegla(index: number) {
    this.reglasAlcance.splice(index, 1);
  }
}

<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <span>Tickets</span> <span *ngIf="datatickets.length > 0"> - {{ campanaName }}</span>
            <br>
            <!--<h5>
                <mat-icon class="text-info align-middle">filter_list</mat-icon> Filtro
            </h5>-->
            <div class="filterContainer">
                <mat-accordion>
                    <mat-expansion-panel [expanded]="expanded_filters">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon class="text-info align-middle">filter_list</mat-icon> Filtro
                        </mat-panel-title>
                        <mat-panel-description>
                          Filtrado de Tickets por campaña
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div>
                        <form>
                            <div class="row">

                             <div class="col-md-4 col-sm-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Campaña</mat-label>
                                    <mat-select name="camp" [(ngModel)]="selectedCamp" required>
                                        <!--<mat-option [value]="0">Todas</mat-option>-->
                                        <!--<mat-option *ngFor="let camp of campaignsData.claw;"  [value]="camp.id + '-c'" value="option">{{ camp.name }}</mat-option>
                                        <mat-option *ngFor="let camp of campaignsData.nest;"  [value]="camp.id" value="option">{{ camp.name }}</mat-option>-->
                                        <mat-option *ngFor="let camp of allCampaigns;"  [value]="camp.id" value="option">{{ camp.name }}</mat-option>
                                    </mat-select>
                                    <mat-error>Requerido</mat-error>
                                  </mat-form-field>
                             </div>

                             <div class="col-md-2 col-sm-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Zonas</mat-label>
                                    <mat-select  name="zones" [(ngModel)]="selectedzones" multiple>
                                        <mat-option *ngFor="let zone of zones;"  [value]="zone.id" value="option">{{ zone.nombre }}</mat-option>
                                    </mat-select>
                                    <mat-error>Requerido</mat-error>
                                  </mat-form-field>
                              </div>


                              <!--<div class="col-md-4 col-sm-12">
                                <mat-form-field class="w-100" appearance="outline">
                                    <mat-label>Estatus Ticket</mat-label>
                                    <mat-select name="strack" [(ngModel)]="selectedStsTrack" >
                                      <mat-option [value]="null">-- Ninguno --</mat-option>
                                      <mat-option *ngFor="let sts of statusTicket;" [value]="sts.id"> <span [ngClass]="sts.class">{{ sts.nombre }}</span></mat-option>
                                    </mat-select>
                                    <mat-error>Requerido</mat-error>
                                  </mat-form-field>
                              </div>-->
                              <div class="col-md-3 col-sm-12">
                                <button (click)="search()" mat-flat-button class="bg-success text-white" style="top: 12px; margin-bottom: 25px;" form="userForm" type="submit">Buscar <mat-icon>search</mat-icon></button>
                              </div>
                            </div>
                          </form>
                      </div>

                    </mat-expansion-panel>
                  </mat-accordion>
            </div>

        </h4>

        <!-- Card on time strategies -->
        <mat-tab-group>
            <mat-tab label="Listado" *ngIf="datatickets.length > 0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-4">

                            <div class="card-body">
                                <div class="overflow-auto w-200">
                                    <mat-form-field>
                                        <mat-label>
                                            <mat-icon>search</mat-icon> Buscar</mat-label>
                                        <input matInput (keyup)="applyFilter($event)" placeholder="Sobre burbuja" #input>
                                    </mat-form-field>
                                </div>
                                    <div class="mat-elevation-z0 table-responsive text-nowrap">
                                        <table  mat-table [dataSource]="dataSource" matSort class="w-100">

                                            <!--<ng-container matColumnDef="ticket">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código Ticket </th>
                                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                            </ng-container>-->

                                            <ng-container matColumnDef="producto">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                                                <td mat-cell *matCellDef="let row"> {{row.producto}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="nombre_campana">
                                                <th [hidden]="campanaName != ''" mat-header-cell *matHeaderCellDef mat-sort-header> Campana </th>
                                                <td [hidden]="campanaName != ''" matTooltip="{{ row.nombre_campana }}" class="truncate-table" mat-cell *matCellDef="let row"> {{row.nombre_campana}} </td>
                                            </ng-container>

                                            <ng-container  matColumnDef="nombre_sucursal" >
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                                <td  matTooltip="{{ row.nombre_sucursal }}" class="truncate-table" mat mat-cell *matCellDef="let row"> {{row.nombre_sucursal}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="nombre_zona">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                                                <td mat-cell *matCellDef="let row"> {{row.nombre_zona}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="comentario">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Comentario </th>
                                                <td mat-cell *matCellDef="let row"> {{row.comentario}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="tipo">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                                                <td mat-cell *matCellDef="let row">
                                                    <span *ngIf="row.tipo" [ngClass]="getTicketsTypeDat(row.tipo).class">{{getTicketsTypeDat(row.tipo).nombre}}</span>
                                                </td>
                                            </ng-container>

                                          <ng-container matColumnDef="creacion_ticket">
                                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Creación </th>
                                              <td mat-cell *matCellDef="let row"> {{row.creacion_ticket | date : "dd/MM/yyyy" }} </td>
                                          </ng-container>

                                            <ng-container matColumnDef="status">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                                <td mat-cell *matCellDef="let row">

                                                    <ng-container *ngIf="row.tipo!=null && row.status!=4">
                                                        <span [matMenuTriggerFor]="menu3" [ngClass]="getStatusTicketsDat(row.status).class">{{getStatusTicketsDat(row.status).nombre}}</span>
                                                        <mat-menu #menu3="matMenu" >
                                                            <ng-container *ngIf="!isClaw">
                                                              <button *ngFor="let stat of statusTicketExclude()" (click)="updateTicket(row.id, stat.id, row.producto, row.camp_id, row.suc_id, row.comp_id)"  mat-menu-item ><span [ngClass]="stat.class">{{ stat.nombre }}</span></button>
                                                            </ng-container>
                                                            <ng-container *ngIf="isClaw">
                                                              <button *ngFor="let stat of statusTicketExclude()" (click)="updateTicket(row.id_encuesta_camp, stat.id, row.producto, row.camp_id, row.id, row.comp_id)"  mat-menu-item ><span [ngClass]="stat.class">{{ stat.nombre }}</span></button>
                                                            </ng-container>
                                                        </mat-menu>
                                                    </ng-container>
                                                    <ng-container *ngIf="row.tipo==null">
                                                        <span [ngClass]="'badge badge-info'">Pendiente Crear</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="row.status==4">
                                                        <span [ngClass]="'badge badge-success'">Resuelto (Anulación)</span>
                                                    </ng-container>

                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Acciones">
                                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                                <td mat-cell *matCellDef="let row" class="text-center">
                                                    <button *ngIf="row.tipo!=null" (click)="openDialogLogList(row)" mat-icon-button matTooltip="Ver detalle" [matTooltipPosition]="'above'" color="accent">
                                                        <mat-icon>remove_red_eye</mat-icon>
                                                    </button>
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                                        </table>
                                        <div>
                                          <td class="mat-cell"  *ngIf="datatickets.length == 0 " colspan="6">No existe información actualmente o no se ha realizado ninguna búsqueda</td>
                                        </div>
                                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                 <!--<div *ngIf="loaded">
                  <div class="row col-sm-10">
                    <span class="text-center " colspan="6">No hay data para mostrar</span>
                  </div>
                 </div>-->
                </div>
            </mat-tab>
            <mat-tab label="Métricas" *ngIf="metrics">

                <div class="row" *ngIf="loaded">

                    <div class="col-md-12">
                      <div class="card mb-4">
                        <br>
                            <h3 class="text-center">Promedios</h3>

                        <div class="card-body">

                          <div class="row">
                            <div class="col-md-3 col-6 resume-card">
                                <div class="">
                                  <h6 class="align-middle text-center fs-6">
                                    <mat-icon class="text-success me-2 align-middle" >fact_check</mat-icon
                                    >
                                    Respuesta Encuesta
                                  </h6>
                                  <h4 class="mt-2 text-center">
                                   <ng-container *ngIf="promedioTiempoRespues() == '-'">-</ng-container>
                                   <ng-container *ngIf="promedioTiempoRespues() != '-'">{{promedioTiempoRespues() | number:'1.1-1'}} días</ng-container>
                                  </h4>
                                </div>
                              </div>
                              <div class="col-md-3 col-6 resume-card">
                                <div class="">
                                  <h6 class="align-middle text-center fs-6">
                                    <mat-icon class="text-warning me-2 align-middle" >supervisor_account</mat-icon
                                    >
                                    Atencion Gerente
                                  </h6>
                                  <h4 class="mt-2 text-center">
                                    <ng-container *ngIf="promedioCreacionTickets() == '-'">-</ng-container>
                                    <ng-container *ngIf="promedioCreacionTickets() != '-'">{{promedioCreacionTickets()| number:'1.1-1'}} días</ng-container>

                                  </h4>
                                </div>
                              </div>

                              <div class="col-md-3 col-6 resume-card">
                                <div class="">
                                  <h6 class="align-middle text-center fs-6">
                                    <mat-icon class="text-info me-2 align-middle" >support_agent</mat-icon
                                    >
                                    Atención Buho
                                  </h6>
                                  <h4 class="mt-2 text-center">
                                    <ng-container *ngIf="promedioAtencionTickets() == '-'">-</ng-container>
                                    <ng-container *ngIf="promedioAtencionTickets() != '-'">{{promedioAtencionTickets()| number:'1.1-1'}} días</ng-container>

                                  </h4>
                                </div>
                              </div>
                              <div class="col-md-3 col-6">
                                <div class="">
                                  <h6 class="align-middle text-center fs-6">
                                    <mat-icon class="font-purple me-2 align-middle" >done_all</mat-icon
                                    >
                                    Resolución Buho
                                  </h6>
                                  <h4 class="mt-2 text-center">
                                    <ng-container *ngIf="promedioResTickets() == '-'">-</ng-container>
                                    <ng-container *ngIf="promedioResTickets() != '-'"> {{promedioResTickets()| number:'1.1-1'}} días</ng-container>

                                  </h4>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row" >
                      <!--tipos-->
                      <div class="col-md-12">
                        <div class="card mb-4">
                            <h5 class="card-header text-center">
                                Tipos de Alertas
                            </h5>
                          <div class="card-body">

                            <div class="row">
                              <div class="col-md-2 col-6 resume-card-mobile">
                                <div class="">
                                  <h6 class="align-middle text-center fs-6">
                                    <span class="badge badge-info">Faltante</span>
                                  </h6>
                                  <h4 class="mt-2 text-center">
                                      {{totalReports?.total_faltante || '-' }}
                                  </h4>
                                </div>
                              </div>
                              <div class="col-md-2 col-6 resume-card-mobile">
                                <div class="">
                                  <h6 class="align-middle text-center fs-6">
                                    <span class="badge badge-info">Dañado</span>
                                  </h6>
                                  <h4 class="mt-2 text-center">
                                      {{totalReports?.total_material_danado || '-'}}
                                  </h4>
                                </div>
                              </div>

                              <div class="col-md-2 col-6 resume-card-mobile">
                                  <div class="">
                                    <h6 class="align-middle text-center fs-6">
                                      <span class="badge badge-info">Kit incompleto</span>
                                    </h6>
                                    <h4 class="mt-2 text-center">
                                      {{totalReports?.total_kit_incompleto || '-'}}
                                    </h4>
                                  </div>
                                </div>
                                <div class="col-md-2 col-6 resume-card-mobile">
                                  <div class="">
                                    <h6 class="align-middle text-center fs-6">
                                      <span class="badge badge-info">Sol. Adicional</span>
                                    </h6>
                                    <h4 class="mt-2 text-center">
                                      {{totalReports?.total_solicitud_adicional || '-'}}
                                    </h4>
                                  </div>
                                </div>
                                <div class="col-md-2 col-6 resume-card-mobile">
                                  <div class="">
                                    <h6 class="align-middle text-center fs-6">
                                      <span class="badge badge-info">Otro</span>
                                    </h6>
                                    <h4 class="mt-2 text-center">
                                      {{totalReports?.total_otro || '-'}}
                                    </h4>
                                  </div>
                                </div>
                                <div class="col-md-2 col-6">
                                  <div class="">
                                    <h6 class="align-middle text-center fs-6">
                                      <span class="badge badge-info">Anulado</span>
                                    </h6>
                                    <h4 class="mt-2 text-center">
                                      {{totalReports?.total_anulacion || '-'}}
                                    </h4>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--status-->
                      <div class="col-md-12">
                         <div class="card mb-4">
                           <div class="card-body">
                            <h5 class="card-header text-center">
                                Tipos de estatus
                            </h5>

                             <div class="row">
                               <div class="col-md-3 col-6 resume-card-mobile">
                                 <div class="">
                                   <h6 class="align-middle text-center fs-6">
                                     <span class="badge badge-inverse">Pendientes Rev.</span>
                                   </h6>
                                   <h4 class="mt-2 text-center">
                                       {{totalReports?.total_st_pend_revision || '-'}}
                                   </h4>
                                 </div>
                               </div>
                               <div class="col-md-3 col-6 resume-card-mobile">
                                 <div class="">
                                   <h6 class="align-middle text-center fs-6">
                                     <span class="badge badge-sp1">En revisión</span>
                                   </h6>
                                   <h4 class="mt-2 text-center">
                                      {{totalReports?.total_st_revision || '-'}}
                                   </h4>
                                 </div>
                               </div>

                               <div class="col-md-3 col-6 resume-card-mobile">
                                   <div class="">
                                     <h6 class="align-middle text-center fs-6">

                                       <span class="badge badge-success">Resuelto</span>
                                     </h6>
                                     <h4 class="mt-2 text-center">
                                      {{totalReports?.total_st_resuelto || '-'}}
                                     </h4>
                                   </div>
                                 </div>
                                 <div class="col-md-3 col-6">
                                   <div class="">
                                     <h6 class="align-middle text-center fs-6">

                                       <span class="badge badge-success">(Anulacion)</span>
                                     </h6>
                                     <h4 class="mt-2 text-center">
                                      {{totalReports?.total_st_anulacion || '-'}}
                                     </h4>
                                   </div>
                                 </div>
                             </div>
                           </div>
                         </div>
                       </div>
                    </div>
                    <div [hidden] = "true" class="row">
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-header text-center">
                                        Tipos de alertas
                                    </h5>
                                    <!--<div id="chart">
                                        <apx-chart [series]="chartOptions.series"
                                                   [chart]="chartOptions.chart"
                                                   [labels]="chartOptions.labels"
                                                   [responsive]="chartOptions.responsive">
                                        </apx-chart>
                                      </div>-->
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-header text-center">
                                        Tipos de estatus
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div  *ngIf="loaded" class="float-end">
                    <button mat-flat-button color="primary" (click)="generateExcelTotal()" class="ms-4"> Excel Totales <mat-icon>library_books</mat-icon></button>
                    <button mat-flat-button color="primary" (click)="generateExcelResumen()" class="ms-4"> Excel Resumen <mat-icon>library_books</mat-icon></button>
                  </div>
                  <div *ngIf="!loaded"
                  style="display: flex; justify-content: center; align-items: center; background: white; height: 500px;">
                  <mat-progress-spinner color="primary" mode="indeterminate">
                  </mat-progress-spinner>
                  </div>
            </mat-tab>
          </mat-tab-group>


        <!-- Card active campaigns strategies-->

    </div>
</div>

<div class="modal-header">
    <h4 class="modal-title"><strong>Estrategia - {{data.strategy.name}}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        
        <mat-divider></mat-divider>

        <div class="container-fluid mt-2">
            <div class="row col-12">
                <div class="col-md-6">
                   <label class="fw-bold">Seleccionar un Producto</label>
                   <select name="" id="" class="form-select mt-2" (change)="seleccionarOpcion($event)">
                        <option *ngFor="let producto of productos" [value]="producto.id" [selected]="producto.id === productSelect?.id">
                            {{producto.nombre}}
                        </option>
                   </select>

                   <div class="mt-3">
                        <label class="fw-bold">SKU</label>
                   </div>
                   <div>
                        <label>{{ productSelect?.sku }}</label>
                   </div>

                   <div class="mt-1">
                    <label class="fw-bold">Descripcion</label>
                    </div>
                    <div>
                        <label>{{ productSelect?.nombre }}</label>
                    </div>

                    <div class="mt-1">
                            <label class="fw-bold">Ultimo uso</label>
                    </div>
                    <div>
                        <label for="">{{ productSelect?.ultimo_uso | date: 'dd/MM/yyyy' }}</label>
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="pdf-container"><iframe id="pdf" name="pdf" width="100%" height="385px" [src]="getSourceURL(fileUrl)"></iframe></div>
                </div>
            </div>
        </div>
 
    </div>

    <div class="modal-footer mt-3 me-5">
        <button mat-flat-button class="bg-info text-white d-flex align-items-center" (click)="guardarSKU(data.strategy.id)" >
            <label style="cursor: pointer !important">Añadir </label>
            <mat-icon class="ms-1">add</mat-icon>
        </button>
    </div>
</mat-dialog-content>

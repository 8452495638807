<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="me-2 align-middle">store</mat-icon>Generar Preproyectos
        </h4>

        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="overflow-auto w-100">
                            <div class="float-end">
                                <button mat-flat-button color="primary" (click)="openTotales()"> Totales <mat-icon>add</mat-icon></button>
                            </div>
                        </div>
                        <div class="table-responsive text-nowrap">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Formulario</th>
                                        <th>Fecha Creación<i class="bx bxs-lock"></i></th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngIf="strategies.length == 0">
                                        <td colspan="9">
                                            <h6 class="text-center">No hay registros</h6>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let str of strategies; let i = index">
                                        <td>{{i+1}}</td>
                                        <td class="td-estrategia" matTooltip="Da click para ver lista de materiales" [matTooltipPosition]="'above'" [matTooltipShowDelay]="1000" role="button">
                                            <strong>{{str.nombre_str}}</strong>
                                        </td>
                                        <td>{{str.created_at}}</td>
                                     
                        
                                        <td class="text-center">
                                            <button mat-icon-button [matMenuTriggerFor]="menuEstrategia" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuEstrategia="matMenu">
                                                <button *ngIf="str.excel_formulario != null" (click)="openGenerate(str)"  mat-menu-item>
                                                    <mat-icon>play_arrow</mat-icon>
                                                    <span>Generar</span> 
                                                </button>
                                                <button *ngIf="str.excel_formulario" mat-menu-item>
                                                    <mat-icon>download</mat-icon>
                                                    <a href="{{str.excel_formulario}}" >Descargar formulario</a>
                                                </button>
                                                <button (click)="openUploadForm(str.id)" mat-menu-item>
                                                    <mat-icon>upload</mat-icon>
                                                    <span>Subir formulario</span>
                                                </button>
                                                <button  (click)="openUploadPDF(str.id)" mat-menu-item>
                                                    <mat-icon>upload</mat-icon>
                                                    <span>Subir pdfs</span>
                                                </button>
                                                <button  (click)="openEditStr(str.id ,0, str)" mat-menu-item>
                                                    <mat-icon>edit</mat-icon>
                                                    <span>Editar</span>
                                                </button>
                                                <button  (click)="deleteStrConfirm(str.id)"  mat-menu-item>
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Eliminar</span>
                                                </button>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button *ngIf="PreprojectTotals.length != 0"  mat-flat-button class="mt-4 bg-info text-white"  (click)="openEditStr(0,0,null)">Generar nuevo registro <mat-icon>add</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'providers_surveys_logs',
  templateUrl: './providers_surveys_logs.component.html',
  styleUrls: ['./providers_surveys_logs.component.scss']
})
export class ProvidersSurveysLogsComponent implements OnInit {

  id_provider_survey: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private proyectServices : ProjectsService,
    private main : MainService,
    private dialogRef: MatDialogRef<ProvidersSurveysLogsComponent>,
  ) {
    this.id_provider_survey = this.data.id_provider_survey;
    this.loadData();
   }

  ngOnInit(): void {
  }

  mainLogoRoute = "../../assets/img/clients/";
  regLogs : any = [];
  message : string = "";
  
  loadData(){
    this.main.ShowLoading();
    this.proyectServices.getProviverSurveyChangelog(this.id_provider_survey).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.regLogs = result.data;
      },
      (err:any) => {
        this.main.HideLoading();
        if(err.error.message != "No changelog were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  reloadData(){
    this.proyectServices.getProviverSurveyChangelog(this.id_provider_survey).subscribe(
      (result:any) => {
       this.regLogs = result.data;
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
        if(err.error.message != "No changelog were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  saveNewMessage(){
    if(this.message!= ""){
      this.main.ShowLoading();
      this.proyectServices.newProviverSurveyMessage({"id_provider_survey": this.id_provider_survey, "message": this.message}).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            this.message = "";
            this.reloadData();
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }
  }

  getUserCompanyOrRol(log : any){
      if(log.id_rol == 3 && log.company_name != null){
        return log.company_name;
      }else{
        return log.rol;
      }
  }

  getLogoCompany(logImage : string):string{
    return "url(" + this.mainLogoRoute + logImage + ")"
  }

}
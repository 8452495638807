import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-diagramation-inventory',
  templateUrl: './projects-diagramation-inventory.component.html',
  styleUrls: ['./projects-diagramation-inventory.component.scss']
})
export class ProjectsDiagramationInventoryComponent implements OnInit {

  
  displayedColumnsStore: string[] = [];
  allColumnsStore: string[] = [];

  constructor() { }

  ngOnInit(): void {
    if (this.storeFile.length > 0) {
      this.displayedColumnsStore = Object.keys(this.storeFile[0]).filter(key => key && key.trim() !== '');
    }

    this.allColumnsStore = this.displayedColumnsStore;
  }

  storeFile = [{"Medio": "DANGLER","POP":"Version 1", "Inventario Inicial" : 113, "Inventario Disponible" : 113, "Inventario Asignado" : 0},
    {"Medio": "DANGLER","POP":"Version 1", "Inventario Inicial" : 113, "Inventario Disponible" : 113, "Inventario Asignado" : 0},
  ];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
   //this.storeFile.filter = filterValue.trim().toLowerCase();

   //if (this.storeFile.paginator) {
   //  this.storeFile.paginator.firstPage();
   //}
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-providers-add-member',
  templateUrl: './providers-add-member.component.html',
  styleUrls: ['./providers-add-member.component.scss']
})
export class ProvidersAddMemberComponent implements OnInit {

  formProviderMember!: FormGroup;
  hostName: string;
  id_provider : number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,    
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private projectService : ProjectsService,
    private main : MainService,
    private dialogRef: MatDialogRef<ProvidersAddMemberComponent>
  ) { 
  
    this.id_provider = data.id_provider;
    this.hostName = window.location.protocol + '//' + window.location.host + "/providers-sign-up/";

   }

  providers : any;

  hideEmail = true;
  hideEmailConfirm = true;
  active_not = false;
  providersList : any = [];

  ngOnInit(): void {
    this.getProviders();
   
    this.createFormProviderMember();
   
  }

  createFormProviderMember() {
    this.formProviderMember = this.fb.group({
      nombre: ['', [ Validators.required, Validators.minLength(3)]],
      email: ['', [ Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")] ],
      can_edit: ['', [ Validators.required, Validators.minLength(3)]],
      id_provider : [this.id_provider, [ Validators.required]],
      pass: ['', [Validators.required, Validators.minLength(8)]],
      passConfirm: ['', [Validators.required, Validators.minLength(8)]],
      active_not : [false, [ Validators.required]],
      hostName : [ this.hostName ],
      providers: ['', [Validators.required]],
    });
  }

  ruSure() {
    if(this.formProviderMember.invalid) { return; }    
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  resetForm() {
    this.formProviderMember.reset();
  }

  getProviders(){
    this.projectService.getProviders().subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.providers = result.data;
       this.createFormProviderMember();
       this.formProviderMember.controls["providers"].setValue([Number(this.id_provider)]);
       console.log("info",this,this.formProviderMember.value)
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros de proveedores.'
          });
        
      }
    );
  }

  saveUser(){
    console.log(this.formProviderMember.value);

    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.projectService.saveMember(this.formProviderMember.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Miembro guardado de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        if(err.error.message.indexOf("ER_DUP_ENTRY") > -1)
        {
          Swal.fire({
            icon: 'error',
            title: 'Miembro duplicado',
            text: 'Ya existe un miembro con ese email.'
          });
        }
        else {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      }
    );

  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MaterialsAddToDistributionComponent } from '../../shared/dialogs/materials-add-to-distribution/materials-add-to-distribution.component';
import { MaterialsImportDistributionComponent } from '../../shared/dialogs/materials-import-distribution/materials-import-distribution.component';
import { GroupsEditComponent } from '../../shared/dialogs/groups-edit/groups-edit.component';
import Swal from 'sweetalert2';
import { MaterialsEditComponent } from '../../shared/dialogs/materials-edit/materials-edit.component';

interface MaterialModel {
  id: number;
  art: string;
  groups: number;
  responsable: string;
  registro: string;
}


@Component({
  selector: 'app-materials-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class MaterialsDistributionComponent implements OnInit {

  isLoading = false;
  displayedColumns: string[] = ['art', 'groups', 'responsable','registro', 'actions'];
  dataSource!: MatTableDataSource<MaterialModel>;
  panelOpenState = false;
  islocked=false;
  campaignData=false;
  canloadDist = false;
  demoData: MaterialModel[] = [
    {
      id: 1,
      art: 'POSTER CHECK OUT 2',
      groups: 2,
      responsable: 'Enrique Soto',
      registro: '28/12/2023',
    },
    {
      id: 2,
      art: 'POSTER CHECK OUT 3',
      groups: 4,
      responsable: 'Adrian sanchez',
      registro: '02/01/2024',
    },
    {
      id: 3,
      art: 'KIT COPETE CLIENTE',
      groups: 1,
      responsable: 'Adrian sanchez',
      registro: '05/01/2024',
    },
    {
      id: 4,
      art: 'KIT COPETE INTERNO',
      groups: 3,
      responsable: 'Enrique Soto',
      registro: '10/01/2024',
    },
    {
      id: 5,
      art: 'DANGLER',
      groups: 4,
      responsable: 'Enrique Soto',
      registro: '15/01/2024',
    },
    {
      id: 6,
      art: 'KIT DANG. BOTADERO',
      groups: 2,
      responsable: 'Adrian sanchez',
      registro: '20/01/2024',
    },
    {
      id: 7,
      art: 'GUIA DE EJECUCION',
      groups: 3,
      responsable: 'Adrian sanchez',
      registro: '25/01/2024',
    },
    {
      id: 8,
      art: 'CLUSTER A',
      groups: 1,
      responsable: 'Enrique Soto',
      registro: '30/01/2024',
    },
    {
      id: 9,
      art: 'CLUSTER B',
      groups: 2,
      responsable: 'Adrian sanchez',
      registro: '05/02/2024',
    },
    {
      id: 10,
      art: 'CLUSTER C',
      groups: 3,
      responsable: 'Enrique Soto',
      registro: '10/02/2024',
    },
  ];  
   
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private matDialog: MatDialog,
  ) { 
    this.dataSource = new MatTableDataSource<MaterialModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;   
  }

  ngOnInit(): void {
  }

  getCampaign() {
    this.campaignData = true;
    this.dataSource = new MatTableDataSource<MaterialModel>(this.demoData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogAddMaterialToDistribution() {
    this.matDialog.open(MaterialsAddToDistributionComponent, {panelClass: 'dialogs-sm'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = false;
          // this.isLoading = true;     TODO:borrar esto
      }
    );
  }

  openDialogImportDistribution() {
    this.matDialog.open(MaterialsImportDistributionComponent, {panelClass: 'dialogs-main'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = false;
          // this.isLoading = true;     TODO:borrar esto
      }
    );
  }

  openEditGroupModal(name:string, editable: boolean) {
    this.matDialog.open(GroupsEditComponent, { 
      data: 
      {
         name: name, 
         isDisabled: editable 
      },
      panelClass: 'dialogs-main'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = false;
          // this.isLoading = true;     TODO:borrar esto
      }
    );
  }

  openEditMaterialModal(name:string) {
    this.matDialog.open(MaterialsEditComponent, { 
      data: 
      {
         name: name, 
      },
      panelClass: 'dialogs-sm'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = false;
          // this.isLoading = true;     TODO:borrar esto
      }
    );
  }

  unlockDistribution() {
    if(!this.islocked) {
      Swal.fire({
        title: '¿Está seguro que desea bloquear esta distribución?',
        text: "No se podra editar la distribucíon y se generara un archivo descargable",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
          this.islocked = !this.islocked ;

        }
      })
    }else{
      this.islocked = !this.islocked ;
    }
  }

}

<div class="col-md-12">
  <div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Datos completos de sucursales</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
  </div>
<div>
<div >     
<div class="col-md-12">
<div class="table-responsive" style="max-height: 600px; overflow-y: auto;">

    <div class="overflow-auto w-100">
        <mat-form-field>
            <mat-label>
                <mat-icon>search</mat-icon> Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Monterrey Centro" #input>
        </mat-form-field>
    </div>

    <table style="overflow: scroll" mat-table [dataSource]="dataSource" matSort #sorting class="w-100">

        <ng-container *ngFor="let column of retireActionsCol()" [matColumnDef]="column">
          <th [hidden]="column == 'id'|| column == 'actions' || column == 'Fecha Apertura'" mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 200px;">{{ column }}</th>
          <td [hidden]="column == 'id'|| column == 'actions' || column == 'Fecha Apertura'" class="pe-3" mat-cell *matCellDef="let row" style="min-width: 200px;">{{ column == 'Activa' ? row[column] == 0 ? 'Inactiva' : 'Activa' : row[column]  }}</td>
        </ng-container>
      
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let row" class="text-center">
            <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent" (click)="openDialogAttributeEditList(row)">
                <mat-icon>edit</mat-icon>
            </button>
        </td>
        </ng-container>
      
     
        <tr mat-header-row *matHeaderRowDef="displayedCols"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedCols"></tr>
      </table>

     
   
</div>
<mat-paginator #pagi table-direc (paginatorLoaded)="dataSource.paginator=$event" [pageSizeOptions]="[7, 25, 30]"></mat-paginator>
</div>
</div>
</div>
</div>

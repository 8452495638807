<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="me-2 align-middle">settings</mat-icon>Configuración
        </h4>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <mat-tab-group mat-align-tabs="start">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon class="me-2">group</mat-icon> Lista de Usuarios
                                </ng-template>
                                <app-users></app-users>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
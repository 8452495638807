import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/services/campaigns.service';

@Component({
  selector: 'app-distribution-irregularities',
  templateUrl: './distribution-irregularities.component.html',
  styleUrls: ['./distribution-irregularities.component.scss']
})
export class DistributionIrregularitiesComponent implements OnInit {

  campaign : any;
  strList : { [key: string] : any } = {};
  disList : any;
  difList : { [key: string] : any } = {};
  disCount = 0;
  difCount = 0;
  isLoading = true;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campS : CampaignsService
  ) { }

  ngOnInit(): void {
    this.campaign = this.data.campaign;
    this.getIrregularities(this.campaign.id);
  }

  getIrregularities(campID: number) {
    this.campS.getIrregularities(campID).subscribe(
      (result : any) => {
        this.strList = this.groupByStrategy(result.data.str);
        this.disList = result.data.dis;
        this.difList = this.groupByStrategy(result.data.dif);
        this.disCount = result.data.dis ? result.data.dis.length : 0;
        this.difCount = result.data.dif ? result.data.dif.length : 0;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );    
  }
  
  groupByStrategy(irrListProp:any) {
    return irrListProp.reduce(function (r:any, e:any) {
      r[e.strategy] = r[e.strategy] || [];
      r[e.strategy].push(e);
      return r;
    }, {});
  }

}

<div class="modal-header">
    <h4 class="modal-title">Configuración de Vistos Buenos</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
  </div>
  
 
  <div class="modal-body-scroll">
    <form [formGroup]="voboForm">
      <!-- Vobo 1 -->
      <div class="vobo-item ">
        <h5 class="fw-bold text-primary">Fase Artes 1</h5>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Responsables Configurados</mat-label>
          <mat-select formControlName="responsable" multiple>
              <mat-option value="Adriana Lopez">Adriana Lopez</mat-option>
              <mat-option value="Laura Servin">Laura Servin</mat-option>
              <mat-option value="Abril Sanchez">Abril Sanchez</mat-option>
              <mat-option value="Fabian Toledo">Fabian Toledo</mat-option>
              <mat-option value="Edgar Rojas">Edgar Rojas</mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Fecha Límite</mat-label>
          <input matInput type="text" value="20/10/2024" placeholder="Fecha Límite" disabled />
        </mat-form-field>
      </div>
  
      <!-- Vobo 2 -->
      <div class="vobo-item ">
        <h5 class="fw-bold text-primary">Fase Artes 2</h5>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Responsables Configurados</mat-label>
          <mat-select formControlName="responsable" multiple>
              <mat-option value="Adriana Lopez">Adriana Lopez</mat-option>
              <mat-option value="Laura Servin">Laura Servin</mat-option>
              <mat-option value="Abril Sanchez">Abril Sanchez</mat-option>
              <mat-option value="Fabian Toledo">Fabian Toledo</mat-option>
              <mat-option value="Edgar Rojas">Edgar Rojas</mat-option>
          </mat-select>
         </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Fecha Límite</mat-label>
          <input matInput type="text" value="20/10/2024" placeholder="Fecha Límite" disabled />
        </mat-form-field>
      </div>
  
      <!-- Vobo 3 -->
      <div class="vobo-item ">
        <h5 class="fw-bold text-primary">Fase Artes 3</h5>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Responsables Configurados</mat-label>
          <mat-select formControlName="responsable" multiple>
              <mat-option value="Adriana Lopez">Adriana Lopez</mat-option>
              <mat-option value="Laura Servin">Laura Servin</mat-option>
              <mat-option value="Abril Sanchez">Abril Sanchez</mat-option>
              <mat-option value="Fabian Toledo">Fabian Toledo</mat-option>
              <mat-option value="Edgar Rojas">Edgar Rojas</mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Fecha Límite</mat-label>
          <input matInput type="text" value="20/10/2024" placeholder="Fecha Límite" disabled />
        </mat-form-field>
      </div>
  
      <!-- Vobo 4 -->
      <div class="vobo-item ">
        <h5 class="fw-bold text-primary">Fase Artes 4</h5>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Responsables Configurados</mat-label>
          <mat-select formControlName="responsable" multiple>
              <mat-option value="Adriana Lopez">Adriana Lopez</mat-option>
              <mat-option value="Laura Servin">Laura Servin</mat-option>
              <mat-option value="Abril Sanchez">Abril Sanchez</mat-option>
              <mat-option value="Fabian Toledo">Fabian Toledo</mat-option>
              <mat-option value="Edgar Rojas">Edgar Rojas</mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill" class="compact-field">
          <mat-label>Fecha Límite</mat-label>
          <input matInput type="text" value="20/10/2024" placeholder="Fecha Límite" disabled />
        </mat-form-field>
      </div>
    </form>
  </div>
  

  <div mat-dialog-actions class="modal-footer">
    <button mat-button color="warn" (click)="cerrarModal()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="guardarVobos()" [disabled]="voboForm.invalid">Guardar</button>
  </div>
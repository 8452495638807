import { Component, OnInit, Inject } from '@angular/core';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-preproject-global',
  templateUrl: './preproject-global.component.html',
  styleUrls: ['./preproject-global.component.scss']
})
export class PreprojectGlobalComponent implements OnInit {

  newObj = {}

  tiposImpresion: string[] = [
    "INDIGO DIGITAL",
    "OFFSET",
    "ROTATIVA",
    "DIGITAL (DOCUCOLOR)",
    "FLEXOGRAFÍA",
    "SERIGRAFÍA",
    "GRAN FORMATO",
    "GRAN FORMATO/TINTA LATEX",
    "PLOTTER CAMA PLANA",
    "SUBLIMADO",
    "TAMPOGRAFÍA",
    "OFFSET PRENSA PLANA",
    "PLOTTER DE CORTE",
    "N/A"
  ];
  
  tiposEnvio: string[] = [
    "TERRESTRE",
    "AÉREO",
    "LOCAL",
    "N/A"
  ];
  
  tiposRecepcion: string[] = [
    "SUCURSALES",
    "PLAZAS",
    "GUÍA",
    "GUÍA ESTAFETA",
    "GUÍA PAQUETEXPRESS",
    "DEACERO OFICINAS",
    "DEACERO BODEGA",
    "DEACERO SALTILLO PLANTA ALAMBRE",
    "DEACERO MONTERREY PLANTA ALAMBRE",
    "DEACERO PLANTA GUADALUPE",
    "CEMIX CORPORATIVO",
    "PAK2GO",
    "ULTIMA MILLA",
    "N/A"
  ];

  constructor(
    private campaignService : CampaignsService,
    public dialogRef: MatDialogRef<PreprojectGlobalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }

  guardarPreproyectoGlobal(data: any){

    this.newObj = {
      campaign: data.campaign.id,
      contacto: data.contacto ?? null,
      impresion: data.impresion ?? null,
      color: data.color ?? null,
      envio: data.envio ?? null,
      recepcion: data.recepcion ?? null,
      fecha_inicio: data.fecha_inicio ?? null,
      fecha_fin: data.fecha_fin ?? null,
      id_preproyecto: data.id ?? 0
    }

    this.campaignService.savePreprojectGlobal(this.newObj).subscribe(
      (result:any) => {
        this.dialogRef.close();
        Swal.fire("Guardado", "", "success"); 
      },
      (err:any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al guardar los registros.'
        });
      }  
    );
  }
}

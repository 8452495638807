<div class="content-wrapped">
    <div class="container-xxl flex-grow-1 container-p-y">
        <h2 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="me-2 align-middle">edit</mat-icon>Configuración
            <div class="float-end">
                <button mat-flat-button color="primary" class="ms-4" (click)=" addDigramation()"><mat-icon>add</mat-icon> Añadir nuevo</button>
            </div>
        </h2>

      <div class="diagramacion-container">
        <div class="row">
          <div *ngFor="let dia of digramationList" class="col-sm-6">
            <mat-card  class="card-configuration ">
              <mat-card-header>
                <mat-card-title><b>Nombre</b>: {{ dia.name }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <!--<div class="mb-4 col-md-10">
                  <h5 class="fw-bold text-primary">
                    <mat-icon class="me-2 align-middle">code</mat-icon>Código de Promociones
                  </h5>
                  <mat-form-field appearance="outline" class="w-100 form-field">
                    <mat-label>Selecciona un código</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let code of codigosPromo" [value]="code">{{ code }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>-->
      
             
               <!-- <div class="mb-4 col-md-10">
                  <h5 class="fw-bold text-primary">
                    <mat-icon class="me-2 align-middle">store</mat-icon>Seleccionar Corte de Sucursales
                  </h5>
                  <mat-form-field appearance="outline" class="w-100 form-field">
                    <mat-label>Corte de Sucursales</mat-label>
                    <mat-select [(ngModel)]="selectedSucursalCorte">
                      <mat-option *ngFor="let corte of sucursalesCorte" [value]="corte">{{ corte.nombre }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>-->
      
             
                <div class="mb-4" *ngIf="showFilters">
                  <h5 class="fw-bold text-primary">
                    <mat-icon class="me-2 align-middle">settings</mat-icon>Reglas de distribución
                  </h5>
                  <div *ngFor="let regla of dia.reglas; let i = index" class="mb-3">
                    <div class="row align-items-center"> 
                      <div class="col-md-3">
                        <mat-form-field appearance="outline" class="form-field">
                          <mat-label>Atributo</mat-label>
                          <mat-select [(ngModel)]="regla.atributo">
                            <mat-option *ngFor="let atributo of atributosSucursales" [value]="atributo">{{ atributo }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      
                      <div class="col-md-3 d-flex justify-content-center">
                        <span class="x-label">Multiplicado por:</span> 
                      </div>
                  
                      <div class="col-md-3">
                        <mat-form-field appearance="outline" class="form-field">
                          <mat-label>Valor</mat-label>
                          <input matInput [(ngModel)]="regla.valor" placeholder="Ej: 10" />
                        </mat-form-field>
                      </div>
                  
                      <div class="col-md-2" style="margin-top: -20px;">
                        <button mat-icon-button color="warn" (click)="eliminarRegla(i,dia.reglas)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
      
                  <button mat-flat-button color="primary" (click)="agregarRegla(dia.reglas)">
                    <mat-icon>add</mat-icon> Añadir Regla
                  </button>
                </div>

                <div class="mb-4"  *ngIf="showCantidad">
                  <h5 class="fw-bold text-primary">
                    <mat-icon class="me-2 align-middle">attach_money</mat-icon>Definir Cantidad
                  </h5>
                  <div>
                  <div class="row align-items-center"> 
                      <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Cantidad</mat-label>
                          <input matInput type="number" min="1" [(ngModel)]="dia.cantidad" name="cantidad" step="-1">
                        </mat-form-field>
                    </div>
                  </div>
                </div>
                </div>
                <div class="mb-4" *ngIf="showPrecio">
                  <h5 class="fw-bold text-primary">
                    <mat-icon class="me-2 align-middle">attach_money</mat-icon>Definir Precio
                  </h5>
                  <div>
                  <div class="row align-items-center"> 
                      <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Precio</mat-label>
                          <input matInput type="number" min="1" [(ngModel)]="dia.price" name="price" step="-1">
                        </mat-form-field>
                    </div>
                  </div>
                </div>
                </div>
              
                <div class="button-group mt-4">
                  <button mat-raised-button color="primary" (click)="guardarConfiguracion()">Guardar Configuración</button>
                </div>
              </mat-card-content>
            </mat-card>
          </div>


        </div>


      </div>
    </div>
  </div>
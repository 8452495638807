import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'surveys-confirm',
  templateUrl: './surveys-confirm.component.html',
  styleUrls: ['./surveys-confirm.component.scss']
})
export class SurveysConfirmComponent implements OnInit {

  constructor( private fb: FormBuilder,
    public dialogo: MatDialogRef<SurveysConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, private surveyS : SurveysService, private main : MainService,) { }
  
  name : string =  "";
  puesto:  string = "";
  id_campana : number = 0;
  id_sucursal : number = 0;
  box_id : number = 0;
  sucursal : any = {};
  products : any = [];
  loading: boolean = false;
  isClaw : boolean = false;
  id_campana_claw = 0;
  ngOnInit(): void {
 
   this.id_campana = this.data.id_campana;
   this.id_sucursal = this.data.sucursal.id;
   this.products = this.data.productListEvaluated
   this.isClaw = this.data.isClaw;
  
   if(this.isClaw){
    this.sucursal = this.data.sucursal;
    this.box_id = this.data.box_id;
    this.id_campana_claw = this.data.id_campana_claw;
   }
  }


  confirmado(): void {
    this.dialogo.close(true);
  }

  enviarEncuesta(){
    this.loading = true;
    if( this.name !== "" && this.puesto !== "" ){
      let confirm = {};
      if(this.isClaw){
        confirm = { payload : this.products , id_campana_claw : this.id_campana_claw, sucursal: this.sucursal, id_distribucion : this.box_id, nombre : this.name, puesto : this.puesto }
      }else{
        confirm = { payload : this.products , id_camp : this.id_campana, id_suc : this.id_sucursal, id_distribucion  : 0, nombre : this.name, puesto : this.puesto }
      }
      console.log(confirm);
      this.surveyS.answerSurvey(confirm).subscribe((result:any)=>{
        this.loading = false; 
        if(result.message = 'Success'){
          Swal.fire({
            icon: 'success',
            title: 'Encuesta enviada',
            html: 'Encuesta enviada de manera correcta!'
          })
          this.confirmado()
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error al enviar la encuesta',
            text: 'Ocurrió un error al enviar la encuesta, por favor vuelve a intentarlo.'
          });
        } 
      },
      (err:any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al enviar la encuesta',
          text: 'Ocurrió un error al enviar la encuesta, por favor vuelve a intentarlo.'
        });
          }
      )  
        console.log(this.products);
        //this.confirmado()
    }
  }

}

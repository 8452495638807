import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SkuAddComponent } from '../shared/dialogs/sku-add/sku-add.component';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import Swal from 'sweetalert2';
import { SkuImageComponent } from '../shared/dialogs/sku-image/sku-image.component';
import { SkuService } from 'src/app/services/sku.service';

@Component({
  selector: 'app-skus',
  templateUrl: './skus.component.html',
  styleUrls: ['./skus.component.scss']
})

export class SkusComponent implements OnInit {

  displayedColumns: string[] = ['id', 'sku', 'nombre', 'descripcion','medidax','mediday','imagen','actions'];
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  skus: { id: number, sku: string, nombre: string, descripcion: string, imagen: string }[] = [];
  displayedCols : string[] = [];

  constructor(
      private matDialog: MatDialog,
      private main: MainService,
      private campaignService : CampaignsService,
      private skuService : SkuService,
  ) { 
    this.getSkus()
  }

  ngOnInit(): void {}

  getSkus(){
    this.main.ShowLoading();
    this.campaignService.getSkus().subscribe(
      (result:any) => {
        this.skus = result.data;
        this.main.HideLoading();
        this.displayedCols = this.displayedColumns;
        this.dataSource = new MatTableDataSource(this.skus);       
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (err:any) => {
        this.main.HideLoading();
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al obtener registros',
          text: 'Ocurrió un error al obtener los registros.'
        });
      }
    );
  }

  openSku(element: any) {
    const dialogRef = this.matDialog.open(SkuAddComponent, {
      panelClass: 'dialogs-md', 
      data : { ...element }
    })

    dialogRef.afterClosed().subscribe(result => {
      this.getSkus()
    });
  }
  
  //Para el buscador de la tabla
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  verSku(element: any) {
    const dialogRef = this.matDialog.open(SkuImageComponent, {
      panelClass: 'dialogs-lg', 
      data : element
    })
  }

  bajaSku(idSku: any){

    Swal.fire({
      title: '¿Estás seguro que deseas dar de baja este SKU?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.skuService.deleteSku(idSku).subscribe(
          (x: any) => {
            Swal.fire({
              icon: 'success',
              title: 'Operación exitosa',
            });
            this.getSkus();
          },
          (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al dar de baja',
              text: 'Ocurrió un error al modificar el registro, por favor vuelva a intentarlo.'
            });
          }
        );   
      }
    });
  }

}

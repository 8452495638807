import { Component, Inject, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'sucursales_changelog',
  templateUrl: './sucursales_changelog.component.html',
  styleUrls: ['./sucursales_changelog.component.scss']
})
export class StoreChangeLogsComponent implements OnInit {

  id_sucursal: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private storeServices : StoreService,
    private main : MainService,
    private dialogRef: MatDialogRef<StoreChangeLogsComponent>,
  ) {
    this.id_sucursal = this.data.id_sucursal;
    this.loadData();
   }

  ngOnInit(): void {
  }

  mainLogoRoute = "../../assets/img/clients/";
  regLogs : any = [];
  message : string = "";
  
  loadData(){
    this.main.ShowLoading();
    this.storeServices.getStoreChangeLog(this.id_sucursal).subscribe(
      (result:any) => {
       this.main.HideLoading();
       this.regLogs = result.data;
      },
      (err:any) => {
        this.main.HideLoading();
        if(err.error.message != "No changelog were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }


  getUserCompanyOrRol(log : any){
      if(log.id_rol == 3 && log.company_name != null){
        return log.company_name;
      }else{
        return log.rol;
      }
  }

  getLogoCompany(logImage : string):string{
    return "url(" + this.mainLogoRoute + logImage + ")"
  }

}
<div class="content-wrapper">

    <!-- Content -->
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">Campañas 
            <button mat-flat-button color="primary" class="ms-4" *ngIf="hasPermissions('cr_camp',null,true)" (click)="openAddCampaignDialog()">Alta de campaña <mat-icon>campaign</mat-icon></button>
        </h4>
        <div class="col-md-4" *ngIf="false">
            <div class="row g-2" >
                <div class="col mb-0">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Compañía</mat-label>
                        <mat-select name="id_compania" [(ngModel)]="selectedCompany" required>
                            <mat-option [value]="0" >Todas</mat-option>
                            <mat-option *ngFor="let p of companiesList" [value]="p.id">{{p.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col mb-0">
                    <button style="margin-top: 12px" mat-flat-button color="primary" class="ms-4" (click)="loadCampaignsFilter()"> Filtrar </button>
                </div>
               
            </div>
        </div>
        
        <div *ngIf="loaded">
        <!-- Card active campaigns -->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-success align-middle">stop</mat-icon> Campañas activas</h5>
                    <div class="card-body">
                        <h6 *ngIf="(campaignsData.progress | json) == '[]'">No hay registros</h6>
                        <div *ngIf="(campaignsData.progress | json) != '[]'" class="table text-nowrap">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre campaña</th>
                                        <th>Status</th>
                                        <th>Fecha Límite</th>
                                        <th *ngIf="!isClient()">Compañía</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngFor="let camp of campaignsData.progress; let i = index">
                                        <td role="button"><strong>{{i+1}}</strong></td>
                                        <td role="button" (click)="goToCampaign(camp.id)"><strong>{{camp.name}}</strong> <span *ngIf="camp.alert > 0" class="ms-2 badge badge-center rounded-pill bg-danger">1</span></td>
                                        <td role="button" class="">
                                            <button type="button" class="btn btn-warning disabled" aria-expanded="false">
                                                En progreso
                                            </button>
                                        </td>
                                        <td role="button"><i> {{camp.deadline  | date : "dd/MM/yyyy"}}</i></td>
                                        <td *ngIf="!isClient()" role="button"><i> {{ getCompanyName(camp.id_compania) }}</i></td>
                                        <td>
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openCampaignLogs(camp)">
                                                <mat-icon matBadge="{{camp.notif==0 ? '' : camp.notif}}" matBadgeColor="warn">forum</mat-icon>
                                            </button>
                                            <button *ngIf="hasPermissions('upd_camp',true,true) || isBuho" mat-icon-button [matMenuTriggerFor]="menuCampana" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="accent">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menuCampana="matMenu">
                                                <button *ngIf="hasPermissions('upd_camp',true,true)" (click)="openEditCampaignDialog(camp)" mat-menu-item>
                                                    <mat-icon>edit</mat-icon>
                                                    <span>Editar</span>
                                                </button>
                                                <button *ngIf="isBuho()" (click)="deleteCampConfirm(camp)" mat-menu-item>
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Eliminar</span>
                                                </button>
                                            </mat-menu> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card active campaigns End-->

        <!-- Card inactive campaigns -->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-warning align-middle">stop</mat-icon> Campañas inactivas {{ actual_year }}</h5>
                    <div class="card-body">
                        <h6 *ngIf="(campaignsData.finished | json) == '[]'">No hay registros</h6>
                        <div *ngIf="(campaignsData.finished | json) != '[]'" class="table text-nowrap">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Nombre campaña</th>
                                        <th>Status</th>
                                        <th>Fecha Límite</th>
                                        <th *ngIf="!isClient()">Compañía</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody class="table-border-bottom-0">
                                    <tr *ngFor="let camp of campaignsData.finished; let i = index" role="button">
                                        <td role="button"><strong>{{i+1}}</strong></td>
                                        <td role="button" (click)="goToCampaign(camp.id)"><strong>{{camp.name}}</strong> <span *ngIf="camp.alert > 0" class="ms-2 badge badge-center rounded-pill bg-danger">1</span></td>
                                        <td role="button" class="">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-success disabled" aria-expanded="false">
                                                    Terminado
                                                </button>
                                            </div>
                                        </td>
                                        <td role="button"><i>{{camp.deadline  | date : "dd/MM/yyyy"}}</i></td>
                                        <td *ngIf="!isClient()" role="button"><i> {{ getCompanyName(camp.id_compania) }}</i></td>
                                        <td>
                                            <button mat-icon-button matTooltip="Chat y Registros" [matTooltipPosition]="'above'" color="accent" (click)="openCampaignLogs(camp)">
                                                <mat-icon matBadge="{{camp.notif==0 ? '' : camp.notif}}" matBadgeColor="warn">forum</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Card inactive campaigns End -->

        <!-- Card inactive campaigns past years-->
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <h5 class="card-header">
                        <mat-icon class="text-danger align-middle">stop</mat-icon> Campañas inactivas años anteriores</h5>
                    <!-- Account -->
                    <div class="card-body">
                        <h6 *ngIf="(campaignsData.pastyears | json) == '{}'">No hay registros</h6>
                        <div class="accordion mt-3" id="accordionExample">
                            <div class="card accordion-item" *ngFor="let year of years">
                                <h2 class="accordion-header" id="headingOne">
                                    <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordionOne" aria-expanded="false" aria-controls="accordionOne">
                                        {{ year }}
                                    </button>
                                </h2>
                                <div id="accordionOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="table text-nowrap">
                                            <table class="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombre campaña</th>
                                                        <th>Status</th>
                                                        <th>Fecha Límite</th>
                                                        <th *ngIf="!isClient()">Compañía</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-border-bottom-0">
                                                    <tr *ngFor="let camp of campaignsData.pastyears[year]; let i = index">
                                                        <td role="button"><strong>{{i+1}}</strong></td>
                                                        <td (click)="goToCampaign(camp.id)"><strong>{{camp.name}}</strong></td>
                                                        <td class="">
                                                            <div class="btn-group">
                                                                <button type="button" class="btn btn-success dropdown-toggle disabled" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    Terminado
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    <li><a class="dropdown-item" href="javascript:void(0);"><span class="badge bg-warning">En progreso</span></a></li>
                                                                    <li><a class="dropdown-item" href="javascript:void(0);"><span class="badge bg-success">Terminado</span></a></li>
                                                                    <li><a class="dropdown-item" href="javascript:void(0);"><span class="badge bg-danger">Detenido</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td><i>{{camp.deadline  | date : "dd/MM/yyyy"}}</i></td>
                                                        <td *ngIf="!isClient()" role="button"><i> {{ getCompanyName(camp.id_compania) }}</i></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>


    </div>
</div>
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import * as chartData from '../components/shared/data/charts';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    // successImg = '../../../assets/img/success-icon.png';
    successImg = '../../../assets/img/green.png';
    // errorImg = '../../../assets/img/error-icon.png';
    errorImg = '../../../assets/img/red.png';


    constructor(
        private main: MainService
    ) { }

    processData ( data: any) {
        const successfulPercent = `${((data.filter( (campaign: any) => campaign.estatus === 'successful'|| ( campaign.guia.toLowerCase()  === 'entrega dedicada' &&  campaign.estatus === null )).length /data.length ) * 100).toFixed(1)}`;
        const successfulQty = data.filter( (campaign: any) => campaign.estatus === 'successful' || ( campaign.guia.toLowerCase()  === 'entrega dedicada' &&  campaign.estatus === null )).length;
        const assignedQty = data.filter( (campaign: any) => campaign.estatus === 'assigned' ).length;
        const orderCreatedQty = data.filter( (campaign: any) => campaign.estatus === 'order_created' ).length;
        const startedQty = data.filter( (campaign: any) => campaign.estatus === 'started' ).length;
        const orderCompletedQty = data.filter( (campaign: any) => campaign.estatus === 'order_completed' ).length;
        const orderErrorQty = data.filter( (campaign: any) => campaign.estatus === 'error' ).length;
        const trackingChartData = chartData.shipmentTracking(data);
        const ShipmentsPerDayChartData = chartData.ShipmentsPerDay(data);
        const filterOptions = {
          zonas   : [...new Set(data.map((el:any) => el['zona']))],
          estatus : [...new Set(data.map((el:any) => el['estatus']))]
        }

        return {
            successfulPercent,
            successfulQty,
            assignedQty,
            orderCreatedQty,
            startedQty,
            orderCompletedQty,
            orderErrorQty,
            trackingChartData,
            ShipmentsPerDayChartData,
            filterOptions
        };
    }

    drawMap( data:any, map:any ) {
        const centerControlDiv = document.createElement('div');
        const centerControl = this.createCenterControl(map);
        centerControlDiv.appendChild(centerControl);
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
        data.forEach((track:any) => {
            const [lat, lng] = track.coords.split(', ');
            const latitude = parseFloat(lat);
            const longitude = parseFloat(lng);
            const direccion = JSON.parse(track.direccion);
            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(latitude, longitude),
                icon: track.estatus === 'successful' ? this.successImg : this.errorImg,
                map: map,
            });
            const contentString =
            '<div id="content">' +
              '<h5 id="firstHeading" class="firstHeading">Sucursal: ' + track.sucursal + '</h5>' + 
              '<div id="bodyContent">' +
                '<p>Direccion: '+ direccion.calle+ ', '+ direccion.colonia + ', '+ direccion.postal_code + ', '+ direccion.ciudad + ', '+ direccion.estado +'</p>'+
                '<span>Guia: </span>'+'<a class="text-primary text-decoration-none" target="_blank" href="https://tracking.buhologistics.com/?id='+ track.guia+'">'+ track.guia +'</a>'+
              "</div>" +
            "</div>";

            const infowindow = new google.maps.InfoWindow({
                content: contentString,
                maxWidth: 250 
            });

            //methods
            marker.addListener('click', () => {
                const position = marker.getPosition();
                if (position) {
                map.setZoom(15);
                map.setCenter(position);
                }
            });
            marker.addListener("click", () => {
              infowindow.open({
                anchor: marker,
                map,
              });
            });
        });
        return map;
    }

    createCenterControl(map:any) {
        const center = { lat: 22.856622, lng: -101.843085 };
        const controlButton = document.createElement('button');
      
        controlButton.style.backgroundColor = '#fff';
        controlButton.style.border = '2px solid #fff';
        controlButton.style.borderRadius = '3px';
        controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlButton.style.color = 'rgb(25,25,25)';
        controlButton.style.cursor = 'pointer';
        controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlButton.style.fontSize = '16px';
        controlButton.style.margin = '8px 10px';
        controlButton.style.padding = '5px';
        controlButton.style.textAlign = 'center';
        controlButton.style.verticalAlign = 'baseline';
        controlButton.style.height = '37.2px';
        controlButton.style.width = '40px'
      
        controlButton.innerHTML = '<mat-icon class="material-icons">zoom_out_map</mat-icon>';
        controlButton.type = 'button';
      
        controlButton.addEventListener('click', () => {
          map.setCenter(center);
          map.setZoom(5);
        });
      
        return controlButton;
    }
    

}
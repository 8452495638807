import { Component,Inject,OnInit } from '@angular/core';
import { newStrategyModel } from 'src/app/models/strategy.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-strategy-add',
  templateUrl: './distribution-strategy-add.component.html',
  styleUrls: ['./distribution-strategy-add.component.scss']
})
export class DistributionStrategyAddComponent implements OnInit {

  newStrategy : newStrategyModel = new newStrategyModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private dialogRef: MatDialogRef<DistributionStrategyAddComponent>
  ) { 
    this.isEdit = this.data.edit;
    this.isBuhoUsr = campaignService.isBuhoUsr();
    if(this.isEdit){
      this.LoadStrategyData(this.main.cloneObj(this.data.data));
    }else{
      this.newStrategy.id_camp = this.data.id_campana;
      this.newStrategy.folder_camp = this.data.folder_camp;
    }
  }
  isBuhoUsr: boolean = false;
  isEdit : boolean = false;

  ngOnInit(): void {
  }

  LoadStrategyData(strData : any){
    this.newStrategy.id_str = strData.id;
    this.newStrategy.name = strData.name;
    this.newStrategy.invoice = strData.invoice;
    this.newStrategy.folder_camp = strData.folder_camp;
  }

  saveStrategy(){
    if(this.newStrategy.name!= ""){
      this.main.ShowLoading();      
      this.campaignService.saveStrategy(this.newStrategy, this.isEdit).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: this.isEdit ? 'Editar' : 'Creado',
              text: this.isEdit ? 'Estrategia actualizada correctamente' :'Estrategia creada correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
    }else{
      Swal.fire({
        icon: 'error',
        title: 'No hay nombre de campaña',
        text: 'Por favor ingrese un nombre válido'
      });
    }
  }

  

}

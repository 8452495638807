<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Asignar valor <span *ngIf="data.setAll">masivo</span><span *ngIf="!data.setAll">a sucursal</span> <mat-icon class="text-info align-middle">spellcheck</mat-icon></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6 *ngIf="data.setAll">Ingresa el valor que quieres asignar a todas las sucursales.</h6>
    <h6 *ngIf="!data.setAll">Ingresa el valor que quieres asignar a la sucursal:<br><strong>{{ nombre }}.</strong></h6>
    <form #f="ngForm">
        <div class="row">
            <div class="col mb-3">

                <!--<mat-form-field class="no-space-bottom w-100" appearance="outline" [hidden]="dataInc.type_id != 5">
                    <mat-label><mat-icon>edit</mat-icon> Asigne un valor</mat-label>
                    <input matInput #input5>
                </mat-form-field>-->

                <mat-form-field class="no-space-bottom w-100" appearance="outline" [hidden]="dataInc.type_id != 4">
                    <mat-label><mat-icon>edit</mat-icon> Asigne un valor</mat-label>
                    <input matInput #input4 type="number"  placeholder="{{getRanges(dataInc.select_data)[0] }} - {{getRanges(dataInc.select_data)[1]}}">
                </mat-form-field>

                <mat-form-field class="no-space-bottom w-100" appearance="outline" [hidden]="dataInc.type_id != 6">
                    <mat-label><mat-icon>edit</mat-icon> Asigne un valor</mat-label>
                    <input matInput #input6 type="number"> 
                </mat-form-field>
              
                <mat-form-field class="no-space-bottom w-100" appearance="outline" class="no-space-bottom w-100" appearance="outline" [hidden]="dataInc.type_id != 5">
                    <mat-label><mat-icon>edit</mat-icon> Asigne un valor</mat-label>
                    <input matInput #input5 > 
                </mat-form-field>

               <mat-form-field class="no-space-bottom w-100" appearance="outline" name="atributo" [hidden]="dataInc.type_id != 3">
                   <mat-label>Asigne un valor</mat-label>
                   <mat-select name="multi" #input3>
                        <ng-container *ngIf="dataInc.type_id == 3">
                            <mat-option  *ngFor="let multi of converToObject(dataInc.select_data)"  [value]="multi">{{multi}}</mat-option>
                        </ng-container>  
                   </mat-select>
               </mat-form-field>
             
               <mat-form-field class="no-space-bottom w-100" appearance="outline"  name="atributo" [hidden]="dataInc.type_id != 1">
                <mat-label>Selecciona una opción</mat-label>
                <mat-select name="multi" #input1>
                    <mat-option [value]="'SI'">SI</mat-option>
                    <mat-option [value]="'NO'">NO</mat-option>
                </mat-select>
                </mat-form-field>

                <mat-form-field class="no-space-bottom w-100" appearance="outline" name="atributo" [hidden]="dataInc.type_id != 2">
                    <mat-label>Selecciona una opción</mat-label>
                    <input name="pickerInput" matInput [matDatepicker]="picker2" #input2 placeholder="DD / MM / YYYY" >
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker [startAt]="startDate" #picker2></mat-datepicker>
                </mat-form-field>

            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button *ngIf="dataInc.type_id == 1" type="button" class="btn btn-sm btn-success" (click)="save( input1.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
    <button *ngIf="dataInc.type_id == 2" type="button" class="btn btn-sm btn-success" (click)="save( input2.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
    <button *ngIf="dataInc.type_id == 3" type="button" class="btn btn-sm btn-success" (click)="save( input3.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
    <button *ngIf="dataInc.type_id == 4"  type="button" class="btn btn-sm btn-success" (click)="save( input4.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
    <button *ngIf="dataInc.type_id == 5" type="button" class="btn btn-sm btn-success" (click)="save( input5.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
    <button *ngIf="dataInc.type_id == 6" type="button" class="btn btn-sm btn-success" (click)="save( input6.value)">Guardar <mat-icon class="align-middle">save</mat-icon></button>
</div>
<div class="content-wrapped">

    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="me-2 align-middle">auto_awesome_motion</mat-icon>SKU's 
        </h4>
        
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">                        
                        <div class="col-md-12">
                            <div class="overflow-auto w-100">
                                <div class="float-end">
                                    <button mat-raised-button color="primary" class="mt-2 mb-2 ms-4" (click)="openSku({})">
                                       Nuevo SKU <mat-icon>add</mat-icon>
                                    </button> 
                                </div>
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event)" placeholder="Ej. 1101X3805D06205" #input>
                                </mat-form-field>
                            </div>
                        
                            <div class="mat-elevation-z8 table-responsive">
                                
                                <table mat-table [dataSource]="dataSource" matSort class="w-100">

                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sku">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>SKU</th>
                                        <td mat-cell *matCellDef="let row"> {{row.sku}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="nombre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
                                        <td mat-cell *matCellDef="let row"> {{row.nombre?.length > 40 ? row.nombre.substring(0, 37) + "..." : row.nombre}}
                                    </ng-container>

                                    <ng-container matColumnDef="descripcion">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Descripción</th>
                                        <td mat-cell *matCellDef="let row"> {{row.descripcion?.length > 40 ? row.descripcion.substring(0, 37) + "..." : row.descripcion}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="medidax">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Medida X</th>
                                        <td mat-cell *matCellDef="let row"> {{row.medida_x}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="mediday">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Medida Y</th>
                                        <td mat-cell *matCellDef="let row"> {{row.medida_y}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="imagen">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>PDF</th>
                                        <td mat-cell *matCellDef="let row"> 
                                            <button *ngIf="row.archivo != null" mat-icon-button matTooltip="Ver" [matTooltipPosition]="'above'" (click)="verSku(row.archivo)">
                                                <mat-icon>image</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                  
                                    <ng-container matColumnDef="actions">
                                      <th mat-header-cell *matHeaderCellDef>Acciones</th>
                                      <td mat-cell *matCellDef="let row" class="text-center">
                                        <div class="row">
                                            <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent" (click)="openSku(row)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Baja" [matTooltipPosition]="'above'" color="error" (click)="bajaSku(row.id)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>

                                    </td>
                                    </ng-container>
                                  
                                 
                                    <tr mat-header-row *matHeaderRowDef="displayedCols"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedCols"></tr>
                                    
                                  </table>
                        
                                <mat-paginator  [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { StrategyMaterialModel } from 'src/app/models/strategy.model';
import { CampaignsService } from 'src/app/services/campaigns.service';

@Component({
  selector: 'app-distribution-material-list',
  templateUrl: './distribution-material-list.component.html',
  styleUrls: ['./distribution-material-list.component.scss']
})
export class DistributionMaterialListComponent implements OnInit {

  materialList = new Array<StrategyMaterialModel>();
  isLoading = true;

  displayedColumns: string[] = ['id', 'name', 'quantity'];
  dataSource!: MatTableDataSource<StrategyMaterialModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campS: CampaignsService
  ) { }

  ngOnInit(): void {    
    this.getStrategyMaterialList(this.data.strategy.id);
  }

  getStrategyMaterialList(strID: number) {
    this.campS.getStrategyMaterialList(strID).subscribe(
      (result : any) => {
        this.materialList = result.data;
        this.dataSource = new MatTableDataSource(this.materialList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );    
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTotal() {
    return this.materialList.map(m => m.cant).reduce((acc, value) => acc + value, 0);
  }

}

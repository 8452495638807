<div class="modal-header">
    <h4 class="modal-title"><strong>Estrategia - {{ data.strategy.name }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <mat-divider></mat-divider>
        <div class="row mt-3 borderMdl">
            <div class="col-md-12 mb-3 mt-3">
                <div class="stepper-wrapper">
                    <div class="stepper-item-art">
                        <div class="step-primary bg-blue"><strong>1</strong></div>
                        <div class="step-name"><strong>Artes</strong></div>
                    </div>
                    <div class="stepper-item-art">
                        <div class="step-primary" [ngClass]="flagArtesPreproyecto ? 'bg-gray2' : 'bg-blue' "><strong>2</strong></div>
                        <div class="step-name"><strong>Preproyecto</strong></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row borderMdl"> 
            <div class="col mb-3 mt-2">

                <!-- ARTES -->
                <div *ngIf="flagArtesPreproyecto" id="divArtes">

                            
                            <div *ngFor="let arte of artes; let i = index">
                                <div class="card-body">

                                <div class="row">
                                    
                                    <div class="col-md-3"></div>
                                    <div class="col-md-3">
                                        <mat-form-field appearance="outline" class="custom-input">
                                            <mat-label>SKU</mat-label>
                                            <input matInput [(ngModel)]="arte.sku" readonly>
                                        </mat-form-field>                                
                                    </div>
    
                                    <div class="col-md-3 d-flex align-items-center">
                                        <label [ngClass]="'text-success d-flex align-items-center'">
                                            <div *ngIf="arte.bitValido == 1">
                                                <label [ngClass]="'text-success d-flex align-items-center ms-2'">
                                                    <mat-icon>check_circle</mat-icon>
                                                    <strong class="ms-1">Válido</strong>
                                                </label>
                                            </div>
                                            <div *ngIf="arte.bitValido == 0">
                                                <label [ngClass]="'text-danger d-flex align-items-center ms-2'">
                                                    <mat-icon>error</mat-icon>
                                                    <strong class="ms-1">Error</strong>
                                                </label>
                                            </div>
                                        </label>
                                    </div>
                                   
    
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-3">
                                        <label><strong>{{ arte.nombre_archivo }}</strong></label>
                                        <div class="pdf-container"><iframe id="pdf" name="pdf" width="95%" height="200px" [src]="getSourceURL(arte.fileUrlArte)"></iframe></div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <button mat-flat-button color="primary" class="mt-2" matTooltip="Subir un nuevo archivo" matTooltipPosition="below" (click)="openDialogArtUploadFile(arte.sku,arte.id_sku,arte.medida_x,arte.medida_y)"> 
                                                    <mat-icon>upload_file</mat-icon>   
                                                </button>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="text-end">
                                                    <a mat-flat-button class="mt-2 bg-info text-white" matTooltip="Ver archivo" matTooltipPosition="below" href="{{arte.archivo}}" target="_blank"> 
                                                        <mat-icon>visibility</mat-icon>   
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
            
                                    </div>
                                    <div class="col-md-3">
                                        <div class="row">
                                            <mat-form-field appearance="outline" class="custom-input">
                                                <mat-label>Nombre Op.</mat-label>
                                                <mat-select [(ngModel)]="arte.material_id" (ngModelChange)="checkChanges(arte)">
                                                    <mat-option *ngFor="let material of materiales" [value]="material.id">
                                                        {{material.nombre}}
                                                    </mat-option>
                                                    
                                                </mat-select>

                                            </mat-form-field>
                                        </div>

                                        <div class="row mb-3 mt-1">
                                            <mat-slide-toggle class="custom-toggle" [(ngModel)]="arte.bitEnviaAccesorios" [checked]="mostrarAccesorios[i]" (change)="onToggleChangeAcc($event, i)" (ngModelChange)="checkChanges(arte)">Enviar accesorios</mat-slide-toggle>
                                        </div>

                                        <div class="row" *ngIf="mostrarAccesorios[i]">
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="custom-input">
                                                    <mat-label>Accesorio 1</mat-label>
                                                    <input matInput [(ngModel)]="arte.accesorio1" readonly>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="custom-input">
                                                    <mat-label>Mult. Acc. 1</mat-label>
                                                    <input matInput [(ngModel)]="arte.mult_accesorio1" readonly>
                                                </mat-form-field> 
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="mostrarAccesorios[i]">
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="custom-input">
                                                    <mat-label>Accesorio 2</mat-label>
                                                    <input matInput [(ngModel)]="arte.accesorio2" readonly>
                                                </mat-form-field> 
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="custom-input">
                                                    <mat-label>Mult. Acc. 2</mat-label>
                                                    <input matInput [(ngModel)]="arte.mult_accesorio2" readonly>
                                                </mat-form-field>     
                                            </div>
                                        </div>

                                        <div class="row">
                                            <mat-form-field appearance="outline" class="custom-input">
                                                <mat-label>Nombre Layout</mat-label>
                                                <input matInput  [(ngModel)]="arte.nombre_layout" (ngModelChange)="checkChanges(arte)">
                                            </mat-form-field> 
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="custom-input">
                                                    <mat-label>Layout</mat-label>
                                                    <mat-select [(ngModel)]="arte.layout" (ngModelChange)="checkChanges(arte)">
                                                        <mat-option value="1x1">1x1</mat-option>
                                                        <mat-option value="2x1">2x1</mat-option>
                                                        <mat-option value="3x1">3x1</mat-option>
                                                        <mat-option value="4x1">4x1</mat-option>
                                                        <mat-option value="1x2">1x2</mat-option>
                                                        <mat-option value="Banner">Banner</mat-option>
                                                        <mat-option value="2x2">2x2</mat-option>
                                                        <mat-option value="3x2">3x2</mat-option>
                                                        <mat-option value="4x2">4x2</mat-option>
                                                        <mat-option value="1x4">1x4</mat-option>
                                                    </mat-select>
                                                </mat-form-field> 
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field appearance="outline" class="custom-input">
                                                    <mat-label>FYV</mat-label>
                                                    <mat-select [(ngModel)]="arte.fyv" (ngModelChange)="checkChanges(arte)">
                                                        <mat-option value="Si">Si</mat-option>
                                                        <mat-option value="No">No</mat-option>
                                                    </mat-select>
                                                </mat-form-field> 
                                            </div>
                                        </div>                                             
                                    </div>
                                    <div *ngIf="arte.file_respuesta_report != null" [ngClass]="arte.bitValido === 0 ? 'col-md-6' : 'col-md-3'">
                                        <label><strong>Reporte</strong></label>
                                        <div class="pdf-container"><iframe id="pdf" name="pdf" width="95%" height="200px" [src]="getSourceURL(arte.fileUrlReport)"></iframe></div>
                                        <div class="text-end">
                                            <a mat-flat-button class="mt-2 bg-info text-white" matTooltip="Ver reporte" matTooltipPosition="below" href="{{arte.file_respuesta_report}}" target="_blank"> 
                                                <mat-icon>visibility</mat-icon>   
                                            </a>
                                        </div>
                                    </div>
                                    <div *ngIf="arte.bitValido == 1" [ngClass]="arte.file_respuesta_report != null ? 'col-md-3' : 'col-md-6'">
                                            <label><strong>Archivo Validado</strong></label>
                                            <div class="pdf-container"><iframe id="pdf" name="pdf" width="95%" height="200px" [src]="getSourceURL(arte.fileUrl)"></iframe></div>
                                            <div class="text-end">
                                                <a mat-flat-button class="mt-2 bg-info text-white" matTooltip="Ver archivo" matTooltipPosition="below" href="{{arte.file_respuesta}}" target="_blank"> 
                                                    <mat-icon>visibility</mat-icon>   
                                                </a>
                                            </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </div>
                </div>

               
        
                <!--PREPROYECTO-->
                <div *ngIf="!flagArtesPreproyecto" id="divPreproyecto">
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Folio</mat-label>
                                        <input matInput [(ngModel)]="strategy.invoice" readonly>
                                    </mat-form-field>
                                    
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Estrategia</mat-label>
                                        <input matInput [(ngModel)]="strategy.name" readonly>
                                    </mat-form-field>
                                    
                                </div>
                            </div>
                            <div class="row mt-1">
                                
                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Impresión</mat-label>
                                        <mat-select [(ngModel)]="dataPreproject.impresion">
                                            <mat-option *ngFor="let p of tiposImpresion" [value]="p">{{p}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    
                                </div>

                                <div class="col-md-3">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Color</mat-label>
                                        <mat-select [(ngModel)]="dataPreproject.color">
                                            <mat-option value="CMYK">CMYK</mat-option>
                                            <mat-option value="RGB">RGB</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    
                                </div>

                                <div class="col-md-3">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Envío</mat-label>
                                        <mat-select [(ngModel)]="dataPreproject.envio">
                                            <mat-option *ngFor="let e of tiposEnvio" [value]="e">{{e}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>

                            <div class="row mt-1">

                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Tipo</mat-label>
                                        <input matInput [(ngModel)]="dataPreproject.tipo">
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Área Responsable</mat-label>
                                        <input matInput [(ngModel)]="dataPreproject.area_responsable">
                                    </mat-form-field>
                                </div>
                               
                                
                               
                            </div>
                           
                        </div>

                        <div class="col-md-6">
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Contacto</mat-label>
                                        <input matInput [(ngModel)]="dataPreproject.contacto">
                                    </mat-form-field>
                                    
                                </div>
                                <div class="col-md-6 d-flex align-items-center">
                                    <button mat-flat-button class="bg-primary text-white fw-bold d-flex align-items-center justify-content-center w-100" (click)="savePreproyecto(dataPreproject)">Generar Preproyecto <mat-icon class="ms-1">output</mat-icon>
                                    </button>
                                </div>
                                
                            </div>

                            <div class="row mt-1">

                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Recepción</mat-label>
                                        <mat-select [(ngModel)]="dataPreproject.recepcion">
                                            <mat-option *ngFor="let r of tiposRecepcion" [value]="r">{{r}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                
                                <div *ngIf="dataPreproject.archivo_layout" class="col-md-3 d-flex align-items-center">
                                    <a mat-flat-button class="bg-info text-white fw-bold d-flex align-items-center justify-content-center w-100" href="{{dataPreproject.archivo_layout}}" target="_blank">Layout <mat-icon class="ms-1">visibility</mat-icon>
                                    </a>
                                </div>
                                <div *ngIf="dataPreproject.archivo_odt" class="col-md-3 d-flex align-items-center">
                                    <a mat-flat-button class="bg-success text-white fw-bold d-flex align-items-center justify-content-center w-100" href="{{dataPreproject.archivo_odt}}" target="_blank">ODT <mat-icon class="ms-1">visibility</mat-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="row mt-1">
                                

                                <div class="col-md-6">
                                    <mat-form-field appearance="outline" class="custom-input">
                                        <mat-label>Fechas</mat-label>
                                        <mat-date-range-input [rangePicker]="picker">
                                          <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="dataPreproject.fecha_inicio">
                                          <input matEndDate placeholder="Fecha Fin" [(ngModel)]="dataPreproject.fecha_fin">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                      </mat-form-field>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                   
                    
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer mt-3 ms-5 me-5" [ngClass]="{'d-flex justify-content-between': !flagArtesPreproyecto}">
        <button *ngIf="!flagArtesPreproyecto" mat-flat-button class="bg-secondary text-white fw-bold d-flex align-items-center" [disabled]="flagArtesPreproyecto" [style.cursor]="flagArtesPreproyecto ? 'not-allowed' : 'pointer'" (click)="switchArtesPreproyecto(artes,dataPreproject,0)">
            <mat-icon class="me-1">arrow_back</mat-icon>
            <label [style.cursor]="flagArtesPreproyecto ? 'not-allowed' : 'pointer'">Atras</label>
        </button>
        <div *ngIf="!flagArtesPreproyecto">
            <button *ngIf="data.strategy.bitPreproyecto != 1" mat-flat-button class="bg-success text-white fw-bold d-flex align-items-center" (click)="cargaVoBoCliente()">
                <label style="cursor: pointer !important">Cargar para VoBo Cliente</label>
                <mat-icon class="ms-1">upload_file</mat-icon>
            </button>
        </div>
        <div *ngIf="flagArtesPreproyecto">
            <button mat-flat-button class="bg-primary text-white fw-bold d-flex align-items-center" (click)="switchArtesPreproyecto(artes,dataPreproject,1)">
                <label style="cursor: pointer !important">Siguiente</label>
                <mat-icon class="ms-1">arrow_forward</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-content>
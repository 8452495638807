import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SurveysService } from 'src/app/services/surveys.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

interface Product {
  nombre: string;
  alerted?: boolean;
  comentario?: string;
  ticket_t?:any;
}

@Component({
  selector: 'app-tickets-generate-extra',
  templateUrl: './tickets-generate-extra.component.html',
  styleUrls: ['./tickets-generate-extra.component.scss']
})
export class TicketsGenerateExtraComponent implements OnInit {

  distributionSelected:any;
  ticketTypes : any = [];
  displayedColumns: string[] = ['nombre','selector', 'actions'];
  dataSource:any;
  isLoading:boolean = true;
  selected:any;
  isClaw:boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private dialogRef: MatDialogRef<TicketsGenerateExtraComponent>,
    private surveyS : SurveysService,
  ) {
    this.data.row.id_campana_nest ? this.isClaw = true : this.isClaw = false;
  }

  ngOnInit(): void {
    this.distributionSelected = this.data.row;
    console.log(this.data.row);
    this.getTicketsTypes();
    this.getBoxData();
  }

  getBoxData(){
    this.surveyS.getMaterialsByBox(this.data.row.id_campana,  this.data.row.id_campana_nest ? this.data.row.id : this.data.row.id_sucursal, this.data.row.id_campana_nest ? "claw" : "nest" ).subscribe((result:any)=>{
      this.dataSource = result.data.materiales;
      this.isLoading = false;
    })
  }

  onSubmit() {
    const selectedProducts = this.dataSource.filter((product:Product) => product.alerted);
    const haveTicket = selectedProducts.every((product: any) => product.ticket_t != null);
    if(!haveTicket){
      Swal.fire({
        icon: 'info',
        title: 'Selecciona tipo de ticket',
        text: 'Por favor selecciona el tipo de ticket.'
      });
      return;
    }
    selectedProducts.forEach((product:any) => {
      product.comentario = 'Extraordinario';
      product.ticket_type = product.ticket_t.id;
    });
    this.generateTicket(selectedProducts);
  }

  onSelectionChange(row: Product) {
    row.ticket_t = null
    this.selected = this.dataSource.filter((product:Product) => product.alerted);
  }

  setTicketType(row: Product, type: any) {
    if (row.alerted) {
      row.ticket_t = type;
    }
  }

  setTicketTypeForAll(type: string) {
    this.dataSource.forEach((product:Product) => {
      if (product.alerted) {
        product.ticket_t = type;
      }
    });
  }

  generateTicket (extra_tickets:any){
    let body =  {
        payload : extra_tickets,
        id_campana_claw : this.distributionSelected.id_campana,
        id_sucursal: this.distributionSelected.id_sucursal,
        id_distribucion : this.distributionSelected.id,
        nombre : "Extraordinario",
        puesto : "Usuario",
        isClaw: this.isClaw
    }
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.surveyS.answerExtraSurvey(body).subscribe((result:any)=>{
      if(result.message = 'Success'){
        Swal.fire({
          icon: 'success',
          title: `Ticket${extra_tickets.length > 1 ?"'s":""} enviado${extra_tickets.length > 1 ?"s" : ""}.`,
          html: 'La informacion se guardo de manera correcta!'
        }).then(() => {
          this.dialogRef.close({ new_ticket: true, isClaw:this.isClaw });
        });
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error al enviar',
          text: 'Ocurrió un error al enviar la información, por favor vuelve a intentarlo.'
        });
      }
    },
    (err:any) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al enviar la encuesta',
        text: 'Ocurrió un error al enviar la encuesta, por favor vuelve a intentarlo.'
      });
    })
  }

  getTicketsTypes(){
    this.ticketTypes = environment.tipos_ticket;
  }

  filterValidTickets(){
    return this.ticketTypes.filter((x:any)=>x.id != 7);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { GroupsCreateFromListComponent } from '../../shared/dialogs/groups-create-from-list/groups-create-from-list.component';
import { GroupsCreateFromExcelComponent } from '../../shared/dialogs/groups-create-from-excel/groups-create-from-excel.component';

interface GroupModel {
  id?: number;
  name?: string;
  creation_time?: string;
  creator?: string;
  rule?: string;
}

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})

export class GroupsComponent implements OnInit {

  isLoading = false;
  displayedColumns: string[] = ['id', 'group_name', 'creation_time', 'creator', 'rule'];
  dataSource!: MatTableDataSource<GroupModel>;
  demoData = [
    {
      id: 1,
      group_name: 'Sucursales plus + 1 Caja',
      creation_time: '27/12/2023',
      creator: 'Enrique Soto',
      rule: 'Tipo Sucursal = Plus + 1 caja'
    },
    {
      id: 2,
      group_name: 'Sucursales plus + 2 Cajas',
      creation_time: '27/12/2023',
      creator: 'Adrian sanchez',
      rule: 'Tipo Sucursal = Plus + 2 cajas'
    },
    {
      id: 3,
      group_name: 'Sucursales plus + 3 Cajas',
      creation_time: '27/12/2023',
      creator: 'Enrique Soto',
      rule: 'Tipo Sucursal = Plus + 3 cajas'
    },
    {
      id: 4,
      group_name: 'Sucursales plus + 4 Cajas',
      creation_time: '27/12/2023',
      creator: 'Enrique Soto',
      rule: 'Tipo Sucursal = Plus + 4 cajas'
    },
    {
      id: 5,
      group_name: 'Sucursales tradicional + 1 Caja',
      creation_time: '27/12/2023',
      creator: 'Enrique Soto',
      rule: 'Tipo Sucursal = Tradicional + 1 caja'
    },
    {
      id: 6,
      group_name: 'Sucursales tradicional + 2 Cajas',
      creation_time: '27/12/2023',
      creator: 'Enrique Soto',
      rule: 'Tipo Sucursal = Tradicional + 2 cajas'
    },
    {
      id: 7,
      group_name: 'Sucursales derma + 3 Cajas',
      creation_time: '27/12/2023',
      creator: 'Adrian sanchez',
      rule: 'Tipo Sucursal = Derma + 3 cajas'
    },
    {
      id: 8,
      group_name: 'Sucursales derma + 4 Cajas',
      creation_time: '27/12/2023',
      creator: 'Adrian sanchez',
      rule: 'Tipo Sucursal = Derma - 1 caja'
    },
    {
      id: 9,
      group_name: 'Sucursales derma + 4 Cajas',
      creation_time: '27/12/2023',
      creator: 'Enrique Soto',
      rule: 'Tipo Sucursal = Derma + 4 cajas'
    },
    {
      id: 10,
      group_name: 'Sucursales derma + 5 Cajas',
      creation_time: '27/12/2023',
      creator: 'Adrian sanchez',
      rule: 'Tipo Sucursal = Derma + 5 cajas'
    },
  ];
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(
    private matDialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource<GroupModel>(this.demoData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;   
   }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogCreateGroupFromList() {
    this.matDialog.open(GroupsCreateFromListComponent, {panelClass: 'dialogs-main'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = false;
          // this.isLoading = true;     TODO:borrar esto
      }
    );
  }

  openDialogCreateGroupFromExcel() {
    this.matDialog.open(GroupsCreateFromExcelComponent, {panelClass: 'dialogs-sm'}).afterClosed().subscribe(
      resp => {        
        this.isLoading = false;
        // this.isLoading = true;     TODO:borrar esto
      }
    );
  }

}

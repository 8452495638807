<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{ isEdit ? 'Editar' : 'Nueva'}} estrategia</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Ingresa la siguiente información:</h6>
    <form #f="ngForm">
        <div class="row">
            <div class="col mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre de estrategia</mat-label>
                    <input matInput placeholder="Ej: Unibanners" name="campaign" [(ngModel)]="newStrategy.name" required>
                    <mat-icon matSuffix>category</mat-icon>
                </mat-form-field>
                <mat-form-field *ngIf="isEdit&&isBuhoUsr" class="w-100" appearance="outline">
                    <mat-label>Folio</mat-label>
                    <input matInput placeholder="Ej: 2341" name="invoice" [(ngModel)]="newStrategy.invoice">
                    <mat-icon matSuffix>category</mat-icon>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="saveStrategy()">Guardar <mat-icon>save</mat-icon></button>
</div>
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-materials-add-to-distribution',
  templateUrl: './materials-add-to-distribution.component.html',
  styleUrls: ['./materials-add-to-distribution.component.scss']
})
export class MaterialsAddToDistributionComponent implements OnInit {

  groupForm!: FormGroup;


  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(){
    console.log('test')
  }


}

<div class="content-wrapped">
  <div class="container-xxl flex-grow-1 main-title-container">
    <h4 class="fw-bold pt-3 text-primary">
      <mat-icon class="align-middle head-icon">extension</mat-icon>
    </h4>
    <div class="title-container">
      <small>OCTUBRE 2024</small>
      <h4>POP NACIONAL OCTUBRE 2024</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-4">
        <div class="heading-container">
          <h3 class="card-header">
            <mat-icon>perm_media</mat-icon> Medios
          </h3>
          <div class="button-container">
            <button mat-raised-button class="nest-button" (click)="newMedia()">
              <mat-icon>add_circle</mat-icon><span class="nb-text">Nuevo medio</span>
            </button>
          </div>
        </div>
        <!-- <div class="card-body" *ngIf="(projectListData.progress | json) != '[]'"> -->
        <div class="card-body">
          <div class="table-container">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre Medio</th>
                  <th>Múltiples<br>elementos<br>por sucursal</th>
                  <th>Sucursales vacías</th>
                  <th>Segmentable</th>
                  <th>Formato Distribución</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody class="table-border-bottom-0">
                <tr *ngFor="let x of projectMedia, let i = index" (click)="openMediaPOP(x.id)">
                  <td>{{ i+1 }}</td>
                  <td><b>{{ x.name }}</b></td>
                  <td><small>{{ x.allowMultipleItems ? "SÍ" : "NO" }}</small></td>
                  <td><small>{{ x.allowEmptyBranches ? "SÍ" : "NO" }}</small></td>
                  <td><small>{{ x.segmentable ? "SÍ" : "NO" }}</small></td>
                  <td><small>{{ x.formato }}</small></td>
                  <td>
                  <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent">
                      <mat-icon matBadgeColor="warn">edit</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="Eliminar" [matTooltipPosition]="'above'" color="accent">
                      <mat-icon>delete</mat-icon>
                  </button>    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="card">
  <div class="card-body">
    <div class="title-container">
      <small>PROYECTO {{ projectID }} <span class="arrow">></span> MEDIOS</small>
      <h3>Lorem Ipsum</h3>
    </div>
    <div class="content-container">
      <div class="button-container">
        <button mat-raised-button class="nest-button" (click)="newMedia()">
          <mat-icon>add_circle</mat-icon><span class="nb-text">Nuevo Medio</span>
        </button>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre Medio</th>
            <th>Múltiples<br>elementos<br>por sucursal</th>
            <th>Sucursales vacías</th>
            <th>Segmentable</th>
          </tr>
        </thead>
        <tbody class="table-border-bottom-0">
          <tr *ngFor="let x of projectMedia, let i = index" (click)="openMediaPOP(i+1)">
            <td>{{ i+1 }}</td>
            <td><b>{{ x.name }}</b></td>
            <td><small>{{ x.allowMultipleItems ? "SÍ" : "NO" }}</small></td>
            <td><small>{{ x.allowEmptyBranches ? "SÍ" : "NO" }}</small></td>
            <td><small>{{ x.segmentable ? "SÍ" : "NO" }}</small></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div> -->
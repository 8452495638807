import { Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { MainService } from 'src/app/services/main.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-stores-attributes-edit',
  templateUrl: './stores-attributes-edit.component.html',
  styleUrls: ['./stores-attributes-edit.component.scss']
})
export class StoresAttributesEditComponent implements OnInit {


  dataRow : any;
  attributes : any;
  types = [{ type_id : 1, name : "SI/NO"}, { type_id : 2, name : "FECHA"}, { type_id : 3, name : "OPCIONES"}, { type_id : 6, name : "NÚMERO"}, { type_id : 4, name : "RANGO"}, { type_id : 5, name : "TEXTO"}];
  minDate : Date = new Date();
  startDate : Date = new Date();
  relation : any;
  numberAtributes : number = 0;
  validationsMessages : string = "";
  errorsMessages : string = "";

  validationSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  validationSubscription: Subscription | undefined;

  saveSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  saveSubscription: Subscription | undefined;


  constructor(@Inject(MAT_DIALOG_DATA) public data : any,
    private dialogRef: MatDialogRef<StoresAttributesEditComponent>,
    private main : MainService,
    private storeS: StoreService,) { 
      this.attributes = this.data.attributes;
      this.dataRow = this.data.data;
      this.relation = this.data.relation;
      this.numberAtributes = this.attributes.filter((x:any)=> x.editable == 1).length;

      //subscripción para la validación
      this.validationSubscription = this.validationSubject.subscribe((valor: number) => {
        if (valor == this.numberAtributes) {
            console.log("validación done")
            if(this.validationsMessages.length == 0){
              this.saveAll();
            }else{
              Swal.fire({
                icon: 'warning',
                title: 'Revise los atributos con alertas antes de guardar' ,
                text: this.validationsMessages
              })
            }
            this.validationSubject.next(0);
        }
      });

      //subscripción para el guardado
       this.saveSubscription = this.saveSubject.subscribe((valor: number) => {
         if (valor == this.numberAtributes) {
          this.main.HideLoading();
             console.log("save done")
               Swal.fire({
                 icon: this.errorsMessages.length == 0? 'success':'warning',
                 title: 'Proceso terminado' ,
                 text: this.errorsMessages.length == 0? 'Sin errores al guardar' : this.errorsMessages
               }).then(() => {            
                this.dialogRef.close({ reload: true });
              });
             this.saveSubject.next(0);
         }
       });
    }

  ngOnInit(): void {
    console.log(this.attributes, this.dataRow, this.relation);
  }

  
  obtenerFechaFormateada() {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(this.startDate, 'dd/MM/yyyy');
  }

  save(id : any, value: any,  date : any):void{
    console.log(id, this.dataRow.id,  value, date);
    if(this.validateMinMax(id, value)){
    if(this.validateUpcommingValue(id)){
    if(value != ""){
      this.main.ShowLoading();
      let tempDATE = date.length != 0 ? this.main.convertToDate(date) : new Date();
      let newSTDATE = ""
      newSTDATE = tempDATE.toISOString().slice(0, 10);
      console.log(newSTDATE);
      this.storeS.newValue({ atributo_id : id, sucursal_id : this.dataRow.id, new_value : value , new_value_date : newSTDATE}).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              text: this.attributes.filter((x:any)=> x.id == id )[0].name + ': Valor de atributo programado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
         (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: this.attributes.filter((x:any)=> x.id == id )[0].name + ': Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
  }
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Ya hay un cambio en cola',
        text: this.attributes.filter((x:any)=> x.id == id )[0].name + ': Ya existe un cambio en cola para este atributo'
      });
     }
  }
  }


  savePart(id : any, value: any,  date : any):void{
    if(value != null && value.length > 0 ){
     
        let currentIndex = this.attributes.filter((x:any)=> x.editable == 1).findIndex((x:any)=> x.id == id);
        console.log("saving", currentIndex);


        let tempDATE = date.length != 0 ? this.main.convertToDate(date) : new Date();
        let newSTDATE = ""
        newSTDATE = tempDATE.toISOString().slice(0, 10);
        console.log(newSTDATE);
        this.storeS.newValue({ atributo_id : id, sucursal_id : this.dataRow.id, new_value : value , new_value_date : newSTDATE}).subscribe(
          (result:any) => {
            if(result.message == "Success") {
            }
            this.saveSubject.next(this.saveSubject.value +  1)
          },
           (err:any) => {
            this.saveSubject.next(this.saveSubject.value +  1)
            console.log(err);
            this.errorsMessages = this.errorsMessages + this.attributes.filter((x:any)=> x.id == id )[0].name + ': Ocurrió un error al guardar este registro  ';
          }
        );

      }else{ 
        this.saveSubject.next(this.saveSubject.value +  1);
      }
    
  }

  comprobateCount :number = 0;
  saveCount :number = 0;

  comprobate(id : any, value: any,  date : any):void{
    if(value != null && value.length > 0 ){
      if(this.validateMinMaxSilent(id, value)){
        if(this.validateUpcommingValue(id)){
      }else{
        this.validationsMessages = this.validationsMessages + this.attributes.filter((x:any)=> x.id == id )[0].name + ': Ya existe un cambio en cola para este atributo  '
         }
      }
    }
    this.validationSubject.next(this.validationSubject.value +  1)
  }

  comprobateAll(){
    this.validationsMessages = "";
    const elements: NodeListOf<Element> = document.querySelectorAll('.ComprobateAttr');
    elements.forEach(elemento => {
      elemento.dispatchEvent(new Event('click'));
    });
  }


  saveAll(){
    this.main.ShowLoading();
    this.errorsMessages  = "";
     const elements: NodeListOf<Element> = document.querySelectorAll('.btnSaveAttr');
     elements.forEach(elemento => {
       elemento.dispatchEvent(new Event('click'));
     });
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  validateUpcommingValue(atributo_id : number) : boolean{
    return this.relation.filter((x:any)=> x.atributo_id == atributo_id && x.new_value_date != null).length == 0;
  }

  getRanges(rangeSelect : string){
    return rangeSelect.split("-");
  }

  validateMinMax(id: number, value : string){
    if(this.attributes.filter((x:any)=> x.id == id )[0].type_id == 6){
      if(parseInt(value) < 0){
        Swal.fire({
          icon: 'warning',
          title:  this.attributes.filter((x:any)=> x.id == id )[0].name + ': El valor no es válido',
          text: 'Ingrese un valor mayor a 0'
        });
      }
      return parseInt(value) >= 0;
    }

    if(this.attributes.filter((x:any)=> x.id == id )[0].type_id == 4){
     let minmax = this.getRanges(this.attributes.filter((x:any)=> x.id == id )[0].select_data);
     if(parseInt(value) < parseInt(minmax[0]) || parseInt(value)> parseInt(minmax[1])){
        Swal.fire({
          icon: 'warning',
          title:  this.attributes.filter((x:any)=> x.id == id )[0].name + ': El valor se sale del rango establecido',
          text: 'Ingrese un valor de ' + minmax[0] + ' a ' + minmax[1]
        });
        return false;
    }else{
      return true;
    }
     }else{
      return true;
     }
  }

  validateMinMaxSilent(id: number, value : string){
    if(this.attributes.filter((x:any)=> x.id == id )[0].type_id == 6){
        if(parseInt(value) < 0) {
            this.validationsMessages = this.validationsMessages + this.attributes.filter((x:any)=> x.id == id )[0].name + ': El valor no puede ser menor que 0 '
        }
        
        return parseInt(value) >= 0;
    }

    if(this.attributes.filter((x:any)=> x.id == id )[0].type_id == 4){
     let minmax = this.getRanges(this.attributes.filter((x:any)=> x.id == id )[0].select_data);
     if(parseInt(value) < parseInt(minmax[0]) || parseInt(value)> parseInt(minmax[1])){
        this.validationsMessages = this.validationsMessages + this.attributes.filter((x:any)=> x.id == id )[0].name + ': El valor se sale del rango establecido  '
        return false;
    }else{
      return true;
    }
     }else{
      return true;
     }
  }
}

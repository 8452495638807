import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { UserLoginModel } from '../models/user.model';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = environment.API_URL;

  userToken: string = "";

  private http: HttpClient;

  constructor(
    handler: HttpBackend
  ) {
    this.http = new HttpClient(handler);
    this.readToken();
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
  }

  login( user: UserLoginModel ) {
    const authData = {
      ...user
    };
    return this.http.post(
      `${ this.url }/users/login`,
      authData
    ).pipe(
      map( (resp : any) => {
        this.saveToken( resp['tkn'] );
        return resp;
      })
    );
  }

  saveToken( idToken: string ) {
    this.userToken = idToken;
    localStorage.setItem('token', idToken);

    let hoy = new Date();
    hoy.setSeconds( 64800 );

    localStorage.setItem('expires', hoy.getTime().toString() );
  }

  readToken() {
    if ( localStorage.getItem('token') ) {
      this.userToken = localStorage.getItem('token')!;
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  isAuthenticated(): boolean {
    if ( this.userToken.length < 2 ) {
      return false;
    }
    const expire = Number(localStorage.getItem('expires'));
    const expireDate = new Date();
    expireDate.setTime(expire);

    if ( expireDate > new Date() ) {
      return true;
    } else {
      return false;
    }
  }

}

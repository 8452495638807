import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stores-attributes-pending-list-reason',
  templateUrl: './stores-attributes-pending-list-reason.component.html',
  styleUrls: ['./stores-attributes-pending-list-reason.component.scss']
})
export class StoresAttributesPendingListReasonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
 
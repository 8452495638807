export class newStrategyModel {
    name!: string;    
    id_camp! : number;
    invoice! : number;
    id_str! : number;
    folder_camp! : string;
}

export class StrategyMaterialModel {
    id!: number;
    name!: string;
    cant!: number;
}
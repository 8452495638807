import { Component, OnInit,Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-materials-edit',
  templateUrl: './materials-edit.component.html',
  styleUrls: ['./materials-edit.component.scss']
})
export class MaterialsEditComponent implements OnInit {

  isLoading = false;
  groupForm!: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {name: string}) { }

  ngOnInit(): void {
  }

  onSubmit(){
    console.log('test')
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { ProjectModel } from 'src/app/models/project.model';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-providers-view-concepto-file',
  templateUrl: './projects-providers-view-concepto-file.component.html',
  styleUrls: ['./projects-providers-view-concepto-file.component.scss']
})
export class ProjectsProvidersViewConceptoFileComponent implements OnInit {

  bodyForm : FormData = new FormData(); 
  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);  
  project: ProjectModel = new ProjectModel();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main : MainService,
    private fb : FormBuilder,
    private projectService : ProjectsService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ProjectsProvidersViewConceptoFileComponent>,
  ) { 
    this.project = this.data.project;
  }

  ngOnInit(): void {
    this.loadForm();
   // this.preloadFormData();
  }

  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }

  preloadFormData(){
    this.bodyForm.append('folder', this.project.folder as string);      
  }

  onValueChange(files : any)
  { 
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un archivo'
      });
    }

    for (const item of files) {
      console.log(item.name, item.name.endsWith('.zip'));
      if(!(item.name.endsWith('.zip')))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten archivos en formato zip'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
      else {

      }
    }
  }

  uploadFile(){
    if(this.form.value.blob != null && this.form.value.blob?.length!=0){

      this.bodyForm.append("blob", this.form.value.blob[0] as File);
      
      this.dialogRef.close({ reload: true, file : this.form.value.blob[0] as File });
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
    }
  }

}

import { Component,Inject,OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { getCampaignsModel, newCampaignModel, CampaignModel, DashboardCampaigns, editCampaignModel } from '../../../../models/campaign.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/services/users.service';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-campaign-add',
  templateUrl: './campaign-add.component.html',
  styleUrls: ['./campaign-add.component.scss']
})
export class CampaignAddComponent implements OnInit {

  constructor(  
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private dialogRef: MatDialogRef<CampaignAddComponent>,
    private userS: UsersService
    ) { 
      this.isEdit = this.data.edit;
      if(this.isEdit){
        this.LoadCampaignData(this.data.data);

      }
      this.isClientUsr = campaignService.isClientUsr();
    }

  isEdit : boolean = false;
  
  newCampaign : any;
  newDateTody : Date = new Date();
  minDate : Date = new Date();
  isClientUsr: boolean = false;
  deadline : Date = new Date();
  visibledeadline : boolean = false;
  
  companiesList : any;
  userObj!: UserModel;

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    if(!this.isEdit){
    this.newCampaign = new newCampaignModel();
    this.newCampaign.name="";
    this.newCampaign.id_compania = 0;
    this.newDateTody.setDate(this.newDateTody.getDate() + 1);
    this.deadline = new Date(this.newDateTody);
    if(this.isClientUsr){
      this.newCampaign.id_compania = this.userObj.companies[0].id_compania;
    }else{
      this.getListCompanies();
    }
    }
  }

  onchangedate(e: Date): void {
    if(!this.isEdit) {
      this.deadline = new Date(e);
      this.deadline.setDate(this.deadline.getDate() - 21);
      this.visibledeadline = true;
    }
  }

  LoadCampaignData(camp : any){
    this.newCampaign = new editCampaignModel();
    this.newCampaign.id_camp = camp.id;
    this.newCampaign.name = camp.name;
    this.newCampaign.deadline = camp.deadline;
    this.newCampaign.active = camp.active;
    this.newCampaign.locked = camp.locked;

    this.newDateTody = new Date(this.newCampaign.deadline);
  }

  saveCampaign(){
    if(!this.isEdit){
      this.createCampaign();
    }else{
      this.editCampaign();
    }
  }

  createCampaign(){
    if(this.newCampaign.name!= "" && this.newCampaign.id_compania!= 0){
      this.main.ShowLoading();
      this.newCampaign.deadline = this.deadline.toISOString().slice(0, 10);
      this.campaignService.newCampaign(this.newCampaign).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Creado',
              text: 'Campaña creada correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
      console.log(this.newCampaign);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  editCampaign(){
    if(this.newCampaign.name!= ""){
      this.main.ShowLoading();
      this.newCampaign.deadline = this.newDateTody.toISOString().slice(0, 10);
      this.campaignService.editCampaign(this.newCampaign).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Editado',
              text: 'Campaña editada correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          if(err.error.message != "No changes detected or no valid campaigns were found in the request") {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
            });
          }
        }
      );
    }else{
      Swal.fire({
        icon: 'error',
        title: 'No hay nombre de campaña',
        text: 'Por favor ingrese un nombre válido'
      });
    }
  }

  getListCompanies(){
    this.userS.getCompanies().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.companiesList = result.data;
          if(this.companiesList.length == 1){
            this.newCampaign.id_compania = this.companiesList[0].id;
          }
        }
      },
      (err:any) => {
        console.log(err);
      }
    );
  }

  hasOnlyCompany() : boolean{
    if(this.companiesList.length == 0 || this.companiesList.length == 1){
      return false;
    }else{
      return true
    }
  }

  getCompanyName(){

  }
}

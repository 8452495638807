import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileValidators } from 'ngx-file-drag-drop';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-upload-file',
  templateUrl: './distribution-upload-file.component.html',
  styleUrls: ['./distribution-upload-file.component.scss']
})
export class DistributionUploadFileComponent implements OnInit {

  bodyForm : FormData = new FormData(); 

  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  campaign : any;
  validated: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private fb : FormBuilder,
    private dialogRef: MatDialogRef<DistributionUploadFileComponent>,
  ) { 
    this.campaign = this.data.campaign;
    this.isClientUsr = this.campaignService.isClientUsr();
  }

  file_validated : boolean = false;
  isClientUsr : boolean = false;

  ngOnInit(): void {
    this.file_validated = false;
    this. preloadFormData();
    this.loadForm();
  }

  preloadFormData(){
    this.bodyForm.append('folder_camp', this.campaign.folder as string);
    this.bodyForm.append('id_camp', this.campaign.id);
    this.bodyForm.append('id_compania', this.campaign.id_compania);
  }

  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }

  onValueChange(files : any)
  { 
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!(item.name.endsWith('.xls') || item.name.endsWith('.xlsx')))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato .XLSX'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
    }
  }

  responsedata : any;

  validateFile(){
  if(this.form.value.blob != null && this.form.value.blob?.length!=0){
      this.bodyForm.append("blob", this.form.value.blob[0] as File);
      this.main.ShowLoading();
      this.campaignService.validateUploadDist(this.bodyForm).subscribe(
        (result:any)=>{
          this.main.HideLoading();
          if(result.message == 'Success'){
            this.file_validated = true;
            this.responsedata = result.data;
            this.validated = true;
          }

        },
        (err:any)=>{
          this.main.HideLoading();
          if(err.error.message == "Invalid file") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'Solo se permiten documentos en formato .XLSX'
            });
      
          }
          else if(err.error.message == "Invalid file data") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'El documento no cumple con el formato establecido.'
            });
      
          } else if(err.error.message == "Missing file data") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'El documento no cumple con el formato establecido: ' + err.error.data
            }); 
      
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al validar, por favor vuelve a intentarlo.'
            });
          }
        }
      )
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
    }
  }

  uploadFile(){
    if(this.form.value.blob != null && this.form.value.blob?.length!=0){
      if(this.isClientUsr) 
        this.bodyForm.append("blob", this.form.value.blob[0] as File);
      
      Swal.fire({
        icon: 'info',
        title: 'Subiendo archivo...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.campaignService.uploadDist(this.bodyForm).subscribe(
        (result:any)=>{
          if(result.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Archivo subido',
              text: 'Archivo subido exitosamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          if(err.error.message == "Invalid file") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'Solo se permiten documentos en formato .XLSX'
            });
      
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        }
      )
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
    }
  }

}

<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Totales</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">

        <h6 class="pb-4">
            <span class="align-middle">Lista de Archivos de Totales</span>
            <div class="float-end align-middle">
                <a *ngIf="totalFiles" href="{{totalFiles[0]?.file}}" target="_blank" mat-stroked-button color="primary">Descarga último archivo <mat-icon class="align-middle ms-1">file_download</mat-icon></a>
                <button mat-flat-button color="primary" class="ms-4"  (click)="openDialogUploadFile()">Subir archivo
                    <mat-icon>upload_file</mat-icon>
                </button>
            </div>
        </h6>        
        <mat-divider></mat-divider>
        <div>
            <div class="row g-2">
                <div class="col mb-0">
                    <div class="table-responsive text-nowrap">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Archivo</th>
                                    <th>Fecha de Registro</th>
                                  
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr *ngFor="let file of totalFiles">
                                    <td>
                                        {{ file.id }}
                                    </td>
                                    <td>
                                        <a href="{{ file.file }}" target="_blank">
                                            {{ file.name }}
                                        </a>
                                    </td>

                                    <td>
                                        {{ file.created_at | date : "dd/MM/yyyy hh:mma": "-0000" }}
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-header">
    <h4 class="modal-title">Respuestas de Formulario de aprobación - {{ nombre_proveedor }}</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <ng-container  *ngFor="let response of filterCurrent()">
            <h6>
                <span> <mat-icon class="align-middle">update</mat-icon> Respuestas del formulario: <strong>{{ response.created_at | date : "dd/MM/yyyy"  }}-  {{ nombre_proveedor }}</strong></span>            
            </h6>
            <div class="container">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Concepto</th>
                                    <th>Respuesta</th>
                                    <th>Comentarios</th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0">
                                <tr>
                                    <td>
                                        Descripción del producto
                                    </td>
                                    <td>
                                        <span *ngIf="response.desc_producto" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                        <span *ngIf="!response.desc_producto" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                    </td>
                                    <td *ngIf="response.desc_producto">
                                        AUTORIZADO
                                    </td>
                                    <td *ngIf="!response.desc_producto">
                                        {{ response.desc_producto_com }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Descripción SKU (revisar números)
                                    </td>
                                    <td>
                                        <span *ngIf="response.sku" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                        <span *ngIf="!response.sku" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                    </td>
                                    <td *ngIf="response.sku">
                                        AUTORIZADO
                                    </td>
                                    <td *ngIf="!response.sku">
                                        {{ response.sku_com }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        VoBo imagen(es)
                                    </td>
                                    <td>
                                        <span *ngIf="response.image_vobo"  class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                        <span *ngIf="!response.image_vobo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                    </td>
                                    <td *ngIf="response.image_vobo">
                                        AUTORIZADO
                                    </td>
                                    <td *ngIf="!response.image_vobo">
                                        {{ response.image_vobo_com }}
                                        <a *ngIf="filterFiles(response.id, 1).length > 0" href="https://drive.google.com/file/d/{{ filterFiles(response.id, 1)[0].archivo }}" target="_blank">Descarga adjuntos aquí <mat-icon class="align-middle">download</mat-icon></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Mecanica promocional
                                    </td>
                                    <td>
                                        <span *ngIf="response.mec_promo" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                        <span *ngIf="!response.mec_promo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                    </td>
                                    <td *ngIf="response.mec_promo">
                                        AUTORIZADO
                                    </td>
                                    <td *ngIf="!response.mec_promo">
                                        {{ response.mec_promo_com }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        VoBo del medio para publicación
                                    </td>
                                    <td>
                                        <span *ngIf="response.pub_vobo" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                        <span *ngIf="!response.pub_vobo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                    </td>
                                    <td *ngIf="response.pub_vobo">
                                        AUTORIZADO
                                    </td>
                                    <td *ngIf="!response.pub_vobo">
                                        {{ response.pub_vobo_com }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Razón social
                                    </td>
                                    <td colspan="2">
                                       {{ response.razon_social }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Empresa recuperadora
                                    </td>
                                    <td colspan="2">
                                        {{ response.empresa_recuperadora }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Concepto (descripción detallada):
                                    </td>
                                    <td colspan="2">
                                       {{ response.concepto }}
                                        <a *ngIf="filterFiles(response.id, 2).length > 0" href="https://drive.google.com/file/d/{{ filterFiles(response.id, 2)[0].archivo }}" target="_blank">Descarga adjuntos aquí <mat-icon class="align-middle">download</mat-icon></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Monto
                                    </td>
                                    <td colspan="2">
                                        <strong>$ {{ response.monto }}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Método de pago
                                    </td>
                                    <td colspan="2">
                                        {{ response.metodo_pago }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>

        <mat-divider></mat-divider>
        <h5 class="mt-3">
            <span> <mat-icon class="align-middle">youtube_searched_for</mat-icon> Historial de Respuestas del formulario</span>
        </h5>
        <div class="container">
            <div class="col-md-12 mb-3">
                <mat-accordion multi>
                    <mat-expansion-panel *ngFor="let response of filterOld()">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <mat-icon class="aling-middle text-muted me-2">event</mat-icon> {{ response.created_at | date : "dd/MM/yyyy"  }} - {{ nombre_proveedor }}
                          </mat-panel-title>
                          <mat-panel-description>
                            <span class="badge bg-label-info">{{  filterFilesCount(response.id) }} archivos adjuntos</span>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Concepto</th>
                                            <th>Respuesta</th>
                                            <th>Comentarios</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-border-bottom-0">
                                        <tr>
                                            <td>
                                                Descripción del producto
                                            </td>
                                            <td>
                                                <span *ngIf="response.desc_producto" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response.desc_producto" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response.desc_producto">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response.desc_producto">
                                                {{ response.desc_producto_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Descripción SKU (revisar números)
                                            </td>
                                            <td>
                                                <span *ngIf="response.sku" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response.sku" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response.sku">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response.sku">
                                                {{ response.sku_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                VoBo imagen(es)
                                            </td>
                                            <td>
                                                <span *ngIf="response.image_vobo"  class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response.image_vobo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response.image_vobo">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response.image_vobo">
                                                {{ response.image_vobo_com }}
                                                <a *ngIf="filterFiles(response.id, 1).length > 0" href="https://drive.google.com/file/d/{{ filterFiles(response.id, 1)[0].archivo }}" target="_blank">Descarga adjuntos aquí <mat-icon class="align-middle">download</mat-icon></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Mecanica promocional
                                            </td>
                                            <td>
                                                <span *ngIf="response.mec_promo" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response.mec_promo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response.mec_promo">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response.mec_promo">
                                                {{ response.mec_promo_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                VoBo del medio para publicación
                                            </td>
                                            <td>
                                                <span *ngIf="response.pub_vobo" class="badge bg-success"><span class="h6 text-white">SI</span></span>
                                                <span *ngIf="!response.pub_vobo" class="badge bg-danger"><span class="h6 text-white">NO</span></span>
                                            </td>
                                            <td *ngIf="response.pub_vobo">
                                                AUTORIZADO
                                            </td>
                                            <td *ngIf="!response.pub_vobo">
                                                {{ response.pub_vobo_com }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Razón social
                                            </td>
                                            <td colspan="2">
                                               {{ response.razon_social }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Empresa recuperadora
                                            </td>
                                            <td colspan="2">
                                                {{ response.empresa_recuperadora }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Concepto (descripción detallada):
                                            </td>
                                            <td colspan="2">
                                                {{ response.concepto }}
                                                <a  *ngIf="filterFiles(response.id, 2).length > 0"  href="https://drive.google.com/file/d/{{ filterFiles(response.id, 2)[0].archivo }}" target="_blank">Descarga adjuntos aquí <mat-icon class="align-middle">download</mat-icon></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Monto
                                            </td>
                                            <td colspan="2">
                                                <strong>$ {{ response.monto }}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Método de pago
                                            </td>
                                            <td colspan="2">
                                                {{ response.metodo_pago }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                      </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</mat-dialog-content>
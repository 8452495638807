<div class="modal-header">
    <h4 *ngIf="!data.edit" class="modal-title" id="mondalAddCampaignTitle">Nuevo corte de campaña</h4>    
    <h4 *ngIf="data.edit" class="modal-title" id="mondalAddCampaignTitle">Editar corte de campaña</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <form>        
        <div class="row">            
            <div class="col mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre de campaña</mat-label>
                    <input  matInput placeholder="Ej: POP AGOSTO 2022" name="campaign"  name="campaign" [(ngModel)]="newCutoff.nombre"   required>
                    <mat-icon matSuffix>campaign</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Fecha límite</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="DD / MM / YYYY" disabled [min]="minDate" [(ngModel)]="newDateTody" name="date"  required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>        
    </form> 
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="save()">Guardar <mat-icon>save</mat-icon></button>
</div>
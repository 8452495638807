<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="me-2 align-middle">group_work</mat-icon>Segmentos
        </h4>
        <div class="row">
            <div class="col-md-12">
                <div class="col-md-12">
                    <div class="card mb-4">
                        <div class="card-body">                        
                            <div class="col-md-12">
                                <div class="overflow-auto w-100">
                                    <div class="float-end">
                                        <button mat-raised-button color="primary" (click)="openDialogCreateGroupFromList()">
                                            Crea segmento desde lista <mat-icon>format_list_bulleted_add</mat-icon>
                                        </button>
                                        <button mat-raised-button color="primary" (click)="openDialogCreateGroupFromExcel()" class="mt-2 mb-2 ms-4">
                                            Crea segmento desde excel <mat-icon>description</mat-icon>
                                        </button>                                    
                                    </div>
                                    <mat-form-field>
                                        <mat-label>
                                            <mat-icon>search</mat-icon> Buscar</mat-label>
                                        <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Sucursales Plus" #input>
                                    </mat-form-field>
                                </div>
                            
                                <div class="mat-elevation-z8 table-responsive">
                                    <table mat-table [dataSource]="dataSource" matSort class="w-100">
                            
                                        <ng-container matColumnDef="id">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Num </th>
                                            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="group_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del segmento </th>
                                            <td mat-cell *matCellDef="let row"> {{row.group_name}} </td>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="creation_time">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                                            <td mat-cell *matCellDef="let row"> {{row.creation_time}} </td>
                                        </ng-container>
                            
                                        <ng-container matColumnDef="creator">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Responsable</th>
                                            <td mat-cell *matCellDef="let row"> {{row.creator}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="rule">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Regla</th>
                                            <td mat-cell *matCellDef="let row"> {{row.rule}} </td>
                                        </ng-container>
                                                        
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        
                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                        </tr>
                                    </table>
                            
                                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                                        <mat-progress-spinner color="primary" mode="indeterminate">
                                        </mat-progress-spinner>
                                    </div>
                                    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
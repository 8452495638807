<nav class="layout-navbar container-fluid navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
    <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
            <mat-icon class="text-white" (click)="menuToggle()">menu</mat-icon>
        </a>
    </div>

    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <div class="navbar-nav align-items-center">
            Portal de Clientes
        </div>
        <ul class="navbar-nav flex-row align-items-center ms-auto">

            <!-- User -->
            <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
                    <div class="avatar avatar-online">
                        <button mat-button [matMenuTriggerFor]="menu" class="p-0"><img src="../../../../assets/img/avatars/user_avatar.png" alt="Usuario" class="w-px-40 h-auto rounded-circle" /></button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item><div class="d-flex">
                                <div class="flex-shrink-0 me-3">
                                    <div class="avatar avatar-online">
                                        <img src="../../../../assets/img/avatars/user_avatar.png" alt="Usuario" class="w-px-40 h-auto rounded-circle align-top" />
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <span class="fw-semibold d-block">{{userObj.name}} <small class="text-muted fw-light">({{roleName}})</small></span>
                                </div>
                            </div></button>
                            <mat-divider></mat-divider>
                            <button *ngIf="!userObj.id_provider_member" mat-menu-item (click)="dialogNotif()">
                                <mat-icon>mail</mat-icon>
                                <span>Activar notificaciones</span>
                            </button>
                            <button mat-menu-item (click)="logOut()">
                                <mat-icon>logout</mat-icon>
                                <span>Salir</span>
                            </button>
                        </mat-menu>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</nav>
 import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
  
  @Component({
    selector: 'app-notif-edit',
    templateUrl: './notif-edit.component.html',
    styleUrls: ['./notif-edit.component.scss']
  })
  export class NotifEditComponent implements OnInit {
  
    rolesList!: {
      id: number,
      rol: string
    }[];
  
    formUser!: FormGroup;
    id_user!: number;
    id_rol!: number;
    notifList : any = [];
    notifUser : any = [];
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data:any,
      private fb: FormBuilder,
      private userS: UsersService,
      private dialogRef: MatDialogRef<NotifEditComponent>,
      private matDialog: MatDialog
    ) {}
  
    ngOnInit(): void {
      this.rolesList = environment.roles;
      this.id_user = this.userS.getUser().id;
      this.id_rol = this.userS.getUser().id_rol;
      this.createFormUser();
      this.setUserForm();
      
    }

    createFormUser() {
      this.formUser = this.fb.group({
        id: ['', [Validators.required]],
        id_rol: ['', [Validators.required]],
        notifications: ['', []]
      });
    }

    getNotif(){
      if(this.id_rol > 1) {
        this.userS.getNotifUserList(this.id_user).subscribe(
          (result:any) => {
            if(result.message == "Success") {
              this.notifList = result.data;
            }
          },
          (err:any) => {
            console.log(err);
          }
        ) 
      }
      else this.notifList = [];
    }
  
    setUserForm() {
      const { active, email, ch_pass, name, role, tel, exp, permissions, ...userFormInf } = this.userS.getUser();
      this.getNotif();
      this.formUser.setValue(userFormInf);
    }
  
    ruSure() {
      Swal.fire({
        title: '¿Estás seguro que deseas editar el registro?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.saveNotif();
        }
      });
    }
  
    saveNotif() {
      Swal.fire({
        icon: 'info',
        title: 'Guardando...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.formUser.controls["notifications"].setValue(this.notifList.filter((n: { id: any, active: any }) => n.active).map((elem: { id: any; }) => elem.id));
      this.userS.editUser(this.formUser.value, this.id_user).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              html: 'Usuario guardado de manera correcta!'
            }).then(() => {            
              this.dialogRef.close({ reload: false });
            });
          }
        },
        (err:any) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
          });
        }
      );
    }

    resetForm() {
      this.notifList = [];
    }
  
  }
  
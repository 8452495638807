import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import {  TicketsGenerateExtraComponent } from '../../shared/dialogs/tickets-generate-extra/tickets-generate-extra.component';
import { ApexChart, ApexNonAxisChartSeries } from 'ng-apexcharts/lib/model/apex-types';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

export interface Metrics {
  total_alertas_pendientes: number;
  total_cajas: number;
  total_cajas_perfectas: number;
  total_encuestas_contestadas: number;
  total_encuestas_pendientes: number;
  total_entregados: number;
  total_extra_tickets: number;
  total_tickets_pendientes: number;
  total_tickets_resueltos: number;
  total_tickets_revision: number;
  tiempo_respuesta: number;
}

export interface TotalReports {
  total_anulacion: number;
  total_faltante: number;
  total_kit_incompleto: number;
  total_material_danado: number;
  total_otro: number;
  total_solicitud_adicional: number;
  promedio_creacion_tickets: number;
  promedio_atencion_buho: number;
  promedio_resolucion_buho: number;
  cant_tickets_resueltos: number;
  cant_cajas_reenviadas: number;
}
@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})
export class TicketListComponent implements OnInit {
  displayedColumns: string[] = ['sucursal','zona', 'alertas', 'st_encuesta', 'st_seguimiento', 'Acciones'];
  displayedColumnsZone: string[] = [
    'zona',
    'total_cajas',
    'total_entregados',
    'total_encuestas_contestadas',
    'total_encuestas_pendientes',
    'total_extra_tickets',
    'total_cajas_perfectas',
    'total_alertas_pendientes',
    'total_tickets_pendientes',
    'total_tickets_revision',
    'total_tickets_resueltos',
    'total_reenviados',
    'total_faltante',
    'total_material_danado',
    'total_kit_incompleto',
    'total_solicitud_adicional',
    'total_otro',
    'total_anulacion'
  ];
  displayedColumnsMaterial: string[] =  [
    "material",
    'total_extra_tickets',
    'total_alertas_pendientes',
    'total_tickets_pendientes',
    'total_tickets_revision',
    'total_tickets_resueltos',
    'total_reenviados',
    'total_faltante',
    'total_material_danado',
    'total_kit_incompleto',
    'total_solicitud_adicional',
    'total_otro',
    'total_anulacion'
  ];


  dataSource!: MatTableDataSource<any>;
  dataSourceZone!: MatTableDataSource<any>;
  dataSourceMaterial!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild("paginator2") paginator2!: MatPaginator;
  @ViewChild("sort2") sort2!: MatSort;

  @ViewChild("paginator3") paginator3!: MatPaginator;
  @ViewChild("sort3") sort3!: MatSort;

  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;

  companias : any = [];
  usrcompany : number = 0;
  campaignsData : any = {};
  zones : any = [];
  expanded_filters : boolean = true;
  metrics!: Metrics;
  loading: boolean = true;

  //---filter group ---
  selectedcompany : any = null;
  selectedzone : any = null;
  selectedCamp : any = 0;
  selectedStsSurv : any = null;
  selectedStsTrack : any = null;
  //---end---
  statusTracking : any = [];
  statusSurv : any = [];
  datatracking : any = [];
  hostName : any = "";
  isClawCamp : boolean = false;
  compania : any = {};

  isback? : boolean = false;


  chartOptions!: {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    labels: string[];
    colors: string[];
    plotOptions: any;
  };

  totalReports!: TotalReports;
  zonesStats: any;
  MaterialStats: any;


  constructor(

    private metricsService: SurveysService,
    private matDialog: MatDialog,
    private _params: ActivatedRoute,
    private _router : Router,
    private surveyS : SurveysService,
    private main : MainService,
    private _titleService: Title,
    private userS: UsersService,
    private campaignService: CampaignsService,
    private changeDetectorRef: ChangeDetectorRef)
    {
      if(_router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString()!= undefined){
        this.isback = _router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().includes("/admin/ticket/");
      }
    }


  ngOnInit(): void {

    this.chartOptions = {
      series: [ 0,   0], // 81% completado, 19% restante
      chart: {
        type: "donut",
        height: 250,  // Ajusta el tamaño
        width: 250
      },
      labels: ['',''],
      colors: ["#28a745", "rgb(191, 187, 187)"], // Verde y rojo
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
            }
        }
      }
    }


    };

    if(this.userS.isBuhoUsr()){
      this.getCompanias();
    }else{
      this.usrcompany = this.userS.getUser().companies[0].id
      this.loadFilters();
      //this.loadCampaigns();
      //this.loadZones();
    }
    this.getStatusTracking();
    this.getStatusSurv();
    //this.loadTickets();
    this.hostName = window.location.protocol + '//' + window.location.host + "/surveys/";
  }

  getStatusCount(id_status : number){
    return this.datatracking? this.datatracking.filter((x:any)=> x.st_encuesta == id_status).length : 0;
  }

  getSeguimientosCount(id_seg : number){
    return this.datatracking? this.datatracking.filter((x:any)=> x.st_seguimiento == id_seg && x.st_encuesta != 1).length : 0;
  }

  fillFilters(){
    if(this.isback){
    this.selectedzone = this.surveyS.getSelecteZone();
    this.selectedCamp  = this.surveyS.getSelecteCamp();
    this.selectedStsSurv  = this.surveyS.getSelecteStsSurv();
    this.selectedStsTrack  = this.surveyS.getSelecteStsTrack();
      if(this.selectedCamp&&this.selectedCamp!=""&&this.selectedCamp!=0) {
        this.loadTracking();
        this.loadMetrics();
      }
    } else{
      this.resetFilters();
    }
  }

  updateOverallFilters(){
    this.surveyS.setSelecteZone(this.selectedzone);
    this.surveyS.setSelecteCamp(this.selectedCamp);
    this.surveyS.setSelecteStsSurv(this.selectedStsSurv);
    this.surveyS.setSelecteStsTrack(this.selectedStsTrack);
  }

  resetFilters(){
    this.surveyS.setSelecteZone(null);
    this.surveyS.setSelecteCamp(0);
    this.surveyS.setSelecteStsSurv(null);
    this.surveyS.setSelecteStsTrack(null);
  }

  loadTracking(){
    this.main.ShowLoading();
    let list_id_zones = this.zones.map((e:any)=>e.id);
    if(this.selectedzone) {
      list_id_zones = [this.selectedzone];
    }

    this.surveyS.getTracking(this.selectedcompany, this.isClaw(this.selectedCamp) ? this.selectedCamp.replace('-c','') : this.selectedCamp , this.isClaw(this.selectedCamp) ? list_id_zones : this.selectedzone,
    this.selectedStsSurv, this.selectedStsTrack, this.isClawNest(this.selectedCamp)).subscribe((results:any)=>{
      this.main.HideLoading();
      if(results.message == "Success") {

        this.isClawCamp = this.isClaw(this.selectedCamp);
        this.searchedCamp = this.selectedCamp;
        this.datatracking = results.data;
        this.dataSource = new MatTableDataSource(this.datatracking);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.updateCampanaName();
        this.updateOverallFilters();

        this.expanded_filters = true;
        setTimeout(() => {
          this.expanded_filters = false;
        }, 150);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  campanaName = "";

  updateCampanaName(){
    if(this.isClawCamp){
      const sel_cn = this.campaignsData.claw.find((x:any)=>{ return x.id == parseInt(this.selectedCamp.replace('-c', ''))});
      this.campanaName = sel_cn?.name;
    }else{
      this.campanaName = this.campaignsData.nest.filter((x:any)=>{ return x.id ==  this.selectedCamp})[0].name;
    }
  }

  applyFilter(event: Event, ds: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    ds.filter = filterValue.trim().toLowerCase();
    if (ds.paginator) ds.paginator.firstPage();
  }

  getCompanias(){
    this.userS.getCompanies().subscribe((results: any)=>{
      if(results.message == "Success") {
        this.companias = [];

        if(results.data.length>1) {
          this.companias.push({ "id": null, "name": "-- Todas --" });
        }
        this.companias = this.companias.concat(results.data);

        if(results.data.length==1) {
          this.selectedcompany = this.companias[0].id;
          this.compania = this.companias[0];
        // this.loadCampaigns();
        // this.loadZones();
        this.loadFilters();
        }
      }
    },
    (err:any) => {
      console.log(err);
    })
  }

  allCampaigns : any[] = [];

  mergeAndSortCampaigns() {
    const clawCampaigns = this.campaignsData.claw.map((campaign : any) => ({
      ...campaign,
      id: campaign.id + '-c',
    }));

     this.allCampaigns = [...clawCampaigns, ...this.campaignsData.nest];

     this.allCampaigns = this.allCampaigns.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }

  isBuhoUser() : boolean {
    return this.userS.isBuhoUsr();
  }

  loadFilters(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getSurveyCampaigns(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          this.campaignsData = x.data;
          const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
          this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
          this.mergeAndSortCampaigns();
         // if(this.campaignsData.nest.length===1) {
         //   this.selectedCamp = this.campaignsData.nest[0].id;
         // }
          //loadzones
          if(this.selectedcompany && this.selectedcompany != 0){
            this.main.ShowLoading();
            this.surveyS.getZones(this.selectedcompany).subscribe((x: any) => {
              this.main.HideLoading();
              if(x.message == "Success") {
                this.zones = x.data;
                this.fillFilters()
              }
            },
            (err:any) => {
                  this.main.HideLoading();
                }
            );
          }else{
            this.zones = [];
          }
          //--------
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.campaignsData = {};
      }
    }else{
      this.main.ShowLoading();

      this.surveyS.getSurveyCampaigns(1).subscribe((x: any) => {
        this.main.HideLoading();
        this.campaignsData = x.data;
        const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
        this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
        this.mergeAndSortCampaigns();
        if(this.campaignsData.nest.length===1) {
          this.selectedCamp = this.campaignsData.nest[0].id;
        }
          //loadzones
          this.main.ShowLoading();
          this.surveyS.getZonesByUser().subscribe((x: any) => {
            this.main.HideLoading();
            if(x.message == "Success") {
              this.zones = x.data;
              this.fillFilters()
            }
          },
          (err:any) => {
                this.main.HideLoading();
              }
          )
          //--------
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }
    //this.selectedCamp = 0
  }


  loadCampaigns(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getSurveyCampaigns(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          this.campaignsData = x.data;
          const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
          this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
          this.mergeAndSortCampaigns();
          //if(this.campaignsData.nest.length===1) {
          //  this.selectedCamp = this.campaignsData.nest[0].id;
          //}
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.campaignsData = {};
      }
    }else{
      this.main.ShowLoading();
      this.surveyS.getSurveyCampaigns(1).subscribe((x: any) => {
        this.main.HideLoading();
        this.campaignsData = x.data;
        const idsClaw = this.campaignsData.claw.map((i:any) => i.id_campana_nest);
        this.campaignsData.nest = this.campaignsData.nest.filter((x:any)=> !idsClaw.includes(x.id));
        this.mergeAndSortCampaigns();
       //if(this.campaignsData.nest.length===1) {
       //  this.selectedCamp = this.campaignsData.nest[0].id;
       //}
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }

    this.selectedCamp = 0
  }

  loadZones(){
    if(this.usrcompany == 0){
      if(this.selectedcompany && this.selectedcompany != 0){
        this.main.ShowLoading();
        this.surveyS.getZones(this.selectedcompany).subscribe((x: any) => {
          this.main.HideLoading();
          if(x.message == "Success") {
            this.zones = x.data;
          }
        },
        (err:any) => {
              this.main.HideLoading();
            }
        );
      }else{
        this.zones = [];
      }
    }else{
      this.main.ShowLoading();
      this.surveyS.getZonesByUser().subscribe((x: any) => {
        this.main.HideLoading();
        if(x.message == "Success") {
          this.zones = x.data;
        }
      },
      (err:any) => {
            this.main.HideLoading();
          }
      )
    }
     this.selectedzone = null
  }

  getStatusTracking(){
    this.statusTracking = environment.status_seguimientos;
  }
  getStatusSurv(){
    this.statusSurv = environment.status_survticket;
  }

  getStatusSurvDat(idstatus : number){
    return environment.status_survticket.filter(x=> x.id  == idstatus)[0];
  }

  getStatusTrackingDat(idstatus : number){
    return environment.status_seguimientos.filter(x=> x.id  == idstatus)[0];
  }

  isClaw(id_camp : any) : boolean{
    let idcampEv = id_camp.toString();
    return idcampEv.includes("-c");
  }

  isClawNest(id_camp : any) : string{
      let idcampEv = id_camp.toString();
      return idcampEv.includes("-c") ? "claw" : "nest";
  }

  searchedCamp : number = 0;

  search(){
   if(this.selectedCamp != 0){
   this.loadTracking();
   this.loadMetrics();
   if(this.tabGroup.selectedIndex==1)
    this.loadStatsZone();
   if(this.tabGroup.selectedIndex==2)
    this.loadStatsMaterial();
   }
  }
  gotoSurvey(row : any){
    this.surveyS.getEncuestaUrl(this.isClawCamp? { box_id : row.id, id_campana : row.id_campana_nest }
      : { id_sucursal : row.id_sucursal, id_campana : this.searchedCamp }).subscribe((x:any)=>{
      if(x.message == "Success") {
        //console.log(x.data)
        var url = this.hostName + x.data.token;
        window.open(url, "_blank");
      }
    });

  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.campaignService.hasPermissions(key, buho, client);
  }

  addExtraTicket(row:any){
    if(this.hasPermissions('upl_extra_tck',null,true)){
      this.matDialog.open(TicketsGenerateExtraComponent, {
        panelClass: ['dialogs-sm'],
        data: {
          row
        }
      }).afterClosed().subscribe(
        resp => {
          if(resp.new_ticket){
            const url = resp.isClaw ? '/admin/ticket/'+row.id_compania + '/' + row.id_campana  + '/' + row.id + '/claw' : '/admin/ticket/'+row.id_compania + '/' + row.id_campana  + '/' + row.id_sucursal + '/nest'
            this._router.navigateByUrl(url);
          }
        }
      );
    }
  }
  get totalEncuestas(): number {
    return (this.metrics?.total_encuestas_contestadas || 0) + (this.metrics?.total_encuestas_pendientes || 0);
  }

  loadMetrics(): void {
    let list_id_zones = this.zones.map((e:any)=>e.id);
    if(this.selectedzone) {
      list_id_zones = [this.selectedzone];
    }
    
    this.metricsService.getMetrics(this.isClaw(this.selectedCamp) ? this.selectedCamp.replace('-c','') : this.selectedCamp ,list_id_zones ,  this.selectedStsSurv, this.selectedStsTrack).subscribe({
      next: ({data}) => {
        this.metrics = data[0][0];
        this.totalReports = data[1][0];
        this.chartOptions = {
          ...this.chartOptions,
          series: [this.metrics.total_encuestas_contestadas, this.metrics.total_encuestas_pendientes]
        }
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  loadStatsZone(): void {
    let list_id_zones = this.zones.map((e:any)=>e.id);
    if(this.selectedzone) {
      list_id_zones = [this.selectedzone];
    }
    this.metricsService.getMetricsZones(this.isClaw(this.selectedCamp) ? this.selectedCamp.replace('-c','') : this.selectedCamp , list_id_zones ,  this.selectedStsSurv, this.selectedStsTrack).subscribe({
      next: ({data}) => {
        this.dataSourceZone = new MatTableDataSource(data[0]);
        this.dataSourceZone.paginator = this.paginator2;
        this.dataSourceZone.sort = this.sort2;
        this.zonesStats = data[0];
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }


  loadStatsMaterial(): void {
    let list_id_zones = this.zones.map((e:any)=>e.id);
    if(this.selectedzone) {
      list_id_zones = [this.selectedzone];
    }
    this.metricsService.getMetricsMateriales(this.isClaw(this.selectedCamp) ? this.selectedCamp.replace('-c','') : this.selectedCamp ,list_id_zones ,  this.selectedStsSurv, this.selectedStsTrack).subscribe({
      next: ({data}) => {
       this.dataSourceMaterial = new MatTableDataSource(data[0]);
       this.dataSourceMaterial.paginator = this.paginator3;
      this.dataSourceMaterial.sort = this.sort3;
       this.MaterialStats=data[0]
        this.loading = false;
      },
      error: (error) => {
        console.error(error)
        this.loading = false;
      }
    });
  }

  onTabChange(event: MatTabChangeEvent) {
    if(event.index === 1) {
      this.loadStatsZone()
    }
    if(event.index === 2) {
      this.loadStatsMaterial()
    }
  }
  generateExcelResumen(){
      if(this.datatracking.length == 0){
        Swal.fire({
          icon: 'warning',
          title: 'Aviso',
          text: 'No hay datos de resumen actualmente'
        }).then(s=>{

        });
      }else{
        const dataExcel= this.datatracking.map((e :any)=>{
          return Object.assign({
            "Campaña": e?.campana ?? "",
            "Zona": e?.zona ?? "",
            "Sucursal": e?.sucursal ?? "",
            "Consecutivo": e?.consecutivo ?? 0,
            "Alertas": e?.alertas ?? 0,
            "Estado Seguimiento": this.getStatusTrackingDat(e?.st_seguimiento).nombre ?? "",
            "Estado Encuesta": this.getStatusSurvDat(e?.st_encuesta).nombre ?? ""
          })
        })
        this.main.generateExcel(dataExcel, "Encuestas_Resumen", "resumen");
      }
  }

  generateExcelResumenZone() {
    if (this.zonesStats.length == 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Aviso',
        text: 'No hay datos de resumen actualmente'
      });
    } else {
      const dataExcel = this.zonesStats.map((e: any) => ({
        "Zona": e?.zona ?? "",
        "Total cajas": e?.total_cajas ?? 0,
        "Cajas entregados": e?.total_entregados ?? 0,
        "Encuestas contestadas": e?.total_encuestas_contestadas ?? 0,
        "Encuestas pendientes": e?.total_encuestas_pendientes ?? 0,
        "Tickets extraordinarios": e?.total_extra_tickets ?? 0,
        "Cajas perfectas": e?.total_cajas_perfectas ?? 0,
        "Alertas pendientes": e?.total_alertas_pendientes ?? 0,
        "Tickets pendientes de atender": e?.total_tickets_pendientes ?? 0,
        "Tickets en revisión": e?.total_tickets_revision ?? 0,
        "Tickets resueltos": e?.total_tickets_resueltos ?? 0,
        "Tickets reenviados": e?.total_reenviados ?? 0,
        "Tipo de ticket: Faltante": e?.total_faltante ?? 0,
        "Tipo de ticket: Material dañado": e?.total_material_danado ?? 0,
        "Tipo de ticket: Kit incompleto": e?.total_kit_incompleto ?? 0,
        "Tipo de ticket: Solicitud adicional": e?.total_solicitud_adicional ?? 0,
        "Tipo de ticket: Otro": e?.total_otro ?? 0,
        "Anulaciones": e?.total_anulacion ?? 0,
      }));

      // PASA EL NUEVO ARRAY CON LOS NOMBRES CORREGIDOS
      this.main.generateExcel(dataExcel, "Reporte_Ejecutivo_Zonas", "resumen");
    }
  }

generateExcelResumenMaterial(){
  if(this.MaterialStats.length == 0){
    Swal.fire({
      icon: 'warning',
      title: 'Aviso',
      text: 'No hay datos de resumen actualmente'
    }).then(s=>{

    });
  }else{
    const dataExcel= this.MaterialStats.map((e :any)=>{
      return Object.assign({
        "Material": e?.material ?? "",
        "Alertas pendientes": e?.total_alertas_pendientes ?? 0,
        "Tickets pendientes de atender": e?.total_tickets_pendientes ?? 0,
        "Tickets en revisión": e?.total_tickets_revision ?? 0,
        "Tickets resueltos": e?.total_tickets_resueltos ?? 0,
        "Tickets reenviados": e?.total_reenviados ?? 0,
        "Tipo de ticket: Faltante": e?.total_faltante ?? 0,
        "Tipo de ticket: Material dañado": e?.total_material_danado ?? 0,
        "Tipo de ticket: Kit incompleto": e?.total_kit_incompleto ?? 0,
        "Tipo de ticket: Solicitud adicional": e?.total_solicitud_adicional ?? 0,
        "Tipo de ticket: Otro": e?.total_otro ?? 0,
        "Anulaciones": e?.total_anulacion ?? 0,
      })
    })
    this.main.generateExcel(  dataExcel, "Reporte_Ejecutivo_Materiales", "resumen");
  }
}
}




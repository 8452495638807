<div *ngIf="!uploaded">
<div class="modal-header">
    <h4 class="modal-title">Cargar valores masivos</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <h6 class="pb-4">
            <span class="align-middle">Carga el archivo de excel:</span>
            <!--<button mat-stroked-button color="primary" class="float-end">Descargar plantilla<mat-icon>download</mat-icon></button>-->
        </h6>
        <form [formGroup]="form">
            <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
            </ngx-file-drag-drop>
        </form>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="btn bg-success text-white" (click)="uploadFile()" form="" type="submit">Registrar cambios <mat-icon>save</mat-icon></button>
</div>
</div>
 
<div *ngIf="uploaded">
  <div class="modal-header">
    <h4 class="modal-title">Resultado de Carga</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
  </div>
<h5>Advertencias:</h5> 

<table *ngIf="uploadWarnings.length > 0" class="table">
    <thead>
      <tr>
        <th scope="col">Sucursales</th>
        <th scope="col">Atributo</th>
        <th scope="col">Valor</th>
        <th scope="col">Mensaje</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let p of uploadWarnings">
        <th>{{p.sucursal}}</th>
        <td>{{ p.atributo }}</td>
        <td>{{ p.value }}</td>
        <td>{{ p.validation }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="uploadWarnings.length == 0" >
    No hay advertencias
  </div>
</div>
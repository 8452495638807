<div class="content-wrapped">
    <!-- Content -->
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4 text-primary">
            <mat-icon class="me-2 align-middle">store</mat-icon>Sucursales por zona
            <div class="float-end">
                <button mat-flat-button color="accent" class="ms-4" (click)="openDialogCutOffList()">Cortes de campaña <mat-icon>event_available</mat-icon></button>                
            </div>
        </h4>
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-body">                        
                        <div class="col-md-12">
                            <div class="overflow-auto w-100">
                                <div class="float-end">
                                    <button mat-flat-button color="primary">Descargar <mat-icon>download</mat-icon></button>
                                    <button mat-flat-button color="warn" class="ms-4" (click)="openDialogAttrsPending()">Pendientes autorización: 2 <mat-icon>priority_high</mat-icon></button>
                                </div>
                                <mat-form-field>
                                    <mat-label>
                                        <mat-icon>search</mat-icon> Buscar</mat-label>
                                    <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Monterrey Centro" #input>
                                </mat-form-field>
                            </div>
                        
                            <div class="mat-elevation-z8 table-responsive">
                                <table mat-table [dataSource]="dataSource" matSort class="w-100">
                        
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="zone">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                                        <td mat-cell *matCellDef="let row"> {{row.zone}} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="store">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                        <td mat-cell *matCellDef="let row"> <strong>{{row.store}}</strong> </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="sub">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Filial </th>
                                        <td mat-cell *matCellDef="let row"> {{row.sub}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="farmID">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> FarmID </th>
                                        <td mat-cell *matCellDef="let row"> {{row.farmID}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="city">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ciudad </th>
                                        <td mat-cell *matCellDef="let row"> {{row.city}} </td>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                        <td mat-cell *matCellDef="let row"> <span class="badge" [ngClass]="row.status == 'ABIERTA' ? 'bg-success' : 'bg-warning'">{{row.status}}</span></td>
                                    </ng-container>
                                    
                                   <!-- <ng-container matColumnDef="dateOpen">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Apertura </th>
                                        <td mat-cell *matCellDef="let row"> {{row.dateOpen}} </td>
                                    </ng-container>-->
                                    
                                    <ng-container matColumnDef="format">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Formato </th>
                                        <td mat-cell *matCellDef="let row"> {{row.format}} </td>
                                    </ng-container>
                        
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                        <td mat-cell *matCellDef="let row" class="text-center">
                                            <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" color="accent" (click)="openDialogAttributeEditList()">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                        
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
                                    </tr>
                                </table>
                        
                                <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                                    <mat-progress-spinner color="primary" mode="indeterminate">
                                    </mat-progress-spinner>
                                </div>
                                <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { UsersService } from './users.service';
import { Metrics } from '../components/tickets/ticket-list/ticket-list.component';
import { AnyAaaaRecord } from 'dns';

@Injectable({
    providedIn: 'root'
  })
  export class SurveysService {

    constructor(
      private main: MainService,
      private usrService : UsersService
    ) { }

  //---filter group ---
    private selectedcompany = new BehaviorSubject<any>(null); // Initial value: null (or whatever default you want)
    public selectedcompany$ = this.selectedcompany.asObservable(); // Expose as an Observable ($ convention)
    // Method to set the selected company
    setSelecteCompany(data: any) {
      this.selectedcompany.next(data); // Emit the new value to subscribers
    }
    // Method to get the current selected company (if needed)
    getSelecteCompanyValue(): any {
      return this.selectedcompany.value; // Get the current value
    }

    private selectedzone = new BehaviorSubject<any>(null);
    public selectedzone$ = this.selectedzone.asObservable();
    setSelecteZone(data: any) {
      this.selectedzone.next(data);
    }
    getSelecteZone(): any {
      return this.selectedzone.value;
    }

    private selectedCamp = new BehaviorSubject<any>(0);
    public selectedCamp$ = this.selectedCamp.asObservable();
    setSelecteCamp(data: any) {
      this.selectedCamp.next(data);
    }
    getSelecteCamp(): any {
      return this.selectedCamp.value;
    }

    private selectedStsSurv = new BehaviorSubject<any>(null);
    public selectedStsSurv$ = this.selectedStsSurv.asObservable();
    setSelecteStsSurv(data: any) {
      this.selectedStsSurv.next(data);
    }
    getSelecteStsSurv(): any {
      return this.selectedStsSurv.value;
    }

    private selectedStsTrack = new BehaviorSubject<any>(null);
    public selectedStsTrack$ = this.selectedStsTrack.asObservable();
    setSelecteStsTrack(data: any) {
      this.selectedStsTrack.next(data);
    }
    getSelecteStsTrack(): any {
      return this.selectedStsTrack.value;
    }

  
  //---end---

    getFilters(camp_id: number) {
        return this.main.getRequest("/surveys/filters/" + camp_id);
    }

    getSurveyCampaigns(company_id: number) {
      return this.main.getRequest("/surveys/getCampaigns/" + company_id);
    }

    getSurvey(camp_id: number, suc_id: number) {
        return this.main.postRequest({ suc_id : suc_id }, "/surveys/show/" + camp_id);
    }

    getSurveyView(camp_id: number, suc_id: number, box_id: number) {
      return this.main.postRequest({ suc_id : suc_id,  camp_id : camp_id, box_id : box_id }, "/surveys/view");
    }

    getEncuestasToken( tokens : any ){
      return this.main.postRequest({ payload : tokens }, "/surveys/generatetokens");
    }

    getEncuestasQR( id_campana : number , url : string ){
      return this.main.postRequestDownload({ id_campana : id_campana, url : url }, "/surveys/generateqr");
    }

    answerSurvey( data : any ){
      return this.main.postRequest(data, "/surveys/setanswer");
    }

    answerExtraSurvey( data : any ){
      return this.main.postRequest(data, "/surveys/setextraordinaryanswer");
    }

    getTracking( id_compania: number, id_campana: number,id_zona: any, st_encuesta: any,st_seguimiento: any, type :string ){
      return this.main.postRequest({ id_compania: id_compania, id_campana: id_campana, list_id_zonas: id_zona,st_encuesta: st_encuesta,st_seguimiento: st_seguimiento, type: type }, "/surveys/tracking");
    }

    getZones(id_comp : number) {
      return this.main.getRequest("/users/zones/show/" + id_comp);
    }

    getZonesByUser() {
      return this.main.getRequest("/users/zones/user");
    }

    getAlerts( id_compania: number, id_campana: number,id_sucursal_box: number, type : string ){
        return this.main.postRequest({ id_campana: id_campana, id_distribucion: id_sucursal_box }, "/surveys/alerts");
    }

    getAlltickets(id_camp : number, type : string, zonas : any){
      return this.main.postRequest({ id_camp : id_camp == 0?  null : id_camp, type, zonas }, "/surveys/all-tickets");
    }

    addTicket(id_campana : number, id_sucursal_distribucion : number, id_encuesta_campana : number, tipo : number, id_compania : number, type : string){
      if(type == "nest"){
        return this.main.postRequest({ id_campana: id_campana, id_sucursal:  id_sucursal_distribucion,id_encuesta_campana: id_encuesta_campana, tipo : tipo, id_compania : id_compania }, "/surveys/ticket/add");
      }else{
        return this.main.postRequest({ id_campana: id_campana, id_distribucion:  id_sucursal_distribucion, id_encuesta_campana: id_encuesta_campana, tipo : tipo }, "/surveys/ticket/add");
      }
    }

    setTrackingNumberTicket(id_distribucion : number, tracking_number : string, id_alerta : number){
        return this.main.postRequest({ id_distribucion, tracking_number, id_alerta }, "/surveys/setTrackingNumberTicket");
    }

    setTicketEnviado(id_alerta : number, id_ticket : number, enviado: number){
      return this.main.postRequest({ id_alerta: id_alerta, id_ticket : id_ticket, enviado : enviado }, "/surveys/setTicketEnviado");
  }

    updateTicket(id_campana : number,  id_sucursal_distribucion : number, id_encuesta_campana : number, status : number, id_compania : number, status_nombre : string, type: string){
      if(type=="nest"){
        return this.main.postRequest({ id_campana: id_campana, id_sucursal:  id_sucursal_distribucion,id_encuesta_campana: id_encuesta_campana, status : status, id_compania : id_compania,status_nombre :status_nombre  }, "/surveys/ticket/update");
      }else{
        return this.main.postRequest({ id_campana: id_campana, id_distribucion:  id_sucursal_distribucion,id_encuesta_campana: [id_encuesta_campana.toString()], status : status, id_compania : id_compania,status_nombre :status_nombre  }, "/surveys/ticket/update");
      }
    }

    updateTicketMasive(id_campana : number,  id_sucursal_distribucion : number, ids_encuesta_campana : any, status : number, id_compania : number, status_nombre : string, type : string){
      if(type=="nest"){
        return this.main.postRequest({ id_campana: id_campana, id_sucursal:  id_sucursal_distribucion, ids_encuesta_campana: this.getPlainIds(ids_encuesta_campana), status : status, id_compania : id_compania,status_nombre :status_nombre  }, "/surveys/ticket/updatemasive");
      }else{
        return this.main.postRequest({ id_campana: id_campana, id_distribucion:  id_sucursal_distribucion,id_encuesta_campana: ids_encuesta_campana, status : status, id_compania : id_compania,status_nombre :status_nombre  }, "/surveys/ticket/update");
      }
    }

    getCampaignSurveyLog(id_surv : number) {
      return this.main.getRequest("/surveys/ticket/changelog/" + id_surv);
    }

    newCampaignSurveyMessage(newMessage : any){
      return this.main.postRequest(newMessage, "/surveys/message/");
    }

    removeTicket(id_campana : number,  id_sucursal_distribucion : number, id_encuesta_campana : number, id_compania : number, type : string){
      if(type=="nest"){
        return this.main.postRequest({ id_campana: id_campana, id_sucursal:  id_sucursal_distribucion,id_encuesta_campana: id_encuesta_campana, id_compania : id_compania }, "/surveys/alert/remove");
      }else{
        return this.main.postRequest({ id_campana: id_campana, id_distribucion:  id_sucursal_distribucion,id_encuesta_campana: id_encuesta_campana }, "/surveys/alert/remove");
      }
    }

    uploadImages(form : FormData){
      return this.main.postRequestFormData(form, "/surveys/files/images");
    }

    getImagesMaterial(id_mat_camp : number) {
      return this.main.getRequest("/surveys/material_image/" + id_mat_camp);
    }

    getMaterialsCampana(id_camp : number) {
      return this.main.getRequest("/surveys/materials/" + id_camp);
    }

    uploadMaterialsCampana(mat : any){
      return this.main.postRequest(mat, "/surveys/materials/edit");
    }

    getEncuestaUrl (token : any ){
      return this.main.postRequest({ payload : token }, "/surveys/getsurveyurl");
    }

    uploadMsgImgs(form : FormData){
      return this.main.postRequestFormData(form, "/surveys/files/msg");
    }

    getTicketsSummary(id_campana : number, type : string){

      return this.main.postRequest({ id_campana : id_campana == 0? null: id_campana , type}, "/surveys/tickets-summary");

    }

    private getPlainIds(toEditIdsSts  : any): string {
      var returnedString: string = "";
      if (toEditIdsSts.length > 0) {
        returnedString = '';
        toEditIdsSts.forEach((e :  any, i : any) => {
          returnedString += e;
          if (toEditIdsSts.length - 1 != i) {
            returnedString += ",";
          }
        });
      }
      else {
        returnedString = '';
      }
      return returnedString;
    }

    getMaterialsByBox (id_camp:any, id_box:any, type : string) {
      if(type == "nest"){
        return this.main.postRequest({ id_campana : id_camp , id_sucursal: id_box}, "/surveys/box-materials");
      }else{
        return this.main.postRequest({ id_campana : id_camp , id_box}, "/surveys/box-materials");
      }
    }

      // Obtener métricas generales
      getMetrics(id_camp:any, zonas: any, estatus_encuestas: AnyAaaaRecord, estatus_seguimiento: any  ): Observable<any> {
        return this.main.postRequest({

            id_campana: id_camp,
            list_id_zonas: zonas,
            st_encuestas: estatus_encuestas,
            st_seguimiento: estatus_seguimiento

        }, '/surveys/stats-encuestas');
      }

      // Obtener métricas generales
      getMetricsTickets(id_camp:any, zonas: any, estatus_encuestas: AnyAaaaRecord, estatus_seguimiento: any  ): Observable<any> {
        return this.main.postRequest({

            id_campana: id_camp,
            list_id_zonas: zonas,
            st_encuestas: estatus_encuestas,
            st_seguimiento: estatus_seguimiento

        }, '/surveys/stats-tickets-all');
      }

       // Obtener métricas generales
       getMetricsZones(id_camp:any, zonas: any, estatus_encuestas: AnyAaaaRecord, estatus_seguimiento: any  ): Observable<any> {
        return this.main.postRequest({

            id_campana: id_camp,
            list_id_zonas: zonas,
            st_encuestas: estatus_encuestas,
            st_seguimiento: estatus_seguimiento

        }, '/surveys/stats-encuestas-zonas');
      }

       // Obtener métricas generales
       getMetricsMateriales(id_camp:any, zonas: any, estatus_encuestas: AnyAaaaRecord, estatus_seguimiento: any  ): Observable<any> {
        return this.main.postRequest({
            id_campana: id_camp,
            list_id_zonas: zonas,
            st_encuestas: estatus_encuestas,
            st_seguimiento: estatus_seguimiento

        }, '/surveys/stats-encuestas-material');
      }


}

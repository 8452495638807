import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistributionPreprojectFileComponent } from '../distribution-preproject-file/distribution-preproject-file.component';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-distribution-preproject',
  templateUrl: './distribution-preproject.component.html',
  styleUrls: ['./distribution-preproject.component.scss']
})
export class DistributionPreprojectComponent implements OnInit {
  
  strategy: any;
  odtFiles: any;
  isClientUsr: boolean = false;
  isLoading = true;
  canUploadOdt: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<DistributionPreprojectComponent>,
  ) { 
    this.strategy = this.data.strategy;
    this.loadData();
    this.isClientUsr = campaignService.isClientUsr();
    this.canUploadOdt = !this.data.islocked && this.campaignService.hasPermissions("upl_odt",true,false);
  }

  ngOnInit(): void {
    
  }

  loadData(){
    this.campaignService.getStrategyOdts(this.strategy.id).subscribe(
      (result:any) => {
        this.isLoading = false;
       this.odtFiles = result.data;
      },
      (err:any) => {
        this.isLoading = false;
        console.log(err);
        if(err.error.message != "No valid odt files were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  openDialogPreprojectFile() {
    this.matDialog.open(DistributionPreprojectFileComponent, {
      panelClass: ['dialogs-main'],
      data: {
        strategy : this.data.strategy
      }
    }).afterClosed().subscribe(
      resp => {
        if(resp?.reload) {
          this.dialogRef.close({ reload: true });
        }
      }
    );
  }

}

<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle"><strong>{{ campaign.name }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <h6>
            <span class="align-middle">Lista de Irregularidades <span class="badge bg-danger">{{ campaign.irr }}</span></span>
        </h6>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <div class="col-sm-12 pb-4">
            <mat-accordion class="elementsList">
                <mat-expansion-panel *ngFor="let el of strList | keyvalue">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Materiales de&nbsp;<strong>{{ el.key }}</strong>&nbsp;que no están en Distribución<span class="ms-2 badge badge-center rounded-pill bg-warning">{{ el.value ? el.value.length : 0 }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="demo-inline-spacing">
                        <div class="list-group list-group-flush">
                            <a class="list-group-item list-group-item-action" *ngFor="let material of el.value">{{ material.name}}</a>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="disCount != 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Materiales de Distribución que no están en Estrategias<span class="ms-2 badge badge-center rounded-pill bg-warning">{{ disCount }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="demo-inline-spacing">
                        <div class="list-group list-group-flush">
                            <a class="list-group-item list-group-item-action" *ngFor="let el of disList">{{ el.name }}</a>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="difCount != 0">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Materiales que no coinciden entre Distribución y Estrategias<span class="ms-2 badge badge-center rounded-pill bg-warning">{{ difCount }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="table-responsive text-nowrap" *ngFor="let el of difList | keyvalue">
                        <span class="mt-4"><strong>{{ el.key }}</strong></span>
                        <table class="table table-hover table-bordered mt-2">
                            <thead>
                                <tr>
                                    <th class="td-name-material text-center">Material</th>
                                    <th class="text-center "># ODT</th>
                                    <th class="text-center "># Distribución </th>
                                </tr>
                            </thead>
                            <tbody class="table-border-bottom-0 ">
                                <tr *ngFor="let material of el.value ">
                                    <td>
                                        {{ material.name}}
                                    </td>
                                    <td class="text-center">
                                        {{ material.s_cant}}
                                    </td>
                                    <td class="text-center">
                                        {{ material.d_cant}}
                                    </td>
                            </tbody>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</mat-dialog-content>
<div class="modal-header">
    <h4 class="modal-title">Configuración Global Preproyectos</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-divider class="mt-2"></mat-divider>
<div class="modal-body">
    <mat-dialog-content>
        <div class="row">
            <label class="fw-bold">Información General</label>
        </div>
        <div class="row mt-1">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Campaña</mat-label>
                    <input matInput [(ngModel)]="data.campaign.name" readonly>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Contacto</mat-label>
                    <input matInput [(ngModel)]="data.contacto">
                </mat-form-field>
            </div>
        </div>
        <mat-divider class="mt-2"></mat-divider>
        <div class="row mt-2">
            <label class="fw-bold">Información para Producción</label>
        </div>
        <div class="row mt-1">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Impresión</mat-label>
                    <mat-select [(ngModel)]="data.impresion">
                        <mat-option *ngFor="let p of tiposImpresion" [value]="p">{{p}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Color</mat-label>
                    <mat-select [(ngModel)]="data.color">
                        <mat-option value="CMYK">CMYK</mat-option>
                        <mat-option value="RGB">RGB</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-divider class="mt-2"></mat-divider>
        <div class="row mt-2">
            <label class="fw-bold">Información para Operaciones</label>
        </div>
        <div class="row mt-1">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Envío</mat-label>
                    <mat-select [(ngModel)]="data.envio">
                        <mat-option *ngFor="let e of tiposEnvio" [value]="e">{{e}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Recepción</mat-label>
                    <mat-select [(ngModel)]="data.recepcion">
                        <mat-option *ngFor="let r of tiposRecepcion" [value]="r">{{r}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Fechas</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                          <input matStartDate placeholder="Fecha Inicio" [(ngModel)]="data.fecha_inicio">
                          <input matEndDate placeholder="Fecha Fin" [(ngModel)]="data.fecha_fin">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                      </mat-form-field>
            </div>
        </div>

      </mat-dialog-content>
</div>
<mat-divider class="mt-3"></mat-divider>
<div class="modal-footer mt-3">
    <button mat-flat-button class="bg-success text-white" (click)="guardarPreproyectoGlobal(data)">Guardar <mat-icon>save</mat-icon></button>
</div>

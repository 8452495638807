import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router} from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { NewPopMaterialComponent } from '../../shared/dialogs/new-pop-material/new-pop-material.component';
import { EditPopMaterialComponent } from '../../shared/dialogs/edit-pop-material/edit-pop-material.component';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-pop-materials',
  templateUrl: './pop-materials.component.html',
  styleUrls: ['./pop-materials.component.scss']
})
export class PopMaterialsComponent implements OnInit {
  // Data Handlers
  mediaID: number | null = null;
  projectID: number | null = null;
  // HTML View Controller Elements
  activepop: number = -1;
  // Temporal
  projectMedia: Array<any> = [
    { id: 1, name: "DANGLER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: false, formato : "Reglas" },
    { id: 2, name: "COPETE DE MESA PROMOCIONES", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" },
    { id: 3, name: "BANNER", allowEmptyBranches: false, allowMultipleItems: false, popMaterials: [], segmentable: true, formato : "Reglas" },
    { id: 4, name: "POSTER CHECKOUT", allowEmptyBranches: false, allowMultipleItems: true, popMaterials: [], segmentable: false, formato : "Por Prioridad" }
  ];

  poplistActual : Array<any> = [];

  currentMedia : any;

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private projService : ProjectsService) { }

  ngOnInit(): void {
    this.getData();
  }


  /* async Promise<void> getData(0):
     Extracts data from URL. */
  async getData(): Promise<void> {
    try {
      // Extract ID
      const url: ParamMap = await firstValueFrom(this.route.paramMap);
      this.projectID = Number(url.get('pid'));
      this.mediaID = Number(url.get('mid'))
      this.currentMedia = this.getMedia(this.mediaID);
      this.poplistActual = this.getPopListActual(this.mediaID);

      if (!this.projectID)
        throw new Error('No se recibió ningún ID.');
    } catch (error) {
      console.error(error);
    }
  }

  getMedia(id:number){
    return this.projService.projectMedia.filter(x=>( x.id == id))[0];
  }

  getPopListActual(media_id:number){
    return this.projService.poplist.filter(x=>( x.media_id == media_id));
  }

  /* async Promise<void> newPOP(0):
     Opens the dialog to create a new POP material element. Then, extracts data. */
  async newPOP(): Promise<void> {
    // Open data-setting dialog
    const npDialogRef: MatDialogRef<NewPopMaterialComponent, any> = this.dialog.open(NewPopMaterialComponent, {
      data: {},
      panelClass: 'dialogs-main'
    });

    try {
      // Use firstValueFrom to get the result from the dialog
      const result = await firstValueFrom(npDialogRef.afterClosed());

      if (result) {
        console.log('Dialog result:', result);
      }
    } catch (error) {
      console.error('Error handling dialog result:', error);
    }
  }

  /* async Promise<void> showPOPDetails(1):
     Opens the dialog to view/edit POP materials. Then reloads.
     < index: The index of the selected POP material element. */
  async showPOPDetails(index: number): Promise<void> {
    const spDialogRef: MatDialogRef<EditPopMaterialComponent, any> = this.dialog.open(EditPopMaterialComponent, {
      data: {
        ...this.poplistActual[index]
      },
      panelClass: 'dialogs-main'
    });
  }

  goToConfign(popid :  number ){
    this.router.navigateByUrl('/admin/projects/pop-config/'+ this.projectID +'/'+ this.mediaID +'/'+ popid)
  }

  goToVobos(){
    this.router.navigateByUrl('/admin/projects/vobos/1')
  }
}

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {

  constructor() { }

  samePasswords( pass1: string, pass2: string ) {

    return ( formGroup: FormGroup ) => {
      const pass1Control = formGroup.controls[pass1];
      const pass2Control = formGroup.controls[pass2];
      if ( pass1Control.value === pass2Control.value ) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ noEqual: true });
      }

    }

  }

  sumOfValues(insp: string, retr: string, rech: string) {
    return(formGroup: FormGroup) => {
      const inspControl = formGroup.controls[insp];
      const retrControl = formGroup.controls[retr];
      const rechControl = formGroup.controls[rech];
      if((Number(retrControl.value) + Number(rechControl.value)) <= Number(inspControl.value)) {
        inspControl.setErrors(null);
      } else {
        inspControl.setErrors({ incorrectValues: true });
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SurveysConfirmComponent } from '../../shared/dialogs/surveys-confirm/surveys-confirm.component';
import jwt_decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { SurveyImageSliderComponent } from '../../shared/dialogs/survey-image-slider/survey-image-slider.component';
import Swal from 'sweetalert2';
import { SurveysComentaryComponent } from '../../shared/dialogs/surveys-comentary/surveys-comentary.component';

@Component({
  selector: 'app-surveys-view',
  templateUrl: './surveys-view.component.html',
  styleUrls: ['./surveys-view.component.scss']
})
export class SurveysViewComponent implements OnInit {

  constructor(private matDialog: MatDialog,  
              private _params: ActivatedRoute,
             private _router : Router,
             private surveyS : SurveysService,
             private main : MainService,
             private _titleService: Title,)
  {
    this.loading = true;
    this._params.params.subscribe(params => {
      if(params.id){
        this.encuestaIds = jwt_decode(params.id!);
     
        this.isClaw_survey = this.encuestaIds.box_id? true : false;
        console.log(this.encuestaIds, this.isClaw_survey);
        this.id_campana_claw = this.isClaw_survey? this.encuestaIds.id_campana : 0;
      }else{
        this._router.navigateByUrl('/admin/dashboard');
      } 
    });
   }

   isClaw_survey : boolean = false;
   encuestaIds : any = {};
   encuestaData : any = {};
   loading : boolean = false; 
   id_campana_claw : any;
   productListEvaluated :any[] = [];
   private apiURL = environment.API_URL;
   //private apiURL = "http://localhost:3004";
   urlImage = this.apiURL + "/surveys/image/";

  ngOnInit(): void {
    this.loadEncuestas();
  }

  loadEncuestas(){
    this.main.ShowLoading();
    this.surveyS.getSurveyView(this.encuestaIds.id_campana, this.encuestaIds.id_sucursal, this.isClaw_survey ? this.encuestaIds.box_id : 0).subscribe((x:any)=>{
        if(x.message == "Success"){
          this.encuestaData = x.data;
          if(this.isClaw_survey){
            console.log(this.encuestaData);
            this.productListEvaluated = x.data.surveyData.materiales.map((x:any)=> {  return {id : x.id, cantidad : x.cantidad, nombre : x.nombre, nombre_mat : x.nombre_mat, status : x.status, images : x.images, alerted : false, cantidad_pza: x.cantidad_pza, comentario : ""}} );
            this._titleService.setTitle("Buho Nest Encuestas - " + this.encuestaData.campana.name + " - " + this.encuestaData.surveyData.sucursal );
            this.answered = this.encuestaData.surveyData.survey_status==1;
          }else{
            this.productListEvaluated = x.data.surveyData.map((x:any)=> {  return {id : x.id, cantidad : x.cantidad, id_material_camp : x.id_material_camp,
            id_zona : x.id_zona, nombre : x.nombre, nombre_mat : x.nombre_mat, status : x.status, images : x.images, alerted : false, cantidad_pza: x.cantidad_pza, comentario : ""}} );
            this._titleService.setTitle("Buho Nest Encuestas - " + this.encuestaData.campana.name + " - " + this.encuestaData.sucursal.nombre );
            this.encuestaData.surveyData["consec"] = this.encuestaData.consec.consec;
            this.encuestaData.surveyData["sucursal"] = this.encuestaData.sucursal.nombre;
            this.answered = this.isSurveyAsnswered();
          }

          this.loading = false;
        }else{
          this._router.navigateByUrl('/admin/dashboard');
        }
        this.main.HideLoading();
    });
  }

  // 1-pendiente 2-correcto 3-alerta

  openConfirm() {
    this.matDialog.open(SurveysConfirmComponent, {
      panelClass: 'dialogs-main',
      data: {
        id_campana : this.encuestaData.campana.id,
        sucursal : this.isClaw_survey?this.encuestaData.campana.sucursal:this.encuestaData.sucursal,
        box_id : this.isClaw_survey? this.encuestaIds.box_id : 0,
        isClaw : this.isClaw_survey,
        id_campana_claw  : this.isClaw_survey ? this.id_campana_claw : 0,
        productListEvaluated : this.productListEvaluated
      }
    }).afterClosed().subscribe(
      resp => {     
        if(resp){
          console.log("confirmado", resp);
          this.loadEncuestas();
        }
      }
    );
  }

  answered = true;

  private isSurveyAsnswered() : boolean{
    console.log(this.productListEvaluated.some((x:any) => x.status != 1));
   return this.productListEvaluated.some((x:any) => x.status != 1)
  }

  getEstafeta():string{
    if(this.encuestaData.surveyData.length>0){
      return this.encuestaData.surveyData[0].estafeta;
    }else{
      return "-"
    }
  }

  getProductStatusObj(idstatus : number):any{
      return environment.status_surveys.filter(x=> x.id  == idstatus)[0];
  }

  getMaterialImage(id:number, nombre : string, images : any){
        if(images !=0){
          this.matDialog.open(SurveyImageSliderComponent, {
            panelClass: ['dialog-img-lg'],
            data: {
              images : images,
              material_info : nombre,
              campana : null
            }
          }).afterClosed().subscribe(
            resp => {
              if(resp?.reload) {
                
              }
            }
          );
        }
  }

  toggleAlerta(event : any, product : any){
   if(!event.checked){
     product.comentario = "";
   }else{

     this.matDialog.open(SurveysComentaryComponent, {
       panelClass: 'dialogs-main',
       data: {
         product : product,
         isClaw : this.isClaw_survey
       }
     }).afterClosed().subscribe(
       resp => {     
         if(resp){

         }else{
          
           product.comentario = "";
           product.alerted = false;
         }
       }
     );

   }
    console.log(event.checked , product);
    
  }


}

<div *ngIf="dataGet"  class="container-xxl">
    <div class="authentication-wrapper authentication-basic container-p-y">
      <div class="authentication-inner">
        <div class="card">
          <div class="card-body">
            <div class="app-brand justify-content-center">
                <a href="index.html" class="app-brand-link gap-2">
                    <span class="app-brand-logo demo">
                        <img src="assets/img/Buho_Nest-BK.png" alt="">
                    </span>
                </a>
            </div>
                <h4 class="my-4">Te damos la bienvenida a Buho Nest 👋</h4>
                <p class="mb-4">Por favor ingresa tus datos y contraseña para acceder a Búho Nest</p>
                <h5>Correo registrado: <strong>{{ dataGet?.email }}</strong></h5>
                <form [formGroup]="formPassword" (ngSubmit)="sendPassword()" id="passwordForm"  autocomplete="off">
                    <div>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput placeholder="Nombre" formControlName="nombre" name="nombre" >
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Puesto</mat-label>
                            <input matInput placeholder="Puesto" formControlName="puesto" name="puesto" >
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Teléfono de contacto</mat-label>
                            <input matInput placeholder="Teléfono" formControlName="phone" name="phone"  maxlength="10">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Contraseña</mat-label>
                            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="pass" name="pass" >
                            <mat-icon matSuffix (click)="hide = !hide" role="button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Confirma contraseña</mat-label>
                            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="passConfirm" name="passConfirm" >
                            <mat-icon matSuffix (click)="hide = !hide" role="button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="alert alert-warning" *ngIf="!formPassword.controls['pass'].valid && formPassword.controls['pass'].touched">
                        Tu contraseña debe cumplir con los siguientes requerimientos:
                        <ul>
                            <li>Mínimo de 8 caracteres</li>
                            <li>Al menos 1 letra Mayúscula</li>
                            <li>Al menos 1 letra minúscula</li>
                            <li>Al menos 1 número</li>
                        </ul>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" class="w-100 mt-2" type="submit">Guardar <mat-icon>save</mat-icon></button>
                    </div>
                </form>
          </div>
        </div>
      </div>
    </div>
</div>

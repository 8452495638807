<div class="col-md-12 mt-3">
    <div class="overflow-auto w-100">
        <div class="float-end">
            <button mat-raised-button color="primary" (click)="openDialogUsersCreate()" class="mt-2 mb-2">
                Agregar nuevo <mat-icon>person_add</mat-icon>
            </button>
        </div>
        <mat-form-field>
            <mat-label>
                <mat-icon>search</mat-icon> Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. mail@mail.com" #input>
        </mat-form-field>
    </div>

    <div class="mat-elevation-z8 table-responsive">
        <table mat-table [dataSource]="dataSource" matSort class="w-100">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rol </th>
                <td mat-cell *matCellDef="let row"> {{row.rol_name}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Completo</th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>
            
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Compañía</th>
                <td mat-cell *matCellDef="let row"> {{getCompanyName(row.companies)}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-center" [hidden]="!hasPermissions('upd_usr',true,null)"> Acciones </th>
                <td mat-cell *matCellDef="let row" class="text-center" [hidden]="!hasPermissions('upd_usr',true,null)">
                    <button mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" (click)="openDialogUsersEdit(row)" color="accent">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <mat-slide-toggle [checked]="row.active == 1" (click)="activateUser(row.id, row.active, row.name, row.id_serv)" matTooltip="¿Activo?" [matTooltipPosition]="'above'" class="text-primary"></mat-slide-toggle>
                    <button [hidden]="!isAdmin()" mat-icon-button matTooltip="Eliminar" [matTooltipPosition]="'above'" color="warn" (click)="deleteUser(row.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No existe información que coincida con "{{input.value}}"</td>
            </tr>
        </table>

        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
            <mat-progress-spinner color="primary" mode="indeterminate">
            </mat-progress-spinner>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
</div>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import { NotifEditComponent } from 'src/app/components/shared/dialogs/notif-edit/notif-edit.component';
import { CampaignsService } from 'src/app/services/campaigns.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() menuEvent = new EventEmitter<boolean>();

  userObj!: UserModel;
  roleName!: any;
  isLoading = true;  
  campaingSearchList : Array<any> = [];

  constructor(
    private auth: AuthService,
    private router: Router,
    private userS: UsersService,
    private matDialog: MatDialog,
    private campaignService: CampaignsService,
  ) { }

  isBuhoUsr : boolean = false;
  modules :any;
  searchQuery  : string = "";

  ngOnInit(): void {
    this.isBuhoUsr = (this.userS.getUser().id_rol == environment.role_buho || this.userS.getUser().id_rol == environment.role_admin_id);
    this.modules = this.userS.getUserModules();
    this.userObj = this.userS.getUser();
    if(this.userObj.id_provider_member == null){
      this.roleName = environment.roles.find(el => el.id === this.userObj.id_rol)?.rol;
    }else{
      this.roleName = "Proveedor"
    }

  }

  menuToggle() {
    this.menuEvent.emit(true);
  }



  dialogNotif() {
    this.matDialog.open(NotifEditComponent, {
      panelClass: 'dialogs-sm'
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;       
      }
    );
  }

  doSearch(evento : any){
    const inputValue = (evento.target as HTMLInputElement).value;
    this.searchQuery = inputValue;

    this.campaingSearchList = [];
    
    if(inputValue.trim()!= ''){
      this.campaignService.searchCampaingFolio(inputValue).subscribe((x: any) => {
      
        this.campaingSearchList = x.data;
  
      },
      (err:any) => {
           // this.main.HideLoading();
          }
      );
    }

  }

  onResultSelection(nombre_campana : string , id : number, searchterm : string, type : string){
   
      if(type == "Folio"){
        this.router.navigateByUrl(`/admin/distribution/${id}?folio=${searchterm}` );
      }else{
        this.router.navigateByUrl(`/admin/distribution/${id}` );
      }
    
      this.searchQuery = "";
      this.campaingSearchList = [];

  }

  hasDistribucion(){
    return this.isBuhoUsr ? true :  this.modules.filter((e:any) => e.clave === 'dist').length > 0
  }

  logOut() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }

}

import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProjectDiagramationAddComponent } from '../../shared/dialogs/project-diagramation-add/project-diagramation-add.component';
import { ProjectDiagramationVobosComponent } from '../../shared/dialogs/project-diagramation-vobos/project-diagramation-vobos.component';
import { ProjectDiagramationVobosConfigComponent } from '../../shared/dialogs/project-diagramation-vobos-config/project-diagramation-vobos-config.component';
import { ProjectsArtUploadComponent } from '../../shared/dialogs/projects-art-upload/projects-art-upload.component';
import { ProjectsDiagramationInventoryComponent } from '../../shared/dialogs/projects-diagramation-inventory/projects-diagramation-inventory.component';
import { ProjectsDiagramationDistributionComponent } from '../../shared/dialogs/projects-diagramation-distribution/projects-diagramation-distribution.component';

@Component({
  selector: 'app-project-diagramation',
  templateUrl: './project-diagramation.component.html',
  styleUrls: ['./project-diagramation.component.scss']
})
export class ProjectDiagramationComponent implements OnInit {

  constructor( private matDialog: MatDialog,
    private projectsS: ProjectsService,
    private _params: ActivatedRoute,
    private main: MainService,
    private _router: Router) { }

  projectPhases = 
    [{phase_id: 1, name : "Artes v1", artes_fecha:'10/10/2024', vobos: [{nombre: "Marketing", fecha : "21/10/2024"}, {nombre: "Trade", fecha : "21/10/2024"}, {nombre: "Promociones", fecha : "21/10/2024"}]},
     {phase_id: 1, name : "Artes v2", artes_fecha:'10/10/2024', vobos: [{nombre: "Marketing", fecha : "21/10/2024"}, {nombre: "Trade", fecha : "21/10/2024"}, {nombre: "Promociones", fecha : "21/10/2024"}]}, 
     {phase_id: 1, name : "Artes v3", artes_fecha:'10/10/2024', vobos: [{nombre: "Marketing", fecha : "21/10/2024"}, {nombre: "Trade", fecha : "21/10/2024"}, {nombre: "Promociones", fecha : "21/10/2024"}]},
    ];
  projectDiagramations = [{id: 1, name: "Diagramación 1", sold : 15, stores: 10, 
    phases :[{id: 2,  phase_id: 1, name:"Fase 1 Artes", art_upload: true, next_vobo: "Marketing", limited_time: "24/10/2024", vobos: 4, ended: 4, vobo_owner: 0,
      vobosList: [{nombre: "Marketing", approved : true}, {nombre: "Trade", approved : true}, {nombre: "Promociones", approved : true}]
    },   
    {id: 2, phase_id: 2, name:"Fase 2 Artes", art_upload: true, next_vobo: "Trade", limited_time: "24/10/2024", vobos: 2, ended: 1, vobo_owner:  1,
      vobosList: [{nombre: "Marketing", approved : true}, {nombre: "Trade", approved : true}, {nombre: "Promociones", approved : false}]},
    {id: 3, phase_id: 3, name:"Fase 3 Artes", art_upload: false, next_vobo: "Promociones", limited_time: "30/10/2024", vobos: 4, ended: 0, vobo_owner:  0,
      vobosList: [{nombre: "Marketing", approved : false}, {nombre: "Trade", approved : false}, {nombre: "Promociones", approved : false}]},
    ]
   },
   {id: 2, name: "Diagramación 1", sold : 15, stores: 10, 
    phases :[{id: 2,  phase_id: 1, name:"Fase 1 Artes", art_upload: true, next_vobo: "Marketing", limited_time: "24/10/2024", vobos: 4, ended: 4, vobo_owner: 0,
      vobosList: [{nombre: "Marketing", approved : true}, {nombre: "Trade", approved : true}, {nombre: "Promociones", approved : true}]
    },   
    {id: 2, phase_id: 2, name:"Fase 2 Artes", art_upload: true, next_vobo: "Trade", limited_time: "24/10/2024", vobos: 2, ended: 1, vobo_owner:  1,
      vobosList: [{nombre: "Marketing", approved : true}, {nombre: "Trade", approved : false}, {nombre: "Promociones", approved : false}]},
    {id: 3, phase_id: 3, name:"Fase 3 Artes", art_upload: false, next_vobo: "Promociones", limited_time: "30/10/2024", vobos: 4, ended: 0, vobo_owner:  0,
      vobosList: [{nombre: "Marketing", approved : false}, {nombre: "Trade", approved : false}, {nombre: "Promociones", approved : false}]}
    ]
   },];

  ngOnInit(): void {
  }

  openEditProjectDiagramDialog() {
    this.matDialog.open(ProjectDiagramationAddComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        edit : true,
        data: this.projectDiagramations
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }


  openVobosDiagramDialog() {
    this.matDialog.open(ProjectDiagramationVobosComponent, {
      panelClass: ['dialogs-xl'],
      data: {
        edit : true,
        data: this.projectDiagramations
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openVobosDiagramConfigDialog() {
    this.matDialog.open(ProjectDiagramationVobosConfigComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        edit : true,
        data: this.projectDiagramations
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openUploadArtFIleDialog(){
    this.matDialog.open(ProjectsArtUploadComponent, {
      panelClass: ['dialogs-md'],
      data: {
        edit : true,
        data: this.projectDiagramations
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openInventoryDialog(){
    this.matDialog.open(ProjectsDiagramationInventoryComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        edit : true,
        data: this.projectDiagramations
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }


  openDistributionDialog(){
    this.matDialog.open(ProjectsDiagramationDistributionComponent, {
      panelClass: ['dialogs-lg'],
      data: {
        edit : true,
        data: this.projectDiagramations
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  onlyPick3Lett(str: string): string {
    return str.substring(0, 3);
  }
}

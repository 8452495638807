<aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme h-100">
    <div class="app-brand demo">
        <a [routerLink]="'dashboard'" class="app-brand-link">
            <span class="app-brand-logo">
                <img src="../../../../assets/img/Buho_Nest-BK.png" alt="" />
            </span>
        </a>

        <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none" (click)="menuHide()">
            <mat-icon color="primary">chevron_left</mat-icon>
        </a>
    </div>
    <ul class="menu-inner py-1 scroll">
        <li class="menu-header small text-uppercase" [hidden]="!hasDistribucion() && !hasProyectos() && !hasDashboardSeg()"><span class="menu-header-text">Main</span></li> 
        <li class="menu-item" [routerLink]="'dashboard'" [routerLinkActive]="'active'" role="button" (click)="menuHide()" [hidden]="!hasDistribucion()">
            <a class="menu-link">
                <mat-icon>campaign</mat-icon>
                <span class="ms-2">Campañas</span>
            </a>
        </li>
        <li class="menu-item" [routerLink]="'distribution'" [routerLinkActive]="'active'" role="button" (click)="menuHide()" [hidden]="!canShow(0) || !distribucion.isActive"  #distribucion="routerLinkActive">
            <a class="menu-link">
                <mat-icon>layers</mat-icon>
                <span class="ms-2">Distribución</span>
            </a>
        </li>
        <li class="menu-item" [routerLink]="'surveys'" [routerLinkActive]="'active'" role="button" (click)="menuHide()" [hidden]="!canShow(0) || !encuestas.isActive"  #encuestas="routerLinkActive">
            <a class="menu-link">
                <mat-icon>playlist_add_check</mat-icon>
                <span class="ms-2">Encuestas</span>
            </a>
        </li>
        <li class="menu-item" [routerLink]="'projects'"  [hidden]="!hasProyectos()"  [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>extension</mat-icon>
                <span class="ms-2">Proyectos</span>
            </a>
        </li>
        <li class="menu-item" [routerLink]="'stores'" [hidden]="!hasSucursales()" [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>store</mat-icon>
                <span class="ms-2">Sucursales</span>
            </a>
        </li>
        <li class="menu-item" [routerLink]="'dashboard-tracking'" [hidden]="!hasDashboardSeg()" [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>dashboard</mat-icon>
                <span class="ms-2">Dashboard Seguimiento</span>
            </a>
        </li>
        <li class="menu-item" [routerLink]="'skus'" [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>auto_awesome_motion</mat-icon>
                <span class="ms-2">SKU's</span>
            </a>
        </li>
     
        <!--<li class="menu-header small text-uppercase" [hidden]="!hasSegmentacion()"><span class="menu-header-text">Segmentación</span></li>
        <li class="menu-item" [routerLink]="'segmentation/groups'"  [hidden]="!hasSegmentacion()" [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>group_work</mat-icon>
                <span class="ms-2">Segmentos</span>
            </a>
        </li>
        <li class="menu-item" [routerLink]="'segmentation/distribution'" [hidden]="!hasSegmentacion()" [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>mediation</mat-icon>
                <span class="ms-2">Distribución</span>
            </a>
        </li>-->
        
        <li class="menu-header small text-uppercase" [hidden]="!hasRecursos()"><span class="menu-header-text">Recursos</span></li>
        <li class="menu-item" [routerLink]="'template-design'" [routerLinkActive]="'active'" role="button" (click)="menuHide()" [hidden]="!hasRecursos()">
            <a class="menu-link">
                <mat-icon>design_services</mat-icon>
                <span class="ms-2">Template Design</span>
            </a>
        </li>        
        <li class="menu-header small text-uppercase" [hidden]="!hasEncuestas()"><span class="menu-header-text">Tickets</span></li>
        <li class="menu-item" [routerLink]="'tickets'" [routerLinkActive]="'active'" role="button" (click)="menuHide()" [hidden]="!hasEncuestas()">
            <a class="menu-link">
                <mat-icon>note</mat-icon>
                <span class="ms-2">Seguimiento</span>
            </a>
        </li>        
        <li class="menu-item" [routerLink]="'tickets-all'" [routerLinkActive]="'active'" role="button" (click)="menuHide()" [hidden]="!hasEncuestas() || !isBuhoUsr">
            <a class="menu-link">
                <mat-icon>note</mat-icon>
                <span class="ms-2">Listado</span>
            </a>
        </li>   
        <li class="menu-header small text-uppercase" [hidden]="!canShow(0) || isClientUsr"><span class="menu-header-text">Otros</span></li>
        <li class="menu-item" [routerLink]="'settings'" [routerLinkActive]="'active'" role="button" (click)="menuHide()" [hidden]="!canShow(0) || isClientUsr">
            <a class="menu-link">
                <mat-icon>settings</mat-icon>
                <span class="ms-2">Configuración</span>
            </a>
        </li>  
        <li class="menu-item" [routerLink]="'providers'" [hidden]="!hasProveedores()"  [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>next_week</mat-icon>
                <span class="ms-2">Proveedores</span>
            </a>
        </li>      
        <li class="menu-item" [routerLink]="'providers-members'" [hidden]="!isProviderMember"  [routerLinkActive]="'active'" role="button" (click)="menuHide()">
            <a class="menu-link">
                <mat-icon>extension</mat-icon>
                <span class="ms-2">Proyectos</span>
            </a>
        </li>     
    </ul>
</aside>
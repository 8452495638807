<div style="z-index: 1300; position: relative;" *ngIf="loading"> 
    <div class="loading-backdrop" id="full-backdrop">
        <div class="loading-comp">
          <mat-progress-spinner [diameter]="140" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</div>
    <div *ngIf="!loading" class="container-encuestas">
        <div class="encuestas-header">
            <span class="badge badge-info"> Encuesta </span>
            <h1> <mat-icon class="text-info align-middle">label</mat-icon> Check List | Recepción de Materiales </h1>
            <div> 
                <span class="first-steps-legend"> Primeros pasos <mat-icon class="align-middle">arrow_right_alt</mat-icon> <a target="_target" href="https://drive.google.com/file/d/1lBgv9vcM9cQGwDlsiCySK7h9HiFg-qbF/view" class="btn btn-sm btn-success" role="button">Ver manual</a> </span> 
                <span *ngIf="encuestaData.campana.id_protocolo" class="first-steps-legend p-2"> <a target="_target" [href]="'https://drive.google.com/file/d/' + encuestaData.campana.id_protocolo + '/view'" class="btn btn-sm btn-success" role="button">Ver Protocolo</a> </span> 

            </div> 
        </div>   
        
        <div class="card">
            <div class="card-header encuestas-card-header">
               <h5>{{ encuestaData.campana.name }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <p> <b>Consecutivo</b> : {{ encuestaData.surveyData.consec }} </p>
                    <p> <b>Sucursal</b> : {{ encuestaData.surveyData.sucursal }} </p>
                    <!--<p> <b>Estafeta</b> : {{ getEstafeta() }} </p>-->
                    <!--<p> <b>Campaña</b> : <span  *ngIf="encuestaData.campana.bloqueo == 0" class="badge badge-info">Activa</span> <span  *ngIf="encuestaData.campana.bloqueo == 1" class="badge badge-error">Terminada</span> </p>
                    <p> <b>Distribución</b> : <span  *ngIf="encuestaData.campana.bloqueo == 0" class="badge badge-info">Activa</span> <span  *ngIf="encuestaData.campana.bloqueo == 1" class="badge badge-error">Terminada</span> </p>-->
                    <p> <b>Encuestas</b> : <span *ngIf="!answered" class="badge badge-warning">Pendiente</span>  <span *ngIf="answered" class="badge badge-success">Terminada</span>  </p>
                    <!--<p> <b>Fecha de inicio</b> : {{ encuestaData.campana.created_at | date : "dd/MM/yyyy"}} </p>-->
                </div>
            </div>     
        </div>
        <br>
        <div class="card"> 
            <div class="card-header encuestas-card-products">
                <h5>Lista productos</h5>
             </div>
            <div class="card-body">
        
                <div *ngFor="let product of productListEvaluated; let i = index" class="encuesta-product">
                    <div class="row">
                        <ng-container  *ngIf="!isClaw_survey">
                            <div class="product-title">
                                <b>{{product.cantidad_pza>1?(product.nombre_mat + " (1 kit = " + product.cantidad_pza + " pz)"):product.nombre_mat }} </b>
                            </div>
                            <div class="col-4 img-container" *ngIf="product.images.length == 0">
                                <img class="img-details center" src="assets/img/nophoto.png" alt="">     
                            </div>
                            <div class="col-4 img-container" *ngIf="product.images.length > 0">
                                <img class="img-details center" (click)="getMaterialImage(product.id_material_camp, product.nombre_mat,product.images)" src="{{ urlImage + product.images[0].archivo}}" alt="">     
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isClaw_survey">
                            <div class="product-title">
                                <b>{{product.cantidad_pza>1?(product.nombre + " (1 kit = " + product.cantidad_pza + " pz)"):product.nombre }} </b>
                            </div>
                            <div class="col-4 img-container" *ngIf="!product?.images||product?.images?.length == 0">
                                <img class="img-details center" src="assets/img/nophoto.png" alt="">     
                            </div>
                            <div class="col-4 img-container" *ngIf="product?.images?.length > 0">
                                <img class="img-details center" (click)="getMaterialImage(product.id, product.nombre,product.images)" src="{{ product.images[0]}}" alt="">     
                            </div>
                        </ng-container>

                        <div class="col-8 row">
                             <div class="col-6">
                                <span><b>Cantidad enviada:</b></span>
                                <br>
                                <input class="col-6 text-center" type="text" value="{{product.cantidad}}" disabled>
                             </div>
                             <div class="col-6 divider-left">
                                <span [ngClass]="getProductStatusObj(product.status).class" style="position:relative; top: -22px">{{ getProductStatusObj(product.status).nombre }}</span>

                                <div class="row" style="position:relative; top: -22px">
                                    <div *ngIf="!answered" class="slidder-alertar">
                                        <b>Alertar</b>
                                        <br>
                                        <mat-slide-toggle (change)="toggleAlerta($event, product)" [(ngModel)]="product.alerted"></mat-slide-toggle>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <hr class="hr" />
                    </div>
                </div>

                <div *ngIf="!answered"  class="text-center">
                    <button class="btn btn-success" (click)="openConfirm()"><mat-icon class="align-middle">play_arrow</mat-icon> Enviar Encuesta</button>
                </div>
         
            </div>       
        </div>  

    </div>


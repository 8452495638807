<div class="modal-header">
    <h4 *ngIf="!data.isEdit" class="modal-title" id="mondalAddCampaignTitle">Nuevo atributo</h4>    
    <h4 *ngIf="data.isEdit" class="modal-title" id="mondalAddCampaignTitle">Editar atributo</h4>    
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <form>        
        <div class="row">            
            <div *ngIf="!data.isEdit" class="col-md-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre de atributo</mat-label>
                    <input matInput placeholder="Ej: Número de cajas"  [(ngModel)]="new_attribute.name" name="name" required>
                    <mat-icon matSuffix>campaign</mat-icon>
                </mat-form-field>
            </div>
            <div  *ngIf="!data.isEdit" class="col-md-12">
                <mat-form-field appearance="outline" class="w-100" name="atributo">
                    <mat-label>Tipo de atributo</mat-label>
                    <mat-select [(ngModel)]="new_attribute.type_id" (selectionChange)="onchangevalue($event.value)"  name="type">
                        <mat-option *ngFor="let type of types" [value]="type.type_id">{{type.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="new_attribute.type_id == 3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Opciones</mat-label>
                    <mat-chip-list #chipList>
                      <mat-chip *ngFor="let opt of options" (removed)="remove(opt)">
                        {{opt}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip>
                      <input placeholder="Nueva opción..."
                             [matChipInputFor]="chipList"
                             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                             [matChipInputAddOnBlur]="addOnBlur"
                             (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                  </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="new_attribute.type_id == 4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Rango Minimo</mat-label>
                    <input  [(ngModel)]="rangemin" name="sele1" matInput type="number"> 
                    <!--<input  [(ngModel)]="new_attribute.select_data" name="sele" matInput >-->
                </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="new_attribute.type_id == 4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Rango Maximo</mat-label>
                    <input  [(ngModel)]="rangemax" name="sele2" matInput type="number"> 
                    <!--<input  [(ngModel)]="new_attribute.select_data" name="sele" matInput >-->
                </mat-form-field>
            </div>

            <div *ngIf="!data.isEdit" class="col mb-0">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Activo desde: </mat-label>
                    <input [(ngModel)]="new_attribute.active_since_dt" name="since" name="pickerInput" (ngModelChange)="onchangedate($event)" matInput [matDatepicker]="picker" [min]="minDate" placeholder="DD / MM / YYYY" disabled required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-slide-toggle  [(ngModel)]="new_attribute.editable" name="editable"  class="py-3">¿Es editable?</mat-slide-toggle>
            </div>

            <div class="col-md-12">
                <mat-slide-toggle  [(ngModel)]="new_attribute.autorization" name="autorization" class="py-3">¿Requiere autorización?</mat-slide-toggle>
            </div>
        </div>        
    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="save()">Guardar <mat-icon>save</mat-icon></button>
</div>
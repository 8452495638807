<!-- Content -->
<div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4 text-primary">
        <ng-container *ngIf="!isEdit"><mat-icon class="me-2 align-middle">hub</mat-icon>Agregar proveedor</ng-container>
        <ng-container *ngIf="isEdit"><mat-icon class="me-2 align-middle">hub</mat-icon>Editar proveedor</ng-container>
    </h4>
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-body">
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2">hub</mat-icon> Información de Proveedor
                            </ng-template>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-12 my-4">
                                        <h5>Captura la información del nuevo proveedor:</h5>
                                        <form autocomplete="off" [formGroup]="formProvider" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
                                            <small>Todos los campos son obligatorios *</small>
                                            <div class="row mt-2">
                                                <div class="col-sm-6">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>Nombre (Alias)</mat-label>
                                                        <input matInput formControlName="name">
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-sm-6">
                                                    <mat-form-field class="w-100" appearance="outline">
                                                        <mat-label>Razón social</mat-label>
                                                        <input matInput formControlName="companyName">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-12 text-end">
                                                    <!--<a class="btn" mat-stroked-button color="primary">Carga logo de Proveedor
                                                        <mat-icon>image</mat-icon>
                                                    </a>-->
                                                    <button mat-flat-button class="bg-success text-white ms-4" form="userForm"
                                                        type="submit">Guardar <mat-icon>save</mat-icon></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf="isEdit">
                            <ng-template mat-tab-label>
                                <mat-icon class="me-2">groups</mat-icon> Miembros
                            </ng-template>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-12 my-4">
                                        <h5>Lista de Miembros</h5>
                                        <div class="overflow-auto w-100">
                                            <div class="float-end">
                                                <button mat-raised-button color="primary" (click)="openDialogProvidersMemberAdd()"
                                                    class="mt-2 mb-2">
                                                    Agregar miembro <mat-icon>person_add</mat-icon>
                                                </button>
                                            </div>
                                            <mat-form-field>
                                                <mat-label>
                                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                                <input matInput (keyup)="applyFilter($event)" #input>
                                            </mat-form-field>
                                        </div>
                
                                        <div class="mat-elevation-z8 table-responsive">
                                            <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                                                <ng-container matColumnDef="id">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.id_provider_member}} </td>
                                                </ng-container>
                
                                                <ng-container matColumnDef="name">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                                                </ng-container>
                
                                                <ng-container matColumnDef="email">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                                                </ng-container>
                
                                                <ng-container matColumnDef="permissions">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Permisos </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <button mat-flat-button [matMenuTriggerFor]="menu2" [ngClass]=" row.can_edit  == 1? 'bg-success text-white' : 'bg-info text-white'">
                                                            {{ row.can_edit == 1 ? 'Aprueba' : 'Solo lectura' }} <mat-icon>expand_more</mat-icon>
                                                        </button>
                                                        <mat-menu #menu2="matMenu">                                            
                                                            <button mat-menu-item><span class="badge bg-info" (click)="setCanEdit(row.id_provider_member, 0)">Solo lectura</span></button>
                                                            <button mat-menu-item><span class="badge bg-success" (click)="setCanEdit(row.id_provider_member, 1)">Aprueba</span></button>                                            
                                                        </mat-menu>
                                                    </td>
                                                </ng-container>
                
                                                <ng-container matColumnDef="notifications">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Notificaciones </th>
                                                    <td mat-cell *matCellDef="let row"> <mat-slide-toggle [checked]="row.active_not == 1" (click)="setNotifyMember(row.id_provider_member, row.active_not)" matTooltip="¿Recibe notificaciones?"
                                                        [matTooltipPosition]="'above'" class="text-primary"></mat-slide-toggle> </td>
                                                </ng-container>
                
                                                <ng-container matColumnDef="actions">
                                                    <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                                    <td mat-cell *matCellDef="let row" class="text-center">
                                                        <button (click)="openDialogEdit(row.id_provider_member)" mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'"
                                                            color="accent">
                                                            <mat-icon>edit</mat-icon>
                                                        </button>
                                                        <button (click)=" openDialogPassword(row.id)" mat-icon-button matTooltip="Reset Password" [matTooltipPosition]="'above'"
                                                            color="accent">
                                                            <mat-icon>lock_reset</mat-icon>
                                                        </button>
                                                        <mat-slide-toggle [checked]="row.active == 1" (click)="activateProviderMember(row.id_provider_member, row.active, row.id)" matTooltip="¿Activo?"
                                                            [matTooltipPosition]="'above'" class="text-primary"></mat-slide-toggle>
                                                        <button (click)="deleteUser(row.id_provider_member)" mat-icon-button matTooltip="Eliminar" [matTooltipPosition]="'above'"
                                                            color="warn">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </td>
                                                </ng-container>
                
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="6">No existe información que coincida con
                                                        "{{input.value}}"</td>
                                                </tr>
                                            </table>
                
                                            <div *ngIf="isLoading"
                                                style="display: flex; justify-content: center; align-items: center; background: white;">
                                                <mat-progress-spinner color="primary" mode="indeterminate">
                                                </mat-progress-spinner>
                                            </div>
                                            <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </mat-tab>
                    </mat-tab-group>                    
                </div>
            </div>
        </div>
    </div>
</div>
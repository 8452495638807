import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as chartData from '../shared/data/charts';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CampaignTrackingModel, ClawCampaignModel } from 'src/app/models/campaign.model';
import { MainService } from 'src/app/services/main.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { MatSelectionList } from '@angular/material/list';
import { Metrics, TotalReports } from '../tickets/ticket-list/ticket-list.component';

@Component({
  selector: 'app-dashboard-tracking',
  templateUrl: './dashboard-tracking.component.html',
  styleUrls: ['./dashboard-tracking.component.scss']
})
export class DashboardTrackingComponent implements OnInit {
  isLoading = true;
  campaignList = new Array<ClawCampaignModel>();
  campaignSelected?: ClawCampaignModel;
  campaignData = new Array<CampaignTrackingModel>();
  ticketsExtraData = new Array<CampaignTrackingModel>();
  filteredCampaignData:any;
  filteredTicketsExtraData:any;
  processedCampaignData:any;
  pendingBoxesDataSource!: MatTableDataSource<any>;
  campaignDataSource!: MatTableDataSource<any>;
  ticketsExtraDataSource!: MatTableDataSource<any>;
  metrics!: Metrics;
  totalReports!: TotalReports;
  filterOptions:any;
  filterSelection:any = {
    zones:  [],
    status: []
  }

  displayedColumns: string[] = ['zona', 'sucursal', 'guia', 'estatus', 'lastUpdate'];
  ShipmentsdisplayedColumns: string[] = ['zona', 'sucursal', 'guia', 'estatus', 'fechaEntrega','fechaContesta' , 'direccion', 'recibio', 'lastUpdate'];
  TicketsExtradisplayedColumns: string[] = ['zona', 'sucursal', 'guia', 'estatus', 'fechaEntrega', 'direccion', 'recibio',  'lastUpdate'];
  estatusMapping_original:any = {
    'assigned': { label:'Recolectado en centro de distribución', color: 'bg-primary' },
    'order_created': {label:'Orden recibida', color: 'bg-purple'},
    'order_completed': {label: 'Orden surtida', color: 'bg-light-info'},
    'started': {label:'¡En ruta!', color: 'bg-warning'},
    'successful': {label: 'Entregado', color: 'bg-success' },
    'indicent': {label:'Incidencia', color: 'bg-warning'},
    'return': {label:'Incidencia', color: 'bg-warning'},
    'error': {label:'Error', color: 'bg-danger'}
  };
  
  estatusMapping:any = {
    'assigned': { label:'En tránsito', color: 'bg-primary' },
    'order_created': {label:'Esperando recolección', color: 'bg-purple'},
    'order_completed': {label: 'Recolectado', color: 'bg-info'},
    'started': {label:'En ruta de entrega', color: 'bg-warning'},
    'successful': {label: 'Entregado', color: 'bg-success' },
    'error': {label:'Incidencia', color: 'bg-danger'},
    'return': {label:'Incidencia', color: 'bg-danger'},
     null: {label:'Incidencia', color: 'bg-danger'},
  };
  
  estatusMappingTable: any = {
    assigned: { label: 'En tránsito', color: 'bg-primary' },
    order_created: { label: 'Esperando recolección', color: 'bg-purple' },
    order_completed: { label: 'Recolectado', color: 'bg-info' },
    started: { label: 'En ruta de entrega', color: 'bg-warning' },
    successful: { label: 'Entregado', color: 'bg-success' },
    'entrega dedicada': { label: 'Entregado', color: 'bg-success' },
    error: { label: 'Incidencia', color: 'bg-danger' },
    incident: { label: 'Incidencia', color: 'bg-danger' },
    canceled: { label: 'Incidencia', color: 'bg-danger' },
    'return': {label:'Devolución', color: 'bg-danger'},
    '1': { label: 'Incidencia', color: 'bg-danger' },
    '': { label: 'Incidencia', color: 'bg-danger' },
  }

  @ViewChild('pendingBoxesPaginator') set pendingBoxesPaginator(value: MatPaginator) { if (this.pendingBoxesDataSource) this.pendingBoxesDataSource.paginator = value; }
  @ViewChild('sort') set sort (value: MatSort) { if (this.pendingBoxesDataSource) this.pendingBoxesDataSource.sort = value; }

  @ViewChild('campaignDataPaginator') set campaignDataPaginator(value: MatPaginator) { if (this.campaignDataSource) this.campaignDataSource.paginator = value; }
  @ViewChild('sort2') set sort2 (value: MatSort) { if (this.campaignDataSource) this.campaignDataSource.sort = value; }

  @ViewChild('ticketsExtraPaginator') set ticketsExtraPaginator(value: MatPaginator) { if (this.ticketsExtraDataSource) this.ticketsExtraDataSource.paginator = value; }
  @ViewChild('sort3') set sort3 (value: MatSort) { if (this.ticketsExtraDataSource) this.ticketsExtraDataSource.sort = value; }

 
  constructor(
    private campaignS: CampaignsService,
    private dashboardS: DashboardService,
    private mainService : MainService,
    private surveyS : SurveysService
    ) { }

  ngOnInit(): void {
    this.getCampaignList();
  }

  loaded : boolean = false;
  summary : any;

  getCampaignList() {
    this.campaignS.getCampaingList().subscribe(
      (result: any) => {
        this.campaignList = result;
        this.campaignList.reverse();
        this.setCampaignData(this.campaignList[0]);
        this.isLoading = false;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  setCampaignData (campaign:any) {
    this.isLoading = true;
    this.mainService.ShowLoading();
    this.campaignSelected = campaign;
    this.campaignS.getCampaignData(campaign.id).subscribe(
      (result: any) => {
        this.campaignData = result[0];
        this.ticketsExtraData = result[1];
        console.log(this.campaignData);
        this.processData(this.campaignData);
        this.loadMetrics(this.campaignData);    
        if(result[0].length > 1) this.initMap(this.campaignData);
        this.pendingBoxesDataSource = new MatTableDataSource(this.campaignData.filter((item: any) => item.estatus !== 'successful'));
        this.campaignDataSource = new MatTableDataSource(this.campaignData);
        this.ticketsExtraDataSource = new MatTableDataSource(this.ticketsExtraData);
        this.filterOptions = {
          zonas   : [...new Set(this.campaignData.map((el:any) => el['zona']))],
          estatus : [...new Set(this.campaignData.map((el:any) => el['estatus']))]
        }
        this.filterOptions.zonas = this.filterOptions.zonas.sort();
        this.filterSelection.zones = this.filterOptions.zonas;
        console.log(this.filterOptions);
        this.isLoading = false;   
        this.mainService.HideLoading();     
      }
    );

    this.loaded = false;
  }

  processData(campaignData:any) {
    this.processedCampaignData = this.dashboardS.processData(campaignData);
  }

  isDedicated(row_sts : any){
    if(row_sts.guia.toLowerCase() === 'entrega dedicada' && row_sts.estatus === null){
      return true
    }else{
      return false;
    }
  }

  async initMap(data?:any): Promise<void> {
    const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
    let map = new Map( document.getElementById('map') as HTMLElement, chartData.mapData);
    this.dashboardS.drawMap(data, map);
  }
  
  parseAddress(address: string) {
    const parsedAddress = JSON.parse(address);
    return parsedAddress ? `${parsedAddress.calle}, ${parsedAddress.colonia}, ${parsedAddress.postal_code}, ${parsedAddress.ciudad}, ${parsedAddress.estado}` : '' ; 
  }

  loadMetrics(camp: any): void {
    let list_id_zones = [...new Set(camp.map((el:any) => el['id_zona_nest']).filter((el:any)=>el))];
    let emp: any = null;
    this.surveyS.getMetrics(this.campaignSelected?.id ,list_id_zones , emp, emp).subscribe({
      next: ({data}) => {
        this.metrics = data[0][0];
        this.totalReports = data[1][0];
        this.loaded = true;
      },
      error: () => {
        this.loaded = true;
      }
    });
  }

  updateTrackingData(id:number) {
    this.isLoading = true;
    this.campaignS.updateTracking(id.toString()).subscribe(
      (result:any) => {
        if (result.status == "success") {
          this.setCampaignData(this.campaignSelected);
          this.isLoading = false;
        }   
      }
    );
  }

  getTotalsQty(){
   return this.processedCampaignData.successfulQty +  this.processedCampaignData.startedQty + this.processedCampaignData.assignedQty + this.processedCampaignData.orderCompletedQty 
    + this.processedCampaignData.orderCreatedQty + this.processedCampaignData.orderErrorQty;
  }

  toggleSelectAll(
    event: MouseEvent,
    selectorZonas: MatSelectionList,
    selectorEstatus: MatSelectionList
  ) {
    
    event.preventDefault();
    this.mainService.ShowLoading();
    setTimeout(()=>{    
      const allSelected = selectorZonas.options
      .toArray()
      .slice(1) 
      .every(option => option.selected);
  
    selectorZonas.options.forEach((option, index) => {
      if (index !== 0) {
        option.selected = !allSelected;
      }
    });

    this.filterCampaign(
      event,
      selectorZonas.selectedOptions.selected,
      selectorEstatus.selectedOptions.selected
    );
    this.mainService.HideLoading();
   }, 200);
  }

  toggleSelectAllSts(
    event: MouseEvent,
    selectorZonas: MatSelectionList,
    selectorEstatus: MatSelectionList
  ) {
    
    event.preventDefault();
    this.mainService.ShowLoading();
    setTimeout(()=>{    
      const allSelected = selectorEstatus.options
      .toArray()
      .slice(1) 
      .every(option => option.selected);
  
    selectorEstatus.options.forEach((option, index) => {
      if (index !== 0) {
        option.selected = !allSelected;
      }
    });

    this.filterCampaign(
      event,
      selectorZonas.selectedOptions.selected,
      selectorEstatus.selectedOptions.selected
    );
    this.mainService.HideLoading();
   }, 200);
  }


  formatDate(date: string) {
    if(!date) return 
    const fechaEntrega = date == null?  " " : date.replace(/undefined-/g, '');
    const fecha = fechaEntrega.split(' ');
    const sinHora = fecha[0];
    const fechaObj = new Date(sinHora);
    const anio = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaObj.getUTCDate().toString().padStart(2, '0');

    return `${anio}-${mes}-${dia}`;
  }

  filterCampaign($event: any, zones:any, status:any ) {
    $event.stopPropagation();
    $event.preventDefault();
    if(zones.length < 1){
      this.filterSelection.zones = zones.map((o:any) => o.value);
      this.setTemporaryCampaignData()
    }else{
      this.filterSelection.zones = zones.map((o:any) => o.value);
      this.filterSelection.status = status.map((o:any) => o.value);
      this.setTemporaryCampaignData();
    }
  }

  setTemporaryCampaignData (data?:any) {
    if(data){
      this.processData(data);
      this.initMap(data);
      this.pendingBoxesDataSource = new MatTableDataSource(data.filter((item: any) => item.estatus !== 'successful'));
      this.campaignDataSource = new MatTableDataSource(data);

      this.loadMetrics(this.filteredCampaignData);
      this.isLoading = false;
    }else{
      this.filteredCampaignData = this.campaignData.filter((el:any) => ( this.filterSelection.zones.includes(el.zona) && this.filterSelection.status.includes(el.estatus) ));
      this.filteredTicketsExtraData = this.ticketsExtraData.filter((el:any) =>( this.filterSelection.zones.includes(el.zona) && this.filterSelection.status.includes(el.status) ));
      this.isLoading = true;
      this.processData(this.filteredCampaignData);
      this.initMap(this.filteredCampaignData);
      this.pendingBoxesDataSource = new MatTableDataSource(this.filteredCampaignData.filter((item: any) => item.estatus !== 'successful'));
      this.campaignDataSource = new MatTableDataSource(this.filteredCampaignData);
      this.ticketsExtraDataSource = new MatTableDataSource(this.filteredTicketsExtraData);
      this.loadMetrics(this.filteredCampaignData);
      this.isLoading = false;
    }
  }
 
  calculateTotals(assigned : number, created : number, completed : number){
    return assigned + created + completed;
  }

  downloadExcel(){
   this.mainService.ShowLoading();
   setTimeout(()=>{ 
    let dataToPrint = this.campaignDataSource.filteredData.map(row => ({
      Zona: row.zona,
      Sucursal: row.sucursal,
      "Guía": row.guia,
      "Paquetería" : row.paqueteria,
      Estatus: this.estatusMappingTable[row.estatus]?.label || row.estatus,
      "Dirección": this.parseAddress(row.direccion),
      Recibio: row.recibio,
      "Última Actualización": this.formatDate(row.last_update),
      "Fecha entrega": row.fecha_entrega,
      "Respuesta Encuesta": this.formatDate(row?.fecha_contesta_encuesta) || ''
     }));
     this.mainService.generateExcel(dataToPrint, "Data Tracking " + this.campaignSelected?.name);
     this.mainService.HideLoading();
    },20);
  }

  zonesFilterremoveSelectAll(){
    return this.filterSelection.zones.filter((x:any)=> x != "selectAll");
  }
}

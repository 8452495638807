import { Component, OnInit } from '@angular/core';
import { ProjectsProvidersFormHistoryComponent } from '../../shared/dialogs/projects-providers-form-history/projects-providers-form-history.component';
import { CampaignLogsComponent } from '../../shared/dialogs/campaign-logs/campaign-logs.component';
import { ProjectsProvidersAddComponent } from '../../shared/dialogs/projects-providers-add/projects-providers-add.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { ProvidersSurveysLogsComponent } from '../../shared/dialogs/providers_surveys_logs/providers_surveys_logs.component';

@Component({
  selector: 'app-providers-projects',
  templateUrl: './providers-projects.component.html',
  styleUrls: ['./providers-projects.component.scss']
})
export class ProvidersProjectsComponent implements OnInit {
  hostName: string;

  constructor(
    private matDialog: MatDialog,
    private _router : Router,
    private projectService : ProjectsService,
    private main : MainService,
    private _params: ActivatedRoute,
  ) { 

    this.hostName = window.location.protocol + '//' + window.location.host + "/providers/";
  }

  ngOnInit(): void {
    this.getProviderView();
  }

  projects : any = [];
  campanas : any = [];
  surveys : any = [];
  responses : any = [];
  files : any = [];

  getProviderView(){
    this.main.ShowLoading();
    this.projectService.getListProjectsProvidersView().subscribe(
      (result:any) => {
        this.main.HideLoading();
        if(result.message == "Success") {
          console.log(result.data);
          this.projects = result.data.projects;
          this.campanas= result.data.campanas;
          this.surveys = result.data.surveys;
          this.files = result.data.files;
          this.responses = result.data.responses;
        }
      },
      (err:any) => {
        this.main.HideLoading();
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener el registro'
          })
      }
    );
  }

  surveysPendientes(){
    return this.surveys.filter((x:any)=> {  return x.status !== 2 } );
  }

  surveysAprobados(){
    return this.surveys.filter((x:any)=> {  return x.status == 2 } );
  }

  campanasPendientes(){
   let idsCampanas = this.surveysPendientes().map((x:any) => x.id_campana_proyecto); 
   return this.campanas.filter((x:any)=>{ return idsCampanas.includes(x.id)})
  }

  campanasAprobadas(){
    let idsCampanas = this.campanasPendientes().map((x:any) => x.id); 
    return this.campanas.filter((x:any)=>{ return !idsCampanas.includes(x.id)})
  }

  getSurveysByCampana(id_campana : any, id_provider : any){
     return  this.surveys.filter((x : any)=> { return x.id_campana_proyecto == id_campana && x.id_provider == id_provider });
  }

  getCampanasByProyecto(id_proyecto : any){
     return this.campanas.filter((x : any)=> { return x.id_proyecto == id_proyecto });
  }

  proyectosPendientes(){
    let idProyectos = this.campanasPendientes().map((x:any)=> x.id_proyecto);
    return this.projects.filter((x:any) => {return idProyectos.includes(x.id)});
  }
   
  proyectosAprobados(){
    let idProyectos = this.proyectosPendientes().map((x:any)=> x.id);
    return this.projects.filter((x:any) => {return !idProyectos.includes(x.id)});
  }

  DownloadElement(url : string){
    window.open(url, "_blank");
  }
   
  getAprobacionesPendientesByProyecto(id_proyecto : any){
    let proyectosPend  = this.campanasPendientes().filter((x:any)=>{ return x.id_proyecto == id_proyecto}).length
    let totalCamps = this.campanas.filter((x:any)=>{ return x.id_proyecto == id_proyecto}).length
 
    return  proyectosPend + " de " + totalCamps + " aprobaciones pendientes";
  }

  checkIfCampanaAprobada(camp_id:  any){
   return this.surveys.filter((x:any)=> {  return x.id_campana_proyecto == camp_id && x.status!= 2 }).length != 0;
  }

  openDialogAddProvidersFile() {
    this.matDialog.open(ProjectsProvidersAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  openDialogFileLogs(id_provider_survey : number) {
    this.matDialog.open(ProvidersSurveysLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        id_provider_survey : id_provider_survey 
      }
    }).afterClosed().subscribe(
      resp => {
       // this.getProviderView();
      }
    );
  }
  
  openDialogFormHistory(id_provider_survey : number, nomber : string) {
    console.log(this.responses, id_provider_survey, this.responses.filter((x : any )=>{ return x.id_provider_survey == id_provider_survey }))
    this.matDialog.open(ProjectsProvidersFormHistoryComponent, {
      panelClass: ['dialogs-lg'],
      data: {        
      responses : this.responses.filter((x : any )=>{ return x.id_provider_survey == id_provider_survey }),
      files : this.files,
      nombre_proveedor : nomber,
      folder : ""}
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  filterFiles(id_provider_survey_response : number, type : number){
    return this.files.filter((x:any)=>{ return x.id_provider_survey_response == id_provider_survey_response && x.type ==type})
  }

  goToProviderMaterial(id_provider_survey  : number) {
    this.main.ShowLoading();
    this.projectService.getFormUrl(id_provider_survey).subscribe((x:any)=>{
      if(x.message == "Success") {
        this.main.HideLoading();
        //console.log(x.data)
        var url = this.hostName + x.data;
        window.open(url, "_blank");
      }else{
        this.main.HideLoading();
      }
    }); 
  }

  gotoForm(id_provider_survey  : number){
    
    this.projectService.getFormUrl(id_provider_survey).subscribe((x:any)=>{
      if(x.message == "Success") {
        //console.log(x.data)
        var url = this.hostName + x.data;
        window.open(url, "_blank");
      }
    });
  }

  responsedataAux : Array<any> = [];

  getPDF(id_provider_survey : number, nombreArchivo : string, nombreProvider : string){
    const pdf = new jsPDF({
      unit: 'px',
      format:  [650, 1022] 
    });

    this.responsedataAux = this.responses.filter((x:any)=>{ return x.id_provider_survey == id_provider_survey && x.current == 1});
    this.main.ShowLoading();
    setTimeout(()=>{  
      this.main.HideLoading();
      let content  : HTMLElement = document.getElementById('canvasToCheck')!;
      pdf.html(content, {
        callback: function (doc) {
          const totalPages = doc.getNumberOfPages();
          for (let i = totalPages; i > 1; i--) {
            doc.deletePage(i);
          }
          let d = new Date();
          doc.save('form_'+ nombreArchivo + '_'+ nombreProvider + d.getDate().toString() + (d.getMonth() + 1).toString() + d.getFullYear().toString() + "_" + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString() +'.pdf');
        }
     }); },500);
  }

  getPDF_old(id_provider_survey : number, nombreArchivo : string, nombreProvider : string){
    const pdf = new jsPDF();

    this.responsedataAux = this.responses.filter((x:any)=>{ return x.id_provider_survey == id_provider_survey && x.current == 1});
    this.main.ShowLoading();
    setTimeout(()=>{  
      this.main.HideLoading();
      let content  : HTMLElement = document.getElementById('canvasToCheck')!;

      html2canvas(content, {
        // Opciones
        allowTaint: true,
        useCORS: false,
        // Calidad del PDF
        scale: 2
      }).then(canvas => {
        let imgData = canvas.toDataURL('image/png');
        let imgProps = pdf.getImageProperties(imgData);
        let pdfWidth = (pdf.internal.pageSize.getWidth());
        let pdfHeight = ((imgProps.height * pdfWidth ) / imgProps.width);
  
        pdf.addImage(imgData, 'PNG', 13, 25, pdfWidth * 0.85, pdfHeight * 0.85);
        pdf.save('form_'+ nombreArchivo + '_'+ nombreProvider + this.getUniqueName() +'.pdf');
      });

        },500);
  }

  

  private getUniqueName(): string {
    let d = new Date();
    let uniqueName = d.getDate().toString() + (d.getMonth() + 1).toString() + d.getFullYear().toString() + "_" + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
    return uniqueName
  }
}

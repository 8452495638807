<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">{{ isEdit? 'Editar' : 'Nueva ' }} campaña</h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<div class="modal-body">
    <h6>Ingresa la siguiente información:</h6>
    <form #f="ngForm">
        <div class="row">
            <div class="col mb-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre de campaña</mat-label>
                    <input  matInput placeholder="Ej: POP AGOSTO 2022" name="campaign" [(ngModel)]="newCampaign.name" required>
                    <mat-icon matSuffix>campaign</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row g-2" *ngIf="!isEdit||(isEdit&&!isClientUsr)">
            <div class="col mb-0">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Fecha {{isEdit?"límite":"de inicio de campaña"}}</mat-label>
                    <input name="pickerInput" (ngModelChange)="onchangedate($event)" matInput [matDatepicker]="picker" [min]="minDate" placeholder="DD / MM / YYYY" disabled [(ngModel)]="newDateTody" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>
            <h6 [hidden]="!visibledeadline">Fecha límite segun inicio de campaña: {{deadline|date: "dd/MM/yyyy"}}</h6>
        </div>

        <div class="row g-2" *ngIf="!isEdit&&!isClientUsr&&hasOnlyCompany()">
            <div class="col mb-0">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Compañía</mat-label>
                    <mat-select name="id_compania" [(ngModel)]="newCampaign.id_compania" required>
                        <mat-option *ngFor="let p of companiesList" [value]="p.id">{{p.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

    </form>
</div>
<div class="modal-footer">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" (click)="saveCampaign()">Guardar <mat-icon>save</mat-icon></button>
</div>
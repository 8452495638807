import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { StoreModel } from 'src/app/models/store.model';
import { StoreService } from 'src/app/services/store.service';
import { StoresAttributesPendingListComponent } from '../../shared/dialogs/stores-attributes-pending-list/stores-attributes-pending-list.component';
import { Router } from '@angular/router';
import { StoresAttributesEditComponent } from '../../shared/dialogs/stores-attributes-edit/stores-attributes-edit.component';
import { MainService } from 'src/app/services/main.service';
import { environment } from 'src/environments/environment';
import { StoresAttributesModalComponent } from '../../shared/dialogs/stores-attributes-modal/stores-attributes-modal.component';
import { StoresAddComponent } from '../../shared/dialogs/stores-add/stores-add.component';
import Swal from 'sweetalert2';
import { StoresCreateListUploadComponent } from '../../shared/dialogs/stores-create-upload/stores-create-upload.component';
import { StoreChangeLogsComponent } from '../..//shared/dialogs/sucursales_changelog/sucursales_changelogs.component';

@Component({
  selector: 'app-stores-list',
  templateUrl: './stores-list.component.html',
  styleUrls: ['./stores-list.component.scss']
})
export class StoresListComponent implements OnInit {

  storeList = new Array<StoreModel>();
  isLoading = true;  

  displayedCols : string[] = [];
  allAtributes : string[] = [];
  attributes = [];
  sucursales = [];
  viewData : any = [];
  relations = [];

  displayedColumns: string[] = ['id', 'Nombre', 'Plaza', 'FarmID', 'Activa'];
  types = [{ type_id : 1, name : "SI/NO"}, { type_id : 2, name : "FECHA"}, { type_id : 3, name : "OPCIONES"}, { type_id : 6, name : "NÚMERO"}, { type_id : 4, name : "RANGO"}, { type_id : 5, name : "TEXTO"}];
  dataSource!: MatTableDataSource<any>;


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private storeS: StoreService,
    private matDialog: MatDialog,
    private _titleService: Title,
    private _router: Router,
    private main: MainService,
  ) {
    this._titleService.setTitle("Buho Nest - Sucursales");
    this.getStoresSlim();
    //this.getStores();
    //this.getStoresList();
  }

  ngOnInit(): void {
  }

  getTypes(){
    return environment.att_types;
  }

  getStoresList() {
    this.storeList = this.storeS.getStoresList();
    this.dataSource = new MatTableDataSource(this.storeList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getStores(){
    this.main.ShowLoading();
    this.storeS.getStoresView().subscribe(
      (result : any) => {
      this.getStoreCutoff();
      this.main.HideLoading();
        this.storeS.attributes = result.data.atributos;
        this.storeS.viewData = result.data.viewData;
        this.storeS.allAtributes = result.data.allAtributes;
        this.storeS.relations = result.data.relations;

        this.attributes =   this.storeS.attributes;
        this.viewData =     this.storeS.viewData;
        this.allAtributes = this.storeS.allAtributes;
        this.relations =    this.storeS.relations;
        
        this.displayedCols = this.displayedColumns;
        
        if(!this.displayedCols.includes("actions")){
          this.allAtributes.push("actions");
          this.displayedCols.push("actions");
        }

        this.dataSource = new MatTableDataSource(this.viewData);       
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getStoresSlim(){
    this.main.ShowLoading();
    this.storeS.getStoresSlimView().subscribe(
      (result : any) => {
        
      this.main.HideLoading();
        this.getStoreCutoff();
        this.storeS.attributes = result.data.atributos;
        this.storeS.viewData = result.data.sucursales;
        this.storeS.allAtributes = result.data.allAtributes;
        this.storeS.relations = result.data.relations;

        this.attributes =   this.storeS.attributes;
        this.viewData =     this.storeS.viewData;
        this.allAtributes = this.storeS.allAtributes;
        this.relations =    this.storeS.relations;
        
        this.displayedCols = this.displayedColumns;
        
        if(!this.displayedCols.includes("actions")){
          this.allAtributes.push("actions");
          this.displayedCols.push("actions");
        }

        this.dataSource = new MatTableDataSource(this.viewData);       
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  cutoffs : any = []; 

  getStoreCutoff(){
    this.main.ShowLoading();
    this.storeS.getCutoffs().subscribe(
      (result : any) => {
        
      this.main.HideLoading();
        this.cutoffs = result.data;

        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
     
      }
    );
  }

  nowCutoffs(){
    let fechaHoy = new Date();

    return this.cutoffs.filter((objeto:any) => new Date(objeto.cutoff_date) >= fechaHoy);
  }

  oldCutoffs(){
    let fechaHoy = new Date();

    return this.cutoffs.filter((objeto:any) => new Date(objeto.cutoff_date) < fechaHoy);
  }

  openDialogChangelog(id_sucursal:number) {
    this.matDialog.open(StoreChangeLogsComponent, {
      panelClass: 'dialogs-lg',
      data: {
        id_sucursal : id_sucursal,
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getStoresSlim();
      }
    );
  }


  openDialogAttrsPending() {
    var pendientes  = this.relations.filter((x:any)=>  x.new_value_date != null);
    this.matDialog.open(StoresAttributesPendingListComponent, {
      panelClass: 'dialogs-lg',
      data: {
        zone : false,
        pendientes : pendientes,
        sucursales : this.viewData,
        attributes: this.attributes
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getStoresSlim();
      }
    );
  }

  openDialogMassiveUpload() {
    var pendientes  = this.relations.filter((x:any)=>  x.new_value_date != null);
    console.log(pendientes);
    this.matDialog.open(StoresCreateListUploadComponent, {
      panelClass: 'dialogs-lg',
      data: {
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getStoresSlim();
      }
    );
  }

  generateExcel(){
    this.main.ShowLoading();
    this.storeS.getStoresView().subscribe(
      (result : any) => {
        
      this.main.HideLoading();
        this.storeS.attributes = result.data.atributos;
        this.storeS.viewData = result.data.viewData;
        this.storeS.allAtributes = result.data.allAtributes;
        this.storeS.relations = result.data.relations;

        this.attributes =   this.storeS.attributes;
        this.viewData =     this.storeS.viewData;
        this.allAtributes = this.storeS.allAtributes;
       
        this.displayedCols = this.displayedColumns;
        
        this.main.generateExcel(this.viewData, "Sucursales_NEST", "sucursales")

        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
        this.isLoading = false;
      }
    );

   
  }
  
  retireActionsCol(){
    return this.displayedCols.filter(item => item !== 'actions')
  }

  goToCampaignCutoff() {
    this._router.navigateByUrl('/admin/stores/cutoffs')
  }

  goToAttributesList() {
    this._router.navigateByUrl('/admin/stores/attributes')
  }

  getNumberOfPendings(){
    return this.relations.filter((x:any)=>  x.new_value_date != null).length;
  }

  openDialogAttributeEditList(row : any) {
    console.log(row);
    this.matDialog.open(StoresAttributesEditComponent, {panelClass: 'dialogs-lg', data : { data : row, attributes : this.attributes, relation : this.relations.filter((x:any)=> row.id == x.sucursal_id) }}).afterClosed().subscribe(
      resp => {        
          
      }
    );
  }

  openDialogAttributeEditListAsync(row : any) {
    console.log(row);
    this.main.ShowLoading();
    this.storeS.getStoresAttr(row.id).subscribe((x:any)=>{
      this.main.HideLoading();
      let sucursalFL = [row];

      const relacionMap = new Map();
      for (const relacion of x.data) {
          const { sucursal_id, atributo_id, value } = relacion;
          if (!relacionMap.has(sucursal_id)) {
              relacionMap.set(sucursal_id, new Map());
          }
          relacionMap.get(sucursal_id).set(atributo_id, value);
      }
   
      const returnData = sucursalFL.map((sucursal) => {
          const atributosRelacionados : any = {};
          for (const atributo  of  this.attributes) {
              const relacion = relacionMap.get(sucursal.id)?.get(atributo['id']);
              atributosRelacionados[atributo['name']] = relacion || '';
          }
          return { ...sucursal, ...atributosRelacionados };
      });

      this.matDialog.open(StoresAttributesEditComponent, {panelClass: 'dialogs-lg', data : { data : returnData[0], attributes : this.attributes, relation : x.data }}).afterClosed().subscribe(
        resp => {        
          this.getStoresSlim()
        }
      );

     });

  }

  openDialogAttributeFullList() {
    this.main.ShowLoading();

    this.storeS.getStoresView().subscribe(
      (result : any) => {
        
      this.main.HideLoading();
        this.storeS.attributes = result.data.atributos;
        this.storeS.viewData = result.data.viewData;
        this.storeS.allAtributes = result.data.allAtributes;
        this.storeS.relations = result.data.relations;

        this.attributes =   this.storeS.attributes;
        this.viewData =     this.storeS.viewData;
        this.allAtributes = this.storeS.allAtributes;
       
        this.displayedCols = this.displayedColumns;
        

        this.matDialog.open(StoresAttributesModalComponent, {panelClass: 'dialogs-lg', data : { }}).afterClosed().subscribe(
          resp => {        
          }
        );

        console.log(result.data);
      },
      (err : any) => {
        this.main.HideLoading();
        console.log(err);
        this.isLoading = false;
      }
    );



  }


  openNewStore() {
    this.matDialog.open(StoresAddComponent, {panelClass: 'dialogs-lg', data : { }}).afterClosed().subscribe(
      resp => { 
        this.getStoresSlim();       
      }
    );
  }

  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.storeS.hasPermissions(key, buho, client);
  }

  deleteSucursal(id:number){
    Swal.fire({
      title: '¿Estás seguro que deseas dar de baja la sucursal?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.storeS.deleteSucursal(id).subscribe(
          (x: any) => {
            this.main.HideLoading();
            Swal.fire({
              icon: 'success',
              title: 'Operación exitosa',
            });
            
          },
          (err:any) => {
            this.main.HideLoading();
            Swal.fire({
              icon: 'error',
              title: 'Error al dar de baja',
              text: 'Ocurrió un error al modificar el registro, por favor vuelva a intentarlo.'
            });
          }
        );   
      }
    });
  }

}

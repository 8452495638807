<div class="modal-header">
    <h4 class="modal-title" id="mondalAddCampaignTitle">Chat y registros: <strong>{{ survcamp.name }}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content class="chat-content">
    <div class="modal-body">
        <section class="chat w-100">
            <div class="messages-chat p-2">
                <ng-template ngFor let-log [ngForOf]="regLogs">
                    <div *ngIf="log.type == 'reg'" class="message">
                        <p class="text system bg-purple text-white">
                            <mat-icon class="align-middle" matTooltip="Mensaje de sistema" [matTooltipPosition]="'above'">info</mat-icon> {{log.content}} <span class="user-name">Por: {{log.name}} - <span class="badge bg-warning">{{ getUserCompanyOrRol(log) }}</span></span><small>{{log.created_at | date : "dd/MM/yyyy hh:mm a": "-0000"}}</small></p>
                    </div>
                    <div *ngIf="log.type == 'msg' && log.id_rol == 3" class="message">
                        <div class="photo" [ngStyle]="{'background-image': getLogoCompany(log.imagen) }"></div>
                        <p class="text">{{log.content}}<small>{{log.created_at | date : "dd/MM/yyyy hh:mm a": "-0000"}}</small>
                            <mat-icon *ngIf="log.dif_rol == 1 && log.seen == 0" class="ms-2 text-danger align-middle maticon-sm">fiber_manual_record</mat-icon>
                        </p>
                    </div>
                    <div *ngIf="log.type == 'msg' && (log.id_rol == 2 || log.id_rol == 1)" class="message">
                        <div class="response">
                            <p class="text">{{log.content}}<small>{{log.created_at | date : "dd/MM/yyyy hh:mm a": "-0000"}}</small>
                                <mat-icon *ngIf="log.dif_rol == 1 && log.seen == 0" class="ms-2 text-danger align-middle maticon-sm">fiber_manual_record</mat-icon>
                            </p>
                        </div>
                        <div class="photo buho"></div>
                    </div>
                    <!--IMGs-->
                    <div *ngIf="log.type == 'img' && log.id_rol == 3" class="message">
                        <div class="photo" [ngStyle]="{'background-image': getLogoCompany(log.imagen) }"></div>
                        <div class="text" (click)="openDialogImageViewer(log.content, log.nombre)"><img class="center img-view" [src]="getIMG(log.content)" alt="imagen subida"/><small>{{log.created_at | date : "dd/MM/yyyy hh:mm a": "-0000"}}</small>
                            <mat-icon *ngIf="log.dif_rol == 1 && log.seen == 0" class="ms-2 text-danger align-middle maticon-sm">fiber_manual_record</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="log.type == 'img' && (log.id_rol == 2 || log.id_rol == 1)" class="message">
                        <div class="response">
                            <div class="text" (click)="openDialogImageViewer(log.content, log.nombre)"><img class="center img-view" [src]="getIMG(log.content)" alt="imagen subida"/><small>{{log.created_at | date : "dd/MM/yyyy hh:mm a": "-0000"}}</small>
                                <mat-icon *ngIf="log.dif_rol == 1 && log.seen == 0" class="ms-2 text-danger align-middle maticon-sm">fiber_manual_record</mat-icon>
                            </div>
                        </div>
                        <div class="photo buho"></div>
                    </div>
                </ng-template>
            </div>
        </section>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="row w-100">
        <div class="col-sm-9">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Escribe tu mensaje</mat-label>
                <input matInput [(ngModel)]="message">
            </mat-form-field>
        </div>
        <div class="col-sm-1">
            <button mat-flat-button class="mt-3 w-100" color="primary" (click)="openDialogUploadFile()"> <mat-icon>attach_file</mat-icon></button>
        </div>
        <div class="col-sm-2">
            <button mat-flat-button class="mt-3 w-100" color="primary" (click)="saveNewMessage()">Enviar <mat-icon>send</mat-icon></button>
        </div>
    </div>
</mat-dialog-actions>
import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls : ["./loading.component.css"]
})
export class LoadingComponent {
   loading:boolean=true;
   
   public loading2: boolean = true;
   private getLoadingSubscription!: Subscription;

  constructor(
    private _mainService: MainService
  ) {}
  
  ngOnInit() {
    this.getLoadingSubscription = this._mainService.getLoadingStatus()
      .subscribe((loadingS: boolean) => this.loading2 = loadingS);
  }

  ngOnDestroy() {
    this.getLoadingSubscription.unsubscribe();
  }
    

  public ToogleLoading(){
    this.loading = !this.loading;
  }

  public ShowLoading(){
    this.loading = true;
  }

  public HideLoading(){
    this.loading = false;
  }

}

<div class="content-wrapped">
  <div class="container-xxl flex-grow-1 main-title-container">
    <h4 class="fw-bold pt-3 text-primary">
      <mat-icon class="align-middle head-icon">perm_media</mat-icon>
    </h4>
    <div class="title-container">
      <small>OCTUBRE 2024 <span class="arrow">></span> POP NACIONAL OCTUBRE 2024 </small>
      <h2>{{currentMedia.name}}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-4">
        <div class="heading-container">
          <h3 class="card-header">
            <mat-icon>shop</mat-icon> Elementos POP
          </h3>
          <div class="button-container">
            <button mat-raised-button class="nest-button" (click)="newPOP()">
              <mat-icon>add_circle</mat-icon><span class="nb-text">Nuevo elemento POP</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="my-3">
            <div class="row">
              <div class="col-md-4 pop-card" *ngFor="let x of poplistActual; let i = index">
                <div class="card p-3 mb-2 mx-1">
                  <div class="ms-2">
                    <div class="row title-row">
                      <h5 class="heading"><mat-icon class="align-middle head-icon">image</mat-icon>{{ x.title }}</h5>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between my-2" (click)="showPOPDetails(i)">
                    <div class="d-flex flex-row align-items-center">
                      <div class="ms-2 c-details">
                        <ul class="list-unstyled mb-0">
                          <li *ngFor="let y of x.info">
                            <strong>{{ y[0] }}:</strong> {{ y[1] }}
                          </li>
                        </ul>
                        <strong *ngIf="x.filters.length!=0">Filtros:</strong>
                        <ul>
                          <li *ngFor="let s of x.filters">
                             <strong>{{ s[0] }}:</strong> {{ s[1] }}  
                          </li>
                        </ul>
                        <strong *ngIf="x.rules.length!=0">Reglas:</strong>
                        <ul *ngFor="let s of x.rules">
                          <li>
                             <strong>Nombre:</strong> {{ s.name }}  
                          </li>
                          <li *ngIf="s.cantidad != null">
                            <strong>Cantidad:</strong> {{ s.cantidad }}  
                          </li>
                          <li *ngFor="let regla of s.reglas">
                            <strong>Atributo:</strong> {{ regla.atributo }} x {{ regla.valor }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <button mat-flat-button color="accent" (click)="goToConfign(x.id)"> Configuración <mat-icon>edit</mat-icon></button>
                    </div>  
                    <!--<div class="col-sm-4">
                      <button mat-flat-button color="primary" (click)="goToVobos()"> VoBos <mat-icon>check</mat-icon></button>
                    </div> -->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="card">
  <div class="card-body">
    <div class="title-container">
      <small>PROYECTO {{ projectID }} <span class="arrow">></span> MEDIO {{ mediaID }}</small>
      <h3>Elementos POP</h3>
    </div>
    <div class="content-container">
      <div class="button-container">
        <button mat-raised-button class="nest-button" (click)="newPOP()">
          <mat-icon>add_circle</mat-icon><span class="nb-text">Nuevo Elemento</span>
        </button>
      </div>
      <div class="my-3">
        <div class="row">
          <div class="col-md-4" *ngFor="let x of poplist">
            <div class="card p-3 mb-2 mx-1">
              <div class="ms-2">
                <div class="row title-row">
                  <div class="col-auto pe-0">
                    <img class="icon" src="../../../../assets/img/pop/test.png">
                  </div>
                  <div class="col p-0 d-flex">
                    <h5 class="heading">{{ x.title }}</h5>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between my-2">
                <div class="d-flex flex-row align-items-center">
                  <div class="ms-2 c-details">
                    <ul class="list-unstyled mb-0">
                      <li *ngFor="let y of x.info">
                        <strong>{{ y[0] }}:</strong> {{ y[1] }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-project-diagramation-vobos-config',
  templateUrl: './project-diagramation-vobos-config.component.html',
  styleUrls: ['./project-diagramation-vobos-config.component.scss']
})
export class ProjectDiagramationVobosConfigComponent implements OnInit {

  voboForm: FormGroup;
  

  constructor(
    public dialogRef: MatDialogRef<ProjectDiagramationVobosConfigComponent>,
    private fb: FormBuilder, private mainService : MainService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Inicializamos el formulario con 4 visto buenos
    this.voboForm = this.fb.group({
      vobo1: this.fb.group({
        responsable: ['', Validators.required],
        fechaLimite: ['10/10/2024', Validators.required]
      }),
      vobo2: this.fb.group({
        responsable: ['', Validators.required],
        fechaLimite: ['10/10/2024', Validators.required]
      }),
      vobo3: this.fb.group({
        responsable: ['', Validators.required],
        fechaLimite: ['10/10/2024', Validators.required]
      }),
      vobo4: this.fb.group({
        responsable: ['', Validators.required],
        fechaLimite: ['10/10/2024', Validators.required]
      })
    });
  }

  guardarVobos() {
    if (this.voboForm.valid) {
      this.dialogRef.close(this.voboForm.value);
    }
  }

  ngOnInit(): void {
    this.mainService.ShowLoading();
    setTimeout(() => {
      this.mainService.HideLoading();
      this.voboForm = this.fb.group({
        vobo1: this.fb.group({
          responsable: ['', Validators.required],
          fechaLimite: ['10/10/2024', Validators.required]
        }),
        vobo2: this.fb.group({
          responsable: ['', Validators.required],
          fechaLimite: ['10/10/2024', Validators.required]
        }),
        vobo3: this.fb.group({
          responsable: ['', Validators.required],
          fechaLimite: ['10/10/2024', Validators.required]
        }),
        vobo4: this.fb.group({
          responsable: ['', Validators.required],
          fechaLimite: ['10/10/2024', Validators.required]
        })
      });
    }, 700);

  }
  

  cerrarModal() {
    this.dialogRef.close();
  }
}

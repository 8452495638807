import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { UsersService } from 'src/app/services/users.service';
import { ProjectWorldModel } from 'src/app/models/project.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-projects-world-add',
  templateUrl: './projects-world-add.component.html',
  styleUrls: ['./projects-world-add.component.scss']
})
export class ProjectsWorldAddComponent implements OnInit {
  newWorld!: ProjectWorldModel;
  id_proj : number = 0;
  isEdit : boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private main: MainService,
    private projectS: ProjectsService,
    private userS: UsersService,
    private dialogRef: MatDialogRef<ProjectsWorldAddComponent>,
  ) { 
    this.newWorld = this.data.world;
    this.id_proj = this.data.id_proj;
    this.isEdit = this.data.edit;
  }

  ngOnInit(): void {
    if(!this.isEdit) {
      this.newWorld = new ProjectWorldModel();
      this.newWorld.name="";
    }
    this.newWorld.id_proj = this.id_proj;
  }

  createProjectWorld(){
    if(this.newWorld.name!= ""){
      this.main.ShowLoading();
      this.projectS.newProjectWorld(this.newWorld).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Creado',
              text: 'Mundo creado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
      console.log(this.newWorld);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  editProjectWorld(){
    if(this.newWorld.name!= ""){
      this.main.ShowLoading();
      this.projectS.editProjectWorld(this.newWorld).subscribe(
        (result:any) => {
          this.main.HideLoading();
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Editado',
              text: 'Mundo editado correctamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err:any) => {
          this.main.HideLoading();
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );
      console.log(this.newWorld);
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Faltan datos',
        text: 'Por favor ingrese los datos requeridos'
      });
    }
  }

  saveProject(){
    if(!this.isEdit){
      this.createProjectWorld();
    }else{
      this.editProjectWorld();
    }
  }

}

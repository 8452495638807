<div class="container-xxl flex-grow-1 container-p-y">
    <div class="alert alert-warning">
        Para ver reflejados los cambios cierra la sesión y vuelve a ingresar al sistema.
    </div>
    <h6>Edita tu perfil</h6>
    <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="ruSure()" class="mt-3" id="profieForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="w-100" class="text-end">
            <button mat-flat-button class="bg-success text-white" type="submit" form="profieForm">
                Guardar <mat-icon>save</mat-icon>
            </button>
        </div>
    </form>
</div>
<div class="content-wrapped">
  <!-- Content -->
  <div class="container-xxl flex-grow-1 container-p-y">
    <h4 class="fw-bold py-3 mb-4 text-primary">
      <span>Seguimiento de tickets de campaña</span>
      <br />
      <!--<h5>
              <mat-icon class="text-info align-middle">filter_list</mat-icon> Filtro
          </h5>-->
      <div class="filterContainer">
        <mat-accordion>
          <mat-expansion-panel [expanded]="expanded_filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="text-info align-middle">filter_list</mat-icon>
                Filtro
              </mat-panel-title>
              <mat-panel-description>
                Filtrado de tickets de campaña
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <form>
                <div class="row">
                  <div *ngIf="companias.length > 1 && isBuhoUser()" class="col-md-3 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Compania</mat-label>
                      <mat-select name="company" [(ngModel)]="selectedcompany"
                        (selectionChange)="loadCampaigns(); loadZones()" required>
                        <mat-option *ngFor="let p of companias" [value]="p.id" value="option">{{ p.name }}</mat-option>
                      </mat-select>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Campaña</mat-label>
                      <mat-select name="camp" [(ngModel)]="selectedCamp" required>
                        <!--<mat-option *ngFor="let camp of campaignsData.claw;"  [value]="camp.id+'-c'" value="option">{{ camp.name }}</mat-option>
                                      <mat-option *ngFor="let camp of campaignsData.nest;"  [value]="camp.id" value="option">{{ camp.name }}</mat-option>-->
                        <mat-option *ngFor="let camp of allCampaigns" [value]="camp.id" value="option">{{ camp.name
                          }}</mat-option>
                      </mat-select>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Zonas</mat-label>
                      <mat-select name="zones" [(ngModel)]="selectedzone">
                        <mat-option [value]="null">-- Ninguna --</mat-option>
                        <mat-option *ngFor="let zone of zones" [value]="zone.id" value="option">{{ zone.nombre
                          }}</mat-option>
                      </mat-select>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Estatus Encuesta</mat-label>
                      <mat-select name="surv" [(ngModel)]="selectedStsSurv">
                        <mat-option [value]="null">-- Ninguno --</mat-option>
                        <mat-option *ngFor="let stsS of statusSurv" [value]="stsS.id"><span [ngClass]="stsS.class">{{
                            stsS.nombre
                            }}</span></mat-option>
                      </mat-select>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-3 col-sm-12">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Estatus Seguimiento</mat-label>
                      <mat-select name="strack" [(ngModel)]="selectedStsTrack">
                        <mat-option [value]="null">-- Ninguno --</mat-option>
                        <mat-option *ngFor="let sts of statusTracking" [value]="sts.id">
                          <span [ngClass]="sts.class">{{
                            sts.nombre
                            }}</span></mat-option>
                      </mat-select>
                      <mat-error>Requerido</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <button (click)="search()" mat-flat-button class="bg-success text-white"
                      style="top: 12px; margin-bottom: 25px" form="userForm" type="submit">
                      Buscar <mat-icon>search</mat-icon>
                    </button>

                  </div>
                </div>
              </form>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </h4>

    <div class="row mb-4 " [hidden]="!(datatracking?.length > 0)">
      <div class="col-md-12">
        <div class="row mb-4">
          <!-- Nueva Tarjeta a la Izquierda -->
          <div class="col-md-6">
            <div class="card" style="height: 100%;">
              <h5 class="card-header text-center">Objetivo general</h5>
              <div class="card-body">

                <div class="row text-center">
                  <div class="col-md-4 d-flex flex-column align-items-center justify-content-center">
                    <h4 class="text-center" style="font-weight: bold">
                      Total de cajas entregadas
                    </h4>
                    <p>{{ metrics?.total_entregados }} Cajas</p>
                  </div>
                  <div class="col-md-4">
                    <h4 class="text-center" style="font-weight: bold">
                      Encuestas contestadas
                    </h4>
                    <p>{{ metrics?.total_encuestas_contestadas }}</p>
                    <p class="metric-desc">Prom. atención: {{ (metrics?.tiempo_respuesta | number:'1.1-1') || '-'}} días</p>
                  </div>
                  <div class="col-md-4">
                    <h4 class="text-center" style="font-weight: bold">
                      Encuestas pendientes
                    </h4>
                    <p>{{ metrics?.total_encuestas_pendientes }}</p>
                  </div>
                </div>
                <div class="row text-center pt-5">
                  <div class="col-md-4 text-center">
                    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                      [labels]="chartOptions.labels" [colors]="chartOptions.colors" class="small-chart"></apx-chart>
                  </div>
                  <div class="col-md-4 d-flex flex-column align-items-center justify-content" style="padding-top: 60px">
                    <h4 class="text-black objetivo-texto" style="font-weight: bold">
                      Objetivo
                    </h4>
                    <p class="objetivo-texto">
                      {{ metrics?.total_cajas ? metrics?.total_cajas : 0 }}
                    </p>
                  </div>
                  <div class="col-md-4">
                    <img src="assets/img/tabla.png" alt="Tabla de datos" class="metric-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        <!-- Tarjetas de métricas -->
          <div class="col-md-6 ">
            <div class="row">
              <div class="col-md-4 pb-2">
                <div class="card metric-card text-center">
                  <div class="badge-dot badge-success"></div>
                  <h6 class="metric-title">Cajas perfectas</h6>
                  <p class="metric-desc w-75 lh-1">
                    Encuestas completadas sin alertas reportadas
                  </p>
                  <h4 class="text-black metric-value">
                    {{ metrics?.total_cajas_perfectas }}
                  </h4>
                  <div class="metric-resp">
                    Resp: {{compania.name}}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card metric-card text-center">
                  <div class="badge-dot badge-warning"></div>
                  <h6 class="metric-title">Alertas Pendientes</h6>
                  <p class="metric-desc w-75 lh-1">
                    Encuestas completadas con alertas pendientes de validar por gerente
                  </p>
                  <h4 class="text-black metric-value">
                    {{ metrics?.total_alertas_pendientes }}
                  </h4>
                  <div class="metric-resp">
                    Resp: {{compania.name}}
                  </div>
                  <div class="metric-time">
                    Prom. atención: {{ (totalReports?.promedio_creacion_tickets | number:'1.1-1') || '-'}} días
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card metric-card text-center">
                  <div class="badge-dot badge-error"></div>
                  <h6 class="metric-title">Pendientes atend.</h6>
                  <p class="metric-desc w-75 lh-1">
                    Encuestas con alertas reportadas pendientes de atender por Búho
                  </p>
                  <h4 class="text-black metric-value">
                    {{ metrics?.total_tickets_pendientes }}
                  </h4>
                  <div class="metric-resp">
                    Resp: Búho
                  </div>
                  <div class="metric-time">
                    Prom. atención: {{ (totalReports?.promedio_atencion_buho | number:'1.1-1') || '-' }} días
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="card metric-card text-center">
                  <div class="badge-dot badge-inverse"></div>
                  <h6 class="metric-title">En revisión</h6>
                  <p class="metric-desc w-75 lh-1">
                    Encuestas con alertas en revisión por Búho
                  </p>
                  <h4 class="text-black metric-value">
                    {{ metrics?.total_tickets_revision }}
                  </h4>
                  <div class="metric-resp">
                    Resp: Búho
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card metric-card text-center">
                  <div class="badge-dot badge-info"></div>
                  <h6 class="metric-title">Resueltos</h6>
                  <p class="metric-desc w-75 lh-1">
                    Encuestas con alertas revisadas por Búho
                  </p>
                  <h4 class="text-black metric-value">
                    {{ metrics?.total_tickets_resueltos }}
                  </h4>
                  <div class="metric-resp">
                    Resp: Búho
                  </div>
                  <div class="metric-time">
                    Prom. resolución: {{ (totalReports?.promedio_resolucion_buho | number:'1.1-1') || '-' }} días
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card metric-card text-center">
                  <div class="badge-dot badge-pink"></div>
                  <h6 class="metric-title">Reenviados</h6>
                  <p class="metric-desc w-75 lh-1">
                    Reenvios de alertas confirmadas con error
                  </p>
                  <h4 class="text-black metric-value">
                    {{ metrics?.total_extra_tickets }}
                  </h4>
                  <div class="metric-resp">
                    Resp: Búho
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" [hidden]="!(datatracking?.length > 0)">
      <!--tipos-->
      <div class="col-md-12">
        <div class="card mb-4">
          <h5 class="card-header text-center">Resumen de materiales reenviados</h5>
          <div class="card-body metrics-height">
            <div class="row">
              <div class="col-md-2 col-6 resume-card-mobile">
                <div class="text-center">
                  <span class="badge badge-info">Faltante</span>
                  <h4 class="mt-2 text-center">{{ totalReports?.total_faltante || 0 }}</h4>
                </div>
              </div>
              <div class="col-md-2 col-6 resume-card-mobile">
                <div class="text-center">
                  <span class="badge badge-info">Kit incompleto</span>
                  <h4 class="mt-2 text-center">{{ totalReports?.total_kit_incompleto || 0 }}</h4>
                </div>
              </div>
              <div class="col-md-2 col-6 resume-card-mobile">
                <div class="text-center">
                  <span class="badge badge-info">Dañado</span>
                  <h4 class="mt-2 text-center">{{ totalReports?.total_material_danado || 0 }}</h4>
                </div>
              </div>
              <div class="col-md-2 col-6 resume-card-mobile">
                <div class="text-center">
                  <span class="badge badge-info">Sol. adicional</span>
                  <h4 class="mt-2 text-center">{{ totalReports?.total_solicitud_adicional || 0 }}</h4>
                </div>
              </div>
              <div class="col-md-2 col-6 resume-card-mobile">
                <div class="text-center">
                  <span class="badge badge-info">Otro</span>
                  <h4 class="mt-2 text-center">{{ totalReports?.total_otro || 0 }}</h4>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="text-center">
                  <span class="badge badge-info">Anulado</span>
                  <h4 class="mt-2 text-center">{{ totalReports?.total_anulacion || 0 }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Card on time strategies -->
    <div class="row" [hidden]="!(datatracking?.length > 0)">
      <div class="col-md-12">
        <div class="card mb-4">
          <mat-tab-group #tabGroup (selectedTabChange)="onTabChange($event)">
            <!-- tab de seguimientos -->
            <mat-tab>
              <ng-template mat-tab-label>
                Seguimiento - {{ campanaName }}
              </ng-template>
              <div class="card-body">
                <div class="overflow-auto w-200">
                  <mat-form-field>
                    <mat-label> <mat-icon>search</mat-icon> Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event,dataSource)" placeholder="Sobre burbuja" #input />
                  </mat-form-field>
                  <button mat-flat-button color="primary" (click)="generateExcelResumen()" class="ms-4 float-end">
                    Descargar <mat-icon>download</mat-icon>
                  </button>
                </div>
                <div class="mat-elevation-z0 table-responsive text-nowrap">
                  <table mat-table [dataSource]="dataSource" matSort class="w-100">
                    <ng-container matColumnDef="zona">
                      <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Zona
                      </th>
                      <td class="col-2" mat-cell *matCellDef="let row">
                        {{ row.zona }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="sucursal">
                      <th class="col-4" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Sucursal
                      </th>
                      <td class="col-4" mat-cell *matCellDef="let row">
                        {{ row.sucursal }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="alertas">
                      <th class="col-1 text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Alertas
                      </th>
                      <td class="col-1" mat-cell *matCellDef="let row">
                        {{ row.alertas }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="st_encuesta">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Estatus encuesta
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <span [ngClass]="getStatusSurvDat(row.st_encuesta).class">{{
                          getStatusSurvDat(row.st_encuesta).nombre }}</span>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="st_seguimiento">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Estatus seguimiento
                      </th>
                      <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.st_encuesta !== 1" [ngClass]="
                            getStatusTrackingDat(row.st_seguimiento).class
                          ">{{
                          getStatusTrackingDat(row.st_seguimiento).nombre
                          }}</span>
                        <span *ngIf="row.st_encuesta == 1" [ngClass]="'badge badge-warning'">Pendiente</span>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Acciones">
                      <th style="padding-right: 10vh" class="col-2 text-end" mat-header-cell *matHeaderCellDef>
                        Acciones
                      </th>
                      <td style="padding-right: 7vh" class="col-2 text-end" mat-cell *matCellDef="let row">
                        <button *ngIf="
                            row.st_seguimiento != 5 &&
                            row.st_encuesta != 1 &&
                            !isClawCamp
                          " mat-icon-button matTooltip="Ver tickets" [matTooltipPosition]="'above'" color="accent"
                          [routerLink]="
                            '/admin/ticket/' +
                            row.id_compania +
                            '/' +
                            row.id_campana +
                            '/' +
                            row.id_sucursal +
                            '/nest'
                          ">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button *ngIf="
                            row.st_seguimiento != 5 &&
                            row.st_encuesta != 1 &&
                            isClawCamp
                          " mat-icon-button matTooltip="Ver tickets" [matTooltipPosition]="'above'" color="accent"
                          [routerLink]="
                            '/admin/ticket/' +
                            row.id_compania +
                            '/' +
                            row.id_campana +
                            '/' +
                            row.id +
                            '/claw'
                          ">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Ir a encuesta" [matTooltipPosition]="'above'" color="accent"
                          (click)="gotoSurvey(row)">
                          <mat-icon>link</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Crear ticket extraordinario" [matTooltipPosition]="'above'"
                          color="accent" (click)="addExtraTicket(row)">
                          <mat-icon>add</mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="6">
                        No existe información actualmente
                      </td>
                    </tr>
                  </table>

                  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </mat-tab>
            <!-- tab de zonas -->
            <mat-tab>
              <ng-template mat-tab-label>
                Seguimiento por zonas
              </ng-template>
              <div class="card-body">
                <div class="overflow-auto w-200">
                  <mat-form-field>
                    <mat-label> <mat-icon>search</mat-icon> Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event,dataSourceZone)" placeholder="Sobre burbuja" #input />
                  </mat-form-field>
                  <button mat-flat-button color="primary" (click)="generateExcelResumenZone()" class="ms-4 float-end">
                    Descargar <mat-icon>download</mat-icon>
                  </button>
                </div>
                <div class="mat-elevation-z0 table-responsive text-nowrap">
                  <table mat-table [dataSource]="dataSourceZone" matSort class="w-100" #sort2="matSort">
                    <ng-container matColumnDef="zona">
                      <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Zona
                      </th>
                      <td class="col-2 table-separator pe-1" mat-cell *matCellDef="let row">
                        {{ row.zona }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_cajas">
                      <th class="col-4" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Total cajas
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_cajas }}
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="total_entregados">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Cajas entregadas
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_entregados}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_encuestas_contestadas">
                      <th class="col-1" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Encuestas contestadas
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_encuestas_contestadas }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_encuestas_pendientes">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Encuestas pendientes
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        <span>
                          {{ row.total_encuestas_pendientes }}
                        </span>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_extra_tickets">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets extraordinarios
                      </th>
                      <td class="col-1 text-center table-separator" mat-cell *matCellDef="let row">
                        {{ row.total_extra_tickets}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_cajas_perfectas">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Cajas perfectas
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_cajas_perfectas}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_alertas_pendientes">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Alertas pendientes
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{row.total_alertas_pendientes}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_tickets_pendientes">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets pendientes de atender
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_tickets_pendientes}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_tickets_revision">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets en revision
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_tickets_revision}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_tickets_resueltos">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets resueltos
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_tickets_resueltos}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_reenviados">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets reenviados
                      </th>
                      <td class="col-1 text-center table-separator" mat-cell *matCellDef="let row">
                        {{ row.total_reenviados || 0 }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_faltante">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Faltante
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_faltante}}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="total_material_danado">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Material dañado
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_material_danado}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_kit_incompleto">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Kit incompleto
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_kit_incompleto}}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="total_solicitud_adicional">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Solicitud adicional
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_solicitud_adicional}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_otro">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Otro
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_otro}}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="total_anulacion">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Anulaciones
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_anulacion}}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsZone"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsZone"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="6">
                        No existe información actualmente
                      </td>
                    </tr>
                  </table>

                  <mat-paginator #paginator2 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </mat-tab>
            <!-- tab de materiales -->
            <mat-tab>
              <ng-template mat-tab-label>
                Seguimiento por material
              </ng-template>
              <div class="card-body">
                <div class="overflow-auto w-200">
                  <mat-form-field>
                    <mat-label> <mat-icon>search</mat-icon> Buscar</mat-label>
                    <input matInput (keyup)="applyFilter($event,dataSourceMaterial)" placeholder="Sobre burbuja" #input />
                  </mat-form-field>
                  <button mat-flat-button color="primary" (click)="generateExcelResumenMaterial()" class="ms-4 float-end"> 
                    Descargar <mat-icon>download</mat-icon>
                  </button>
                </div>
                <div class="mat-elevation-z0 table-responsive text-nowrap">
                  <table mat-table [dataSource]="dataSourceMaterial" class="w-100" matSort #sort3="matSort">
                    <ng-container matColumnDef="material">
                      <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Material
                      </th>
                      <td class="col-2 pe-1 table-separator" mat-cell *matCellDef="let row">
                        {{ row.material }}
                      </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="total_extra_tickets">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets extraordinarios
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_extra_tickets}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_alertas_pendientes">
                      <th class="col-4" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Alertas pendientes
                      </th>
                      <td class="col-4 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_alertas_pendientes }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_tickets_pendientes">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets pendientes de atender
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_tickets_pendientes}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_tickets_revision">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Trckets en revision
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_tickets_revision}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_tickets_resueltos">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets resueltos
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_tickets_resueltos}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_reenviados">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tickets reenviados
                      </th>
                      <td class="col-1 text-center table-separator" mat-cell *matCellDef="let row">
                        {{ row.total_reenviados}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_faltante">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Faltante
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_faltante}}
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="total_material_danado">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Material dañado
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_material_danado}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_kit_incompleto">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Kit incompleto
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_kit_incompleto}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_solicitud_adicional">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Solicitud adicional
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_solicitud_adicional}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_otro">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Tipo de ticket: Otro
                      </th>
                      <td class="col-1 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_otro}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="total_anulacion">
                      <th class="col-2" mat-header-cell *matHeaderCellDef mat-sort-header>
                        Total anulacion
                      </th>
                      <td class="col-2 text-center" mat-cell *matCellDef="let row">
                        {{ row.total_anulacion }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsMaterial"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsMaterial"></tr>

                    <tr class="mat-row" *matNoDataRow>
                      <td class="mat-cell" colspan="6">
                        No existe información actualmente
                      </td>
                    </tr>
                  </table>

                  <mat-paginator #paginator3 [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
    <!-- Card active campaigns strategies-->
  </div>
</div>
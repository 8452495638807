import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CampaignsService } from 'src/app/services/campaigns.service';
import * as JSZip from 'jszip';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
const fs = require('file-saver');

@Component({
  selector: 'app-templates-file-viewer',
  templateUrl: './templates-file-viewer.component.html',
  styleUrls: ['./templates-file-viewer.component.scss']
})
export class TemplatesFileViewertComponent implements OnInit {
  
  
  isLoading = true;
  urls : any;
  hostName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<TemplatesFileViewertComponent>,
  ) { 
    this.urls = this.data.register;
    console.log( this.data )
    this.hostName = window.location.protocol + '//' + window.location.host;
  }

  ngOnInit(): void {
    
  }

  loadData(){
  
  }

  async downloadAll(){
    var promiseValidation = 0; 
    var zip = new JSZip();

    Swal.fire({
      icon: 'info',
      title: 'Descargando archivos...',
      text:'Esto puede tardar unos momentos, favor de no cerrar o recargar la pagina.',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    var proccData = new Promise((resolve, reject) => {
      try{
      console.log("empezando descarga")
      let folder = zip.folder(this.urls.namemat);
      this.urls.links.forEach(async (y:any, i:number)=>{
          await this.main.getFilefromUrl(this.hostName + y.url).then(fileA=>{
            if(fileA){
              folder?.file(y.name, fileA );
              //console.log("file added")
            }
            promiseValidation = promiseValidation + 1; 
            console.log(promiseValidation);
            if (promiseValidation == this.urls.links.length) {
              //console.log("fin validacion");
              resolve("ok");
            }
          }); 
        });
        
      }catch(ex){ 
        console.log('error' + ex)
        promiseValidation = promiseValidation + 1; 
      }
    });

    proccData.then(x=>{
      zip.generateAsync({ type: "blob" })
      .then((content) => {
        let d = new Date();
        console.log("genero archivo para descarga...");
        fs.saveAs(content, this.urls.namemat.replace(' ', '_') + ".zip");
        Swal.fire({
          icon: 'success',
          title: 'Archivos descargados',
          text: 'Se descargará en su navegador en unos momentos'          
        }).then(() => {            
         
        });
      });
     })

  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { StoreService } from 'src/app/services/store.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-stores-create-upload',
  templateUrl: './stores-create-upload.component.html',
  styleUrls: ['./stores-create-upload.component.scss']
})
export class StoresCreateListUploadComponent implements OnInit {

  bodyForm : FormData = new FormData(); 
  form!: FormGroup;
  fileControl = new FormControl([]);  
  

  constructor(
    private fb : FormBuilder,
    private main: MainService,
    private store : StoreService,
    private dialogRef: MatDialogRef<StoresCreateListUploadComponent>,
  ) {
    this.loadForm();
  }

  ngOnInit(): void {
  }

  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }

  onValueChange(files : any)
  { 
  }


  generatePlantillaExcel(){
    this.main.generateExcel([{Nombre : "", Plaza : "", FarmId : ""}], "SucursalesPlantilla_NEST", "sucursales")
  }

  uploaded : boolean = false;

  uploadFile(){
    Swal.fire({
      title: '¿Esta seguro que desea cargar este archivo?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
  .then((willDelete) => {
    if (willDelete.isConfirmed) {
      if(this.form.value.blob != null && this.form.value.blob?.length!=0){
        this.bodyForm.append("blob", this.form.value.blob[0] as File);    
        this.main.ShowLoading();
        this.store.uploadStoresCreateMasiveFile(this.bodyForm).subscribe(
          (result:any) => {
           this.uploaded = true;
           if(result.data.suc_id_dup.length == 0){
            Swal.fire({
              icon: 'success',
              title: 'Exitoso',
              text: 'El archivo fue cargado con éxito'
            });
           }else{
            Swal.fire({
              icon: 'warning',
              title: 'Cargado',
              text: 'El archivo fue cargado, pero algunas sucursales no se guardaron por FarmId duplicado, porfavor revise los IDs'
            });
           }

          this.main.HideLoading();
            this.dialogRef.close()
        
          },
          (err:any) => {
   
            console.log(err);
 
              Swal.fire({
                icon: 'error',
                title: 'Error al cargar el archivo',
                text: 'Ocurrió un error al cargar el archivo.'
              });
            
          }
        );
    }else{
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'El documento no ha sido adjuntado'
        });
      }
    }
   });


  }

}

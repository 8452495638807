import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProjectsAddComponent } from '../../shared/dialogs/projects-add/projects-add.component';
import Swal from 'sweetalert2';
import { ProjectsPromotionsAddComponent } from '../../shared/dialogs/projects-promotions-add/projects-promotions-add.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsCampaignAddComponent } from '../../shared/dialogs/projects-campaign-add/projects-campaign-add.component';
import { CampaignLogsComponent } from '../../shared/dialogs/campaign-logs/campaign-logs.component';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { MainService } from 'src/app/services/main.service';
import { CampaignModel, DashboardCampaigns } from 'src/app/models/campaign.model';
import { ProjectsService } from 'src/app/services/projects.service';
import { ProjectCampListModel, ProjectCampaignModel, ProjectModel, ProjectWorldListModel, ProjectWorldModel } from 'src/app/models/project.model';
import { ProjectsCampaignsLogsComponent } from '../../shared/dialogs/projects-campaigns-logs/projects-campaigns-logs.component';
import { ProjectsWorldAddComponent } from '../../shared/dialogs/projects-world-add/projects-world-add.component';

@Component({
  selector: 'app-projects-view',
  templateUrl: './projects-view.component.html',
  styleUrls: ['./projects-view.component.scss']
})
export class ProjectsViewComponent implements OnInit {
  campaignsData!: ProjectCampListModel;
  years: string[] = [];
  loaded : boolean = false;
  id_proy_camp : number = 0;
  project : ProjectModel = new ProjectModel();
  constructor(
    private matDialog: MatDialog,
    private campaignService: CampaignsService,
    private projectsS: ProjectsService,
    private _params: ActivatedRoute,
    private main: MainService,
    private _router: Router
    
    
  ) {
    this.campaignsData = new ProjectCampListModel();
    this._params.params.subscribe(params => {
      if(params.id){
        this.id_proy_camp = params.id;
        this.loadCampaign();
      }else{
        this._router.navigateByUrl('/admin/dashboard');
      } 
    });
   }


  selectedSucursalCorte: any;
  sucursalesCorte = [
    { nombre: 'Corte Septiembre' },
    { nombre: 'Corte Agosto' }
  ];

  ngOnInit(): void {
  }

  isBuho():boolean{
    return this.campaignService.isBuhoUsr();

   }

  loadCampaign() {
    this.main.ShowLoading();
    this.projectsS.getProjects(this.id_proy_camp).subscribe((x: any) => {
      this.main.HideLoading();
      this.campaignsData = x.data;
      console.log( this.campaignsData);
      this.loaded = true;
    },
    (err:any) => {
      console.log(err);
          this.main.HideLoading();
    })
  }

  openEditProjectDialog() {
    this.matDialog.open(ProjectsAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : true,
        data: this.campaignsData.project
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  deleteProjectConfirm() {
    Swal.fire({
      title: '¿Está seguro que desea eliminar este Proyecto?. Esto no se puede deshacer.',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.main.ShowLoading();
          this.projectsS.deleteProject(this.id_proy_camp).subscribe(
            (x: any) => {
              this.main.HideLoading();
              this.goToProjectList();
            },
            (err:any) => {
              this.main.HideLoading();
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
              });
            }
          );    
        }
      });
  }


  openUploadPromo(){
    this.matDialog.open(ProjectsPromotionsAddComponent, {
      panelClass: ['dialogs-md'],
      data: {
        project: this.campaignsData.project
      }
    }).afterClosed().subscribe(
      resp => {
      }
    );
  }

  goToCampaign(id: number) {
    this._router.navigateByUrl('/admin/projects/config/' + id)
  }

  goToProjectList() {
    this._router.navigateByUrl('/admin/projects')
  }

  openCampaignLogs(camp: any) {
    this.matDialog.open(ProjectsCampaignsLogsComponent, {
      panelClass: ['dialogs-md'],
      data: {
        campaign: camp
      }
    }).afterClosed().subscribe(
      resp => {
        if (resp.reload) this.loadCampaign();
      }
    );
  }

  openEditCampaignDialog(camp: any) {
    this.matDialog.open(ProjectsCampaignAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : true,
        data: camp
      }
    }).afterClosed().subscribe(
      resp => {
        if (resp.reload) this.loadCampaign();
      }
    );
  }

  deleteCampConfirm(campObj: ProjectCampaignModel) {
    Swal.fire({
      title: '¿Está seguro que desea eliminar esta Campaña?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          if (willDelete.isConfirmed) {
            this.main.ShowLoading();
            this.projectsS.deleteProject(campObj.id).subscribe(
              (x: any) => {
                this.main.HideLoading();
                this.loadCampaign();
              },
              (err:any) => {
                this.main.HideLoading();
                Swal.fire({
                  icon: 'error',
                  title: 'Error al eliminar',
                  text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
                });
              }
            );    
          }
        } 
      });
  }

  openAddProjectDialog() {
    this.matDialog.open(ProjectsCampaignAddComponent, {
      panelClass: ['dialogs-sm'],
      data: {
        edit : false,
        id_proy_camp: this.id_proy_camp,
        //id_world: id_world
      }
    }).afterClosed().subscribe(
      resp => {
        if (resp.reload) this.loadCampaign();
      }
    );
  }

  openProjectMedia(id: number): void {
    this._router.navigateByUrl('/admin/projects/project/' + id + '/media');
  }

  openProjectDiagramation(id: number){
    this._router.navigateByUrl('/admin/projects/project/diagramation/' + id );
  }


  hasPermissions(key : string, buho : boolean|any, client : boolean|any):  boolean {
    return this.campaignService.hasPermissions(key, buho, client);
  }

}

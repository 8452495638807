import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-groups-edit',
  templateUrl: './groups-edit.component.html',
  styleUrls: ['./groups-edit.component.scss'],
  encapsulation : ViewEncapsulation.None,

})
export class GroupsEditComponent implements OnInit {

  groups = [
    {
      id:1,
      name: 'Sucursal Plus + 3 Cajas',
    },
    {
      id:2,
      name: 'Sucursal Plus + 4 Cajas',
    },
    {
      id:3,
      name: 'Sucursal Plus + 5 Cajas',
    },
    {
      id:4,
      name: 'Sucursal Plus + 6 Cajas',
    }
  ]

  attributes = [
      {
        "id": 29,
        "name": "Estatus",
        "type_id": 3,
        "select_data": "[\"ABIERTA\",\"PROXIMA APERTURA\",\"CERRADA\"]",
        "default_value": "",
        "created_at": "2024-01-15T12:28:43.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 1,
        "show": 0
      },
      {
        "id": 72,
        "name": "Ciudad",
        "type_id": 5,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-22T15:14:10.000Z",
        "deleted": 0,
        "active_since": "2024-01-21T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 2,
        "show": 0
      },
      {
        "id": 32,
        "name": "#Cajas",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T12:31:48.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 3,
        "show": 0
      },
      {
        "id": 33,
        "name": "Póster de Caja",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T12:32:43.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 4,
        "show": 0
      },
      {
        "id": 30,
        "name": "Formato Actual",
        "type_id": 3,
        "select_data": "[\"TRADICIONAL\",\"PLUS\"]",
        "default_value": "",
        "created_at": "2024-01-15T12:29:43.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 5,
        "show": 0
      },
      {
        "id": 31,
        "name": "Espacio para exhibidores",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T12:30:56.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 6,
        "show": 0
      },
      {
        "id": 34,
        "name": "Preciador de Caja",
        "type_id": 4,
        "select_data": "0-1",
        "default_value": "",
        "created_at": "2024-01-15T12:35:17.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 0,
        "autorization": 0,
        "order": 7,
        "show": 0
      },
      {
        "id": 35,
        "name": "Botadero Impulsivo",
        "type_id": 4,
        "select_data": "0-1",
        "default_value": "",
        "created_at": "2024-01-15T12:37:15.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 8,
        "show": 0
      },
      {
        "id": 36,
        "name": "Botadero Emotivo",
        "type_id": 4,
        "select_data": "0-1",
        "default_value": "",
        "created_at": "2024-01-15T12:38:31.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 9,
        "show": 0
      },
      {
        "id": 37,
        "name": "Mesa Promocional",
        "type_id": 4,
        "select_data": "0-2",
        "default_value": "",
        "created_at": "2024-01-15T12:44:27.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 10,
        "show": 0
      },
      {
        "id": 38,
        "name": "Copete Mesa Promocional",
        "type_id": 3,
        "select_data": "[\"COUCHE\",\"TROVICEL(RIGIDO)\"]",
        "default_value": "",
        "created_at": "2024-01-15T12:45:20.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 11,
        "show": 0
      },
      {
        "id": 40,
        "name": "8MTS X 60CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:03:48.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 12,
        "show": 0
      },
      {
        "id": 41,
        "name": "8MTS X 50CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:04:38.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 13,
        "show": 0
      },
      {
        "id": 42,
        "name": "8MTS X 40CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:05:11.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 14,
        "show": 0
      },
      {
        "id": 43,
        "name": "6MTS X 60CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:05:33.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 15,
        "show": 0
      },
      {
        "id": 44,
        "name": "6MTS X 50CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:05:52.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 16,
        "show": 0
      },
      {
        "id": 45,
        "name": "6MTS X 40CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:06:13.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 17,
        "show": 0
      },
      {
        "id": 46,
        "name": "4MTS X 60CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:06:48.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 18,
        "show": 0
      },
      {
        "id": 47,
        "name": "4MTS X 50CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:07:55.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 19,
        "show": 0
      },
      {
        "id": 49,
        "name": "4MTS X 40CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:08:45.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 20,
        "show": 0
      },
      {
        "id": 50,
        "name": "8MTS X 80CMS (Banner Tradicional)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:09:12.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 21,
        "show": 0
      },
      {
        "id": 51,
        "name": "3MTS X 60CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:17:52.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 22,
        "show": 0
      },
      {
        "id": 52,
        "name": "4MTS x 40CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:18:17.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 23,
        "show": 0
      },
      {
        "id": 53,
        "name": "4MTS X 50CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:19:45.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 24,
        "show": 0
      },
      {
        "id": 54,
        "name": "4MTS X 60CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:20:21.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 25,
        "show": 0
      },
      {
        "id": 55,
        "name": "4MTS X 75CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:20:50.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 26,
        "show": 0
      },
      {
        "id": 56,
        "name": "6MTS X 40CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:21:16.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 27,
        "show": 0
      },
      {
        "id": 57,
        "name": "6MTS X 50CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:21:30.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 28,
        "show": 0
      },
      {
        "id": 58,
        "name": "6MTS X 60CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:22:00.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 29,
        "show": 0
      },
      {
        "id": 59,
        "name": "7MTS X 60CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:22:23.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 30,
        "show": 0
      },
      {
        "id": 60,
        "name": "8MTS X 40CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:22:41.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 31,
        "show": 0
      },
      {
        "id": 61,
        "name": "8MTS X 50CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:22:57.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 32,
        "show": 0
      },
      {
        "id": 62,
        "name": "8MTS X 60CMS (Banner Emotivo)",
        "type_id": 6,
        "select_data": "",
        "default_value": "",
        "created_at": "2024-01-15T13:23:10.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 33,
        "show": 0
      },
      {
        "id": 63,
        "name": "Cantidad Total Banners",
        "type_id": 4,
        "select_data": "0-4",
        "default_value": "",
        "created_at": "2024-01-15T13:24:22.000Z",
        "deleted": 0,
        "active_since": "2024-01-14T18:00:00.000Z",
        "id_compania": 1,
        "active": 1,
        "editable": 1,
        "autorization": 0,
        "order": 34,
        "show": 0
      }
    ];
  
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: {name: string, isDisabled: boolean}) { }

  ngOnInit(): void {
  }
}

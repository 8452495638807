import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Injectable  } from '@angular/core'
import { MainService } from '../services/main.service';
import { Router } from '@angular/router';
import { catchError, Observable, of} from 'rxjs';
import { UsersService } from '../services/users.service';
import { UserModel } from '../models/user.model';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardResolver implements Resolve<any>{
    constructor(
        private _main: MainService, 
        private _router : Router,
        private userS: UsersService,
        private auth: AuthService
    ) {

    }

    resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
        var hasDist = false;
        var UserObj: UserModel = this.userS.getUser();
        
        if(UserObj.id_rol == 1 || UserObj.id_rol == 2){
            return this.LetPassDash();
        }else{
            UserObj.modules.forEach((module:any) => {
                if(module.clave == 'dist') hasDist = true;
            });
            if(hasDist){
                return this.LetPassDash();
             }else{
                //si es cliente (idrol = 3) reviso si tiene modulos, sino lo mando al login TODO: Poner un mensaje de error que no tiene modulos
                if(UserObj.modules.length > 0){
                    //si no tiene distribución se va al primer modulo en lista
              
                    switch(UserObj.modules[0].clave){
                        case 'encu' :
                            return this.RedirectTicket();
                            break;
                        case 'proy' :
                            return this.RedirectProyects();
                            break;
                        case 'prov' :
                            return this.RedirectProviders();
                            break;
                        case 'dash-seg' :
                            return this.RedirectDashSeg();
                            break;
                        default:
                            return this.checkIfMember(UserObj);
                            //return this.LogOut();
                            break;
                    }
                  
                }else{
                    return this.checkIfMember(UserObj);
                }
       
                
             }
        }


    }


    checkIfMember(user : UserModel){
        return new Promise((resolve, reject) => {
        if(user.id_provider_member!= null){   
          this._router.navigateByUrl('provider-members/providers/projects');
          resolve(false);
            
        }else{
            this.LogOut();
            resolve(false);
        }
       }); 
    }


    LogOut(): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this.auth.logout();
            resolve(false);
        });       
    }


    LetPassDash(): Promise<boolean>{
        return new Promise((resolve, reject) => {
            resolve(false);
        });       
    }

    RedirectTicket(): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this._router.navigateByUrl('/admin/tickets');
            resolve(false);
        });       
    }

    RedirectProyects(): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this._router.navigateByUrl('/admin/projects');
            resolve(false);
        });       
    }


    RedirectProviders(): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this._router.navigateByUrl('/admin/providers');
            resolve(false);
        });       
    }

    RedirectDashSeg(): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this._router.navigateByUrl('/admin/dashboard-tracking');
            resolve(false);
        });       
    }

    GetUsrModules(): Promise<any>{
        return new Promise((resolve, reject) => {
            var UserObj: UserModel = this.userS.getUser();
            resolve(UserObj.modules);
        });       
    }

    //Test Function
    FakeUserGrants(): Promise<any>{
        return new Promise((resolve, reject) => {
            resolve({
                'modules': ['distribucion','encuestas'],
            });
        });       
    }
}
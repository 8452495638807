import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistributionArtFileComponent } from '../distribution-art-file/distribution-art-file.component';
import { MainService } from 'src/app/services/main.service';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { DistributionArtProductComponent } from '../distribution-art-product/distribution-art-product.component';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-distribution-art',
  templateUrl: './distribution-art.component.html',
  styleUrls: ['./distribution-art.component.scss']
})
export class DistributionArtComponent implements OnInit {

  strategy: any;
  artFiles: any;
  isLoading = true;
  canUploadArt: boolean = false;
  skus: { idStrategySku: number, idSku: number, sku: string, nombre: string, numFiles: number, medida_x: number, medida_y: number, ultimo_uso: string }[] = [];
  contFiles: number = 0;
  mostrarSeleccionSku = true;
  artes: { nombre_archivo: string, archivo: string, sku: string, nombre: string, bitValido: number, blob: string, file_respuesta: string, file_respuesta_report: string, nombre_file: string, nombre_file_report: string, fileUrl: SafeResourceUrl, fileUrlReport: SafeResourceUrl, fileUrlArte: SafeResourceUrl  }[] = [];

  public sanitizedSource!: SafeResourceUrl;
  public sanitizedSourceReport!: SafeResourceUrl;
  public sanitizedSourceArte!: SafeResourceUrl;


  archivos: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private campaignService : CampaignsService,
    private main : MainService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<DistributionArtComponent>,
    public sanitizer: DomSanitizer,
  ) {
    this.strategy = this.data.strategy;
    this.loadData();
    this.canUploadArt = !this.data.islocked && this.campaignService.hasPermissions("upl_art",false,true);
   }

  ngOnInit(): void {
    this.sanitizedSourceArte = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
    this.sanitizedSourceReport = this.sanitizer.bypassSecurityTrustResourceUrl(this.archivos[0]?.file);
  }

  loadData(){
    this.campaignService.getStrategySkus(this.strategy.id).subscribe(
      (result:any) => {
        this.skus = result.data;

        //Para saber si hay archivos subidos y mostrar el boton seleccionar
        if(this.skus.length > 0){
          this.skus.forEach( item => {
            if(item.numFiles > 0){
              this.contFiles++;
            }
          })
        }
      },
      (err:any) => {
        if(err.error.message != "No data were found in the request")
        {
          Swal.fire({
            icon: 'error',
            title: 'Error al obtener registros',
            text: 'Ocurrió un error al obtener los registros.'
          });
        }
      }
    );
  }

  openDialogArtUploadFile(sku: string, idSku: number, medidaX: number, medidaY: number) {
    this.matDialog.open(DistributionArtFileComponent, {
      panelClass: ['dialogs-md'],
      data: {
        strategy : this.data.strategy,
        sku: sku,
        idSku: idSku,
        medida_x: medidaX,
        medida_y: medidaY,
        toggleEnfocus: 0
      }
    }).afterClosed().subscribe(
      resp => {
        /* if(resp?.reload) {
          this.dialogRef.close({ reload: true });
        } */
        //this.loadData();
      }
    );
  }


  openDialogArtNewProduct() {
    console.log(this.data.strategy)
    this.matDialog.open(DistributionArtProductComponent, {
      panelClass: ['dialogs-md'],
      data: {
        strategy : this.data.strategy,
      }
    }).afterClosed().subscribe(
      resp => {
        this.loadData();
        this.contFiles++;
      }
    );
  }


  deleteStrategySku(id: number){

    Swal.fire({
        title: "¿Seguro que quieres eliminarlo?",
        text: "No podrás revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
          if (result.isConfirmed) {
            this.campaignService.deleteStrategySkus(id).subscribe(
              (result:any) => {
                Swal.fire("Eliminado", "", "success");
                this.loadData();
              },
              (err:any) => {
                if(err.error.message != "No data were found in the request")
                {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error al eliminar el producto',
                    text: 'Ocurrió un error al eliminar el registro.'
                  });
                }
              }
            );
          }
      });

  }

  switchDiv() {

    this.mostrarSeleccionSku = !this.mostrarSeleccionSku;

    //Carga la parte de artes
    if(!this.mostrarSeleccionSku){

      this.campaignService.getArtes(this.strategy.id).subscribe(
        (result:any) => {
          
          this.artes = result.data;

          this.artes.forEach((item) => {

            item.fileUrlArte = this.sanitizer.bypassSecurityTrustResourceUrl(item.archivo);
            item.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.file_respuesta);
            item.fileUrlReport = this.sanitizer.bypassSecurityTrustResourceUrl(item.file_respuesta_report);

          })
        
        },
        (err:any) => {
            Swal.fire({
              icon: 'error',
              title: 'Error al obtener registros',
              text: 'Ocurrió un error al obtener los registros.'
            });
        }
      );

    }
  }

  getSourceURL(fileUrl: SafeResourceUrl): SafeResourceUrl {
    return fileUrl;
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/services/main.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stores-attributes-values-list-setall',
  templateUrl: './stores-attributes-values-list-setall.component.html',
  styleUrls: ['./stores-attributes-values-list-setall.component.scss']
})
export class StoresAttributesValuesListSetallComponent implements OnInit {
  dataInc : any;
  types = [{ type_id : 1, name : "SI/NO"}, { type_id : 2, name : "FECHA"}, { type_id : 3, name : "OPCIONES"}, { type_id : 6, name : "NÚMERO"}, { type_id : 4, name : "RANGO"}, { type_id : 5, name : "TEXTO"}];
  startDate : Date = new Date();
  sucursal_id : number =0;
  nombre : string = ""
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public main : MainService,
    public storeService : StoreService,
    public dialogRef: MatDialogRef<StoresAttributesValuesListSetallComponent>
  ) { 
    this.dataInc = this.data.atributo;
    this.sucursal_id = this.data.sucursal_id;
    this.nombre = this.data.nombre;
  }

  ngOnInit(): void {
    console.log(this.dataInc);
  }

  converToObject(obj: string){
    try{
      return JSON.parse(obj);
    }catch(ex){
      return [];
    }
  }

  getTypes(){
    return environment.att_types;
  }


  save(value:any){
    if(this.validateMinMax(value)){
    Swal.fire({
      title: '¿Esta seguro del dato ingresado?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          title: 'Guardando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        if(this.data.setAll){
          this.storeService.setMasiveValue({ atributo_id : this.dataInc.id, value : value }).subscribe(
            (result:any) => {
              if(result.message == "Success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Guardado',
                  text: 'Datos guardados correctamente'          
                }).then(() => {            
                  this.dialogRef.close();
                });
              }
            },
            (err:any) => {
  
                console.log(err);
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
                });
              }
            
          );
        }else{
          let tempDATE = new Date();
          let newSTDATE = ""
          newSTDATE = tempDATE.toISOString().slice(0, 10);
          console.log(newSTDATE);
          this.storeService.newValue({ atributo_id : this.dataInc.id, sucursal_id : this.sucursal_id, new_value : value , new_value_date : newSTDATE}).subscribe(
            (result:any) => {
              if(result.message == "Success") {
                Swal.fire({
                  icon: 'success',
                  title: 'Guardado',
                  text: 'Datos guardados correctamente'          
                }).then(() => {            
                 
                });
              }
            },
            (err:any) => {
  
                console.log(err);
                Swal.fire({
                  icon: 'error',
                  title: 'Error al guardar',
                  text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
                });
              }
            
          );
        }

      }
    });
  }
  }

  getRanges(rangeSelect : string){
    return rangeSelect.split("-");
  }

  validateMinMax(value : string){
    if(this.dataInc.type_id == 4){
     let minmax = this.getRanges(this.dataInc.select_data);
     if(parseInt(value) < parseInt(minmax[0]) || parseInt(value)> parseInt(minmax[1])){
        Swal.fire({
          icon: 'warning',
          title: 'El valor se sale del rango establecido',
          text: 'Ingrese un valor de ' + minmax[0] + ' a ' + minmax[1]
        });
        return false;
    }else{
      return true;
    }
     }else{
      return true;
     }
  }
}
